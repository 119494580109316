import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataEditorComponent } from "./data-editor/data-editor.component";
import { SchemaWizardModule, ArrayItemUiSchemaService } from "@rezonence/ng-schema-wizard";
import { RouterModule } from "@angular/router";
import { RouteParam } from "./route.param";
import { UiSchemaPipe } from "./ui-schema/ui.schema.pipe";
import { MatSelectModule } from "@angular/material/select";
import { FlexLayoutModule } from "@angular/flex-layout";
import { JsonFormsModule } from "@jsonforms/angular";
import { SchemaSectionPipe } from "./schema/schema-section.pipe";
import { ResourceRendererComponent } from "./resource-renderer/ResourceRendererComponent";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from "@angular/material/card";
import { ResourceModule } from "../resource/ResourceModule";
import { resourceRendererTester } from "./resource-renderer/resource-renderer.tester";
import { MatButtonModule } from "@angular/material/button";
import { CoreModule } from "../core/CoreModule";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatTableModule } from "@angular/material/table";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { ResponseSelectorRendererComponent } from "./response-selector-renderer/response-selector-renderer.component";
import { MatIconModule } from "@angular/material/icon";
import {
  MicrositeSelectionRendererComponent
} from "./microsite-selection-renderer/MicrositeSelectionRendererComponent";
import { micrositeSelectionRendererTester } from "./microsite-selection-renderer/microsite-selection-renderer.tester";
import { responseSelectorRendererTester } from "./response-selector-renderer/response-selector-renderer.tester";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormatLabelPipe } from "./response-selector-renderer/format-label.pipe";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { EditorToolbarComponent } from "./editor-toolbar/EditorToolbarComponent";
import { FormatOptionPipe } from "./response-selector-renderer/format-option.pipe";
import { FreeWallProgressComponent } from "./freewall-progress/freewall-progress.component";
import { MicrositeModule } from "../microsite";
import { FreewallEditorComponent } from "./freewall-editor/FreewallEditorComponent";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MicrositesRendererComponent } from "./microsites-renderer/MicrositesRendererComponent";
import { micrositesRendererTester } from "./microsites-renderer/microsites.renderer.tester";
import { DatasetLabelPipe } from "./dataset-label/dataset-label.pipe";
import { DatasetValuesPipe } from "./dataset-values/DatasetValuesPipe";
import { MatInputModule } from "@angular/material/input";
import { MicrositeRendererComponent } from "./microsite-renderer/microsite-renderer.component";
import { micrositeRendererTester } from "./microsite-renderer/microsite-renderer.tester";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FormsModule } from "@angular/forms";
import { Route } from "@rezonence/freewall-creator-config";
import { PreviewModule } from "../preview";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { DatasetErrorMessagePipe } from "./dataset-error/dataset-error-message.pipe";
import { NotEmptyPipe } from "./dataset-error/not-empty.pipe";
import { responseUiSchema } from "./response/response-ui.schema";
import { answerUiSchema } from "./answer/answer-ui.schema";
import { RecordContainerComponent } from "./record-container";
import { DatasetContainerComponent } from "./dataset-container";
import { questionUiSchema } from "./question/question-ui.schema";
import { CodeEditorComponent } from "./code-editor/code-editor.component";
import { freewallConfigSchema } from "@rezonence/core";
import { ModePipe } from "./code-editor/mode.pipe";
import { IsCodePipe } from "./data-editor/is-code.pipe";
import { PlaceholderPipe } from "./placeholder/placeholder.pipe";
import { CodeEditorModule } from "@rezonence/code-editor";
import { MatChipsModule } from '@angular/material/chips';
import { FreeWallEditorPage } from "./freewall-editor/FreeWallEditorPage";
import { InPlaceFreeWallEditorPage } from "./inplace-freewall-editor/InPlaceFreeWallEditorPage";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AuthGuard } from "../authenticator";
import { JsonSchema, UISchemaElement } from "@jsonforms/core";
import { CssMigrationService } from "./css";
import { loadHookToken, saveHookToken } from "@rezonence/freewall-db-hooks";
import { ValidatorHook } from "./ValidatorHook";
import { postLoadHookToken } from "./freewall-editor";
import { MicrositesLoaderService, MinimumVersionHook } from "./microsites";
import { CustomCodeLoaderService } from "./code-editor";
import { JsonFormService, JsonFormModule } from '@rezonence/ng-json-form'
import { FreeWallSchemaModule } from "../freewall/FreeWallSchemaModule";

@NgModule({
  declarations: [
    DataEditorComponent,
    UiSchemaPipe,
    SchemaSectionPipe,
    ResourceRendererComponent,
    MicrositeSelectionRendererComponent,
    ResponseSelectorRendererComponent,
    ResponseSelectorRendererComponent,
    EditorToolbarComponent,
    FreeWallProgressComponent,
    FormatOptionPipe,
    FormatLabelPipe,
    FreewallEditorComponent,
    FreeWallEditorPage,
    MicrositesRendererComponent,
    DatasetLabelPipe,
    DatasetValuesPipe,
    MicrositeRendererComponent,
    DatasetErrorMessagePipe,
    NotEmptyPipe,
    RecordContainerComponent,
    DatasetContainerComponent,
    CodeEditorComponent,
    ModePipe,
    IsCodePipe,
    PlaceholderPipe,
    InPlaceFreeWallEditorPage
  ],
  imports: [
    JsonFormModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    CodeEditorModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatSelectModule,
    ResourceModule,
    MatTooltipModule,
    MatTabsModule,
    MicrositeModule,
    RouterModule.forChild([
      {
        path: `${Route.Advanced}/:${RouteParam.Id}/:${RouteParam.Dataset}`,
        component: FreeWallEditorPage,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: Route.Edit,
        component: InPlaceFreeWallEditorPage
      }
    ]),
    CommonModule,
    FreeWallSchemaModule,
    SchemaWizardModule,
    JsonFormsModule,
    MatExpansionModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatButtonModule,
    CoreModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatSidenavModule,
    PreviewModule,
    MatButtonToggleModule,
    CodeEditorModule
  ],
  exports: [
    RouterModule,
    DatasetValuesPipe,
    FreewallEditorComponent
  ],
  providers: [
    {
      provide: loadHookToken,
      multi: true,
      useClass: CssMigrationService
    },
    {
      provide: saveHookToken,
      multi: true,
      useClass: CssMigrationService
    },
    {
      provide: saveHookToken,
      multi: true,
      useClass: ValidatorHook
    },
    {
      provide: postLoadHookToken,
      multi: true,
      useClass: MicrositesLoaderService
    },
    {
      provide: postLoadHookToken,
      multi: true,
      useClass: CustomCodeLoaderService
    },
    {
      provide: loadHookToken,
      multi: true,
      useClass: MinimumVersionHook
    }
  ]
})
export class DataEditorModule {
  constructor(
    private jsonFormService: JsonFormService,
    private schemaByPathService: ArrayItemUiSchemaService) {
    this.registerJsonFormRenderers()
    this.registerUiSchemas()
  }

  private registerUiSchemas() {
    this.registerUiSchema(
      freewallConfigSchema.definitions.Question,
      questionUiSchema
    )
    this.registerUiSchema(
      freewallConfigSchema.definitions.Answer,
      answerUiSchema
    )
    this.registerUiSchema(
      freewallConfigSchema.definitions.FreeWallResponse,
      responseUiSchema
    )
  }

  private registerUiSchema(schema: JsonSchema, uiSchema: UISchemaElement) {
    this.schemaByPathService.registerUiSchema((_, scopedSchema) => {
      return JSON.stringify(Object.keys(scopedSchema.properties || {})) ===
        JSON.stringify(Object.keys(schema.properties));
    }, uiSchema);
  }

  private registerJsonFormRenderers() {
    const renderers = [
      { tester: resourceRendererTester, renderer: ResourceRendererComponent },
      {
        tester: micrositeSelectionRendererTester,
        renderer: MicrositeSelectionRendererComponent
      },
      {
        tester: responseSelectorRendererTester,
        renderer: ResponseSelectorRendererComponent
      },
      { tester: micrositesRendererTester, renderer: MicrositesRendererComponent },
      { tester: micrositeRendererTester, renderer: MicrositeRendererComponent }
    ]
    this.jsonFormService.registerRenderer(...renderers);
  }
}
