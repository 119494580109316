import { Component, EventEmitter, Output, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "login",
    styles: [
        `
            .mat-form-field {
                width: 100%;
            }

            .login-button {
                width: 100%;
            }
        `
    ],
    template: `
        <form (ngSubmit)="submit(emailFormControl.value, $event)" [formGroup]="formGroup">
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input type="email" matInput [formControl]="emailFormControl" placeholder="me@example.com" autocomplete="email">
            </mat-form-field>
            <div>
                <button mat-flat-button type="submit" color="primary" [disabled]="!emailFormControl.valid" class="login-button">Continue</button>
            </div>
        </form>
  `
})
export class LoginComponent {

    @Output()
    onSubmit: EventEmitter<string> = new EventEmitter();

    readonly emailFormControl: FormControl;

    readonly formGroup: FormGroup;

    constructor(formBuilder: FormBuilder) {
        this.emailFormControl = new FormControl('', [Validators.required, Validators.email])
        this.formGroup = formBuilder.group({
            email: this.emailFormControl,
        });
    }

    @Input()
    set email(input: string) {
        this.emailFormControl.setValue(input);
    }

    submit(email: string, event: Event): void {
        event.preventDefault();
        this.onSubmit.next(email);
    }
}
