import { filenamePrefixByDestinationPrefix } from './filenamePrefixByDestinationPrefix'
import { R3ZConfig, ResolveConfigRequest } from '../freewall'
import { Optional } from './Optional'
import { downloadJson } from './downloadJson'

export class ConfigDownloader {
  constructor(private jsonDownloader: <T>(url: URL) => Promise<Optional<T>> = url => downloadJson(url)) {
  }
  toDownloadPath(resolveConfigRequest: ResolveConfigRequest): string {
    return `/${resolveConfigRequest.prefix}/${resolveConfigRequest.id}/${filenamePrefixByDestinationPrefix[resolveConfigRequest.prefix]}Conf.json`
  }

  toDownloadUrl(resolveConfigRequest: ResolveConfigRequest): URL {
    const url = new URL(resolveConfigRequest.cdn.toString())
    url.pathname = this.toDownloadPath(resolveConfigRequest)
    return url
  }

  async downloadConfig<T extends R3ZConfig>(request: ResolveConfigRequest): Promise<Optional<T>> {
    const url = this.toDownloadUrl(request);
    return this.jsonDownloader<T>(url);
  }
}
