<div *ngIf="freeWallService.freewalls$ | async as freewalls; else loading" fxLayout="column"
  fxLayoutAlign="start stretch" class="start">
  <ng-container *ngIf="modeResolver.mode$ | async as mode">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="action-bar" fxLayoutGap="20px">
      <button mat-flat-button color="primary" class="add-button" matTooltip="Create a new FreeWall"
        [matMenuTriggerFor]="menu">
        <mat-icon fxHide.gt-xs>
          add
        </mat-icon>
        <span fxHide.xs>
          Create FreeWall
        </span>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <ng-template matMenuContent>
          <button mat-menu-item (click)="freeWallService.createNew(freewalls, CreateFreewallType.Opinion)">
            <mat-icon>ballot</mat-icon>
            <span>Opinion</span>
          </button>
          <button mat-menu-item (click)="freeWallService.createNew(freewalls, CreateFreewallType.Quiz)">
            <mat-icon>how_to_reg</mat-icon>
            <span>Quiz</span>
          </button>
          <ng-container *ngIf="mode === Mode.Advanced">
            <button mat-menu-item (click)="freeWallService.createNew(freewalls, CreateFreewallType.Data)">
              <mat-icon>bar_chart</mat-icon>
              <span>Precision</span>
            </button>
            <button mat-menu-item (click)="freeWallService.createNew(freewalls, CreateFreewallType.Typeform)">
              <mat-icon>assignment</mat-icon>
              <span>Typeform</span>
            </button>
            <button mat-menu-item (click)="freeWallService.createNew(freewalls, CreateFreewallType.Existing)">
              <mat-icon>link</mat-icon>
              <span>Import existing</span>
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>
      <app-search-bar [(query)]="query" *ngIf="freewalls.length">
      </app-search-bar>
    </div>
    <div class="grid-wrapper" #gridWrapper [class.yg-bright]="!freewalls.length" fxLayout="column" fxLayoutAlign="center">
      <ng-container *ngIf="freewalls.length; else landing">
        <app-grid [items]="freewalls | tempFreeWall | filterFreewall: query" [preferredWidth]="380"
          [idMapper]="idMapperService.idMapper" [gutter]="30" [currentItemId]="route.fragment | async"
          (currentItemIdChange)="applyFreeWallIdToFragment($event)">
          <ng-template gridCell let-item="item" let-last="last" let-first="first">
            <slide [freeWall]="item" (onDeleted)="freeWallService.removeFreewall(freewalls, item.recordId)"
              (freeWallCloned)="freeWallService.addClonedFreewall(freewalls, $event)">
            </slide>
          </ng-template>
        </app-grid>
      </ng-container>
      <ng-template #landing>
        <div fxLayoutAlign="center" class="landing-container">
          <landing>
          </landing>
        </div>
      </ng-template>

    </div>
  </ng-container>
</div>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate">
  </mat-progress-bar>
</ng-template>