<ng-container *ngIf="data as microsite">
  <ng-container
    *ngIf="microsite.templateId | micrositeSchema | async as micrositeInfo">
    <ng-container
      *ngIf="micrositeInfo.schema | resolveRefs | async as mSchema">
      <ng-container *ngIf="path | composePath: 'config' as basePath">
        <mat-toolbar-row
          fxLayout="row"
          fxLayoutAlign="end center"
          class="mat-body">
          <mat-slide-toggle
            [(ngModel)]="preview"
            matTooltip="Preview {{ microsite.title }}">
            Preview
          </mat-slide-toggle>
        </mat-toolbar-row>
        <div *ngIf="preview; else config" class="preview-container">
          <ng-container
            *ngIf="context.recordId | datasetValues | async as values">
            <app-microsite-preview
              [recordId]="context.recordId"
              [freeWallConfig]="values.freewall.data"
              [microsite]="microsite">
            </app-microsite-preview>
          </ng-container>
        </div>
        <ng-template #config>
          <ng-container *ngIf="schema[path]">
            <jsonforms-outlet
              [schema]="schema[path].properties.title"
              [path]="path | composePath: 'title'">
            </jsonforms-outlet>
            <jsonforms-outlet
              [schema]="schema[path].properties.url"
              [path]="path | composePath: 'url'"
              *ngIf="microsite.templateId === MicrositeType.existing">
            </jsonforms-outlet>
            <jsonforms-outlet [schema]="mSchema" [path]="basePath">
            </jsonforms-outlet>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
