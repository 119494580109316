import { Component } from "@angular/core";
import { FreeWallEditorContext } from "../freewall-editor"
import { RecordContainerComponent } from "../record-container"

@Component({
  selector: "app-dataset-container",
  templateUrl: "./dataset-container.component.html",
  styleUrls: ["./dataset-container.component.css"]
})
export class DatasetContainerComponent extends RecordContainerComponent {

  constructor(context: FreeWallEditorContext) {
    super(context);
  }
}
