import {LinkMacro, SingleTrackerType} from "@rezonence/sdk";

const base = `https://tracker.yougov.com/campaign/325?name=${LinkMacro.AdId}`;

export const yougovProfilesTrackers: Partial<Record<SingleTrackerType, string[]>> = {
    [SingleTrackerType.Engagement]: [
        `${base}:${LinkMacro.AnswerIndex}&format=img&${LinkMacro.Timestamp}`
    ],
    [SingleTrackerType.Impression]: [
        `${base}&format=img&${LinkMacro.Timestamp}`
    ]
}
