import { Injectable } from "@angular/core";
import {AnyFile, ScannedFile} from "@djabry/fs-s3";
import {ResourceService} from "../resource/ResourceService";
import {FileNameSanitizerService} from "./file-name-sanitizer.service";
import {CreativeFileService} from "../../core/CreativeFileService";
import {AWSService} from "../../core/AWSService";
import {basename, extname} from "path";

@Injectable({
  providedIn: "root"
})
export class UploadService {

  constructor(private resourceService: ResourceService,
              private fileService: CreativeFileService,
              private awsService: AWSService,
              private santizer: FileNameSanitizerService) {
  }

  async toDestination(freeWallId: string, file: File): Promise<AnyFile> {
    const folder = await this.resourceService.toUserStorageFolder(freeWallId);
    const sanitizedFileName = this.santizer.sanitize(file.name);
    const sanitizedKey = `${folder.key}/${sanitizedFileName}`;
    const key = await this.toUniqueKey(folder, sanitizedKey);
    return {
      ...folder,
      key
    };
  }

  async toUniqueKey(folder: AnyFile, key: string): Promise<string> {
    const fileExists = await this.fileService.isFile({...folder, key});
    if (fileExists) {
      const fileName = this.incrementFileNameVersion(key);
      const newKey = `${folder.key}/${fileName}`;
      return this.toUniqueKey(folder, newKey);
    } else {
      return `${folder.key}/${basename(key)}`;
    }
  }

  incrementFileNameVersion(key: string): string {
    const extension = extname(key);
    const baseName = basename(key, extension);
    const fileNameParts = baseName.split("_");
    const currentVersion = Number(fileNameParts.pop()) || 0;
    const name = currentVersion ? fileNameParts.join("_") : baseName;
    return `${name}_${currentVersion + 1}${extension}`;
  }

  async uploadFiles(freeWallId: string, files: File[]): Promise<ScannedFile[]> {
    return Promise.all(files.map(async file => {
      const destination = await this.toDestination(freeWallId, file);
      return this.fileService.uploadFile(file, destination);
    }));
  }
}
