import { DashboardRequestConverter } from "@rezonence/freewall-compiler";
import { DemoType } from "./DemoType";
import { recordFolderByDemoType } from "./recordFolderByDemoType";

export class AdIdBuilder {
  constructor(private requestConverter: DashboardRequestConverter, private params: { identityId: string }) {
  }

  build(request: { recordId: string, demoType: DemoType }): string {
    return this.requestConverter.toAdId({
      ...this.params,
      recordId: request.recordId,
      recordFolder: recordFolderByDemoType[request.demoType]
    });
  }

}
