import {ErrorObject} from "ajv";
import {Category} from "../wizard/category";

export class ErrorsByStep {
  errors: Record<string, ErrorObject[]> = {};

  toKey(category: Category, index: number): string {
    return `${category}_${index}`;
  }

  getErrorsForStep(category: Category, index: number): ErrorObject[] {
    const key = this.toKey(category, index);
    return this.errors[key] || [];
  }

  collectErrors(category: Category, index: number, errors: ErrorObject[]) {
    this.errors[this.toKey(category, index)] = errors;
  }
}
