import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dataset } from '@rezonence/core';
import { ImportFreeWallQueryParams } from "@rezonence/campaigns-config";
import { ImporterQueryParams } from '@rezonence/freewall-creator-config';
import {
  catchError,
  concatMap,
  filter,
  map,
  Observable,
  of
} from 'rxjs';
import { FreewallMetaPair } from '../../core/freewall.meta.pair';
import { InPlaceEditorService } from './InPlaceEditorService';
import { DemoAdIdResolver } from '../../preview';
import { DemoType } from '../../preview/DemoType';

@Component({
  templateUrl: './InPlaceFreeWallEditorPage.html',
  providers: [InPlaceEditorService]
})
export class InPlaceFreeWallEditorPage {
  readonly freeWall$: Observable<FreewallMetaPair> = this.editor.importedFreeWall$;

  readonly error$: Observable<Error> = this.freeWall$.pipe(
    catchError((thrown) => of(thrown)),
    filter((thrown) => thrown instanceof Error)
  );

  readonly adId$ = this.freeWall$.pipe(
    concatMap(({ recordId }) =>
      this.adIdResolver.toAdId({
        demoType: DemoType.Shared,
        recordId,
      })
    )
  );

  dataset: Dataset = Dataset.Freewall;

  constructor(
    private activatedRoute: ActivatedRoute,
    private adIdResolver: DemoAdIdResolver,
    private editor: InPlaceEditorService
  ) { }

  redirectToSource(adId: string) {
    const { redirectTo, sourceId } = this.activatedRoute.snapshot
      .queryParams as ImporterQueryParams;

    const url = new URL(redirectTo);
    url.searchParams.append(ImportFreeWallQueryParams.SourceId, adId);
    url.searchParams.append(ImportFreeWallQueryParams.DestinationId, sourceId);
    location.href = url.toString();
  }
}
