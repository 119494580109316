<ng-container *ngIf="$any(props.label) | singular as itemTitle">
  <app-array-template [(data)]="data"
                      [itemTitle]="itemTitle"
                      [label]="$any(props.label)"
                      [minItems]="scopedSchema.minItems || 0"
                      [itemFactory]="itemFactory">
    <ng-template let-index="index">
      <jsonforms-outlet [schema]="scopedSchema"
                        [uischema]="arrayItemUiSchema"
                        [path]="propsPath | composePath : index.toString()">
      </jsonforms-outlet>
    </ng-template>
  </app-array-template>
</ng-container>
