<ng-container *ngIf="dataset">
  <app-code-editor *ngIf="dataset | isCode; else formEditor"
                   [(data)]="data"
                   [placeholder]="dataset | placeholder | async"
                   [recordId]="recordId"
                   [mode]="dataset | mode"
                   (errors)="errors.emit($event)">
  </app-code-editor>
  <ng-template #formEditor>
    <rez-json-form [(data)]="data"
                   [schema]="schema"
                   [uiSchema]="dataset | uiSchema"
                   (errors)="errors.emit($event)">
    </rez-json-form>
  </ng-template>
</ng-container>
