import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from '@angular/common'
import { JsonFormsModule } from "@jsonforms/angular";
import { JsonFormComponent } from "./JsonFormComponent";
import { JsonFormService } from "./JsonFormService";
import { ResolveRefsPipe } from "./ResolveRefsPipe";
import { JsonFormModuleOptions } from "./JsonFormModuleOptions";
import Ajv from "ajv";

@NgModule({
  imports: [CommonModule, JsonFormsModule],
  declarations: [JsonFormComponent, ResolveRefsPipe],
  exports: [JsonFormComponent, ResolveRefsPipe]
})
export class JsonFormModule {
  static forRoot({ ajv }: JsonFormModuleOptions): ModuleWithProviders<JsonFormModule> {
    return {
      ngModule: JsonFormModule,
      providers: [
        JsonFormService,
        { provide: Ajv, useValue: ajv },
      ]
    }
  }
}
