import './WizardComponent.scss';
import { Component, OnInit, ViewChildren, ViewEncapsulation, QueryList, Input } from "@angular/core";
import { Router } from "@angular/router";
import { FreeWall } from "@rezonence/core/freewall/freewall";
import { CreativeFreeWallDB } from "../../core/CreativeFreeWallDB";
import { Dataset } from "@rezonence/core/freewall/dataset";
import { Meta } from "@rezonence/core/freewall/meta";
import { Answer } from "@rezonence/core/config-extractor";
import { StepDirective } from "../step/step.directive";
import { Category } from "./category";
import { WizardService } from "./wizard.service";
import { Placement, Route } from "@rezonence/freewall-creator-config";
import { ErrorObject } from "ajv";
import { ErrorsByStep } from "../step/errors.by.step";
import { MatTab } from "@angular/material/tabs";
import { ResponseMode } from "../response/response.mode";
import { environment } from "../../../environments/environment";
import { Optional } from "@rezonence/sdk";
import { FreeWallEditorContext } from "../../data-editor"
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-wizard',
  templateUrl: './WizardComponent.html',
  styleUrls: [
    "./WizardComponent.css"
  ]
})
export class WizardComponent implements OnInit {

  @Input()
  set freeWallId(input: string) {
    this.context.recordId = input;
  }

  get freeWallId(): string {
    return this.context.recordId;
  }

  @ViewChildren(MatTab)
  tabs: QueryList<MatTab>;

  @ViewChildren(StepDirective)
  steps: QueryList<StepDirective>;

  readonly defaultDemoLink = environment.defaultDemoLink;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ResponseMode = ResponseMode;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Category = Category;
  selectedIndex: number;

  errorsByStep = new ErrorsByStep();
  lastMetaHash: string;
  lastConfigHash: string;

  constructor(
    private router: Router,
    private freeWallDB: CreativeFreeWallDB,
    public wizardService: WizardService,
    private context: FreeWallEditorContext
  ) { }

  get focus(): string {
    if (this.steps) {
      const step = this.steps.toArray()[this.selectedIndex];
      if (step) {
        return step.focus;
      }
    }
    return null;
  }

  get mode(): string {
    if (this.steps) {
      const step = this.steps.toArray()[this.selectedIndex];
      if (step) {
        return step.mode;
      }
    }

    return null;
  }

  get hint(): boolean {
    if (this.steps) {
      const step = this.steps.toArray()[this.selectedIndex];
      if (step) {
        return step.hint;
      }
    }

    return false;
  }

  get currentStep(): StepDirective {
    return this.steps.toArray()[this.selectedIndex];
  }

  get currentAnswerIndex(): Optional<number> {
    return Optional.truthy(
      this.steps &&
      this.currentStep &&
      typeof this.currentStep.answerIndex === 'number'
    ).map(() => this.currentStep.answerIndex);
  }

  get currentErrors(): ErrorObject[] {
    const currentStep = this.steps.toArray()[this.selectedIndex];
    return currentStep
      ? this.errorsByStep.getErrorsForStep(
        currentStep.category,
        currentStep.indexWithinCategory
      )
      : [];
  }

  get nextTab(): MatTab {
    const nextIndex = this.selectedIndex + 1;
    const tabsArray = this.tabs.toArray();
    return tabsArray[nextIndex];
  }

  get continueMessage(): string {
    const nextTab = this.nextTab;
    if (nextTab) {
      return nextTab.textLabel;
    } else {
      return 'Done';
    }
  }

  get bottomInstructions(): string {
    return 'Please answer the question above to reveal the rest of the article';
  }

  get topInstructions(): string {
    return 'Please answer the question below to reveal the rest of the article';
  }

  get previousMessage(): string {
    if (this.tabs && this.tabs.toArray()[this.selectedIndex - 1]) {
      const tab = this.tabs.toArray()[this.selectedIndex - 1];

      return tab.textLabel;
    } else {
      return 'Previous';
    }
  }

  toCurrentAnswer(config: FreeWall): Optional<Answer> {
    return this.currentAnswerIndex.map(
      (index) => config.questions[0]?.answers[index]
    );
  }

  toResponseIndex(config: FreeWall): number {
    const responseModes = [Placement.response, Placement.kicker] as string[];
    return Optional.of(responseModes.includes(this.mode))
      .map(() => this.toCurrentAnswer(config))
      .map(
        (answer) =>
          this.mode === Placement.response ? answer.rix : answer.kix,
        () => -2
      ).item;
  }

  collectErrors(category: Category, index: number, errors: ErrorObject[]) {
    this.errorsByStep.collectErrors(category, index, errors);
  }

  isEven(input: number): boolean {
    return input % 2 === 0;
  }

  isQuiz(config: FreeWall): boolean {
    return (
      config && !!config.questions[0].answers.find((answer) => !answer.tof)
    );
  }

  correctAnswers(config: FreeWall): Answer[] {
    if (!config || !config.questions[0]) {
      return [];
    }
    return config.questions[0].answers.filter((answer) => answer.tof);
  }

  ngOnInit() {
    this.selectedIndex = 0;
  }

  async back() {
    await this.router.navigate([Route.Home], { queryParamsHandling: "merge" });
  }

  async continueWizard(
    config: FreeWall,
    meta: Meta,
    freeWallId: string
  ): Promise<void> {
    await this.save(config, meta, freeWallId);
    if (this.nextTab) {
      this.selectedIndex++;
    } else {
      await this.back();
    }
  }

  toHash<T>(input: T): string {
    return JSON.stringify(input);
  }

  previous() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  numTabs(): number {
    if (this.tabs) {
      return this.tabs.length;
    }

    return 0;
  }

  async save(config: FreeWall, meta: Meta, freeWallId: string) {
    if (JSON.stringify(meta) !== this.lastMetaHash) {
      await this.freeWallDB.saveDatasetValue(Dataset.Meta, freeWallId, meta);
      this.lastMetaHash = this.toHash(meta);
    }
    const configHash = this.toHash(config);
    if (configHash !== this.lastConfigHash) {
      await this.freeWallDB.saveDatasetValue(
        Dataset.Freewall,
        freeWallId,
        config
      );
      this.lastConfigHash = configHash;
    }
  }
}
