<ng-container *ngIf="(authService.token$ | async) as authToken">
  <mat-toolbar class="top-bar" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container *ngIf="!router.url.startsWith('/' + Route.Callback)">
      <img *ngIf="router.url.startsWith('/' + Route.Home); else homeButton" src="/assets/YG.png" width="120px" />
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <a mat-flat-button color="primary" href="https://business.yougov.com/product/freewall?marketo=contact" target="_bank">
          Contact
        </a>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <ng-container *ngIf=" authService.userProfile$ | async as profile">
          <div class="mat-body">{{profile.email}}</div>
          </ng-container>
          <button mat-icon-button [matMenuTriggerFor]="signOutMenu" class="menu-button" *ngIf="authToken.exists; else signIn">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </mat-toolbar>

  <ng-template #signIn>
    <ng-container *ngIf="!authToken.exists">
      <div fxLayout="row">
        <button mat-stroked-button color="primary" matTooltip="Sign up or sign in" (click)="authService.requestLogin()">
          Sign in
        </button>
      </div>
    </ng-container>
  </ng-template>

  <mat-menu #signOutMenu="matMenu">
    <button mat-menu-item (click)="awsService.logOut()" matTooltip="Sign out of this account">Sign out</button>
  </mat-menu>

  <ng-template #homeButton>
    <button mat-icon-button
            [routerLink]="Route.Home"
            [fragment]="recordId"
            queryParamsHandling="merge" matTooltip="Go to home screen">
      <mat-icon>home</mat-icon></button>
  </ng-template>
</ng-container>

