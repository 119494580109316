import { ArrayUtils, truthyArrayItem } from "@rezonence/array-utils";
import { Dataset, DatasetItem, FreeWall, SaveItemRequest } from "@rezonence/core";
import { FreeWallResponse, FreeWallImage, EncodedIframe } from "@rezonence/sdk";
import { LoadSaveHook } from "@rezonence/freewall-db-hooks";

export class ZeroRemover implements LoadSaveHook {

  nonZeroFields: (keyof FreeWallResponse | keyof FreeWallImage | keyof EncodedIframe)[] = [
    "img", "vid", "bar", "ifs", "src", "lnk"
  ];

  removeNonZeroField(object: FreeWallImage | EncodedIframe | FreeWallResponse) {
    for (const field of this.nonZeroFields) {
      if (object && object[field] === 0) {
        delete object[field];
      }
    }
  }

  async transform(item: DatasetItem<any> | SaveItemRequest<any>): Promise<DatasetItem<any> | SaveItemRequest<any>> {
    if (item && item.datasetName === Dataset.Freewall) {
      const config = item.value as FreeWall;
      if (config) {
        const allResponses = ArrayUtils.flatten([config.responses, config.kickers].filter(truthyArrayItem));
        [...allResponses, config.image, config.iframe].forEach(c => this.removeNonZeroField(c));
      }
    }
    return item;
  }

}
