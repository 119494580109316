import {Injectable} from "@angular/core";
import {CodeDataset, CodeItem,TemplateFileSuffix} from "@rezonence/core";
import {cssExtensionFilename} from "./css.extension.filename";
import {FreewallFilenamePrefix,Optional} from "@rezonence/sdk";
import {CreativeFileService} from "./CreativeFileService";
import {AnyFile} from "@djabry/fs-s3/lib";
import {environment} from "../../environments/environment";
import {AWSService} from "./AWSService";
import {ExtensionWriteRequest} from "./extension.write.request";

@Injectable({
  providedIn: "root"
})
export class CodeExtensionService {

  fileNameByDatasetType: Record<CodeDataset, string> = {
    [CodeDataset.Css]: cssExtensionFilename,
    [CodeDataset.CustomCode]: `${FreewallFilenamePrefix.Ad}${TemplateFileSuffix.Extension}`
  };

  constructor(private fileService: CreativeFileService,
              private awsService: AWSService) {
  }

  async writeExtensionFile(type: CodeDataset, data: CodeItem, recordId: string): Promise<void> {
    const saveRequest = this.toExtensionWriteRequest(type, data);
    const destination = await this.toCodeExtensionFile({recordId, fileName: saveRequest.fileName});
    await this.fileService.write(saveRequest.body, destination);
  }

  toExtensionWriteRequest(type: CodeDataset, data: CodeItem): ExtensionWriteRequest {
    return {
      body: data.code || "",
      fileName: this.fileNameByDatasetType[type]
    };
  }

  async toCodeExtensionFile(request: {recordId: string; fileName: string}): Promise<AnyFile> {
    const identityId = await this.awsService.identityId();
    const prefix = this.fileService.toFreeWallAssetPrefix({identityId, recordId: request.recordId});
    return {
      bucket: environment.userStorageBucket,
      key: `${prefix}/${request.fileName}`
    };
  }

  async readExtensionFile(request: {recordId: string; dataset: CodeDataset}): Promise<string> {
    const extFile = await this.toCodeExtensionFile({
      recordId: request.recordId,
      fileName: this.fileNameByDatasetType[request.dataset]
    });
    const codeOptional = await Optional.switchPromise(Optional.of(await this.fileService.isFile(extFile))
      .map(f => this.fileService.readString(f)));
    return codeOptional.item;
  }

}
