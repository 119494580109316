import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Microsites} from "@rezonence/core";
import {CreativeFreeWallDB} from "../../core/CreativeFreeWallDB";
import {Microsite} from "@rezonence/core";

@Component({
  selector: "app-microsite-selector",
  templateUrl: "./microsite-selector.component.html",
  styleUrls: ["./microsite-selector.component.css"]
})
export class MicrositeSelectorComponent implements OnInit {

  @Input()
  set freeWallId(id: string) {
    this.micrositesPromise = this.db.getMicrosites(id);
  }

  @Output()
  micrositeSelected: EventEmitter<Microsite> = new EventEmitter<Microsite>();

  micrositesPromise: Promise<Microsites>;

  constructor(private db: CreativeFreeWallDB) {
  }

  ngOnInit(): void {
  }

}
