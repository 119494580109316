import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Microsite, FreeWall } from "@rezonence/core";

@Component({
  selector: "app-microsite",
  templateUrl: "./microsite.component.html",
  styleUrls: ["./microsite.component.css"]
})
export class MicrositeComponent implements OnInit {

  @Input()
  set microsite(m: Microsite) {
    if (JSON.stringify(m) !== JSON.stringify(this._microsite)) {
      this._microsite = m;
      this.micrositeChange.emit(m);
    }
  }

  get microsite(): Microsite {
    return this._microsite;
  }

  preview: boolean;

  @Output()
  micrositeChange: EventEmitter<Microsite> = new EventEmitter<Microsite>();

  @Input()
  freeWallConfig: FreeWall;

  @Input()
  recordId: string;

  private _microsite: Microsite;

  constructor() { }

  ngOnInit() {
  }

}
