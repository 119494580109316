import { Component } from "@angular/core";
import { AuthService } from "../authenticator";
import { AWSService } from "../core/AWSService";
import { Router } from "@angular/router";
import { Route } from "@rezonence/freewall-creator-config";

@Component({
  selector: "app-toolbar-component",
  templateUrl: "toolbar.component.html",
  styleUrls: ["./toolbar.component.css"]
})
export class ToolbarComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Route = Route;

  constructor(
    public authService: AuthService,
    public awsService: AWSService,
    public router: Router) { }

  get recordId(): string | undefined {
    const url = this.router.parseUrl(this.router.url);
    return url.root.children?.primary?.segments[1]?.path
  }

}
