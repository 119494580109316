<div *ngIf="config">
  <div *ngIf="answer.tof">
    <div *ngIf="advanced && wizardService.mode.enableAnswerResponseMapping">
      <mat-select [placeholder]="'Select a '+ mode" [(ngModel)]="responseIndex" [ngModelOptions]="{standalone: true}">
        <mat-option [value]="-2">None</mat-option>
        <mat-option [value]="-1">Random</mat-option>
        <mat-option *ngFor="let response of config[mode + 's']; let i = index" [value]="i" >{{mode | titlecase}} {{i + 1}}</mat-option>
      </mat-select>
      &nbsp; Or
      <button mat-button color="primary" (click)="createNew()">Create a new {{mode}}</button>
    </div>

    <div *ngIf="responseIndex >= 0">
        <response [responseIndex]="responseIndex" [config]="config" [advanced]="advanced" [mode]="mode"
          [freeWallId]="freeWallId"></response>
    </div>
  </div>
</div>
