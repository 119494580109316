import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import { FreeWall, Microsite, MicrositeLinkResolver } from "@rezonence/core";
import { fromEvent, Subscription } from "rxjs";
import { micrositeConfigMessagePrefix, messagePrefix, FreeWallData, FreeWallEvent, SizeData, Subject } from "@rezonence/sdk";
import { MicrositeWindowAdapterService } from "../microsite-window-adpater/microsite-window-adapter.service";
import { filter } from "rxjs/operators";
import {ResourceService} from "../../resource/resource/ResourceService";
import {LinkService} from "../link/LinkService";
import { preferredPlacement } from "./preferred.placement";

@Component({
  selector: "app-microsite-preview",
  templateUrl: "./microsite-preview.component.html",
  styleUrls: ["./microsite-preview.component.css"]
})
export class MicrositePreviewComponent implements OnInit, OnDestroy {

  height: number;

  @Input()
  microsite: Microsite;

  @Input()
  recordId: string;

  @Input()
  freeWallConfig: FreeWall;

  renderedMicrositePromise: Promise<Microsite>;

  private subscriptions: Subscription[] = [];

  private validOrigin: string;

  private startDate = new Date();

  private micrositeLinkPromise: Promise<string>;

  constructor(private windowAdapter: MicrositeWindowAdapterService,
              private linkService: LinkService,
              private resourceService: ResourceService) {
  }

  registerValidOrigin(iframeUrl: string) {
    const url = new URL(iframeUrl);
    this.validOrigin = url.origin;
  }

  ngOnInit(): void {
    this.renderedMicrositePromise = this.linkService.renderLinks({
      object: this.microsite,
      recordId: this.recordId
    });
    this.micrositeLinkPromise = this.loadMicrositeLink();
  }

  async loadMicrositeLink(): Promise<string> {
    const link = MicrositeLinkResolver.toTemplateLink(this.microsite);
    const renderedLink = await this.resourceService.renderLinkMacros({
      link,
      freewallId: this.recordId
    });

    this.registerValidOrigin(renderedLink);
    const renderedMicrosite = await this.renderedMicrositePromise;
    this.subscriptions.push(fromEvent(window, "message")
      .pipe(filter((event: MessageEvent) => event.origin === this.validOrigin))
      .pipe(filter((event: MessageEvent) => typeof event.data === "string"))
      .subscribe((message: MessageEvent) => this.respondToMicrosite(message, renderedMicrosite)));
    return renderedLink;
  }

  respondToMicrosite(receivedMessage: MessageEvent, renderedMicrosite: Microsite): void {
      const messageString = receivedMessage.data;
      if (messageString === `${micrositeConfigMessagePrefix}?`) {
        this.respondToMessage(receivedMessage,
          `${micrositeConfigMessagePrefix}:${JSON.stringify(renderedMicrosite.config)}`);
      } else if (messageString.startsWith(messagePrefix)) {
        const message = this.decodeMicrositeMessage(messageString);
        if (message.sub === Subject.Size) {

          const heightMessage = message as FreeWallEvent<SizeData>;
          this.height = heightMessage.dat.height;

        } else {
          const mockFreeWallData = this.createFreeWallData();
          const freeWallEvent: FreeWallEvent<FreeWallData> = {
            dat: mockFreeWallData,
            src: "FW",
            sub: Subject.Data
          };
          const encodedMessage = this.encodeMicrositeMessage(freeWallEvent);
          this.respondToMessage(receivedMessage, encodedMessage);

        }

      }
  }

  createFreeWallData(): FreeWallData {
    const question = [...this.freeWallConfig.questions].shift();
    return {
      trackerSource: "https://example.com/bar",
      placement: preferredPlacement[this.microsite.templateId],
      answerOrder: question ? question.answers.map((a, index) => index) : [],
      startTime: this.startDate.getTime(),
      configId: "www.example.com",
      url: location.href,
      questionIndex: 0,
      creativeIndex: 0,
      audioLevel: 0,
      creativeId: "foo/bar/baz",
      sessionId: "foo",
      config: this.freeWallConfig,
      googleAnalytics: {
        enabled: false,
        accountId: "example",
      }
    };
  }

  encodeMicrositeMessage<T>(message: FreeWallEvent<T>): string {
    return messagePrefix + JSON.stringify(message);
  }

  decodeMicrositeMessage<T>(input: string): FreeWallEvent<T> {
    const payloadString = input.replace(messagePrefix, "");
    return JSON.parse(payloadString);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private respondToMessage<T>(event: MessageEvent, response: string) {
    (event.source as Window).postMessage(response, this.validOrigin);
  }
}
