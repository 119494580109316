import { Pipe, PipeTransform } from "@angular/core";
import { FreewallMetaPair } from "../core/freewall.meta.pair";

@Pipe({
    name: 'tempFreeWall'
})
export class TempFreeWallPipe implements PipeTransform {

    transform(freeWalls: FreewallMetaPair[], temp?: boolean): FreewallMetaPair[] {
        return freeWalls.filter(fw => this.filter(fw, temp));
    }

    filter(fw: FreewallMetaPair, temporary: boolean = false): boolean {
        const fallbackIfNoMeta = true;
        const isTemporary = !!(fw.meta ? fw.meta.temporary : fallbackIfNoMeta)
        return temporary === isTemporary;
    }
}
