export enum NoShowReason {
    UnlockCap = 1,
    CustomPublisherCap = 3,
    AntiAdBlocking = 4,
    NoPlaceholder = 5,
    ArticleCap = 6,
    ImpressionsCap = 7,
    RandomCap = 9,
    NthArticleCap = 13,
    ViewCap = 14,
    EngagementRateOptimisation = 12,
    VideoError = 15,
    DeliveryComplete = 17,
    M2E = 18,

    /**
     * Doubleserve no-shows
     */
    DoubleserveCap = 20,
    FreeWallAlreadyPresent = 21,
    NoBidsRequested = 22,
    NoBidsReceived = 23,

    WrongContext = 24

}
