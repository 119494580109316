import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/CoreModule";
import { AWSService } from "../core/AWSService";
import { CreatorModule } from "../creator";
import { DataMigrationService } from "./DataMigrationService";
import { filter, map, Subscription, concatMap, combineLatest } from "rxjs";
import { MagicLinkService } from "../authenticator";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    CreatorModule
  ]
})
export class MigratorModule {

  readonly migrationSubscription: Subscription;

  constructor(magicLinkService: MagicLinkService, awsService: AWSService, migrator: DataMigrationService) {

    this.migrationSubscription = combineLatest([
      magicLinkService.token$,
      awsService.identityId$
    ]).pipe(
      filter(([token, identityId]) => token.exists && identityId === token.item.identityId),
      map(([token]) => token.item.idToken),
      concatMap(idToken => migrator.migrateData(idToken))
    ).subscribe();
  }
}
