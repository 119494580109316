<div *ngIf="resource | resourceType as mode"
     class="{{resourceClass}}"
     (click)="resourceClicked.emit()">
  <ng-container *ngIf="resource.link as link">
    <mat-card class="resource-card">
      <mat-card-subtitle class="card-subtitle">
        {{resource.title | fileName}}
      </mat-card-subtitle>
      <div *ngIf="mode === ResourceType.image">
        <img [src]="link" [alt]=resource.title>
      </div>
      <div *ngIf="mode === ResourceType.video">
        <video [src]="link">
        </video>
      </div>
    </mat-card>
  </ng-container>
</div>
