import { Answer, Dataset, FreeWall, FreeWallConfig, FreeWallDB } from "@rezonence/core";
import { ArrayUtils } from "@rezonence/array-utils";
import { DatasetHook } from "./DatasetHook";
import { Tracker } from "@rezonence/sdk";

export class AnswerTrackersMigrator extends DatasetHook<FreeWall> {

  constructor() {
    super(Dataset.Freewall);
  }


  async transformValue(input: FreeWall): Promise<FreeWall> {
    for (const answer of this.toAnswers(input)) {
      const trackers = Array.isArray(answer.trackers) ? answer.trackers : []
      answer.trackers = trackers.map(t => this.migrateTracker(t))
    }
    return input;
  }

  protected migrateTracker(tracker: Tracker): Tracker {
    return typeof tracker === "string" ? {
      trackerURL: tracker as string
    } : tracker
  }

  protected toAnswers(config: FreeWallConfig): Answer[] {
    return ArrayUtils.flatten((config.questions || []).map(question => question.answers || []));
  }

}
