import { FreeWallImporter } from "./freewall.importer";
import { AnyFile, IFileService, ScannedFile, WriteOptions } from "@djabry/fs-s3";
import { CDN, R3ZConfig } from "../config-extractor";
import { TemplateFolderResolver, prefixMap, CompileFolder } from "../freewall-templates";
import { resolve } from "path";
import { FreewallFilenamePrefix, ConfigDownloader, DestinationPrefix } from "@rezonence/sdk";
import { TemplateFileSuffix } from "../freewall-compiler/template.file.suffix";
import { s3PrefixMap } from "../config-extractor/s3.prefix.map";
import { compileBucket } from "../aws";

export class DefaultFreeWallImporter implements FreeWallImporter {

  templateSuffixesToInclude: TemplateFileSuffix[];

  constructor(private fileService: IFileService,
    private downloader: ConfigDownloader,
    private templateResolver: TemplateFolderResolver) {

    this.templateSuffixesToInclude = [
      TemplateFileSuffix.Conf,
      TemplateFileSuffix.FormatJson,
    ];
  }

  importFreeWallToLocalFolder(adId: string, localPath: string, writeOptions?: WriteOptions,
    includeCompiled?: boolean, useAdConfig?: boolean): Promise<any> {
    return this.importToLocalFolder(FreewallFilenamePrefix.Ad, adId, localPath, writeOptions, includeCompiled,
      useAdConfig);
  }

  importPubConfToLocalFolder(cid: string, localPath: string, writeOptions?: WriteOptions,
    includeCompiled?: boolean, useAdConfig?: boolean): Promise<any> {
    return this.importToLocalFolder(FreewallFilenamePrefix.Pb, cid, localPath, writeOptions, includeCompiled,
      useAdConfig);

  }

  importFreeWallResources(adId: string, destinationFolder: AnyFile, writeOptions?: WriteOptions): Promise<any> {
    return this.importResources(FreewallFilenamePrefix.Ad, adId, destinationFolder, writeOptions);
  }

  importPubResources(configId: string, destinationFolder: AnyFile, writeOptions?: WriteOptions): Promise<any> {
    return this.importResources(FreewallFilenamePrefix.Pb, configId, destinationFolder, writeOptions);
  }

  async importToLocalFolder(filenamePrefix: FreewallFilenamePrefix, configId: string, destinationFolder: string,
    wO?: WriteOptions, includeCompiled?: boolean, useAdConfig?: boolean): Promise<void> {
    await this.importResources(filenamePrefix, configId, { key: destinationFolder }, wO, includeCompiled);
    const destinationPrefix = s3PrefixMap[filenamePrefix];
    const config = await this.resolveConfig(destinationPrefix, configId);
    const writeOptions: WriteOptions = { overwrite: true, skipSame: true };
    const configFileName = this.toConfigFileName(filenamePrefix, useAdConfig);
    const configFilePath = resolve(destinationFolder, configFileName);
    const configString = JSON.stringify(config, null, 4);
    await this.fileService.write(configString, { key: configFilePath }, writeOptions);
  }

  toConfigFileName(prefix: FreewallFilenamePrefix, useAdConfig: boolean): string {
    return (useAdConfig && prefix === FreewallFilenamePrefix.Ad) ? "ProgConf.json" : `${prefix}Conf.json`;
  }

  async resolveConfig(prefix: DestinationPrefix, configId: string): Promise<R3ZConfig> {
    const optionalConfig = await this.downloader.downloadConfig<R3ZConfig>({
      id: configId,
      cdn: new URL(CDN.S3),
      prefix
    });
    return optionalConfig.item;
  }

  async importResources(prefix: FreewallFilenamePrefix, configId: string, destinationFolder: AnyFile,
    writeOptions?: WriteOptions, includeCompiled?: boolean): Promise<ScannedFile[]> {
    const destinationPrefix = s3PrefixMap[prefix];
    const config = await this.resolveConfig(destinationPrefix, configId);

    // First find all the filenames of templates
    const templateFolder = this.templateResolver.resolve({ version: config.version, compileFolder: prefixMap[prefix] as CompileFolder });
    const templatePath = templateFolder.key;
    const resourcePath = `${destinationPrefix}/${configId}`;

    const templateFiles = await this.fileService.list({ key: templatePath, bucket: templateFolder.bucket });
    const resourceFiles = await this.fileService.list({
      key: `${resourcePath}/`,
      bucket: compileBucket
    });

    const resourcesToImport = [];

    for (const resourceFile of resourceFiles) {

      // If the file is not a template file or it should be included then download it
      // let filename = this.getFilename(resourceFile);

      const isTemplateFile = templateFiles.find(templateFile => {
        return resourceFile.key.replace(resourcePath, templatePath) === templateFile.key;
      });

      // If it's not a template file and the key is not a 'folder'
      if ((!isTemplateFile || includeCompiled) && !resourceFile.key.endsWith("/")) {
        resourcesToImport.push(resourceFile);
      }
    }

    // Copy over all the resource files to the destination
    await Promise.all(resourcesToImport.map(async resourceFile => {

      const destinationKey = resourceFile.key.replace(resourcePath, destinationFolder.key);
      const destinationFile = { key: destinationKey, bucket: destinationFolder.bucket };

      await this.fileService.copy(resourceFile, destinationFile, writeOptions);
    }));

    return resourcesToImport;

  }

}
