import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Dataset, DatasetValue} from "@rezonence/core";
import {ErrorObject} from "ajv";
import {JsonSchema} from "@jsonforms/core";

@Component({
  selector: "app-data-editor",
  templateUrl: "./data-editor.component.html"
})
export class DataEditorComponent {

  @Input()
  recordId: string;

  @Input()
  dataset: Dataset;

  @Input()
  schema: JsonSchema;

  @Output()
  errors: EventEmitter<ErrorObject[]> = new EventEmitter<ErrorObject[]>();

  @Output()
  dataChange: EventEmitter<DatasetValue> = new EventEmitter<DatasetValue>();

  @Input()
  set data(data: DatasetValue) {
    this._data = data;
    this.dataChange.emit(data);
  }

  get data(): DatasetValue {
    return this._data;
  }

  private _data: DatasetValue;

}
