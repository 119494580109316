export enum ElementId {
    instructionsText = "r3z-inst",
    topInstructionsText = "topInstructionsText",
    topInstructionBar = "r3z-inst-top",
    bottomInstructionBar = "r3z-inst-bot",
    bottomInstructionsText = "bottomInstructionsText",
    banner = "r3z-addoor",
    bannerCard = "r3z-banner-card",
    innerBanner = "r3z-ban",
    response = "r3z-response",
    responseImage = "r3z-rimg",
    kicker = "r3z-kicker",
    kickerImage = "r3z-kimg",
    answerList = "r3z-anslst",
    bannerIframe = "r3z-fwifrm",
    responseIframePrefix = "r3z-rifrm-",
    kickerIframePrefix = "r3z-kifrm-",
    playButton = "playButton",
    stickyInstructions = "r3z-stick",
    timeUntilUnlock = "timeUntilUnlockSpan",
    loadingAnimation = "loadingAnimation",
    videoResponse = "videoResponse",
    scrollPrompt = "scrollPrompt",
    backgroundImage = "backgroundImage",
    xsIframe = "r3z-ifrm",
    mpuContainer = "r3zMpuContainer",
    contextMenu = "r3z-cmenu",
    questionAndAnswers = "r3z-qna",
    question = "r3z-quest",
    hint = "r3z-hint",
    retry = "r3z-try"
}
