import isBase64 from "is-base64";

export class Base64Utils {

    /**
     * Encode string as base 64
     * @param inputString {string} - The string to encode
     * @return {string}
     */
    static base64Encode(inputString: string): string {
        return Buffer.from(inputString).toString("base64");
    }

    /**
     * Decode a base64-encoded string
     * @param {string} encodedString - The string to decode
     * @return {string}
     */
    static base64Decode(encodedString: string): string {
        return Buffer.from(encodedString, "base64").toString("utf8");
    }

    /**
     * Determine whether a string is base64 encoded
     * @param input
     */
    static isBase64(input: string): boolean {
        return isBase64(input);
    }
}
