import { Pipe, PipeTransform } from "@angular/core";
import {GridService} from "../grid/grid.service";

@Pipe({
  name: "numberOfColumns"
})
export class NumberOfColumnsPipe implements PipeTransform {

  constructor(private gridService: GridService) {
  }

  transform(containerWidth: number, preferredWidth: number): number {
    return this.gridService.getNumberOfColumns({containerWidth, preferredWidth});
  }

}
