import { DatasetItem } from "./dataset.item";
import { DatasetValue } from "./dataset.value";
import { Dataset } from "./dataset";
import type DynamoDB from "aws-sdk/clients/dynamodb";
import { identityIdDatasetNameIndex } from "../authorisation/identity.id.dataset.index";
import { Duration } from "@rezonence/duration";
import { datasetNameModifiedIndexName } from "./dataset.name.modified.index.name";
import { DatasetItemKey } from "./dataset.item.key";
import { SaveItemRequest } from "./save.item.request";

export class FreeWallDB {

  constructor(private dbPromise: Promise<DynamoDB.DocumentClient>, private tableName: string) {
  }

  listAllItems<T extends DatasetValue>(datasetName: Dataset,
    dateRange: [Date, Date] = this.defaultDateRange()):
    AsyncIterable<DatasetItem<T>> {
    const [startDate, endDate] = dateRange;
    const dbRequest = {
      IndexName: datasetNameModifiedIndexName,
      ExclusiveStartKey: null,
      TableName: this.tableName,
      KeyConditionExpression: `${DatasetItemKey.DatasetName} = :${DatasetItemKey.DatasetName} and #${DatasetItemKey.Modified} between :minTime and :maxTime`,
      ExpressionAttributeNames: {
        [`#${DatasetItemKey.Modified}`]: DatasetItemKey.Modified
      },
      ExpressionAttributeValues: {
        [`:${DatasetItemKey.DatasetName}`]: datasetName,
        ":minTime": startDate.getTime(),
        ":maxTime": endDate.getTime()
      }
    };

    return this.queryItems(dbRequest);

  }

  defaultMaxTime(): number {
    return (new Date()).getTime() + Duration.MsInMinute;
  }

  defaultDateRange(): [Date, Date] {
    return [new Date(0), new Date(this.defaultMaxTime())]
  }

  listItems<T extends DatasetValue>(identityId: string,
    datasetName: Dataset,
    dateRange: [Date, Date] = this.defaultDateRange()): AsyncIterable<DatasetItem<T>> {
    const [startDate, endDate] = dateRange;
    const dbRequest = {
      IndexName: identityIdDatasetNameIndex,
      ExclusiveStartKey: null,
      TableName: this.tableName,
      KeyConditionExpression: `#${DatasetItemKey.IdentityId} = :${DatasetItemKey.IdentityId} and ${DatasetItemKey.DatasetName} = :${DatasetItemKey.DatasetName}`,
      ExpressionAttributeNames: {
        [`#${DatasetItemKey.Modified}`]: DatasetItemKey.Modified,
        [`#${DatasetItemKey.IdentityId}`]: DatasetItemKey.IdentityId
      },
      ExpressionAttributeValues: {
        [`:${DatasetItemKey.IdentityId}`]: identityId,
        [`:${DatasetItemKey.DatasetName}`]: datasetName,
        ":minTime": startDate.getTime(),
        ":maxTime": endDate.getTime()
      },

      FilterExpression: `#${DatasetItemKey.Modified} between :minTime and :maxTime`
    };
    return this.queryItems(dbRequest);
  }

  async* queryItems<T extends DatasetValue>(query: DynamoDB.DocumentClient.QueryInput): AsyncIterable<DatasetItem<T>> {
    const db = await this.dbPromise;
    let data = await db.query(query).promise();
    for (const item of data.Items) {
      yield item as DatasetItem<T>;
    }
    while (data.LastEvaluatedKey) {
      query.ExclusiveStartKey = data.LastEvaluatedKey;
      data = await db.query(query).promise();
      for (const item of data.Items) {
        yield item as DatasetItem<T>;
      }
    }
  }

  async getItem<T extends DatasetValue>(identityId: string,
    datasetName: Dataset, recordId: string): Promise<DatasetItem<T>> {
    const db = await this.dbPromise;
    const data = await db.get({
      TableName: this.tableName,
      Key: {
        [DatasetItemKey.IdKey]: `${identityId}_${recordId}`,
        [DatasetItemKey.DatasetName]: datasetName
      }
    }).promise();

    return data.Item as DatasetItem<T>;
  }

  async saveItem<T extends DatasetValue>(item: SaveItemRequest<T>): Promise<DatasetItem<T>> {

    const db = await this.dbPromise;
    const value: DatasetItem<T> = {
      modified: (new Date()).getTime(),
      ...item,
      idKey: `${item.identityId}_${item.recordId}`
    };

    await db.put({
      TableName: this.tableName,
      Item: value
    }).promise();

    return value;
  }

  async deleteAll(identityId: string, recordId: string): Promise<void> {

    const datasetNames = Object.values(Dataset);
    for (const datasetName of datasetNames) {
      await this.deleteItem(identityId, datasetName as Dataset, recordId);
    }

  }

  async deleteItem(identityId: string, datasetName: Dataset, recordId: string): Promise<void> {

    const db = await this.dbPromise;
    await db.delete({
      TableName: this.tableName,
      Key: {
        [DatasetItemKey.IdKey]: `${identityId}_${recordId}`,
        [DatasetItemKey.DatasetName]: datasetName
      }
    }).promise();

  }

}
