import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LinkedFile} from "../../core/linked.file";

@Component({
  selector: "app-resources",
  templateUrl: "./resources.component.html",
  styleUrls: ["./resources.component.css"]
})
export class ResourcesComponent implements OnInit {

  @Input()
  resources: LinkedFile[];

  @Output()
  resourceClicked: EventEmitter<LinkedFile> = new EventEmitter<LinkedFile>();

  constructor() { }

  ngOnInit() {
  }

}
