import { Injectable } from "@angular/core";
import { RouteResolver } from "./RouteResolver";
import { Observable, map } from "rxjs";
import { Route } from "@rezonence/freewall-creator-config";

@Injectable({
    providedIn: 'root'
})
export class ToolbarVisibilityResolver {
    readonly toolbarVisible$: Observable<boolean>;

    constructor(routeResolver: RouteResolver) {
        this.toolbarVisible$ = routeResolver.route$.pipe(
            map(v => ![Route.Demo, Route.Edit].includes(v.item))
        );
    }

}