import { Injectable } from "@angular/core";
import { Microsite, MicrositeType } from "@rezonence/core";
import capitalize from "lodash/capitalize";

@Injectable({
  providedIn: "root"
})
export class MicrositeTitleGenerator {

  toSuggested(templateId: MicrositeType, index: number): string {
    return `${capitalize(templateId)} ${index}`;
  }
  generate(templateType: MicrositeType, allMicrosites: Microsite[]): string {
    let index = 1;
    while (allMicrosites.map(m => m.title).includes(this.toSuggested(templateType, index))) {
      index++;
    }
    return this.toSuggested(templateType, index);
  }
}
