import {VideoService} from "./video.service";
import {AnyFile, IFileService, ScannedFile} from "@djabry/fs-s3";
import {Utils} from "../utils";
import ElasticTranscoder from "aws-sdk/clients/elastictranscoder";
import {Job} from "aws-sdk/clients/elastictranscoder";
import {environment} from "../aws/environment";
import {basename, extname} from "path";

export class DefaultVideoService implements VideoService {

    pipelineId: string;
    inputBucket: string;
    outputBucket: string;
    youtubeBucket: string;
    presetId: string;
    private transcoder: ElasticTranscoder;

    constructor(private fileService: IFileService, private utils: Utils,
                presetId?: string, transcoder?: ElasticTranscoder) {

        presetId = presetId || environment.TRANSCODER_PRESET_ID;

        this.pipelineId = environment.TRANSCODER_PIPELINE_ID;
        this.inputBucket = environment.TRANSCODER_INPUT_BUCKET;
        this.outputBucket = environment.TRANSCODER_OUTPUT_BUCKET;

        this.presetId = presetId;
        this.transcoder = transcoder || new ElasticTranscoder({region: environment.REGION});

    }

    uploadVideo(file: File): Promise<ScannedFile[]> {
        // First scan the file
        return this.fileService.calculateUploadMD5(file).then(md5 => {
            return this.findJob(md5).then(job => {

                if (job) {
                    console.log("The video has already been processed, retrieving results");
                    // If a job is found then wait for it to finish and upload the resultant files
                    return this.waitForTranscodeJob(job.Id);

                } else {

                    const inputFile = {bucket: this.inputBucket, key: `${md5}/${file.name}`};

                    return this.fileService.uploadFile(file, inputFile).then((iFile) => {
                        return this.startTranscodeJob(iFile).then(startedJob => {
                            return this.waitForTranscodeJob(startedJob.Id);

                        });
                    });

                }
            });
        });
    }

    doStartTranscodeJob(inputFile: ScannedFile): Promise<Job> {

        const extension = extname(inputFile.key);
        const filename = basename(inputFile.key, extension);

        const key = `${inputFile.md5}/${filename}${extension}`;

        // Copy the input file to the correct location
        return this.fileService.copy(inputFile, {bucket: this.inputBucket, key}).then(files => {

            const outputKey = `${inputFile.md5}/${this.presetId}/${filename}.mp4`;

            // Delete the output file if it already exists to ensure that transcoder doesn't crash
            return this.fileService.deleteAll({bucket: this.outputBucket, key: outputKey})
                .then((deletedFiles) => {

                return this.transcoder.createJob({
                    PipelineId: this.pipelineId,
                    Input: {
                        Key: key
                    },

                    // Preserve the filename and add the .mp4 extension
                    Outputs: [
                        {
                            Key: outputKey,
                            PresetId: this.presetId
                        }
                    ]
                }).promise().then(data => {

                    // console.log("Started job", data.Job);
                    return data.Job;

                });

            });

        });

    }

    /**
     * Finds a valid job according to the current preset id
     * @param md5
     * @param pageToken
     * @returns {any}
     */
    doFindJob(md5: string, pageToken: string): Promise<Job> {

        console.log("Listing transcoder jobs");

        return this.transcoder.listJobsByPipeline({
            PipelineId: this.pipelineId,
            Ascending: "false",
            PageToken: pageToken
        }).promise().then(data => {

            const job = data.Jobs.find((j: Job) => {
                const key: string = j.Input.Key;

                const output = (j["Outputs"] as any[]).find((outputConfig) => {

                    // The value of Status is one of the following: Submitted, Progressing, Complete, Canceled, or Error

                    // Only valid jobs are ones where the output is not Canceled or in Error
                    const status = outputConfig.Status;

                    const presetId = outputConfig.PresetId;

                    return presetId === this.presetId && status !== "Canceled" && status !== "Error";

                });

                return key.indexOf(md5) > -1 && output;

            });

            if (job) {
                console.log("Found existing job: ", job["Id"]);
                return job;

            } else {

                if (data.NextPageToken) {

                    return this.doFindJob(md5, data.NextPageToken).then(j => {

                        return j;
                    });

                } else {
                    console.log("Didn't find an existing job");
                    // If this is the end of the list of jobs then return null;
                    return null;
                }

            }

        });

    }

    findJob(md5: string): Promise<Job> {

        console.log("Finding job");

        return this.fileService.list({bucket: this.inputBucket, key: md5}).then(inputFiles => {

                return this.fileService.list({bucket: this.outputBucket, key: md5}).then(outputFiles => {

                    if (inputFiles.length || outputFiles.length) {
                        // If the input or output files associated with the job exist then find it in elastic transcoder
                        console.log("Some existing video files were found");
                        return this.doFindJob(md5, null);

                    } else {

                        console.log("No existing job found");

                        return null;

                    }

                });
            }
        );

    }

    startTranscodeJob(inputFile: ScannedFile): Promise<Job> {

        return this.findJob(inputFile.md5).then(job => {

            if (!job) {

                // if The job has never been started then start it;
                return this.doStartTranscodeJob(inputFile);

            } else {

                return job;
            }

        });

    }

    doWait(jobId: string): Promise<Job> {
        console.log("Waiting for video job", jobId);

        return this.transcoder.waitFor("jobComplete", {Id: jobId}).promise().then(data => {

            return data.Job;

        });

    }

    waitForTranscodeJob(jobId: string): Promise<ScannedFile[]> {
        return this.doWait(jobId).then((job) => {

            const promises = job.Outputs.map(outputConfig => {
                const key = outputConfig.Key;
                return this.fileService.isFile({bucket: this.outputBucket, key});
            });

            return Promise.all(promises).then((results: any) => {

                return results as Promise<ScannedFile[]>;

            });
        });
    }

    /**
     *
     * @param inputFile
     */
    getVideo(inputFile: ScannedFile): Promise<AnyFile[]> {

        // Check if the video has already been encoded
        const outputFolderString = `${inputFile.md5}/${this.presetId}`;

        const outputFolder = {bucket: this.outputBucket, key: outputFolderString} as AnyFile;

        return this.fileService.list(outputFolder).then(files => {

            if (files.length) {
                // If the video has already been encoded then return the files
                return files as AnyFile[];

            } else {
                return this.startTranscodeJob(inputFile).then(job => {

                    return job.Outputs.map(outputConfig => {

                        return {
                            key: outputConfig.Key,
                            bucket: this.outputBucket
                        } as AnyFile;
                    });

                });
            }

        });

    }

}
