export enum SingleTrackerType {
    Click = 'clk',
    Impression = 'imp',
    Unlock = 'ulk',
    Engagement = 'eng',
    InView = 'ivw',
    VideoStart = 'vst',
    Video25PercentComplete = 'v25',
    Video50PercentComplete = 'v50',
    Video75PercentComplete = 'v75',
    VideoComplete = 'ven',
    ViewabilityJs = 'vjs'
}
