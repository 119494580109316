import { FreewallMetaPair } from "../../core/freewall.meta.pair";
import { Dataset, FreeWall, Meta } from "@rezonence/core";
import { CreativeFreeWallDB } from "../../core/CreativeFreeWallDB";
import { AWSService } from "../../core/AWSService";
import { LoadingMessageComponent } from "../../core/loading-message/loading-message.component";
import { MatDialog } from "@angular/material/dialog";
import { Injectable } from "@angular/core";
import { Optional } from "@rezonence/sdk";
import { Route } from "@rezonence/freewall-creator-config";
import { wizardModeQueryParam } from "../../creator/wizard/wizard.mode.query.param";
import { CreateFreewallType } from "../../create/CreateFreewallType";
import { QuickstartService } from "../../create/QuickstartService";
import { WizardService } from "../../creator/wizard/wizard.service";
import { WizardModeKey } from "../../creator/wizard/wizard.mode.key";
import { Router } from "@angular/router";
import { Subject, Observable, merge, concatMap, shareReplay, map } from "rxjs";
import { ShareService } from "../../share/ShareService";
import { environment } from "../../../environments/environment";
import { CloudFreewallCompiler } from "../../core/CloudFreewallCompiler";
import { CreatePreviewRequest } from "../../preview/preview/create.preview.request";
import { previewFolder } from "../../preview/preview.folder";
import { SharedRequest } from "../../share/shared.request";
import { FreeWallTitleGenerator, QuickstartOperation, WizardOperation } from "../../create";
import { SaveRecord } from "../SaveRecord";
import { collect } from "@rezonence/array-utils";

@Injectable({
  providedIn: "root",
})
export class FreewallOrganiserService {

  readonly freewalls$: Observable<FreewallMetaPair[]>;

  wizardModeByFreeWallType: Record<WizardOperation, WizardModeKey> = {
    [WizardOperation.Quiz]: WizardModeKey.SimpleQuiz,
    [WizardOperation.Opinion]: WizardModeKey.SimpleOpinion
  };

  protected freewallSource$: Subject<FreewallMetaPair[]> = new Subject<FreewallMetaPair[]>();

  constructor(private router: Router,
    private saveRecord: SaveRecord,
    private freeWallDB: CreativeFreeWallDB,
    private titleGenerator: FreeWallTitleGenerator,
    private createFreeWallService: QuickstartService,
    private wizardService: WizardService,
    private awsService: AWSService,
    private dialog: MatDialog,
    private shareService: ShareService,
    private compiler: CloudFreewallCompiler) {
    this.freewalls$ = merge(awsService.identityId$.pipe(concatMap(identityId => this.listFreeWalls(identityId))), this.freewallSource$).pipe(
      map(fws => fws.sort((a, b) => b.modified - a.modified)),
      shareReplay(1)
    )
  }

  async listFreeWalls(identityId: string): Promise<FreewallMetaPair[]> {
    const freeWalls = await collect(this.freeWallDB.listItems<FreeWall>(identityId, Dataset.Freewall));
    const metaItems = await collect(this.freeWallDB.listItems<Meta>(identityId, Dataset.Meta));
    return freeWalls.map(fw => ({
      ...fw,
      meta: metaItems
        .filter(m => m.recordId === fw.recordId)
        .map(m => m.value)
        .shift()
    })) as FreewallMetaPair[];
  }

  toLoadingMessage(type: CreateFreewallType): string {
    return type === CreateFreewallType.Existing
      ? "Importing existing FreeWall..."
      : `Creating ${type} FreeWall...`;
  }

  async loadWithDialog(type: CreateFreewallType, executor: () => Promise<void>) {
    const loadingRef = this.dialog.open(LoadingMessageComponent, { disableClose: true });
    loadingRef.componentInstance.progressMessage = this.toLoadingMessage(type);
    try {
      await executor();
    } finally {
      loadingRef.close();
    }
  }

  async createNew(freewalls: FreewallMetaPair[], type: CreateFreewallType): Promise<void> {
    await this.loadWithDialog(type, async () => {
      if (Object.values(QuickstartOperation).includes(type as QuickstartOperation)) {
        await this.createWithQuickStart(freewalls, type as QuickstartOperation);
      } else {
        await this.createWithWizard(freewalls, type as WizardOperation);
      }
    });
  }

  removeFreewall(freewalls: FreewallMetaPair[], id: string): void {
    this.freewallSource$.next(freewalls.filter(freewall => freewall.recordId !== id));
  }

  addFreewall(freewalls: FreewallMetaPair[], freewall: FreewallMetaPair): void {
    freewalls = [freewall, ...freewalls];
    this.freewallSource$.next(freewalls);
  }

  async addClonedFreewall(freewalls: FreewallMetaPair[], freewall: FreewallMetaPair) {
    const loadingRef = this.dialog.open(LoadingMessageComponent, { disableClose: true });
    loadingRef.componentInstance.progressMessage = "Preparing preview...";
    await this.compileAndShareFreewall(freewall.recordId, freewall.meta);
    this.addFreewall(freewalls, freewall);
    loadingRef.close();
  }

  async reloadFreeWallsIfRequired(): Promise<void> {
    if (this.saveRecord.recordIds.length) {
      this.saveRecord.recordIds = [];
      const identityId = await this.awsService.identityId();
      this.freewallSource$.next(await this.listFreeWalls(identityId));
    }

  }

  async compileAndShareFreewall(recordId: string, meta: Meta): Promise<void> {
    const sourceLink = environment.defaultDemoLink;
    const request: SharedRequest = { recordId, sourceLink };
    const shareLink = await this.shareService.getShareLink(request);
    meta.demoSourceUrl = sourceLink;
    meta.demoLink = shareLink;
    await this.freeWallDB.saveDatasetValue(Dataset.Meta, recordId, meta);
    const microsites = await this.freeWallDB.getMicrosites(recordId);
    const storedConfig = await this.freeWallDB.getDatasetValue<FreeWall>(Dataset.Freewall, recordId);
    const previewRequest: CreatePreviewRequest = {
      recordId,
      config: storedConfig,
      demoPage: sourceLink,
      recordFolder: previewFolder,
      microsites,
      autoShare: true
    };
    await this.compiler.invoke(previewRequest);
    await this.shareService.share(request);
  }

  protected async createWithQuickStart(freewalls: FreewallMetaPair[], type: QuickstartOperation): Promise<void> {
    const result = await this.createFreeWallService.create(type, freewalls);
    await Optional.switchPromise(result.map(async metaPair => {
      await this.compileAndShareFreewall(metaPair.recordId, metaPair.meta);
      this.addFreewall(freewalls, metaPair);
      await this.router.navigate([Route.Advanced, metaPair.recordId, Dataset.Freewall]);
    }));
  }

  protected async createWithWizard(freeWalls: FreewallMetaPair[], type: WizardOperation): Promise<void> {
    await Optional.switchPromise(Optional.of(this.wizardModeByFreeWallType[type]).map(async wizardModeKey => {
      const modes = this.wizardService.listModes();
      const mode = modes.find(m => m.key === wizardModeKey);
      const config = await mode.createConfig();
      const freeWall = await this.freeWallDB.saveDatasetValue(Dataset.Freewall, undefined, config);
      const metaItem = await this.freeWallDB.saveDatasetValue(Dataset.Meta, freeWall.recordId, {
        title: this.titleGenerator.generate(freeWalls, type),
        labels: []
      });
      this.addFreewall(freeWalls, {
        ...freeWall,
        meta: metaItem.value
      });
      await this.router.navigate([Route.Wizard, freeWall.recordId], {
        queryParams: {
          [wizardModeQueryParam]: wizardModeKey
        }
      });
    }));
  }

}
