import { Pipe, PipeTransform } from "@angular/core";
import {FreeWall} from "@rezonence/core";
import {ResourceService} from "../../resource/resource/ResourceService";
import {DatasetItem} from "@rezonence/core";

@Pipe({
  name: "bannerLink"
})
export class BannerLinkPipe implements PipeTransform {

  constructor(private resourceService: ResourceService) {
  }

  transform(freeWall: DatasetItem<FreeWall>): Promise<string> {
    if (freeWall && freeWall.value && freeWall.value.image && freeWall.value.image.img) {
      return this.resourceService.renderLinkMacros({
        freewallId: freeWall.recordId,
        link: freeWall.value.image.img
      });
    } else {
      return undefined;
    }
  }

}
