import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/CoreModule";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [],
  imports: [
    CoreModule,
    CommonModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class CreateModule {
}
