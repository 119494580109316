import {ButtonStyle} from "../config-extractor";

export const cssPropertyByButtonStyleKey: Record<keyof ButtonStyle, string> = {
    backgroundColour: "background-color",
    fontColour: "color",
    fontWeight: "font-weight",
    borderColour: "border-color",
    borderRadius: "border-radius",
    borderWidth: "border-width",
    borderStyle: "border-style"
};
