import { extname } from 'path'
import { minify as minifyCSS } from 'csso'
import { IFileService, ScannedFile } from '@djabry/fs-s3'
import { prefixMap, CompileFolder, TemplateFolderResolver } from '../freewall-templates'
import { CryptoUtils } from '../utils/crypto.utils'
import { FreewallFilenamePrefix } from '@rezonence/sdk'
import { LocalFile } from '@jabrythehutt/fs-s3-core'
import { S3File } from '@jabrythehutt/fs-s3'

export class TemplateService<T extends LocalFile = S3File> {
  templateCache: Record<string, string>

  minifiedCache: Record<string, Promise<string>>

  templateListCache: { [key: string]: Promise<ScannedFile[]> }

  constructor (private fileService: IFileService, private templateResolver: TemplateFolderResolver<T>) {
    this.templateCache = {}
    this.templateListCache = {}
    this.minifiedCache = {}
  }

  listTemplateFiles(version: string, prefix: FreewallFilenamePrefix, cache: boolean): Promise<ScannedFile[]> {
    const templateFolder = this.templateResolver.resolve({ version, compileFolder: prefixMap[prefix] as CompileFolder })

    if (cache) {
      if (!this.templateListCache[templateFolder.key]) {
        this.templateListCache[templateFolder.key] = this.fileService.list(templateFolder)
      }
      return this.templateListCache[templateFolder.key]
    } else {
      return this.fileService.list(templateFolder)
    }
  }

  readFromMinCache (cacheKey: string, useCache: boolean, executor: () => Promise<string>): Promise<string> {
    if (!useCache) {
      return executor()
    } else {
      if (!this.minifiedCache[cacheKey]) {
        this.minifiedCache[cacheKey] = executor()
      }

      return this.minifiedCache[cacheKey]
    }
  }

  async minify (fileString: string, useCache: boolean, extension: string, cacheKey: string): Promise<string> {
    if (extension === '.css') {
      return await this.readFromMinCache(cacheKey, useCache, async () => {
        const minifiedString = minifyCSS(fileString).css
        if (fileString && !minifiedString) {
          throw new Error('Failed to minify CSS file')
        }
        return minifiedString
      })
    } else {
      return fileString
    }
  }

  async readTemplateFile (templateFile: ScannedFile | string, debug: boolean,
    useCache: boolean, extension?: string): Promise<string> {
    if (debug) {
      if (typeof templateFile === 'string') {
        return templateFile
      } else {
        return this.fileService.readString(templateFile)
      }
    } else {
      let cacheKey = ''
      let templateString = ''

      if (typeof templateFile === 'string') {
        templateString = templateFile
      } else if (templateFile) {
        extension = extension || extname(templateFile.key)

        cacheKey = templateFile.md5

        if (!cacheKey) {
          throw new Error('Template file has no md5')
        }

        if (useCache && this.templateCache[cacheKey]) {
          templateString = this.templateCache[cacheKey]
        } else {
          templateString = await this.fileService.readString(templateFile)

          if (useCache) {
            this.templateCache[cacheKey] = templateString
          }
        }
      }

      cacheKey = cacheKey || CryptoUtils.calculateMd5Hash(templateString)
      return this.minify(templateString, useCache, extension, cacheKey)
    }
  }
}
