import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LinkedFile} from "../../core/linked.file";
import {ResourceType} from "../resource.type";

@Component({
  selector: "app-resource",
  templateUrl: "./resource.component.html",
  styleUrls: [
    "./resource.component.css"
  ]
})
export class ResourceComponent implements OnInit {

  @Input()
  resource: LinkedFile;

  @Output()
  resourceClicked: EventEmitter<void> = new EventEmitter<void>();

  ResourceType = ResourceType;

  resourceClass = "resource";

  constructor() { }

  ngOnInit() {
  }

}
