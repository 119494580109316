import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LoadingResult} from "./loading.result";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.css"]
})
export class LoadingComponent implements OnInit {

  executionPromise: Promise<LoadingResult>;

  @Input()
  progressMessage: string;

  @Output()
  ready: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set loadingPromise(promise: Promise<any>) {
    this.executionPromise = this.executePromise(promise);
  }

  constructor(private snackBar: MatSnackBar) {
  }

  async executePromise(promise: Promise<any>): Promise<LoadingResult> {
    try {
      this.ready.emit(false);
      await promise;
      return LoadingResult.Success;
    } catch (err) {
      this.snackBar.open(err.message);
      return LoadingResult.Failure;
    } finally {
      this.ready.emit(true);
    }
  }

  ngOnInit() {
    this.executionPromise = Promise.resolve(LoadingResult.Success);
  }

}
