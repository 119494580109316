import {Component, Input, OnInit, Output} from "@angular/core";
import {FreeWall} from "@rezonence/core/freewall/freewall";
import {freewallConfigSchema} from "@rezonence/core";
import {allControl} from "../all.control";
import {ReplaySubject} from "rxjs";
import {ErrorObject} from "ajv";
import {FreeWallImage} from "@rezonence/sdk";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.css"]
})

export class BannerComponent implements OnInit {

  @Input()
  set config(input: FreeWall) {
    this._config = input;
  }

  get config(): FreeWall {
    return this._config;
  }

  get image(): FreeWallImage {
    return this.config.image;
  }

  set image(image: FreeWallImage) {
    this.config.image = image;
  }

  schema = {
    properties: {
      img: {
        ...freewallConfigSchema.definitions.FreeWallImage.properties.img
      }
    }
  };
  uiSchema = allControl;

  @Output()
  errors: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  @Input()
  freeWallId: string;
  private _config: FreeWall;

  ngOnInit() {

  }

}
