import { NgModule } from "@angular/core";
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from "../../environments/environment";

@NgModule({
    imports: [
        GoogleTagManagerModule.forRoot({
            id: environment.gtmId,
        })
    ]
})
export class AnalyticsModule {
}