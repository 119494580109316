import { Injectable } from "@angular/core";
import { CreativeFileService } from "../core/CreativeFileService";
import { AWSService } from "../core/AWSService";
import { FreeWall } from "@rezonence/core/freewall/freewall";
import { Dataset } from "@rezonence/core/freewall/dataset";
import { CreativeFreeWallDB } from "../core/CreativeFreeWallDB";
import { AnyFile } from "@djabry/fs-s3";
import cloneDeep from "lodash/cloneDeep";
import { environment } from "../../environments/environment";
import { DefaultFreeWallImporter } from "@rezonence/core/freewall-importer/default.freewall.importer";
import { ImportFreewallRequest } from "./import.freewall.request";
import { FreewallMetaPair } from "../core/freewall.meta.pair";
import { DatasetDictionary } from "../data-editor/dataset.dictionary";
import { DatasetValue } from "@rezonence/core/freewall/dataset.value";
import { DatasetItem } from "@rezonence/core/freewall/dataset.item";
import { DestinationPrefix, FreeWallConfig } from "@rezonence/sdk";
import { CreativeConfigDownloader } from "./creative.config.downloader";
import { CreativeTemplateFolderResolver } from "../core/creative.template.folder.resolver";
import { CreateIdHook } from "../core/CreateIdHook";
import { CreativeMicrositeResolver, MicrositeTitleGenerator } from "../microsite";
import { Microsite, MicrositeLinkResolver } from "@rezonence/core";

@Injectable({
  providedIn: "root"
})
export class CreativeFreeWallImporter extends DefaultFreeWallImporter {

  constructor(private fService: CreativeFileService,
    private titleGenerator: MicrositeTitleGenerator,
    private configDownloader: CreativeConfigDownloader,
    private awsService: AWSService,
    private freeWallDB: CreativeFreeWallDB,
    private idGenerator: CreateIdHook,
    private micrositeResolver: CreativeMicrositeResolver,
    templateResolver: CreativeTemplateFolderResolver) {
    super(fService, configDownloader, templateResolver);
  }

  async extractValues(request: ImportFreewallRequest): Promise<Partial<DatasetDictionary<DatasetValue>>> {
    const freeWallConfigOptional = await this.configDownloader.downloadConfig<FreeWallConfig>({
      id: request.adId,
      prefix: DestinationPrefix.Ads,
      cdn: new URL(environment.defaultCDN)
    });
    const freeWallConfig = freeWallConfigOptional.item;
    const templatedMicrosites = await this.micrositeResolver.extractFromConfig(freeWallConfig, request.adId);
    const microsites: Microsite[] = templatedMicrosites.map(m => ({
      url: MicrositeLinkResolver.toMicrositeLink(m),
      ...m,
      title: ""
    }))
    microsites.forEach(m => m.title = this.titleGenerator.generate(m.templateId, microsites));
    return {
      [Dataset.Meta]: request.meta,
      [Dataset.Microsites]: microsites,
      [Dataset.Freewall]: request.includeTrackers ? freeWallConfig : this.removeTrackers(freeWallConfig)
    };
  }

  async importFreeWall(request: ImportFreewallRequest): Promise<FreewallMetaPair> {
    console.log("Importing", request.meta.title);
    const datasetValues = await this.extractValues(request);
    const identityId = await this.awsService.identityId();
    const recordId = await this.idGenerator.createNewIdForDataset(this.freeWallDB, Dataset.Freewall, identityId);
    const savedItems = await this.saveAll(datasetValues, { identityId, recordId });
    const assetFolder = this.fService.toFreeWallAssetPrefix({ identityId, recordId });
    const destinationFile: AnyFile = {
      bucket: environment.userStorageBucket,
      key: assetFolder
    };
    await this.importFreeWallResources(request.adId, destinationFile,
      { overwrite: true, skipSame: true, parallel: true });
    const freewallItem = savedItems.freewall as DatasetItem<FreeWall>;
    return { ...freewallItem, meta: request.meta };
  }

  protected removeTrackers(config: FreeWall): FreeWall {
    const cleanConfig = cloneDeep(config);
    delete cleanConfig.trackers;
    cleanConfig.questions?.forEach(question => question.answers?.forEach(answer => {
      delete answer.trackers;
    }));
    return cleanConfig;
  }

  protected async saveAll(values: Partial<DatasetDictionary<DatasetValue>>,
    params: { identityId: string; recordId: string }):
    Promise<Partial<DatasetDictionary<DatasetItem<DatasetValue>>>> {
    const savedItems = await Promise.all(Object.keys(values)
      .filter(key => !!values[key])
      .map(key => this.freeWallDB.saveDatasetValue(key as Dataset, params.recordId, values[key])));
    return savedItems.reduce((record, item) => ({
      ...record,
      [item.datasetName]: item
    }), {});
  }

}
