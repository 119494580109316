import {Component, Input, OnInit, Output} from "@angular/core";
import {FreeWall} from "@rezonence/core/freewall/freewall";
import {freewallConfigSchema} from "@rezonence/core";
import {ControlElement} from "@jsonforms/core";
import {allControl} from "../all.control";
import {ReplaySubject} from "rxjs";
import {ErrorObject} from "ajv";

@Component({
  selector: "app-links",
  templateUrl: "./links.component.html",
  styleUrls: ["./links.component.css"]
})
export class BannerLinkComponent implements OnInit {

  schema = freewallConfigSchema.definitions.FreeWallImage.properties.lnk;
  uiSchema = allControl;

  @Output()
  errors: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  @Input()
  config: FreeWall;

  @Input()
  freeWallId: string;

  get link(): string {
    return this.config.image.lnk;
  }

  set link(input: string) {
    this.config.image.lnk = input;
  }

  constructor() {
  }

  ngOnInit() {
  }

}
