import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/CoreModule";
import {ResourceLinkPipe} from "./resource/resource-link.pipe";
import {ResourceComponent} from "./resource/resource.component";
import {ResourcesComponent} from "./resources/resources.component";
import {ResourceTypePipe} from "./resource/resource-type.pipe";
import {LoadingComponent} from "./loading/loading.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {UploadComponent} from "./upload/UploadComponent";
import {MatTabsModule} from "@angular/material/tabs";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FilterSelectedPipe} from "./resource/filter.selected.pipe";
import {ByTypePipe} from "./resource/by.type.pipe";

@NgModule({
  declarations: [
    ResourceLinkPipe,
    ResourceComponent,
    ResourcesComponent,
    ByTypePipe,
    ResourceTypePipe,
    LoadingComponent,
    UploadComponent,
    FilterSelectedPipe
  ],
  imports: [
    MatProgressBarModule,
    MatTabsModule,
    CoreModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule
  ],
  exports: [
    UploadComponent,
    LoadingComponent,
    ResourceLinkPipe,
    ResourcesComponent,
    ByTypePipe,
    ResourceTypePipe,
    FilterSelectedPipe
  ]
})
export class ResourceModule {

}
