export enum Subject {
    Unlock = 'Unlock',
    Ready = 'Ready',
    Transition = 'Transition',
    Engagement = 'Engagement',
    Click = 'Click',
    Data = 'Data',
    Size = 'Size',
    AnswerClick = 'AnswerClick',
    AnswerInteraction = 'AnswerInteraction',
    Shutdown = 'Shutdown',
    View = 'View',
    Mouse = 'Mouse',
    Location = 'Location'
}
