import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/CoreModule";
import {ShareLinkComponent} from "./share-link/share-link.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {ClipboardModule} from "ngx-clipboard";

@NgModule({
  declarations: [
    ShareLinkComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    ClipboardModule
  ]
})
export class ShareModule {
}
