import { LocalFile } from "@jabrythehutt/fs-s3-core";
import { MicrositeSchema } from "./microsite.schema";
import { MicrositeType } from "./microsite.type";
import { S3File } from "@jabrythehutt/fs-s3";
import { CompileFolder, TemplateFolderResolver } from "../freewall-templates";
import { micrositeDestinationFolder } from './microsite.destination.folder'
import { Optional, downloadJson } from "@rezonence/sdk";
import { micrositeConfigFolder } from "./microsite.config.folder";
import { infoOutputFileName } from "./info.output.file.name";

export class MicrositeSchemaResolver<T extends LocalFile = S3File> {

  constructor(private templateResolver: TemplateFolderResolver<T>) {
  }

  async resolve(request: { templateId: MicrositeType, version: string, cdn: URL }): Promise<Optional<MicrositeSchema>> {
    const infoFileSuffix = `${micrositeDestinationFolder}/${request.templateId}/${micrositeConfigFolder}/${infoOutputFileName}`
    const micrositeInfoFile = this.templateResolver.resolve({
      version: request.version,
      compileFolder: infoFileSuffix as CompileFolder
    });
    const path = TemplateFolderResolver.toPublicPathName(micrositeInfoFile);
    const url = new URL(request.cdn);
    url.pathname = path;
    return downloadJson<MicrositeSchema>(url);
  }

  async listMicrositeSchemas(request: { cdn: URL, version: string }): Promise<MicrositeSchema[]> {
    const optionalSchemas = await Promise.all(Object.values(MicrositeType).map(templateId => this.resolve({ ...request, templateId })));
    return optionalSchemas.filter(s => s.exists).map(s => s.item);
  }
}
