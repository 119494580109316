import { Injectable } from "@angular/core";
import { WizardDialogService, WizardConfig } from "@rezonence/ng-schema-wizard";
import { ImportFreewallRequest } from "../import.freewall.request";
import { generateImportConfigSchema, GenerateImportConfig } from "@rezonence/freewall-creator-config";
import createDefault from "json-schema-defaults";
import { Optional, DestinationPrefix } from "@rezonence/sdk";
import { FreewallMetaPair } from "../../core/freewall.meta.pair";
import { CreativeFreeWallImporter } from "../CreativeFreeWallImporter";
import { ConfigIdStateService } from "../config-id/ConfigIdStateService";
import { ConfigState } from "../config-id/config.state";
import { MatSnackBar } from "@angular/material/snack-bar";
import isURL from "validator/es/lib/isURL";
import { DemoParam } from "@rezonence/freewall-tag";

@Injectable({
  providedIn: "root"
})
export class ImportWizardService {

  constructor(private wizardDialogService: WizardDialogService,
    private configStateService: ConfigIdStateService,
    private snackbar: MatSnackBar,
    private importer: CreativeFreeWallImporter) {
  }

  toImportFreeWallRequest(config: Required<GenerateImportConfig>): ImportFreewallRequest {
    return {
      meta: {
        title: config.title,
        labels: []
      },
      adId: this.toAdId(config.adIdOrUrl),
      includeTrackers: config.includeTrackers
    };
  }

  toWizardConfig(data: GenerateImportConfig): WizardConfig<GenerateImportConfig> {
    const stepKeys: (keyof GenerateImportConfig)[] = ["adIdOrUrl", "title", "includeTrackers"];
    return {
      data,
      schema: generateImportConfigSchema,
      steps: stepKeys.map(key => ({
        uiSchema: {
          type: "Control",
          scope: `#/properties/${key}`
        }
      }))
    };
  }

  parseRequest(importConfig: GenerateImportConfig): Required<GenerateImportConfig> {
    return {
      includeTrackers: false,
      ...importConfig
    }
  }

  extractAdId(url: URL): string {
    return url.searchParams.get(DemoParam.AdId);
  }

  toAdId(adIdOrUrl: string): string {
    const adId = isURL(adIdOrUrl) ? this.extractAdId(new URL(adIdOrUrl, location.origin)) : adIdOrUrl;
    return decodeURIComponent(adId);
  }

  // TODO Use async validation to catch non-existent ad ids once JSONForms supports it
  async validateAdId(input: Optional<GenerateImportConfig>): Promise<Optional<Required<GenerateImportConfig>>> {
    const output = await Optional.switchPromise(input.map(async rawRequest => {
      const adId = this.toAdId(rawRequest.adIdOrUrl);
      const request = this.parseRequest(rawRequest);
      const state = await this.configStateService.getState(DestinationPrefix.Ads, adId);
      if (state !== ConfigState.Existing) {
        this.snackbar.open(`No FreeWall with ID: ${adId} found`, "OK");
        const wizardConfig = this.toWizardConfig(request);
        const response = await this.wizardDialogService.requestData(wizardConfig);
        return this.validateAdId(response);
      }
      return Optional.of(request);
    }));
    return Optional.unWrap(output);
  }

  async handleImportRequest(request: ImportFreewallRequest): Promise<FreewallMetaPair> {
    try {
      return await this.importer.importFreeWall(request);
    } catch (err) {
      this.snackbar.open(`${err.message}`, "OK");
      throw err;
    }
  }

  async importFreeWall(): Promise<Optional<FreewallMetaPair>> {
    const data = createDefault(generateImportConfigSchema) as GenerateImportConfig;
    const wizardConfig = this.toWizardConfig(data);
    const importDataOptional = await this.validateAdId(
      await this.wizardDialogService.requestData(wizardConfig));
    const importFreeWallRequest = importDataOptional.map(request => this.toImportFreeWallRequest(request));
    return Optional.switchPromise(importFreeWallRequest.map(request => this.handleImportRequest(request)));
  }
}
