import { SubjectMap } from './SubjectMap';
import { Subject } from 'rxjs';

export function createSubjectMap<T, S extends Subject<any>>(
  keys: (keyof T)[],
  subjectFactory: <K extends keyof T>(key: K) => Subject<T[K]>
): SubjectMap<T, S> {
  return Object.fromEntries(
    keys.map((key) => [key, subjectFactory(key)])
  ) as SubjectMap<T, S>;
}
