import {Component, Input, OnInit, Output} from "@angular/core";
import {Meta} from "@rezonence/core/freewall/meta";
import {metaSchema} from "@rezonence/core";

import {ErrorObject} from "ajv";
import {ReplaySubject} from "rxjs";
import {ControlElement} from "@jsonforms/core";
import {allControl} from "../all.control";

@Component({
  selector: "app-intro",
  templateUrl: "./intro.component.html",
  styleUrls: ["./intro.component.css"]
})
export class IntroComponent implements OnInit {

  @Output()
  errors: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  @Input()
  meta: Meta;

  schema = metaSchema.properties.title;

  uiSchema: ControlElement = allControl;

  constructor() {
  }

  get title(): string {
    return this.meta.title;
  }

  set title(input: string) {
    this.meta.title = input;
  }

  ngOnInit() {

  }

}
