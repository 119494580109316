import {CSSConfig, IndexedConfig, ButtonStyle, CustomCSS} from "../config-extractor";
import {defaultButtonStyleValue} from "./default.button.style.value";
import {buttonSelectorPrefix} from "./button.selector.prefix";
import {buttonIndexSelector} from "./button.index.selector";
import {StyleOrder} from "../config-extractor/style.order";
import {cssPropertyByButtonStyleKey} from "./css.property.by.button.style.key";

export class CssConfigCompiler {

    // Assume that all number values correspond to pixels
    unitsByValueType = {
        number: "px"
    };

    toUnit(value: string | number): string {
        // Assume that unknown value types have no units associated with them
        return this.unitsByValueType[typeof value] || "";
    }

    toButtonPropertyString(key: keyof ButtonStyle, value: string | number): string {
        const cssKey = cssPropertyByButtonStyleKey[key];
        const prefix = `${cssKey}:${value}`;
        const unit = this.toUnit(value);
        // Apply the "important" rule to override the default FreeWall CSS
        return `${prefix}${unit}!important;`;
    }

    generateStyleString(style: Partial<ButtonStyle>): string {
        const buttonStyle = {
            ...defaultButtonStyleValue,
            ...style
        };

        return Object.keys(buttonStyle).reduce((styleString: string, key: keyof ButtonStyle) =>
            styleString + this.toButtonPropertyString(key, buttonStyle[key]), "");
    }

    toButtonIndexSelector(order: StyleOrder, index: number): string {
        // The button index starts at 1
        const buttonNumber = index + 1;
        // Don't apply an index selector if it's being applied to all the buttons
        return buttonNumber > 0 ? buttonIndexSelector[order] + buttonNumber : "";
    }

    toButtonSelector(order: StyleOrder, index: number): string {
        return buttonSelectorPrefix + this.toButtonIndexSelector(order, index);
    }

    compileButtonStyleEntry(order: StyleOrder, entry: IndexedConfig): string {
        const heading = this.toButtonSelector(order, entry.index);
        return [
            this.toBlock({heading, body: this.generateStyleString(entry.style)}),
            this.toBlock({heading: `${heading}:hover`, body: this.generateStyleString(entry.hover)})
        ].join("");
    }

    compileButtonStyles(config: Partial<Record<StyleOrder, IndexedConfig[]>>): string {
        return Object.values(StyleOrder).reduce((cssString, configKey) => {
            return cssString + (config[configKey] || []).reduce((sectionString, entry) =>
                sectionString + this.compileButtonStyleEntry(configKey, entry),
                "");
        }, "");
    }

    compileCustomCssEntry(entry: CustomCSS): string {
        const body = entry.entries.reduce((output, e) =>
                `${output + e.key}:${e.value};`,
            "");
        return this.toBlock({heading: entry.selector, body});
    }

    compileLegacyStyles(cssConfig: CSSConfig): string {
        const buttonStyles = this.compileButtonStyles(cssConfig);
        const customStyles = (cssConfig.custom || []).map(e => this.compileCustomCssEntry(e)).join("");
        return buttonStyles + customStyles;
    }

    compile(cssConfig: CSSConfig): string {
        return cssConfig.code || this.compileLegacyStyles(cssConfig);
    }

    private toBlock(request: {heading: string, body: string}): string {
        return `${request.heading}{${request.body}}`;
    }
}
