import {Directive, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Observable, ReplaySubject, Subscription} from "rxjs";
import {ErrorObject} from "ajv";
import {Category} from "../wizard/category";

@Directive({
  selector: "[wizardStep]"
})
export class StepDirective implements OnInit, OnDestroy {

  @Input()
  indexWithinCategory: number;
  @Input()
  link: boolean;
  @Input()
  mode: string;
  @Input()
  category: Category;
  @Input()
  hint: boolean;
  @Input()
  answerIndex: number;
  @Input()
  focus: string;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

}
