import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {ErrorObject} from "ajv";
import { type Ace } from "ace-builds";
import { CodeItem } from "@rezonence/core";
import { Language } from "@rezonence/code-editor";

/**
 * Responsible for loading custom code associated with a FreeWall in a code editor component
 */
@Component({
  selector: "app-code-editor",
  templateUrl: "./code-editor.component.html",
  styleUrls: ["./code-editor.component.css"]
})
export class CodeEditorComponent {

  @Input()
  data: CodeItem;

  @Input()
  mode: Language;

  @Input()
  recordId: string;

  @Input()
  placeholder: string;

  @Output()
  dataChange: EventEmitter<CodeItem> = new EventEmitter<CodeItem>();

  @Output()
  errors: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  async onTextChange(code: string) {
    this.data.code = code;
    this.dataChange.emit({...this.data});
  }

  collectErrors(errors: Ace.Annotation[]) {
    this.errors.next(errors.map(e => this.toErrorObject(e)));
  }

  private toErrorObject(aceError: Ace.Annotation): ErrorObject {
    const codeField: keyof CodeItem = "code";
    return {
      keyword: "",
      instancePath: `/${codeField}`,
      schemaPath: `#/${codeField}`,
      message: aceError.text,
      title: aceError.text,
      params: {},
      parentSchema: {
        title: `Custom ${this.mode}`
      }
    } as ErrorObject;
  }

}
