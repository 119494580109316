import { Directive } from "@angular/core";

@Directive({
  selector: "[gridCell]"
})
export class CellDirective {

  constructor() { }

}
