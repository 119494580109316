<div
  [fxHide]="hidden"
  fxLayout="column"
  fxLayoutAlign="center"
  class="enum-selector">
  <mat-form-field>
    <mat-label>{{label | formatLabel}}</mat-label>
    <mat-select (selectionChange)="onChange($event)"
                [value]="data"
                [id]="id">
      <mat-option *ngFor="let option of options; trackBy: trackElement" [value]="option">
        {{option | formatOption : responseType | titlecase}}
      </mat-option>
    </mat-select>
    <mat-error class="mat-caption">
      {{error}}
    </mat-error>
  </mat-form-field>
</div>

