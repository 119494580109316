import {Component, EventEmitter, OnInit, Output} from "@angular/core";

@Component({
  selector: "app-microsites-toolbar",
  templateUrl: "./microsites-toolbar.component.html",
  styleUrls: ["./microsites-toolbar.component.css"]
})
export class MicrositesToolbarComponent implements OnInit {

  @Output()
  createNewClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
