import {WizardMode} from "./wizard.mode";
import {Category} from "./category";
import {WizardModeKey} from "./wizard.mode.key";
import {Answer, FreeWallConfig} from "@rezonence/core";
import {FreeWallResponse} from "@rezonence/core/config-extractor";
import {defaultFreeWall} from "./default.freewall";

/**
 * This is the default FreeWall wizard mode and enables all the steps
 */
export class FullWizardMode implements WizardMode {


  readonly description: string = "Full";
  readonly key: WizardModeKey = WizardModeKey.Full;
  enableAnswerResponseMapping = true;

  shouldDisplay(_c: FreeWallConfig, _ca: Category, _i?: number): boolean {
    return true;
  }

  async createConfig(): Promise<FreeWallConfig> {
    return JSON.parse(JSON.stringify(defaultFreeWall));
  }

  createAnswer(config: FreeWallConfig): Answer {
    const question = config.questions[0];
    const answers =  question.answers;
    const responseIndex = answers.length;
    return {
      ans: "",
      tof: true,
      aln: null,
      rix: responseIndex,
      kix: responseIndex,
      trackers: []
    };
  }

  createResponse(_c: FreeWallConfig, _m: string): FreeWallResponse {
    return {
      ato: true,
      img: "",
      vid: "",
      ifs: ""
    };
  }

  isCompatible(config: FreeWallConfig): boolean {
    return config.questions.length === 1;
  }

}
