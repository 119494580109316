import {Component, Input, OnInit} from "@angular/core";
import {ShareLinkData} from "./share.link.data";
import {KeyValue} from "@angular/common";

@Component({
  selector: "app-share-link",
  templateUrl: "./share-link.component.html",
  styleUrls: ["./share-link.component.css"]
})
export class ShareLinkComponent implements OnInit {

  @Input()
  shareLinkData: ShareLinkData;

  constructor() { }

  ngOnInit() {

  }

  originalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return 0;
  }

}
