export enum GaEventAction {
    NoShows = "No Shows",
    Impressions = "Impressions",
    Views = "Views",
    BannerIframeClicks = "Banner Iframe Clicks",
    ResponseIframeClicks = "Response Iframe Clicks",
    KickerIframeClicks = "Kicker Iframe Clicks",
    MouseEnters = "Mouse Enters",
    Unlocks = "Unlocks",
    Transitions = "Transitions",
    Engagements = "Engagements",
    ResponseClicks = "Response Clicks",
    ResponseMouseEnters = "Response Mouse Enters",
    KickerViews = "Kicker Views",
    KickerClicks = "Kicker Clicks",
    KickerMouseEnters = "Kicker Mouse Enters",
    RightAnswers = "Right Answers",
    WrongAnswers = "Wrong Answers",
    Retries = "Retrys",
    ResponseVideoStart = "Response Video Start",
    ResponseVideoEnded = "Response Video Ended",
    KickerVideoStart = "Kicker Video Start",
    KickerVideoEnded = "Kicker Video Ended",
    ResponseViews = "Response Views"
}
