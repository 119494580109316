import type Ajv from "ajv";
import { type Schema } from "jsonschema";
import { ValidationError } from "./ValidationError";
import cloneDeep from "lodash/cloneDeep";

/**
 * A thin wrapper around AJV that throws a useful validation error
 */
export class SchemaValidator {

  constructor(public readonly ajv: Ajv) {
  }

  validate<T>(object: T, schema: Schema): Readonly<T> {
    const validator = this.ajv.compile(schema);
    const objectToTest = cloneDeep(object) as T;
    if (validator(objectToTest)) {
      return Object.freeze(objectToTest);
    }
    throw new ValidationError(this.ajv.errorsText(validator.errors))
  }
}
