import {NgModule} from "@angular/core";
import { MicrositePreviewComponent } from "./microsite-preview/MicrositePreviewComponent";
import {MicrositesComponent} from "./microsites/microsites.component";
import {CoreModule} from "../core/CoreModule";
import {MatExpansionModule} from "@angular/material/expansion";
import {CommonModule} from "@angular/common";
import {MicrositeConfigComponent} from "./microsite-config/MicrositeConfigComponent";
import { SchemaWizardModule } from "@rezonence/ng-schema-wizard";
import {MicrositeComponent} from "./microsite/microsite.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { FormsModule } from "@angular/forms";
import {MicrositeSelectorComponent} from "./microsite-selector/MicrositeSelectorComponent";
import {ResourceModule} from "../resource/ResourceModule";
import {MatButtonModule} from "@angular/material/button";
import {MicrositesToolbarComponent} from "./microsites-toolbar/microsites-toolbar.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MicrositesEditorComponent} from "./microsites-editor/MicrositesEditorComponent";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import {MicrositeSchemaPipe} from "./microsite-schema/MicrositeSchemaPipe";
import { FreeWallSchemaModule } from "../freewall/FreeWallSchemaModule";

@NgModule({
  imports: [
    CoreModule,
    MatExpansionModule,
    CommonModule,
    FreeWallSchemaModule,
    SchemaWizardModule,
    MatSlideToggleModule,
    FormsModule,
    ResourceModule,
    MatButtonModule,
    MatTooltipModule,
    MatToolbarModule,
    MatDialogModule,
    FlexLayoutModule,
    MatCardModule
  ],
  declarations: [
    MicrositePreviewComponent,
    MicrositesComponent,
    MicrositeConfigComponent,
    MicrositeComponent,
    MicrositeSelectorComponent,
    MicrositesToolbarComponent,
    MicrositesEditorComponent,
    MicrositeSchemaPipe,
  ],
  exports: [
    MicrositeSchemaPipe,
    MicrositesToolbarComponent,
    MicrositePreviewComponent,
    MicrositesComponent,
    MicrositesEditorComponent,
    MicrositeComponent,
  ]
})
export class MicrositeModule {

}
