import {Injectable} from "@angular/core";
import {JsonSchema, UISchemaElement} from "@jsonforms/core";
import {Optional} from "@rezonence/sdk";
import {ArrayItemTester} from "./array-item.tester";

@Injectable({
  providedIn: "root"
})
export class ArrayItemUiSchemaService {

  private providers: {tester: ArrayItemTester; uiSchema: UISchemaElement}[] = [];

  constructor() {
  }

  registerUiSchema(tester: ArrayItemTester, uiSchema: UISchemaElement) {
    this.providers.push({tester, uiSchema});
  }

  getUiSchema(dataPath: string, scopedSchema: JsonSchema): Optional<UISchemaElement> {
    return Optional.of(this.providers
      .filter(p => p.tester(dataPath, scopedSchema))
      .map(p => p.uiSchema).pop());
  }
}
