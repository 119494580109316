import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from "@angular/core";
import {Optional} from "@rezonence/sdk";

@Component({
  selector: "app-array-template",
  templateUrl: "./array-template.component.html",
  styleUrls: ["./array-template.component.css"]
})
export class ArrayTemplateComponent<T> {

  @Input()
  minItems: number;

  @Input()
  label: string;

  @Input()
  itemTitle: string;

  @Input()
  itemFactory: () => Promise<Optional<T>>;

  @ContentChild(TemplateRef, {static: false})
  itemTemplate: TemplateRef<any>;

  @Input()
  data: T[];

  @Output()
  dataChange: EventEmitter<T[]> = new EventEmitter<T[]>();

  @Output()
  itemRemoved: EventEmitter<number> = new EventEmitter<number>();

  selectedIndex: number;

  constructor() { }

  @Input()
  tabLabelMapper: (index: number, item: T) => string = index => `${index + 1}`;

  async add() {
    const itemOptional = await this.itemFactory();
    if (itemOptional.exists) {
      this.data = this.data || [];
      this.data.push(itemOptional.item);
      this.dataChange.emit(this.data);
      this.selectedIndex = this.data.length - 1;
    }
  }

  async remove(index: number) {
    this.data.splice(index, 1);
    this.dataChange.emit(this.data);
    this.itemRemoved.emit(index);
    this.selectedIndex = Math.min(this.data.length - 1, this.selectedIndex);
  }

  trackElement(index: number): number {
    return index;
  }

}
