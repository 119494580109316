import { Injectable } from "@angular/core";
import { ShareRequest } from "./share.request";
import { SharedRequest } from "./shared.request";
import { SharedStatus } from "./shared.status";
import { environment } from "../../environments/environment";
import { CreativeFileService } from "../core/CreativeFileService";
import { DestinationPrefix } from "@rezonence/sdk";
import { toDemoUrl } from "@rezonence/freewall-demo-client";
import { DemoAdIdResolver } from "../preview/DemoAdIdResolver";
import isEqual from "lodash/isEqual";
import { DemoType } from "../preview/DemoType";

@Injectable({
  providedIn: "root"
})
export class ShareService {

  constructor(private fileService: CreativeFileService,
    private adIdResolver: DemoAdIdResolver) {
  }

  toDestinationFolder(adId: string): string {
    return `${DestinationPrefix.Ads}/${adId}`;
  }

  async toDestinationFolders(recordId: string): Promise<Record<DemoType, string>> {
    const adIdBuilder = await this.adIdResolver.createAdIdBuilder();
    return Object.fromEntries(Object.values(DemoType).map(demoType => ([demoType, this.toDestinationFolder(adIdBuilder.build({ recordId, demoType }))]))) as Record<DemoType, string>;
  }

  async share(request: ShareRequest): Promise<string> {
    const demoFolders = await this.toDestinationFolders(request.recordId);
    const source = {
      bucket: environment.compileBucket,
      key: demoFolders.preview
    };
    const destination = {
      ...source,
      key: demoFolders.shared
    };
    await this.fileService.copy(source, destination,
      { makePublic: true, skipSame: true, parallel: true, overwrite: true });
    return this.getShareLink(request);
  }

  async getShareLink(request: SharedRequest): Promise<string> {
    return toDemoUrl({
      aid: await this.adIdResolver.toSharedAdId(request.recordId),
      url: request.sourceLink
    }).toString();
  }

  async isShared(request: SharedRequest): Promise<SharedStatus> {
    const shareLink = await this.getShareLink(request);
    const demoFolders = await this.toDestinationFolders(request.recordId);
    const previewS3Folder = {
      bucket: environment.compileBucket,
      key: demoFolders.preview,
    };
    const previewFiles = await this.fileService.list(previewS3Folder);
    const expectedFiles = previewFiles.map(f => ({
      ...f,
      key: f.key.replace(demoFolders.preview, demoFolders.shared)
    }))
    const expectedKeys = expectedFiles.map(f => f.key);
    const sharedFiles = await this.fileService.list({ ...previewS3Folder, key: demoFolders.shared });
    const expectedSharedFiles = sharedFiles.filter(f => expectedKeys.includes(f.key));
    const shared = previewFiles.length && isEqual(expectedFiles, expectedSharedFiles);
    return {
      shareLink,
      shared
    };

  }
}
