import {AndCondition, ControlElement, Rule, RuleEffect, SchemaBasedCondition} from "@jsonforms/core";
import {ControlElementRequest} from "./control.element.request";

export class UiSchemaService {
  fillControlElementRequest(input: ControlElementRequest): Required<ControlElementRequest> {
    return {
      scopePrefix: "#",
      state: true,
      ...input
    };
  }

  toScope(request: Required<ControlElementRequest>): string {
    return `${request.scopePrefix}/properties/${request.field}`;
  }

  toControlElement(request: ControlElementRequest): ControlElement {
    const fullRequest = this.fillControlElementRequest(request);
    return {
      type: "Control",
      scope: this.toScope(fullRequest)
    };
  }

  toHiddenControlElement(request: ControlElementRequest, keyField: string): ControlElement {
    const controlElement = this.toControlElement(request);
    if (request.field !== keyField) {
      controlElement.rule = {
        effect: RuleEffect.SHOW,
        condition: {
          schema: { required: [keyField] },
          scope: request.scopePrefix,
        } as SchemaBasedCondition
      };
    }
    return controlElement;
  }

  toMultiRuleControlElement(controlElement: ControlElement,
                            requests: ControlElementRequest[],
                            effect: RuleEffect): ControlElement {
    for (const request of requests) {
      if (controlElement.rule && controlElement.rule.condition
        && (controlElement.rule.condition as AndCondition).conditions) {
        (controlElement.rule.condition as AndCondition).conditions.push(this.toCondition(request));
      } else {
        controlElement.rule = {
          effect,
          condition: {
            type: "AND",
            conditions: [
              this.toCondition(request)
            ]
          } as AndCondition
        };
      }
    }
    return controlElement;
  }

  toRule(request: ControlElementRequest): Rule {
    return {
      effect: RuleEffect.SHOW,
      condition: this.toCondition(request)
    };
  }

  toCondition(request: ControlElementRequest): SchemaBasedCondition {
    const fullRequest = this.fillControlElementRequest(request);
    return {
      schema: {enum: [fullRequest.state]},
      scope: this.toScope(fullRequest)
    };
  }
}
