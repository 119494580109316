import {FreewallMetaPair} from "../../core/freewall.meta.pair";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IdMapperService {

  idMapper = (item: FreewallMetaPair) => item ? item.recordId : undefined;
}
