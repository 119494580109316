import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { PreviewComponent } from "./preview/PreviewComponent";
import { SchemaWizardModule } from "@rezonence/ng-schema-wizard";
import { CoreModule } from "../core/CoreModule";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { CommonModule } from "@angular/common";
import { DemoUrlComponent } from "./demo-url/DemoUrlComponent";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { ShareModule } from "../share/ShareModule";
import { MatListModule } from "@angular/material/list";
import { loadHookToken, saveHookToken } from "@rezonence/freewall-db-hooks";
import { DefaultDemoStripper } from "./DefaultDemoStripper";
import { ShareFreeWallDemoComponent } from "./ShareFreeWallDemoComponent";

@NgModule({
  imports: [
    SchemaWizardModule,
    CoreModule,
    MatProgressBarModule,
    MatButtonModule,
    FlexLayoutModule,
    CommonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    ShareModule,
    MatListModule
  ],
  exports: [
    PreviewComponent
  ],
  declarations: [
    PreviewComponent,
    DemoUrlComponent,
    ShareFreeWallDemoComponent,
  ],
  providers: [
    {
      provide: loadHookToken,
      useClass: DefaultDemoStripper,
      multi: true
    },
    {
      provide: saveHookToken,
      useClass: DefaultDemoStripper,
      multi: true
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PreviewModule { }
