import { Pipe, PipeTransform } from "@angular/core";
import {singular} from "pluralize";

@Pipe({
  name: "singular"
})
export class SingularPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value.endsWith("*") ? `${singular(value.slice(0, -1))}${value.slice(-1)}` : singular(value);
  }

}
