<div class="upload-container">
  <app-loading [loadingPromise]="loadingPromise" [progressMessage]="progressMessage" (ready)="ready = $event">
  </app-loading>
  <div *ngIf="ready">
    <mat-tab-group *ngIf="request.enableSwitchMode && request.resourceTypes.length > 1" [(selectedIndex)]="selectedTab">
      <mat-tab *ngFor="let resourceType of request.resourceTypes" [label]="resourceType | titlecase">
      </mat-tab>
    </mat-tab-group>
    <div class="upload-body">
      <input #fileInput hidden id="{{request.mode}}-upload" (change)="onFiles($event)" type="file" accept="{{accept}}" multiple>
      <label #uploadLabel class="upload-label">
        <button mat-flat-button color="primary" (click)="fileInput.click()">Upload {{request.mode}}</button>
      </label>
      <div *ngIf="existingResources | async | byType: request.mode | filterSelected : currentResourcePath as resources">
        <ng-container *ngIf="resources.length">
          <p>Or select an existing {{request.mode}}</p>
          <app-resources [resources]="resources"
                       (resourceClicked)="selectResource($event)">
          </app-resources>
        </ng-container>
      </div>
    </div>
  </div>
</div>
