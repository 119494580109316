<div fxFlexFill class="grid-viewport-container" appGridContainer>
  <ng-container *ngIf="gridService.numberOfColumns$ | async as numberOfColumns">
    <ng-container *ngIf="items | batch : numberOfColumns as batches;">
        <cdk-virtual-scroll-viewport [orientation]="'vertical'"
                                      fxFlexFill 
                                     [itemSize]="(itemHeight$ | async) || preferredWidth"
                                     [maxBufferPx]="500"
                                     [minBufferPx]="100"
                                     class="grid-viewport">
          <ng-container *cdkVirtualFor="let batch of batches; trackBy: trackBatch; let l = last;">
            <div appRowContainer [style.height.px]="itemHeight$ | async" class="row-container">
              <app-grid-row [items]="batch"
                            [idMapper]="idMapper"
                            [rowLength]="numberOfColumns"
                            [cellTemplate]="cellTemplate"
                            [gutter]="gutter"
                            [last]="l">
              </app-grid-row>
              <div [style.height.px]="gutter" class="gutter">
              </div>
            </div>
          </ng-container>
        </cdk-virtual-scroll-viewport>

    </ng-container>
  </ng-container>
</div>
