<ng-container
  *ngIf="freeWallId | datasetValues | async as values; else loading">
  <div
    class="wizard light-theme"
    fxLayout="row"
    *ngIf="values.freewall.data as config"
    fxFlexFill>
    <div
      class="steps divider-vertical"
      fxLayout="column"
      fxLayoutAlign="space-between"
      fxFlex.gt-md="0 0 50%"
      fxFlexFill>
      <mat-tab-group
        [(selectedIndex)]="selectedIndex"
        class="wizard-steps"
        headerPosition="below">
        <mat-tab
          [label]="'Title'"
          *ngIf="wizardService.mode.shouldDisplay($any(config), Category.Title)">
          <app-intro
            [meta]="$any(values.meta.data)"
            class="step"
            [indexWithinCategory]="0"
            wizardStep
            [category]="Category.Title"
            mode="banner"
            (errors)="collectErrors(Category.Title, 0, $event)">
          </app-intro>
        </mat-tab>
        <mat-tab
          [label]="'Media slot 1'"
          *ngIf="wizardService.mode.shouldDisplay($any(config), Category.Banner)">
          <app-banner
            [config]="$any(config)"
            [freeWallId]="freeWallId"
            class="step"
            [indexWithinCategory]="0"
            wizardStep
            [category]="Category.Banner"
            mode="banner"
            focus="banner"
            (errors)="collectErrors(Category.Banner, 0, $event)">
          </app-banner>
        </mat-tab>
        <mat-tab
          [label]="'Links'"
          *ngIf="wizardService.mode.shouldDisplay($any(config), Category.Links)">
          <app-links
            [config]="$any(config)"
            class="step"
            [indexWithinCategory]="0"
            wizardStep
            [category]="Category.Links"
            mode="banner"
            focus="links"
            (errors)="collectErrors(Category.Links, 0, $event)">
          </app-links>
        </mat-tab>
        <mat-tab
          label="Question"
          *ngIf="wizardService.mode.shouldDisplay($any(config), Category.Question)">
          <app-question
            [config]="$any(config)"
            class="step"
            [indexWithinCategory]="0"
            wizardStep
            [category]="Category.Question"
            mode="banner"
            focus="question"
            (errors)="collectErrors(Category.Question, 0, $event)">
          </app-question>
        </mat-tab>
        <mat-tab
          label="Answers"
          *ngIf="wizardService.mode.shouldDisplay($any(config), Category.Answers, 0)">
          <app-answers
            [config]="$any(config)"
            class="step"
            [validityMode]="false"
            wizardStep
            [indexWithinCategory]="0"
            [category]="Category.Answers"
            mode="banner"
            focus="answers"
            (errors)="collectErrors(Category.Answers, 0, $event)">
          </app-answers>
        </mat-tab>
        <mat-tab
          label="Answer validity"
          *ngIf="wizardService.mode.shouldDisplay($any(config), Category.Answers, 1)">
          <app-answers
            [config]="$any(config)"
            class="step"
            [validityMode]="true"
            [indexWithinCategory]="1"
            wizardStep
            [category]="Category.Answers"
            mode="banner"
            focus="answers"
            (errors)="collectErrors(Category.Answers, 0, $event)">
          </app-answers>
        </mat-tab>
        <mat-tab
          label="Hint"
          *ngIf="isQuiz($any(config)) && wizardService.mode.shouldDisplay($any(config), Category.Answers, 2)">
          <app-hint
            [config]="$any(config)"
            [indexWithinCategory]="2"
            wizardStep
            [category]="Category.Answers"
            mode="banner"
            [hint]="true"
            focus="hint"
            (errors)="collectErrors(Category.Answers, 2, $event)">
          </app-hint>
        </mat-tab>
        <ng-container
          *ngFor="let answer of $any(config)?.questions[0]?.answers; let i = index;">
          <ng-container *ngIf="Category.Responses as category">
            <ng-container *ngIf="answer.tof">
              <ng-container
                *ngIf="wizardService.mode.shouldDisplay($any(config), category, 2 * i)">
                <mat-tab [label]="'Media slot 2'">
                  <app-responses
                    [mode]="ResponseMode.response"
                    [config]="$any(config)"
                    class="step"
                    [indexWithinCategory]="2 * i"
                    [answerIndex]="i"
                    wizardStep
                    [category]="category"
                    [freeWallId]="freeWallId">
                  </app-responses>
                </mat-tab>
              </ng-container>
              <ng-container *ngIf="(2 * i) + 1 as kickerCategoryIndex">
                <ng-container
                  *ngIf="wizardService.mode.shouldDisplay($any(config), category, kickerCategoryIndex)">
                  <mat-tab [label]="'Media slot 3'">
                    <app-responses
                      [mode]="ResponseMode.kicker"
                      [config]="$any(config)"
                      class="step"
                      [indexWithinCategory]="kickerCategoryIndex"
                      wizardStep
                      [category]="category"
                      [answerIndex]="i"
                      [freeWallId]="freeWallId">
                    </app-responses>
                  </mat-tab>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <mat-tab
          label="Share"
          *ngIf="wizardService.mode.shouldDisplay($any(config), Category.Share)">
          <app-share
            [recordId]="freeWallId"
            [sourceLink]="$any(values.meta).demoSourceUrl || defaultDemoLink"
            [configHash]="lastConfigHash"
            wizardStep
            [category]="Category.Share"
            mode="preview"
            [indexWithinCategory]="0">
          </app-share>
        </mat-tab>
      </mat-tab-group>
      <div class="controls">
        <div class="divider-horizontal"></div>
        <button
          mat-flat-button
          class="previous-button"
          color="primary"
          (click)="previous()"
          [disabled]="selectedIndex <= 0">
          <mat-icon>chevron_left</mat-icon>
          <span>{{previousMessage}}</span>
        </button>
        <button
          mat-flat-button
          class="continue-button"
          color="primary"
          (click)="continueWizard($any(config), $any(values.meta.data), freeWallId)"
          [disabled]="!!(currentErrors && currentErrors.length)">
          <span>{{continueMessage}}</span>
          <mat-icon>chevron_right</mat-icon>
        </button>
        <wizard-nav
          [tabs]="steps"
          [(currentIndex)]="selectedIndex"
          [stepErrors]="errorsByStep"
          fxHide.lt-md>
        </wizard-nav>
      </div>
    </div>

    <div class="preview" fxFlex.gt-md="0 0 50%" fxHide.lt-lg>
      <app-creator-preview
        [config]="$any(config)"
        [topInstructions]="topInstructions"
        [bottomInstructions]="bottomInstructions"
        [freewallId]="freeWallId"
        [css]="$any(values.css.data)"
        [microsites]="$any(values.microsites.data)"
        [focus]="focus"
        [mode]="mode"
        [hint]="hint"
        [responseIndex]="toResponseIndex($any(config))"
        [demoPage]="$any(values.meta).demoSourceUrl">
      </app-creator-preview>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
</ng-template>
