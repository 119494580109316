<div class="container">
  <div *ngIf="enableSharing" class="toolbar">
    <app-demo-url
      [recordId]="compileRequestMap$.recordId | async"
      [demoUrl]="demoPage$ | async"
      (demoUrlChange)="demoPageChange.next($event)"
      (shareClick)="shareLink.next($event)"
      (onReload)="reloadIframe()">
    </app-demo-url>
  </div>

  <ng-container *ngIf="compilation$ | async as promise">
    <ng-container *ngIf="promise | async; else errors">
      <iframe #previewIframe [src]="freeWallPreviewUrl$ | async | safe"></iframe>
    </ng-container>
    <ng-template #errors>
      <ng-container *ngIf="errors$ | async as errors; else loading" class="errorModal">
        <ul *ngIf="errors.length">
          <li *ngFor="let error of errors" [style.color]="'warn'">
            {{ error }}
          </li>
        </ul>
      </ng-container>
    </ng-template>
    <ng-template #loading>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>
  </ng-container>
</div>
