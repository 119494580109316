import { Injectable } from "@angular/core";
import { Observable, Subscription, debounceTime, lastValueFrom, combineLatest } from "rxjs";
import { concatMap, filter, map, shareReplay, take, tap } from "rxjs/operators";
import { MagicLinkService } from "./MagicLinkService";
import { ExchangeTokenResponse, User, tokenDurationSeconds } from "@rezonence/magic-link";
import { Optional } from "@rezonence/sdk";
import { MatDialog } from "@angular/material/dialog";
import { Duration } from "@rezonence/duration";
import { LoginDialogComponent } from "./LoginDialogComponent";
import localForage from "localforage";
import { lastEmailKey } from "./lastEmailKey";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  readonly user$: Observable<Optional<User>>;
  readonly userProfile$: Observable<User>;
  readonly token$: Observable<Optional<ExchangeTokenResponse>>;
  protected readonly tokenRefreshSubscription: Subscription;

  constructor(private magicLink: MagicLinkService,
    private dialog: MatDialog) {
    this.token$ = magicLink.token$;
    this.user$ = this.token$.pipe(
      concatMap(() => this.magicLink.userMetadata()),
      shareReplay(1)
    );
    this.userProfile$ = this.user$.pipe(
      filter(user => user.exists),
      map(optionalProfile => optionalProfile.item)
    );
    this.tokenRefreshSubscription = this.token$.pipe(
      debounceTime((tokenDurationSeconds * Duration.MsInSecond) * 0.8),
      filter(token => token.exists),
      concatMap(() => this.magicLink.refreshToken()),
      filter(token => !token.exists),
      concatMap(() => this.requestLogin(true))
    ).subscribe();

  }

  async getLastEmail(): Promise<Optional<string>> {
    const email = await localForage.getItem<string>(lastEmailKey);
    return Optional.of(email);
  }

  async login(email: string): Promise<Optional<ExchangeTokenResponse>> {
    const response = await this.magicLink.authenticate({ email });
    await localForage.setItem(lastEmailKey, email);
    return response;
  }

  async requestLogin(disableClose = false): Promise<Optional<ExchangeTokenResponse>> {
    const loginDialog = this.dialog.open(LoginDialogComponent, {
      disableClose,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal'
    });
    const lastEmail = await this.getLastEmail();
    loginDialog.componentInstance.displayLogin = true;
    if (lastEmail.exists) {
      loginDialog.componentInstance.email = lastEmail.item;
    }

    return lastValueFrom(loginDialog.componentInstance.submit.pipe(
      filter(email => !!email),
      tap(() => { loginDialog.componentInstance.displayLogin = false }),
      concatMap(email => this.login(email)),
      tap(() => loginDialog.close()),
      take(1)
    ));
  }

  async logout() {
    await this.magicLink.signOut();
  }

}
