import type { Ace } from "ace-builds";
import {LanguageOptions} from "./language.options";

export const defaultEditorOptions: Ace.EditorOptions & LanguageOptions = {
    highlightActiveLine: true,
    minLines: 5,
    maxLines: Infinity,
    printMargin: false,
    wrap: "free",
    fontSize: 14,
    enableBasicAutocompletion: false,
    enableSnippets: true,
    enableLiveAutocompletion: true
} as Ace.EditorOptions & LanguageOptions;
