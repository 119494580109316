import { Injectable } from "@angular/core";
import { User } from "@rezonence/magic-link";
import { Optional } from "@rezonence/sdk";
import { distinctUntilChanged, map, Observable } from "rxjs";
import { AuthService } from "../authenticator";
import { Mode } from "./Mode";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ModeResolver {

    readonly mode$: Observable<Mode>;

    constructor(authService: AuthService) {
        this.mode$ = authService.user$.pipe(
            map(user => this.toMode(user)),
            distinctUntilChanged(),
        );
    }

    toMode(user: Optional<User>): Mode {
        return user.map(u => this.toUserMode(u), () => Mode.Basic).item;
    }

    toUserMode(user: User): Mode {
        const userDomain = user.email.split("@").pop();
        return Optional.truthy(environment.companyDomains.includes(userDomain)).map(() => Mode.Advanced, () => Mode.Basic).item;
    }

}