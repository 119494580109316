import { angularMaterialRenderers } from "@jsonforms/angular-material";
import { ArrayRendererComponent } from "./array-renderer/array-renderer.component";
import { arrayRendererTester } from "./array-renderer/array-renderer.tester";
import { ColourRendererComponent } from "./colour-renderer/colour-renderer.component";
import { colourRendererTester } from "./colour-renderer/colour-renderer.tester";
import { ObjectRendererComponent } from "./object-renderer/object-renderer.component";
import { objectRendererTester } from "./object-renderer/object-renderer.tester";

export const renderers = [
    ...angularMaterialRenderers,
    {
      tester: arrayRendererTester,
      renderer: ArrayRendererComponent
    },
    {
      tester: colourRendererTester,
      renderer: ColourRendererComponent
    },
    {
      tester: objectRendererTester,
      renderer: ObjectRendererComponent
    }
]
