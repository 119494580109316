import { escapeRegExp } from './escapeRegExp'

/**
 * A compilation process will substitute specific keys like [KEY]
 * and replace them with appropriate string content
 */
export enum StringFromTemplating {
  /**
   * The publisher or ad config (depending on context)
   */
  Config = '[CFG]',

  /**
   * Custom CSS for the FreeWall
   */
  Css = '[CSS]',

  /**
   * The (Git) branch this build is using
   */
  Branch = '[BRANCH]',

  /**
   * The codebase version this build is using
   */
  Version = '[VERSION]'
}

/**
 * Takes a StringFromTemplating and returns a safe regex that can be used to search globally
 * @param input
 */
export function globalRegexFor(input: StringFromTemplating): RegExp {
  return new RegExp(escapeRegExp(input), 'g')
}
