import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "formatOption"})
export class FormatOptionPipe implements PipeTransform {
  transform(option: number, responseType: string): string {
    return option === -2
      ? "None"
      : option === -1
        ? "Random"
        : `${responseType} ${option + 1}`;
  }
}
