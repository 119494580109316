<ng-container *ngIf="microsite">
  <ng-container *ngIf="microsite.config as micrositeConfig">
    <ng-container *ngIf="microsite.templateId | micrositeSchema | async as micrositeSchema">
      <rez-json-form [(data)]="microsite.config"
                     [schema]="micrositeSchema.schema"
                     [uiSchema]="uiSchema">
      </rez-json-form>
    </ng-container>
  </ng-container>

</ng-container>



