import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GridComponent} from "./grid/grid.component";
import {GridRowComponent} from "./grid-row/grid-row.component";
import {BatchPipe} from "./batch/batch.pipe";
import {FlexLayoutModule} from "@angular/flex-layout";
import {CellDirective} from "./cell/cell.directive";
import {NumberOfColumnsPipe} from "./number-of-columns/number-of-columns.pipe";
import {RowContainerDirective} from "./row-container/row-container.directive";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {GridContainerDirective} from "./grid-container/grid-container.directive";

@NgModule({
  declarations: [
    GridComponent,
    GridRowComponent,
    BatchPipe,
    CellDirective,
    NumberOfColumnsPipe,
    RowContainerDirective,
    GridContainerDirective
  ],
  exports: [
    GridComponent,
    CellDirective
  ],
  imports: [
    FlexLayoutModule,
    ScrollingModule,
    CommonModule
  ]
})
export class GridModule {
}
