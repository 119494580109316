import {PascalCase, CamelCase} from "type-fest";
import camelCase from "lodash/camelCase";
import { pascalCase } from "./pascalCase";

export class CaseTransformer {

    /**
     * Transform an array of strings into a record where the keys are "PascalCase" versions of the values
     * e.g. ["fooBar", "baz-foo"] => { FooBar: "fooBar", BazFoo: "baz-foo"}
     *
     * @param input
     */
    toPascalCaseKeyPairs<T extends string>(input: T[]): Record<PascalCase<T>, string> {
        return input.reduce((record, value) => ({
            ...record,
            [this.toPascalCase(value)]: value
        }), {} as Record<PascalCase<T>, string>);
    }

    /**
     * Transform a "kebab-case", "snake_case" or "camelCase" string into a "PascalCase" one
     * @param input
     */
    toPascalCase<T extends string>(input: T): PascalCase<T> {
       return pascalCase(input);
    }

    /**
     * Transform a "kebab-case", "snake_case" or "PascalCase" string into a "camelCase" one
     * @param input
     */
    toCamelCase<T extends string>(input: T): CamelCase<T> {
        return camelCase(input) as CamelCase<T>;
    }
}
