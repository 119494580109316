import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "login-dialog",
    template: `
    <div class="yg-bright" fxFlexFill fxLayoutAlign="center center" fxLayout="column">
        <mat-card class="login-card" fxLayout="column" fxLayoutGap="30px" *ngIf="displayLogin">
            <img src="/assets/YouGov-Logo_Grapefruit.png" alt="YouGov logo" class="logo">
            <h1 mat-card-title>Please enter your email address to continue</h1>
            <div mat-card-content>
                <login (onSubmit)="onSubmit($event)" [email]="email"></login>
            </div>
            <div class="mat-small">
              We will use the information provided in this form to respond to your request
              and to send you other information about YouGov's products and services by email.
              You can opt-out of receiving marketing communications at any time by
              <a
                href="https://commercial.yougov.com/global-unsubscribe.html"
                target="_blank"
              >clicking here</a>,
              and a link to unsubscribe will be included in each email.
              You can read more about how we use your data in our
              <a
                href="https://platform.yougov.com/legal/privacy-notice"
                target="_blank"
              >privacy notice</a>.
            </div>
        </mat-card>
    </div>

  `,
  styleUrls: [
    "./LoginDialogComponent.css"
  ]
})
export class LoginDialogComponent {

    @Input()
    email: string;

    @Input()
    displayLogin: boolean;

    @Output()
    submit: EventEmitter<string> = new EventEmitter();

    constructor(public dialogRef: MatDialogRef<LoginDialogComponent>) {
    }

    onSubmit(email: string) {
        this.submit.emit(email);
    }
}
