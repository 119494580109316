import {Injectable} from "@angular/core";
import {CreativeFileService} from "../../core/CreativeFileService";
import {ConfigState} from "./config.state";
import { DestinationPrefix, FreewallFilenamePrefix, Optional } from "@rezonence/sdk";
import {TemplateFileSuffix} from "@rezonence/core/freewall-compiler/template.file.suffix";
import { environment } from "../../../environments/environment";
import {PrefixStatePair} from "./prefix.state.pair";

@Injectable({
  providedIn: "root"
})
export class ConfigIdStateService {

  schemaFormatSuffixByDestinationPrefix: Record<DestinationPrefix, string> = {
    [DestinationPrefix.Ads]: "AdId",
    [DestinationPrefix.Pub]: "ConfigId"
  };

  fileNamePrefixByDestinationPrefix: Record<DestinationPrefix, FreewallFilenamePrefix> = {
    [DestinationPrefix.Ads]: FreewallFilenamePrefix.Ad,
    [DestinationPrefix.Pub]: FreewallFilenamePrefix.Pb
  };

  prefixStatePairBySchemaFormat: Record<string, PrefixStatePair>;

  constructor(private fileService: CreativeFileService) {
    this.prefixStatePairBySchemaFormat = {};
    for (const prefix of Object.values(DestinationPrefix)) {
      for (const state of Object.values(ConfigState)) {
        const pair = {
          prefix,
          state
        };
        const value = this.toSchemaFormat(pair);
        this.prefixStatePairBySchemaFormat[value] = pair;
      }
    }
  }

  toSchemaFormat(pair: PrefixStatePair): string {
     return pair.state + this.schemaFormatSuffixByDestinationPrefix[pair.prefix];
  }

  toPrefixAndState(schemaFormat: string): Optional<PrefixStatePair> {
    return Optional.of(this.prefixStatePairBySchemaFormat[schemaFormat]);
  }

  toKey(prefix: DestinationPrefix, configId: string): string {
    const fileNamePrefix = this.fileNamePrefixByDestinationPrefix[prefix];
    return `${prefix}/${configId}/${fileNamePrefix}${TemplateFileSuffix.Conf}`;
  }

  async getState(prefix: DestinationPrefix,
                 configId: string): Promise<ConfigState> {
    const key = this.toKey(prefix, configId);
    const exists = await this.fileService.isFile({
      key,
      bucket: environment.compileBucket
    });
    return exists ? ConfigState.Existing : ConfigState.New;
  }
}
