import {Component, EventEmitter, Input, OnInit, Output, QueryList} from "@angular/core";
import {StepDirective} from "../step/step.directive";
import {Category} from "../wizard/category";
import {ErrorsByStep} from "../step/errors.by.step";

@Component({
  selector: "wizard-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"]
})
export class NavComponent implements OnInit {

  icons: Record<string, string>;

  @Input()
  stepErrors: ErrorsByStep;

  @Output()
  currentIndexChange: EventEmitter<number>;

  @Input()
  tabs: QueryList<StepDirective>;

  private _currentIndex: number;

  constructor() {

    this.currentIndexChange = new EventEmitter();
    this.icons = {
      [Category.Title]: "title",
      [Category.Banner]: "panorama",
      [Category.Links]: "near_me",
      [Category.Question]: "help_outline",
      [Category.Answers]: "format_list_numbered",
      [Category.Responses]: "photo_library",
      [Category.Share]: "share"
    };
  }

  isEnabled(category: Category): boolean {
    const tabsArray = this.tabs.toArray();
    const firstCategoryStepIndex = tabsArray.findIndex(t => t.category === category);
    return !tabsArray.slice(0, firstCategoryStepIndex)
      .find(step => this.stepErrors.getErrorsForStep(step.category, step.indexWithinCategory).length);
  }

  @Input()
  set currentIndex(index: number) {
    this._currentIndex = index;
    this.currentIndexChange.emit(index);
  }

  get currentIndex(): number {
    return this._currentIndex;
  }

  selectTab(category: Category) {
    const tabs = this.tabs.toArray();
    const index = tabs.findIndex(tab => tab.category === category);
    this.currentIndex  = index;
  }

  get categories(): Category[] {
    return this.tabs
      .filter((tab, index, arr) =>
        // Only keep the unique categories
       arr.findIndex(otherTab => otherTab.category === tab.category) === index
    ).map(tab => tab.category);
  }

  displayTick(category: Category): boolean {
    const tabs = this.tabs.toArray();

    const tickedCategories = tabs.filter((tab, index) => index < this.currentIndex).map(tab => tab.category);

    return tickedCategories.indexOf(category) > -1;

  }

  displayTab(index: number): boolean {

    const tabs = this.tabs.toArray();
    const previousTab = tabs[index - 1];

    if (!previousTab) {

      return true;
    } else {

      const currentTab = tabs[index];

      if (currentTab.category !== previousTab.category) {

        return true;
      }

    }

    return false;
  }

  selected(category: string): boolean {
    const tabs = this.tabs.toArray();
    const selectedTab = tabs[this.currentIndex];
    return category === selectedTab.category;
  }

  complete(category: Category): boolean {
    const tabs = this.tabs.toArray();

    const completeCategories = tabs.filter((tab, index) => index  <= this.currentIndex).map(tab => tab.category);

    return completeCategories.indexOf(category) > -1;
  }

  ngOnInit() {
  }

}
