import {
  isObjectArrayControl,
  isPrimitiveArrayControl,
  or,
  RankedTester,
  rankWith,
} from "@jsonforms/core";

export const arrayRendererTester: RankedTester = rankWith(6,
  or(isObjectArrayControl, isPrimitiveArrayControl));
