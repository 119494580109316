<div class="wizard-nav">
  <div *ngIf="tabs" class="wizard-nav-tabs">
    <div *ngFor="let category of categories; let ci = index" [class.selected]="complete(category)" class="nav-tab">
      <button mat-button class="nav-button" (click)="selectTab(category)" [disabled]="!isEnabled(category)">
        <div>
          <mat-icon>{{icons[category]}}</mat-icon>
        </div>
        <div>
          {{category}}
        </div>
        <div *ngIf="selected(category)">
          <ng-container *ngFor="let tab of tabs; let i = index">
            <div *ngIf="selected(tab.category)" [class.selected]="i <= currentIndex" class="pip">
            </div>
          </ng-container>
        </div>
      </button>
    </div>

  </div>
</div>

