<ng-container *ngIf="recordId | datasetValues | async as values">
  <ng-container *ngIf="datasets$ | async as datasets">
    <div
      class="editor-container"
      [class.vertical]="vertical"
      [class.full-height]="fullHeight$ | async"
      fxLayout="column"
      fxLayoutAlign="start stretch">
      <app-editor-toolbar
        [recordId]="recordId"
        [dataset]="dataset"
        [values]="values"
        [actionLabel]="actionLabel"
        [errors]="errors | async"
        (saved)="freeWallSaved.emit()"></app-editor-toolbar>
      <div class="editor-body" #editorBody>
        <mat-tab-group
          *ngIf="vertical || !preview"
          [selectedIndex]="datasets.indexOf(dataset)"
          (selectedTabChange)="tabChanged(datasets, $event)"
          fxFlex.lt-lg="0 0 100%"
          fxFlex.gt-md="0 0 50%"
          [ngClass.gt-md]="'split'">
          <ng-container *ngFor="let d of datasets">
            <mat-tab [label]="d | datasetLabel">
              <ng-container *ngIf="values[d] as dataSchemaPair">
                <app-data-editor
                  [recordId]="recordId"
                  [dataset]="d"
                  [schema]="dataSchemaPair.schema"
                  [(data)]="dataSchemaPair.data"
                  (errors)="errorInput[d].next($event)">
                </app-data-editor>
              </ng-container>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
        <app-preview
          [recordId]="recordId"
          [enableSharing]="true"
          [demoPage]="values.meta.data.demoSourceUrl"
          (demoPageChange)="values.meta.data.demoSourceUrl = $event"
          [microsites]="values.microsites.data"
          [config]="values.freewall.data"
          [css]="values.css.data"
          [customCode]="values.customCode.data"
          (shareLink)="demoShared(recordId, values.meta.data, $event)"
          fxFlex.gt-md="0 0 50%"
          fxHide.lt-lg>
        </app-preview>
      </div>
    </div>
  </ng-container>
</ng-container>
