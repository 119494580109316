import { ReplaySubject } from 'rxjs';
import { SubjectMap } from './SubjectMap';
import { createSubjectMap } from './createSubjectMap';

export function createReplaySubjectMap<T>(
  keys: (keyof T)[]
): SubjectMap<T, ReplaySubject<any>> {
  return createSubjectMap<T, ReplaySubject<any>>(
    keys,
    () => new ReplaySubject(1)
  );
}
