<div class="intro" *ngIf="meta">
  <h3>
    Title
  </h3>
  <p>
    Hello, welcome to the FreeWall builder wizard. Follow these simple steps to create your FreeWall, and don’t worry,
    we’ll be giving you hints and tips the whole way through to make sure you get it right.
  </p>
  <p>
    Let’s start with a title for your FreeWall.
  </p>
  <rez-json-form [(data)]="title" [schema]="schema" [uiSchema]="uiSchema" (errors)="errors.next($event)">
  </rez-json-form>

</div>


