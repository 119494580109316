<div *ngIf="config">
  <h3>
    <ng-container *ngIf="singleResponse && !wizardService.mode.enableAnswerResponseMapping; else multiResponseTitle">
      Media slot {{slotIndex}}
    </ng-container>
    <ng-template #multiResponseTitle>Answer {{answerIndex +1}} {{slotPosition}} media slot</ng-template>
  </h3>
  <p *ngIf="!singleResponse && answer.ans"><strong>Answer text:&nbsp;&nbsp;</strong>{{answer.ans}}<br><br></p>
  <p *ngIf="top">Select the image you'd like to present at the top of the page when the user clears the FreeWall<ng-container *ngIf="!singleResponse"> by clicking on answer {{answerIndex +1}}</ng-container>.</p>
  <p *ngIf="!top">Select the image you'd like to present at the bottom of the page<ng-container *ngIf="!singleResponse"> when the clicks on answer {{answerIndex +1}}</ng-container>.</p>
  <br>
  <div *ngIf="answer.tof">
    <mapper [answer]="answer"
            [config]="config"
            [mode]="mode"
            [freeWallId]="freeWallId"
            [advanced]="advanced">
    </mapper>
  </div>
  <div *ngIf="!answer.tof">
    <p>
      Answer {{answerIndex + 1 }} is incorrect so no {{mode}} will be shown
    </p>
  </div>
</div>

