import {FunctionNameResolver} from "@rezonence/serverless";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class FunctionNameService extends FunctionNameResolver {
  constructor() {
    super();
  }
}
