import {Pipe, PipeTransform} from "@angular/core";
import {basename} from "path";

@Pipe({
  name: "fileName"
})
export class FileNamePipe implements PipeTransform {
  transform(filePath: string) {
    return basename(filePath);
  }

}
