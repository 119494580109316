import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Microsite } from "@rezonence/core";
import {defaultUiSchema} from "../../data-editor/ui-schema/default.ui.schema";

@Component({
  selector: "app-microsite-config",
  templateUrl: "./microsite-config.component.html",
  styleUrls: ["./microsite-config.component.css"]
})
export class MicrositeConfigComponent implements OnInit {

  get microsite(): Microsite {
    return this._microsite;
  }

  @Input()
  set microsite(microsite: Microsite) {
    if (JSON.stringify(microsite) !== JSON.stringify(this._microsite)) {
      this._microsite = microsite;
      this.micrositeChange.emit(microsite);
    }
  }
  uiSchema = defaultUiSchema;

  @Output()
  micrositeChange: EventEmitter<Microsite> = new EventEmitter<Microsite>();

  private _microsite: Microsite;

  constructor() {
  }

  ngOnInit(): void {
  }

}
