export enum FreewallCookie {

    /**
     * Number of answers clicked
     */
    AnswersClicked = "nac",

    /**
     * Number of retries clicked
     */
    RetriesClicked = "ntc",

    /**
     * Impressions on a site
     */
    PublisherImpressions = "nip",
    
    /**
     * Views on a site
     */
    PublisherViews = "nvp",

    /**
     * Represents number of Freewalls cleared on a publisher
     */
    FreewallsCleared = "ncp",
    
    /**
     * Number of times the tag has been delivered
     */
    TagsDelivered = "ntd", 
    
    /**
     * Number of articles given initially for free
     */
    InitialArticles = "nif"
}
