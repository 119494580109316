import { CompileFreewallRequest } from "../api";
import { ParsedCompileFreewallRequest } from "./parsed.compile.freewall.request";
import defaults from "json-schema-defaults";
import { schemaOf } from "@rezonence/schema-transformer";
import merge from "lodash/merge";
import { LocalFile } from "@jabrythehutt/fs-s3-core";

export class RequestParser {

  constructor(private fallbackVersion: string) {
  }

  schemaDefaults = defaults(schemaOf<CompileFreewallRequest<LocalFile>>());

  parse<T extends LocalFile>(input: Readonly<CompileFreewallRequest<T>>): ParsedCompileFreewallRequest<T> {
    const mergedConfig = merge({
      config: {
        version: this.fallbackVersion
      }
    }, this.schemaDefaults, input);
    return Object.freeze(mergedConfig);
  }
}
