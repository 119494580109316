import { WizardMode } from "../wizard/wizard.mode";
import { WizardModeKey } from "../wizard/wizard.mode.key";
import { Answer, FreeWallConfig, FreeWallResponse } from "@rezonence/core";
import { Category } from "../wizard/category";
import { defaultFreeWall } from "../wizard/default.freewall";
import cloneDeep from "lodash/cloneDeep";
import { generateDataConfigSchema } from "@rezonence/freewall-creator-config";

export class SimpleOpinionWizardMode implements WizardMode {

  readonly description: string = "Simple opinion";
  readonly key: WizardModeKey = WizardModeKey.SimpleOpinion;
  enableAnswerResponseMapping = false;

  async createConfig(): Promise<FreeWallConfig> {
    const config = cloneDeep(defaultFreeWall) as FreeWallConfig;
    for (const question of config.questions) {
      question.hnt = generateDataConfigSchema.properties.hint.default;
    }
    config.kickers = [];
    config.responses = [config.responses[0]];
    const answers = config.questions[0].answers;
    for (const answer of answers) {
      // Set each answer to not have any kickers
      answer.kix = -2;
      // Map every answer to the first response
      answer.rix = 0;
      // Set all answers to be correct
      answer.tof = true;
    }
    return config;

  }

  shouldDisplay(config: FreeWallConfig, category: Category, indexWithinCategory?: number): boolean {

    // Disable the banner link and preview tab
    const disabledCategories = [
      Category.Links,
    ];

    // Only permit answer text input (first "Answers" tab) and editing the first response (first "Responses" tab)
    const firstIndexOnlyCategories = [
      Category.Answers,
    ];

    if (disabledCategories.includes(category)) {
      return false;
    }

    if (firstIndexOnlyCategories.includes(category) && indexWithinCategory > 0) {
      return false;
    }

    if (category === Category.Responses) {
      const firstCorrectAnswerIndex = config.questions[0].answers.findIndex(a => a.tof);
      const responseTabIndex = firstCorrectAnswerIndex * 2;
      return responseTabIndex === indexWithinCategory;
    }

    return true;
  }

  createAnswer(): Answer {
    return {
      ans: "",
      tof: true,
      // Point all answers to the first response
      rix: 0,
      // Disable kickers
      kix: -2,
      trackers: []
    };
  }

  createResponse(_: FreeWallConfig, _1: string): FreeWallResponse {
    return {
    };
  }

  isImageBanner(config: FreeWallConfig): boolean {
    return !config.iframe && !!config.image && !!config.image.img
  }

  isCompatible(config: FreeWallConfig): boolean {
    return this.isImageBanner(config) && config.questions.length === 1 && config.questions[0].answers.every(ans => ans.tof);
  }

}
