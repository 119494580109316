import {DashboardRequest} from "../api/dashboard.request";
import {ParsedDashboardRequest} from "./parsed.dashboard.request";
import defaults from "json-schema-defaults";
import {schemaOf} from "@rezonence/schema-transformer";
import merge from "lodash/merge";

export class DashboardRequestParser {

    schemaDefaults = defaults(schemaOf<DashboardRequest>());

    parse(request: DashboardRequest): ParsedDashboardRequest {
        const mergedConfig = merge({}, this.schemaDefaults, request);
        return Object.freeze(mergedConfig);
    }
}
