import {Pipe, PipeTransform} from "@angular/core";
import {ResourceService} from "./ResourceService";

@Pipe({
  name: "resourceLink"
})
export class ResourceLinkPipe implements PipeTransform {

  constructor(private resourceService: ResourceService) {
  }

  async transform(link: string, freewallId: string): Promise<string> {
    return link ? await this.resourceService.renderLinkMacros({
      link,
      freewallId
    }) : link;
  }

}
