import {Component, OnInit} from "@angular/core";
import {JsonFormsAngularService, JsonFormsControl} from "@jsonforms/angular";
import {JsonFormsState} from "@jsonforms/core";
import {FreeWallConfig} from "@rezonence/core";
import {ResponseType} from "./response-type";
import {Dataset} from "@rezonence/core";
import { JsonFormService } from "@rezonence/ng-json-form";

@Component({
  selector: "app-response-selector-renderer",
  templateUrl: "./response-selector-renderer.component.html",
  styleUrls: ["./response-selector-renderer.component.css"]
})
export class ResponseSelectorRendererComponent extends JsonFormsControl implements OnInit {

  responseType: string;

  Dataset = Dataset;

  constructor(protected jsonFormsService: JsonFormsAngularService,
              private formService: JsonFormService) {
    super(jsonFormsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.responseType = ResponseType[this.propsPath.split(".").pop()];
  }

  get freeWallConfig(): FreeWallConfig {
    return this.formService.getValue<FreeWallConfig>(this.jsonFormsService);
  }

  trackElement(index: number, _element: any): number {
    return index ? index : null;
  }

  get options(): number[] {
    const responses = this.freeWallConfig[`${this.responseType}s`] || [];
    return [...responses.map((r, index) => index), -1, -2].sort();
  }

}
