import { SaveItemRequest } from "@rezonence/core";
import { SaveHook } from "@rezonence/freewall-db-hooks";
import { Validator } from './Validator';
import { schemaByDataset } from "./schema/schema.by.dataset";
import { Injectable } from "@angular/core";

@Injectable()
export class ValidatorHook implements SaveHook {

  constructor(
    private validator: Validator
  ) { }

  async transform(datasetItem: SaveItemRequest<any>): Promise<SaveItemRequest<any>> {
    if (datasetItem) {
      const dataset = datasetItem.datasetName;
      const schema = schemaByDataset[dataset];
      this.validator.validate(datasetItem.value, schema);
    }
    return datasetItem;
  }

}
