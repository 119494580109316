import { Component, OnInit } from "@angular/core";
import {
  ControlWithDetailProps, createCleanLabel,
  findUISchema,
  GroupLayout,
  JsonFormsState, Paths,
  setReadonly,
  UISchemaElement
} from "@jsonforms/core";
import { JsonFormsAngularService, JsonFormsControlWithDetail } from "@jsonforms/angular";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

@Component({
  selector: "app-object-renderer",
  templateUrl: "./object-renderer.component.html",
})
export class ObjectRendererComponent extends JsonFormsControlWithDetail {
  detailUiSchema: UISchemaElement;
  constructor(jsonFormsService: JsonFormsAngularService) {
    super(jsonFormsService);
  }
  mapAdditionalProps(props: ControlWithDetailProps) {
    this.detailUiSchema = findUISchema(
      props.uischemas,
      props.schema,
      undefined,
      props.path,
      "Group"
    );
    if (isEmpty(props.path)) {
      this.detailUiSchema.type = "VerticalLayout";
    } else {
      const groupLayout = (this.detailUiSchema as GroupLayout);
      if (typeof props.label === "string") {
        groupLayout.label = props.schema.title;
      } else {
        const lastPathElement = props.path.split(".").pop();
        groupLayout.label = startCase(lastPathElement);
      }
    }
    if (!this.isEnabled()) {
      setReadonly(this.detailUiSchema);
    }
  }
}
