import { Pipe, PipeTransform } from "@angular/core";
import {Dataset} from "@rezonence/core";
import {UISchemaElement} from "@jsonforms/core";
import {uiSchemaByDataset} from "./ui.schema.by.dataset";

@Pipe({
  name: "uiSchema"
})
export class UiSchemaPipe implements PipeTransform {

  transform(dataset: Dataset): UISchemaElement {
    return uiSchemaByDataset[dataset];
  }

}
