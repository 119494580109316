import { MagicLinkClient, OpenIdTokenResponse } from "@rezonence/magic-link";
import { Injectable } from "@angular/core";
import { TokenExchangeService } from "./TokenExchangeService";
import { InfrastructureResolver } from "../core/InfrastructureResolver";
import { Magic } from "magic-sdk";
import { Optional } from "@rezonence/sdk";
import { map, Observable, of, from, shareReplay, concatMap, filter, startWith, concatWith, distinctUntilChanged, last } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class MagicLinkService extends MagicLinkClient {

    public readonly token$: Observable<Optional<OpenIdTokenResponse>>;

    constructor(tokenExchanger: TokenExchangeService, infra: InfrastructureResolver) {
        super(tokenExchanger, new Magic(infra.config.magic.magicKey));
        const startState = from(this.isLoggedIn()).pipe(
            filter(loggedIn => loggedIn),
            concatMap(() => this.refreshToken()),
            startWith(Optional.empty<OpenIdTokenResponse>()),
            last()
        );

        this.token$ = startState.pipe(
            concatWith(tokenExchanger.token$.pipe(map(t => Optional.of(t)))),
            distinctUntilChanged((v1, v2) => JSON.stringify(v1.item) === JSON.stringify(v2.item)),
            shareReplay(1)
        );
    }
}