import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class MicrositeWindowAdapterService {

  constructor() {
  }

  toOutbox<T>(contentWindow: Window, targetOrigin: string): Subject<T> {
    const subject = new Subject<any>();
    subject.subscribe(message => contentWindow.postMessage(message, targetOrigin));
    return subject;
  }
}
