<h3>
  Share
</h3>
<p>
  You can now share a demo of your FreeWall with others.
</p>
<div *ngIf="shareLink$ | async; else loading; let shareLink">
  <mat-form-field class="share-link">
    <input #clipboardTarget type="text" matInput placeholder="Share link" [value]="shareLink" readonly>
  </mat-form-field>
  <button [ngxClipboard]="clipboardTarget" class="copy-link-button" mat-button color="primary">
    Copy link
  </button>
</div>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>