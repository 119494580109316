export enum MicrositeType {
    carousel = "carousel",
    existing = "existing",
    survey = "survey",
    vast = "vast",
    multiVideo = "multi-video",
    typeform = "typeform",
    logo = "logo",
    chat = "chat",
    video = "video",
}
