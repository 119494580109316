import { crossFetch } from "@rezonence/sdk";
import { ExchangeTokenRequest } from "./ExchangeTokenRequest";
import { ExchangeTokenResponse } from "./ExchangeTokenResponse";
import { TokenExchanger } from "./TokenExchanger";

export class TokenExchangeClient implements TokenExchanger {

    constructor(private tokenExchangeEndpoint: URL) {
    }

    async exchange(request: ExchangeTokenRequest): Promise<ExchangeTokenResponse> {
        const response = await crossFetch(this.tokenExchangeEndpoint.toString(), {
            method: "POST",
            body: JSON.stringify(request)
        });
        return (await response.json()) as ExchangeTokenResponse;
    }

}