import {Component, Input, OnInit, Output} from "@angular/core";
import {FreeWall} from "@rezonence/core/freewall/freewall";
import {ControlElement} from "@jsonforms/core";
import {freewallConfigSchema} from "@rezonence/core";
import {ReplaySubject} from "rxjs";
import {ErrorObject} from "ajv";
import {allControl} from "../all.control";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.css"]
})
export class QuestionComponent implements OnInit {

  @Output()
  errors: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  schema = freewallConfigSchema.definitions.Question.properties.que;
  uiSchema: ControlElement = allControl;

  get question(): string {
    return this.config.questions[0].que;
  }

  set question(input: string) {
    this.config.questions[0].que = input;
  }

  @Input()
  config: FreeWall;

  constructor() { }

  ngOnInit() {
  }

}
