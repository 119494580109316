import S3 from 'aws-sdk/clients/s3'
import { FileService } from '@djabry/fs-s3'
import { DefaultUtils } from '@rezonence/core/utils/default.utils'
import { TemplateService } from '@rezonence/core/freewall-compiler/template.service'
import { DefaultVideoService } from '@rezonence/core/video-service/default.video.service'
import ElasticTranscoder from 'aws-sdk/clients/elastictranscoder'
import { TemplateFolderResolver } from '@rezonence/core'
import { RequestParser } from '../parser'
import { createValidator } from '../validator'
import { RequestBuilder } from '../request-builder'
import { FreewallCompiler } from './freewall.compiler'
import { ScopeModifierFactory } from './scope.modifier.factory'
import { ConfigCompilerFactory } from './config.compiler.factory'
import { LocalFile } from '@jabrythehutt/fs-s3-core'
import { S3File } from '@jabrythehutt/fs-s3'

export function createFreewallCompiler<T extends LocalFile = S3File>(templateResolver: TemplateFolderResolver<T>, version: string, s3: S3 = new S3()): FreewallCompiler<T> {
  const fileService = new FileService(s3)
  const parser = new RequestParser(version);
  const validator = createValidator();
  const builder = new RequestBuilder()
  const utils = new DefaultUtils()
  const templateService = new TemplateService(fileService, templateResolver)
  const elasticTranscoder = new ElasticTranscoder()
  const videoService = new DefaultVideoService(fileService, utils, undefined, elasticTranscoder)
  const compilationRecord = {}
  const scopeModifierFactory = new ScopeModifierFactory(fileService, videoService)
  const modifiers = scopeModifierFactory.create()
  const compilerFactory = new ConfigCompilerFactory(utils, fileService, templateService, compilationRecord, modifiers)
  return new FreewallCompiler(parser, validator, builder, compilerFactory)
}
