import { YmdPartitionColumn } from "./YmdPartitionColumn";
import {HourPartitionColumn} from "./HourPartitionColumn";

// eslint-disable-next-line local-rules/multi-exports
export type YmdhPartitionColumn = YmdPartitionColumn | HourPartitionColumn;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const YmdhPartitionColumn = {
    ...YmdPartitionColumn,
    ...HourPartitionColumn
};
