export enum GaParam {
    Version = "v",
    AccountId = "tid",
    Type = "t",
    SessionId = "cid",
    EventCategory = "ec",
    EventLabel = "el",
    EventAction = "ea",
    CacheBuster = "z",
    UserId = "uid"
}
