import {Sortable} from "./sortable";
import {isPlainObject} from "is-plain-object";
import {KeySortError} from "./key.sort.error";

export class KeySorter {

    static isSortable(input: Sortable): boolean {
        return isPlainObject(input) || Array.isArray(input);
    }

    static toBaseObject<T extends Sortable>(input: T): Partial<T> {
        return (isPlainObject(input) ? {} : []) as Partial<T>;
    }

    static sort<T extends Sortable>(input: T): T {
        if (!KeySorter.isSortable(input)) {
            throw new TypeError(KeySortError.InvalidInput);
        }
        const sortedKeys = Object.keys(input).sort();
        const sortedObject = KeySorter.toBaseObject(input);
        for (const key of sortedKeys) {
            const value = input[key];
            sortedObject[key] = KeySorter.isSortable(value) ? KeySorter.sort(value) : value;
        }
        return sortedObject as T;
    }
}
