import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "app-loading-message",
  templateUrl: "./loading-message.component.html",
  styleUrls: ["./loading-message.component.css"]
})
export class LoadingMessageComponent implements OnInit {

  @Input()
  progressMessage: string;

  @Input()
  value: number;

  constructor() { }

  ngOnInit() {
  }

}
