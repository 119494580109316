import {Pipe, PipeTransform} from "@angular/core";
import {ResourceType} from "../resource.type";
import {ScannedFile} from "@djabry/fs-s3";
import {ResourceTypeService} from "./resource-type.service";

@Pipe({name: "resourceType"})
export class ResourceTypePipe implements PipeTransform {

  constructor(private resourceTypeService: ResourceTypeService) {
  }

  transform(resource: ScannedFile): ResourceType | undefined {
    if (resource && resource.mimeType) {
      const resourceTypeOptional = this.resourceTypeService.toResourceType(resource);
      return resourceTypeOptional.item;
    }
  }
}
