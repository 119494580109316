import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModeResolver, Mode} from "../../mode";
import { CreateFreewallType } from "../../create/CreateFreewallType";
import { FreewallOrganiserService } from "../freewall/FreewallOrganiserService";
import { IdMapperService } from "../id/id.mapper.service";

@Component({
  selector: "home",
  templateUrl: "./StartComponent.html",
  styleUrls: [
    "./StartComponent.css"
  ]
})
export class StartComponent implements OnInit {
  query: string;
  Mode = Mode;
  CreateFreewallType = CreateFreewallType;

  constructor(public router: Router,
    public route: ActivatedRoute,
    public freeWallService: FreewallOrganiserService,
    public modeResolver: ModeResolver,
    public idMapperService: IdMapperService) {
  }

  async applyFreeWallIdToFragment(id: string) {
    await this.router.navigate([], {
      relativeTo: this.route,
      fragment: id
    });
  }

  ngOnInit(): void {
    this.freeWallService.reloadFreeWallsIfRequired();
  }
}
