import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CloudFreewallCompiler } from '../../core/CloudFreewallCompiler';
import {
  FreeWallConfig,
  Microsites,
  CustomCodeConfig,
  CssConfig
} from '@rezonence/core';
import {
  CloudCompileRequest,
  FreeWallPreviewCompileRequest,
  freeWallPreviewCompileRequestKeys
} from '@rezonence/freewall-creator-config';
import {
  createReplaySubjectMap,
  fromSubjectMap,
  SubjectMap
} from '@rezonence/rxjs';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  shareReplay,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  map,
  catchError
} from 'rxjs/operators';
import { Duration } from '@rezonence/duration';
import { previewFolder } from '../preview.folder';
import { stringChangeComparator } from '../../core/string.change.comparator';
import { CompilationResult } from './CompilationResult';
import { DemoAdIdResolver } from '../DemoAdIdResolver';
import { DisplayDemoRequest, toDemoUrl } from '@rezonence/freewall-demo-client';
import { DemoType } from '../DemoType';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent {
  compileRequestMap$: SubjectMap<FreeWallPreviewCompileRequest> =
    createReplaySubjectMap<FreeWallPreviewCompileRequest>(
      freeWallPreviewCompileRequestKeys
    );

  demoPage$: Subject<string> = new ReplaySubject<string>(1);

  @Output()
  demoPageChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  shareLink: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  enableSharing: boolean;

  @Input()
  set demoPage(input: string) {
    this.demoPage$.next(input);
  }

  @Input()
  set recordId(recordId: string) {
    this.compileRequestMap$.recordId.next(recordId);
  }

  @Input()
  set config(config: FreeWallConfig) {
    this.compileRequestMap$.config.next(config);
  }

  @Input()
  set microsites(microsites: Microsites) {
    this.compileRequestMap$.microsites.next(microsites);
  }

  @Input()
  set customCode(customCode: CustomCodeConfig) {
    this.compileRequestMap$.customCode.next(customCode);
  }

  @Input()
  set css(cssConfig: CssConfig) {
    this.compileRequestMap$.css.next(cssConfig);
  }

  @ViewChild('previewIframe', { static: false })
  iframe: ElementRef<HTMLIFrameElement>;

  adId$: Observable<string> = this.compileRequestMap$.recordId.pipe(
    concatMap((recordId) =>
      this.adIdResolver.toAdId({ recordId, demoType: DemoType.Preview })
    )
  );

  compileRequest$: Observable<CloudCompileRequest> = fromSubjectMap(
    this.compileRequestMap$
  ).pipe(
    distinctUntilChanged(stringChangeComparator),
    debounceTime(Duration.MsInSecond),
    map((request) => this.toCloudCompileRequest(request))
  );

  compilation$: Observable<Promise<CompilationResult>> =
    this.compileRequest$.pipe(
      map((request) => this.toCompilationResult(request)),
    );

  demoUrlRequest$: Observable<DisplayDemoRequest> = combineLatest([
    this.adId$,
    this.demoPage$
  ]).pipe(map(([aid, url]) => ({ aid, url, scroll: true })));

  errors$: Observable<string[]> = this.compilation$.pipe(
    concatMap((promise) => promise),
    map(() => []),
    catchError((error) => of([error.message]))
  );

  freeWallPreviewUrl$: Observable<string> = this.demoUrlRequest$.pipe(
    map((request) => toDemoUrl(request)),
    map((url) => url.toString()),
    shareReplay(1),
  );

  constructor(
    private adIdResolver: DemoAdIdResolver,
    public compiler: CloudFreewallCompiler
  ) { }

  toCloudCompileRequest(
    request: FreeWallPreviewCompileRequest
  ): CloudCompileRequest {
    return {
      ...request,
      recordFolder: previewFolder,
    };
  }

  reloadIframe(): void {
    this.iframe.nativeElement.src = this.iframe?.nativeElement?.src;
  }

  private async toCompilationResult(
    request: CloudCompileRequest,
  ): Promise<CompilationResult> {
    await this.compiler.invoke(request);
    return {};
  }
}
