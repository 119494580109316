import {Category} from "./category";
import {InjectionToken} from "@angular/core";
import {WizardModeKey} from "./wizard.mode.key";
import {Answer, FreeWallConfig, FreeWallResponse} from "@rezonence/core";
export const WIZARD_MODE = new InjectionToken<WizardMode>("Wizard mode");
export interface WizardMode {
  /**
   * The unique identifier of the mode used for selecting it via a query parameter
   */
  readonly key: WizardModeKey;
  /**
   * The description of the wizard mode
   */
  readonly description: string;

  /**
   * Whether to allow the user to change the mapping of answers to responses
   */
  enableAnswerResponseMapping: boolean;

  /**
   * Whether to display a particular tab given its coordinates
   *
   * @param config
   * @param {Category} category - The category of the tab e.g. "Title"
   * @param {number} indexWithinCategory - The index of the tab within the tab category
   * @return {boolean} - Whether the tab should be displayed
   */
  shouldDisplay(config: FreeWallConfig, category: Category, indexWithinCategory?: number): boolean;

  /**
   * Generate a customized FreeWall config for use with the wizard mode
   *
   * @return {FreeWallConfig}
   */
  createConfig(): Promise<FreeWallConfig>;

  createAnswer(config: FreeWallConfig): Answer;

  createResponse(config: FreeWallConfig, responseMode: string): FreeWallResponse;

  isCompatible(config: FreeWallConfig): boolean;
}
