import { DatasetItem, SaveItemRequest, FreeWallDB, Dataset, DatasetValue } from "@rezonence/core";
import { Optional } from "@rezonence/sdk";
import { LoadSaveHook } from "@rezonence/freewall-db-hooks";

export abstract class DatasetHook<V extends DatasetValue> implements LoadSaveHook {

    constructor(private dataset: Dataset) {
    }

    async transform<T extends DatasetItem<any> | SaveItemRequest<any>>(input: T, db: FreeWallDB): Promise<T> {
        const transformed = await Optional.switchPromise(this.toOptional(input).map(v => this.transformValue(v, db)));
        return transformed.map(value => ({
            ...input,
            value
        }), () => input).item;
    }

    toOptional<T extends DatasetItem<any> | SaveItemRequest<any>>(input: T): Optional<V> {
        return Optional.truthy(input && input.datasetName === this.dataset).map(() => input.value as V);
    }

    abstract transformValue(input: V, db: FreeWallDB): Promise<V>;


}
