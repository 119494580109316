import { ConfigDownloader, DestinationPrefix, FreeWallConfig, Optional } from "@rezonence/sdk";
import { MicrositeSourceParser } from "./MicrositeSourceParser";
import { truthyArrayItem, uniqueArrayItem } from "@rezonence/array-utils";
import { TemplatedMicrosite } from "../freewall";

export class MicrositeResolver {
  constructor(private sourceParser: MicrositeSourceParser, private configDownloader: ConfigDownloader) {
  }

  toMicrositeSources(config: FreeWallConfig): string[] {
    const responseIframeSources = [
      ...(config.responses || []),
      ...(config.kickers || [])
    ].map(r => r.ifs)
    const iframeSources = [
      config.iframe?.src,
      ...responseIframeSources,
    ];
    return [
      ...responseIframeSources,
      ...iframeSources
    ].filter(truthyArrayItem).filter(uniqueArrayItem)
  }

  async toMicrosites(listRequest: { cdn: URL, adId: string }, config: FreeWallConfig): Promise<TemplatedMicrosite[]> {
    const sources = this.toMicrositeSources(config);
    const microsites = await Promise.all(sources.map(micrositeSource => this.sourceParser.parse({
      ...listRequest,
      micrositeSource
    })));
    return microsites.filter(m => m.exists).map(m => m.item);
  }

  async listMicrosites(request: { cdn: URL, adId: string }): Promise<TemplatedMicrosite[]> {
    const optionalConfig = await this.configDownloader.downloadConfig<FreeWallConfig>({
      cdn: request.cdn,
      id: request.adId,
      prefix: DestinationPrefix.Ads
    });
    const optionalMicrosites = await Optional.switchPromise(optionalConfig.map(config => this.toMicrosites(request, config)));
    return optionalMicrosites.item || [];
  }
}
