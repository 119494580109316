import {AnyFile, IFileService, ScannedFile, WriteOptions} from "@djabry/fs-s3";
import {CompileConfigRequest} from "./compile.config.request";
import {TemplateService} from "./template.service";
import {Utils} from "../utils";
import {globalRegexFor, Optional, StringFromTemplating} from "@rezonence/sdk";
import { Config } from "./config";
import {CompilationScope} from "./compilation.scope";
import {basename, extname} from "path";
import {TemplateFileSuffix} from "./template.file.suffix";
import {FreewallFilenamePrefix} from "@rezonence/sdk";
import {ConfigType} from "./config.type";
import {ConfigScopeModifier} from "./config.scope.modifier";
import {PublisherConfigPlaceholder} from "../publisher-config-compiler";
import {CryptoUtils} from "../utils";
import {filenamePrefixByDestinationPrefix} from "@rezonence/sdk";

/**
 * A simple web-friendly Doubleserve/Publisher config compiler
 */
export class ConfigCompiler {

    configTypeByPlaceholder: Record<StringFromTemplating | PublisherConfigPlaceholder, ConfigType> = {
        [PublisherConfigPlaceholder.DoubleserveConfig]: ConfigType.Json,
        [StringFromTemplating.Branch]: ConfigType.String,
        [StringFromTemplating.Css]: ConfigType.Css,
        [StringFromTemplating.Version]: ConfigType.String,
        [StringFromTemplating.Config]: ConfigType.Json
    };

    constructor(private fileService: IFileService,
                private templateService: TemplateService,
                private utils: Utils,
                private useCache: boolean = true,
                private compilationRecord: Record<string, string> = {},
                private writeOptions: WriteOptions =
                    {makePublic: true, overwrite: true, parallel: false, skipSame: true},
                public readonly scopeModifiers: ConfigScopeModifier[] = []) {
    }

    async addResourcesToScope(resourceFolders: AnyFile[], destinationFolder: AnyFile, scope: CompilationScope) {
        for (const folder of resourceFolders) {
            const resourceFiles = await this.fileService.list(folder);
            for (const file of resourceFiles) {
              const sourceParts = file.key.split(folder.key);
              const destinationSuffix = sourceParts.pop();
              const destinationKey = destinationFolder.key + destinationSuffix;
                if (destinationKey) {
                    scope.addFile(file, destinationKey);
                }
            }
        }
    }

    generateCodeAndFormatFileNames(): string[] {
        const fileNameSuffixes = [TemplateFileSuffix.Code, TemplateFileSuffix.FormatCss];
        const fileNamePrefixes = [FreewallFilenamePrefix.Ad, FreewallFilenamePrefix.Pb];
        return fileNamePrefixes.map((prefix) => {
            return fileNameSuffixes.map((suffix) => {
                return `${prefix}${suffix}`;
            });
        }).reduce((a, b) => {
            return a.concat(b);
        });
    }

    toDestinationKey(templateFile: ScannedFile, destinationFolder: AnyFile): string {
        return `${destinationFolder.key}/${basename(templateFile.key)}`;
    }

    async compile<T extends Config>(request: CompileConfigRequest): Promise<void> {
        const scope = await this.buildCompilationScope(request);
        // add extension files to scope
        const codeFilesWithExtensions = await this.generateCodeAndFormatFileNames();

        await Promise.all(codeFilesWithExtensions.map(async key => {
            await this.mergeCodeExtensions(key, request.destinationFolder, scope);
        }));

        for (const modifier of this.scopeModifiers) {
            await modifier(request, scope);
        }

        request.configsToInsert[StringFromTemplating.Css] = request.configsToInsert[StringFromTemplating.Css]
            || (await this.readCssFileFromScope(request.destinationFolder, scope)).item;
        await this.renderTemplateFiles(scope, request);

        await Promise.all(Object.keys(scope.filesToCopy).map(async key => {
            const inputFile = scope.filesToCopy[key];
            const destinationFile = {
                ...request.destinationFolder,
                key
            };
            await this.writeToDestination(inputFile, destinationFile, this.useCache);
        }));

    }

    getFileExtensionPointName(codeFileName: string): string {
        const extension = extname(codeFileName);
        return `${basename(codeFileName, extension)}.ext${extension}`;
    }

    async readScopeFile(scope: CompilationScope, destinationKey: string): Promise<Optional<string>> {
        const destinationFileOrString = scope.filesToCopy[destinationKey];

        if (!destinationFileOrString) {
            return Optional.empty();
        }

        const result = typeof destinationFileOrString === "string" ? destinationFileOrString :
            (await this.fileService.readString(destinationFileOrString));
        return Optional.of(result);
    }

    async mergeCodeExtensions<T extends Config>(codeFileName: string, destinationFolder: AnyFile,
                                                scope: CompilationScope) {
        const codeExtensionFileName = this.getFileExtensionPointName(codeFileName);
        const codeExtensionFileKey = `${destinationFolder.key}/${codeExtensionFileName}`;
        const destinationKey = `${destinationFolder.key}/${codeFileName}`;
        const originalCodeStringOptional = await this.readScopeFile(scope, destinationKey);
        const extensionCodeStringOptional = await this.readScopeFile(scope, codeExtensionFileKey);

        if (originalCodeStringOptional.exists && extensionCodeStringOptional.exists) {
            const modifiedCodeString = [originalCodeStringOptional.item, extensionCodeStringOptional.item].join("\n");
            scope.addFile(modifiedCodeString, destinationKey);
        }
    }

    async buildCompilationScope<T extends Config>(request: CompileConfigRequest): Promise<CompilationScope> {
        const scope = new CompilationScope();
        this.addTemplateFilesToScope(scope, request);
        await this.addResourcesToScope(request.resourceFolders, request.destinationFolder, scope);
        return scope;
    }

    addTemplateFilesToScope(scope: CompilationScope, request: CompileConfigRequest) {
        for (const templateFile of request.templateFiles) {
            scope.addFile(templateFile, this.toDestinationKey(templateFile, request.destinationFolder));
        }
    }

    async renderTemplateFiles<T extends Config>(scope: CompilationScope, request: CompileConfigRequest):
        Promise<void> {
        const templateSources = request.templateFiles
            .map(file => this.toDestinationKey(file, request.destinationFolder))
            .map(key => ({
                key,
                file: scope.filesToCopy[key]
            }));

        for (const templateSource of templateSources) {
            const templateString = await this.templateService.readTemplateFile(
                templateSource.file,
                request.options.debug,
                this.useCache,
                extname(templateSource.key)
            );
            const modifiedTemplateString = this.insertValuesIntoPlaceholders(request, templateString);
            scope.addFile(modifiedTemplateString, templateSource.key);
        }
    }

    async writeToDestination(inputFile: string | ScannedFile,
                             destinationFile: AnyFile,
                             cache: boolean):
        Promise<void> {
        const hash = typeof inputFile === "string" ?
            CryptoUtils.calculateMd5Hash(inputFile) : (inputFile as ScannedFile).md5;
        // Skip writing the file if it's already been written to the destination
        if (cache && this.compilationRecord[destinationFile.key] === hash) {
            console.log("Skipping writing already compiled file", basename(destinationFile.key));
        } else {
            if (typeof inputFile === "string") {
                await this.fileService.write(inputFile as string, destinationFile, this.writeOptions);
            } else {
                await this.fileService.copy(inputFile as ScannedFile, destinationFile, this.writeOptions);
            }
        }

        this.compilationRecord[destinationFile.key] = hash;
    }

    insertValuesIntoPlaceholders(request: CompileConfigRequest, templateString: string): string {
        let renderedTemplateString = templateString;
        for (const placeholder of Object.keys(request.configsToInsert)) {
            const value = request.configsToInsert[placeholder];
            const configType = this.configTypeByPlaceholder[placeholder];
            if (value) {
                if (configType === ConfigType.Css) {
                    renderedTemplateString = this.utils.insertCSS(renderedTemplateString, placeholder, value);
                } else if (configType === ConfigType.Json) {
                    renderedTemplateString = this.utils.insertJSON(renderedTemplateString, placeholder, value);
                } else {
                    renderedTemplateString = renderedTemplateString
                        .replace(globalRegexFor(placeholder as StringFromTemplating), value);
                }
            }
        }
        return renderedTemplateString;
    }

    async readCssFileFromScope(destinationFolder: AnyFile, scope: CompilationScope): Promise<Optional<string>> {
        const cssFileKey = this.toCssFileKey(destinationFolder);
        const result = await Optional.switchPromise(cssFileKey.map(key => this.readScopeFile(scope, key)));
        return Optional.unWrap(result);
    }

    toCssFileKey(destinationFolder: AnyFile): Optional<string> {
        const destinationPrefix = Object.keys(filenamePrefixByDestinationPrefix)
            .find(prefix => destinationFolder.key.startsWith(`${prefix}/`));
        const filenamePrefix = filenamePrefixByDestinationPrefix[destinationPrefix];
        return Optional.of(filenamePrefix).map(prefix =>
            `${destinationFolder.key}/${filenamePrefix}${TemplateFileSuffix.FormatCss}`);
    }

}
