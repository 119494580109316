import { WizardDialogService, WizardStep } from "@rezonence/ng-schema-wizard";
import { CreativeFreeWallDB } from "../core/CreativeFreeWallDB";
import { CreativeUtils } from "../core/creative.utils";
import { AWSService } from "../core/AWSService";
import { Dataset, DatasetItem, DatasetValue, FreeWallConfig, Meta, Microsite, Microsites } from "@rezonence/core";
import { FreewallMetaPair } from "../core/freewall.meta.pair";
import cloneDeep from "lodash/cloneDeep";
import { defaultFreeWall } from "../creator/wizard/default.freewall";
import { EncodedIframe } from "@rezonence/sdk";
import { TitleConfig } from "@rezonence/freewall-creator-config";
import { Schema } from "jsonschema";
import schemaDefaults from "json-schema-defaults";
import { MicrositeType } from "@rezonence/core/microsite/microsite.type";
import { FreeWallTitleGenerator, QuickstartOperation } from "../create";

export class QuickstartWizardService {

  get baseFreewallConfig(): FreeWallConfig {
    return cloneDeep<FreeWallConfig>(defaultFreeWall);
  }

  constructor(protected wizardDialogService: WizardDialogService,
    protected titleGenerator: FreeWallTitleGenerator,
    protected db: CreativeFreeWallDB,
    private utils: CreativeUtils,
    protected awsService: AWSService) {
  }

  async saveMicrosite(microsite: Microsite, freeWallConfig: FreeWallConfig): Promise<FreewallMetaPair> {
    const freeWallData = await this.db.saveItem<FreeWallConfig>({
      identityId: await this.awsService.identityId(),
      datasetName: Dataset.Freewall,
      recordId: undefined,
      value: freeWallConfig
    });
    const metaItemValue = { title: microsite.title, labels: [] };
    const metaItem: DatasetItem<Meta> = {
      ...freeWallData,
      datasetName: Dataset.Meta,
      value: metaItemValue
    };
    const micrositeItem: DatasetItem<Microsites> = {
      ...freeWallData,
      datasetName: Dataset.Microsites,
      value: [microsite]
    };
    const items: DatasetItem<DatasetValue>[] = [metaItem, micrositeItem];
    for (const item of items) {
      await this.db.saveItem(item);
    }
    return { meta: metaItem.value, ...freeWallData };
  }

  toWizardStep(field: string): WizardStep {
    return {
      uiSchema: {
        type: "Control",
        scope: `#/properties/${field}`
      }
    };
  }

  toIframe(micrositeUrl: string): EncodedIframe {
    return {
      src: micrositeUrl,
      hdq: true,
      det: false,
      dyn: true,
      viz: true,
      rev: true,
      rat: 1
    };
  }

  toDefaults<T extends TitleConfig>(schema: Schema, existing: FreewallMetaPair[], type: QuickstartOperation): T {
    return {
      ...schemaDefaults(schema),
      title: this.titleGenerator.generate<QuickstartOperation>(existing, type)
    };
  }

  toBaseMicrosite(templateId: MicrositeType, title: string): { id: string; templateId: MicrositeType; title: string } {
    return { id: this.utils.randomString(), templateId, title };
  }

}
