import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Dataset, Meta } from "@rezonence/core";
import { FreeWallEditorContext } from "./FreeWallEditorContext";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { ErrorObject } from "ajv";
import { DatasetDictionary } from "../dataset.dictionary";
import { map } from "rxjs/operators";
import { FreewallEditorService } from "./FreewallEditorService";
import { freeWallEditorDatasets } from "../freewall.editor.datasets";
import { previewFolder } from "../../preview/preview.folder";
import { CreativeFreeWallDB } from "../../core/CreativeFreeWallDB";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ToolbarVisibilityResolver } from "../../ToolbarVisibilityResolver";

@Component({
  selector: "app-freewall-editor",
  templateUrl: "./freewall-editor.component.html",
  providers: [
    FreewallEditorService,
    FreeWallEditorContext
  ],
  styleUrls: ["./freewall-editor.component.css"]
})
export class FreewallEditorComponent {

  @Input()
  readonly actionLabel: string

  @Output()
  datasetChange = new EventEmitter<Dataset>()

  @Output()
  freeWallSaved = new EventEmitter<void>()

  readonly fullHeight$: Observable<boolean>;

  preview: boolean;
  vertical = false;
  recordFolder = previewFolder;
  errorInput: Partial<DatasetDictionary<ReplaySubject<ErrorObject[]>>>;
  errors: Observable<Partial<DatasetDictionary<ErrorObject[]>>>;
  datasets$: BehaviorSubject<Dataset[]> =
    new BehaviorSubject<Dataset[]>(freeWallEditorDatasets);

  constructor(
    private db: CreativeFreeWallDB,
    private context: FreeWallEditorContext,
    toolbarVis: ToolbarVisibilityResolver
  ) {
    this.fullHeight$ = toolbarVis.toolbarVisible$.pipe(map(vis => !vis));
    this.errorInput = freeWallEditorDatasets
      .reduce((previous, dataset) => ({
        ...previous,
        [dataset]: new ReplaySubject<ErrorObject[]>(1)
      }), {});

    this.errors = this.datasets$
      .pipe(map(datasets => datasets.map(d => this.errorInput[d])))
      .pipe(map(errorsArray => errorsArray.reduce((previous, current, index) => ({
        ...previous,
        [freeWallEditorDatasets[index]]: current
      }), {})));
  }

  get dataset(): Dataset {
    return this.context.dataset
  }

  get recordId(): string {
    return this.context.recordId
  }

  @Input()
  set recordId(value: string) {
    this.context.recordId = value
  }

  @Input()
  set dataset(value: Dataset) {
    this.context.dataset = value
  }

  async demoShared(recordId: string, meta: Meta, link: string) {
    // Save the FreeWall metadata object whenever a link is shared
    meta.demoLink = link;
    await this.db.saveDatasetValue(Dataset.Meta, recordId, meta);
  }

  tabChanged(datasets: Dataset[], tabChangedEvent: MatTabChangeEvent): void {
    this.datasetChange.emit(datasets[tabChangedEvent.index])
  }
}
