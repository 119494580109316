import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ExchangeTokenResponse } from "@rezonence/magic-link";
import { Optional } from "@rezonence/sdk";
import { concatMap, filter, lastValueFrom, map, take } from "rxjs";
import { AWSService } from "../core/AWSService";
import { AuthService } from "./AuthService";


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private awsService: AWSService) {
    }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        return lastValueFrom(this.authService.token$.pipe(
            concatMap(async token => token.exists ? token : this.requestLogin()),
            map(t => t.exists),
            take(1)
        ));
    }

    async requestLogin(): Promise<Optional<ExchangeTokenResponse>> {
        const token = await this.authService.requestLogin(true);
        await Optional.switchPromise(token.map(idToken => this.waitForAuthorisation(idToken.identityId)));
        return token;
    }

    protected waitForAuthorisation(identityId: string): Promise<string> {
        return lastValueFrom(this.awsService.identityId$.pipe(
            filter(id => id === identityId),
            take(1)
        ));
    }


}