const msInSecond = 1000;
const msInMinute = msInSecond * 60;
const msInHour = msInMinute * 60;
const msInDay = msInHour * 24;
const msInWeek = msInDay * 7;

export enum Duration {
    MsInSecond = msInSecond,
    MsInMinute = msInMinute,
    MsInHour = msInHour,
    MsInDay = msInDay,
    MsInWeek = msInWeek
}
