import Ajv, { Options } from "ajv";
import { applyFormats } from "./applyFormats";

export function createAjv(options?: Options): Ajv {
  const ajv = new Ajv({
    allErrors: true,
    ...options
  });
  applyFormats(ajv);
  return ajv;
}
