import { Pipe, PipeTransform } from "@angular/core";
import { JsonSchema } from "@jsonforms/core";
import { resolveRefs } from "json-refs";

@Pipe({ name: "resolveRefs" })
export class ResolveRefsPipe implements PipeTransform {
  async transform(schema: JsonSchema): Promise<JsonSchema> {
    const result = await resolveRefs(schema);
    return result.resolved;
  }
}
