export enum DynamoError {
    AccessDeniedException = "AccessDeniedException",
    ConditionalCheckFailedException = "ConditionalCheckFailedException",
    IncompleteSignatureException = "IncompleteSignatureException",
    ItemCollectionSizeLimitExceededException = "ItemCollectionSizeLimitExceededException",
    LimitExceededException = "LimitExceededException",
    MissingAuthenticationTokenException = "MissingAuthenticationTokenException",
    ResourceInUseException = "ResourceInUseException",
    ResourceNotFoundException = "ResourceNotFoundException",
    ThrottlingException = "ThrottlingException",
    UnrecognizedClientException = "UnrecognizedClientException",
    ValidationException = "ValidationException",
    ProvisionedThroughputExceededException = "ProvisionedThroughputExceededException",
    TableAlreadyExistsException = "TableAlreadyExistsException"
}
