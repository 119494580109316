import {ArrayUtils} from "@rezonence/array-utils";
import {S3VariantService} from "../aws/s3.variant.service";
import {environment} from "../aws/environment";
import {CDN} from "./cdn";
import {defaultProtocol} from "../aws/default.protocol";

const s3Endpoints = ArrayUtils.flatten(["http:", defaultProtocol]
    .map(protocol => S3VariantService.toVariants({
        protocol,
        bucket: environment.COMPILE_BUCKET,
        region: environment.REGION
    }))).map(e => `${e}/`);

// Deal with previously incorrect endpoint variants
const legacyS3Endpoints = s3Endpoints.map(e => e
    .replace(".s3.", ".S3.")
    .replace(".s3-", ".S3-")
);

export const cdnVariants = ArrayUtils.uniqueValues([
    ...Object.values(CDN),
    ...s3Endpoints,
    ...legacyS3Endpoints,
    "https://e43e8e18e725ae6b22e0-f0fde0bbc5469096ab6f7c4eba4934a9.ssl.cf3.rackcdn.com/",
    "http://221d828d3ea01cfd83e8-f0fde0bbc5469096ab6f7c4eba4934a9.r89.cf3.rackcdn.com/",
    "https://d3vuqzsqy1r5zj.cloudfront.net/",
    "http://d3vuqzsqy1r5zj.cloudfront.net/",
    "http://cdn1.rezonence.com/",
    "http://cdn2.rezonence.com/",
    "http://cdn3.rezonence.com/",
    "https://cdn3.rezonence.com/"
]);
