import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router"
import { map } from "rxjs";
import { FreeWallEditorContext } from "../../data-editor"
import { RouteParam } from "../../data-editor/route.param";

@Component({
  templateUrl: './WizardPage.html',
  providers: [
    FreeWallEditorContext
  ]
})
export class WizardPage {

  readonly freeWallId$ = this.route.paramMap.pipe(
    map(paramMap => paramMap.get(RouteParam.Id))
  )

  constructor(private route: ActivatedRoute) { }

}
