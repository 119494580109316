import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Microsites, FreeWall } from "@rezonence/core";
import { MatDialog } from "@angular/material/dialog";
import {CreateMicrositeService} from "../create-microsite/CreateMicrositeService";
import { CreativeUtils } from "../../core/creative.utils";

@Component({
  selector: "app-microsites-editor",
  templateUrl: "./microsites-editor.component.html",
  styleUrls: ["./microsites-editor.component.css"]
})
export class MicrositesEditorComponent implements OnInit {

  @Input()
  recordId: string;

  @Input()
  freeWallConfig: FreeWall;

  @Input()
  set microsites(input: Microsites) {
    if (JSON.stringify(input) !== JSON.stringify(this._microsites)) {
      this._microsites = input;
      this.micrositesChange.emit(this._microsites);
    }
  }

  get microsites(): Microsites {
    return this._microsites;
  }

  @Output()
  micrositesChange: EventEmitter<Microsites> = new EventEmitter<Microsites>();

  private _microsites: Microsites;

  constructor(private dialog: MatDialog,
    private utils: CreativeUtils,
              private createMicrositeService: CreateMicrositeService) {
  }

  async createNew() {
    const id = this.createNewId();
    const optionalMicrosite = await this.createMicrositeService.createMicrosite(id);
    if (optionalMicrosite.exists) {
      this.microsites.push(optionalMicrosite.item);
    }
  }

  ngOnInit() {
  }

  private createNewId(): string {
    let id = this.createId();
    while (this.microsites.find(m => m.id === id)) {
      id = this.createId();
    }
    return id;
  }

  private createId(): string {
    return this.utils.randomString();
  }

}
