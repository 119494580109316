import {Pipe, PipeTransform} from "@angular/core";
import {DatasetDictionary} from "../dataset.dictionary";
import {FreewallEditorService} from "../freewall-editor/FreewallEditorService";
import {DataSchemaPair} from "../freewall-editor/data.schema.pair";

@Pipe({
  name: "datasetValues"
})
export class DatasetValuesPipe implements PipeTransform {

  constructor(private editorService: FreewallEditorService) {
  }

  async transform(recordId: string):
    Promise<Partial<DatasetDictionary<DataSchemaPair>>> {
    return this.editorService.getValuesForRecordId(recordId);
  }

}
