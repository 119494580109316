
import {Injectable} from "@angular/core";
import { type Ace, require as aceRequire } from "ace-builds";

@Injectable({
    providedIn: "root"
})
export class BeautifierService {

  beautify(session: Ace.EditSession) {
    const beautifier = aceRequire("ace/ext/beautify");
        beautifier.beautify(session);
    }
}
