export enum EventType {

    /**
     * Answer button clicked
     */
    AnswerClicked = 'cans',

    /**
     * Engagement
     */
    Engagement = 'engt',

    /**
     * FreeWall rendered
     */
    FreeWallRendered = 'mviz',

    /**
     * FreeWall didn't display
     */
    NoShow = 'nosh',

    /**
     * FreeWall transition from banner to response/kicker
     */
    TransitionToResponse = 'tran',

    /**
     * Banner in view
     */
    InView = 'invw',

    /**
     * Video status event
     */
    VideoStatus = 'vdst',

    /**
     * Response in view
     */
    ResponseInView = 'rinv',

    /**
     * Kicker in view
     */
    KickerInView = 'kinv',

    /**
     * Banner clicked
     */
    BannerClicked = 'cimg',

    /**
     * FreeWall unlocked
     */
    FreeWallUnlocked = 'unlk',

    /**
     * Iframe clicked
     */
    IframeClicked = 'iclk',

    /**
     * Response clicked
     */
    ResponseClicked = 'rclk',

    /**
     * Kicker clicked
     */
    KickerClicked = 'kclk',

    /**
     * Mouse entered banner
     */
    MouseEnteredBanner = 'ment',

    /**
     * Video started
     */
    VideoStarted = 'vst',

    /**
     * 25% of video completed
     */
    Video25PercentCompleted = 'v25',

    /**
     * 50% of video completed
     */
    Video50PercentCompleted = 'v50',

    /**
     * 75% video quartile
     */
    Video75PercentCompleted = 'v75',

    /**
     * Video completed
     */
    VideoCompleted = 'ven',

    /**
     * FreeWall click
     */
    FreeWallClicked = 'clk',

    /**
     * FreeWall tag delivered
     */
    Impression = 'tagd',

    /**
     * Retry button click
     */
    RetryButtonClicked = 'ctry',

    /**
     * Video plays click
     */
    PlayButtonClicked = 'play',

    /**
     * Doubleserve impression
     */
    DoubleserveImpression = 'dimp',

    /**
     * FreeWall requested
     */
    FreeWallRequested = 'fre',

    /**
     * Bids requested
     */
    BidsRequested = 'breq',

    /**
     * Bids received
     */
    BidsReceived = 'brec',

    /**
     * Ad slot refreshed
     */
    AdSlotRefreshed = 'asr',

    /**
     * Mouse has entered the response placement
     */
    ResponseMouseEnter = 'rmen',

    /**
     * Mouse has entered the kicker placement
     */
    KickerMouseEnter = 'kmen',

    /**
     * Activation request to the api failed
     */
    ActivationApiError = 'activation_api_error'
}
