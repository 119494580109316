import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WizardConfig } from "../wizard.config";
import { WizardStep } from "../wizard.step";
import { ErrorObject } from "ajv";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { ControlElement, iterateSchema, toDataPath, UISchemaElement } from "@jsonforms/core";
import cloneDeep from "lodash/cloneDeep";

@Component({
  selector: "rez-wizard-step",
  templateUrl: "./wizard-step.component.html",
})
export class WizardStepComponent implements OnInit {

  @Input()
  set config(c: WizardConfig<any>) {
    this._config = c;
    this.config$.next(c);
  }

  get config(): WizardConfig<any> {
    return this._config;
  }

  @Input()
  set stepIndex(index: number) {
    this._stepIndex = index;
    this.stepIndex$.next(index);
  }

  get stepIndex(): number {
    return this._stepIndex;
  }

  get step(): WizardStep {
    return this.config.steps[this.stepIndex];
  }

  errorCollector: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  @Output()
  errors: Observable<ErrorObject[]>;

  private _config: WizardConfig<any>;
  private _stepIndex: number;
  private stepIndex$: ReplaySubject<number> = new ReplaySubject<number>(1);
  private config$: ReplaySubject<WizardConfig<any>> = new ReplaySubject<WizardConfig<any>>(1);
  private dataPaths$: Observable<string[]>;

  constructor() {
    this.dataPaths$ = combineLatest([this.config$, this.stepIndex$])
      .pipe(map(([config, stepIndex]) => {
        const uiSchema = config.steps[stepIndex].uiSchema;
        return this.toDataPaths(uiSchema);
      }));

    this.errors = combineLatest([this.dataPaths$, this.errorCollector])
      .pipe(map(([dataPaths, errors]) => errors.filter(error => this.isApplicableError(dataPaths, error))));
  }

  toDataPaths(input: UISchemaElement): string[] {
    const uiSchema = cloneDeep(input);
    const dataPaths = [];
    iterateSchema(uiSchema, (element) => {
      const scope = (element as ControlElement).scope;
      if (scope) {
        dataPaths.push(toDataPath(scope));
      }
    });
    return dataPaths;
  }

  isApplicableError(dataPaths: string[], error: ErrorObject): boolean {
    return !!dataPaths.find(dataPath => error.instancePath.startsWith(dataPath));
  }

  ngOnInit(): void {

  }
}
