import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-array-element-header",
  template: `
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="element-label"
      fxLayoutGap="10px"
    >
      <mat-label fxFlex="grow">{{label}}</mat-label>
      <button mat-icon-button (click)="removeClick.emit()"
              [matTooltip]="removeCaption"
              class="delete-button"
              color="primary">
        <mat-icon >close</mat-icon>
      </button>
    </div>

  `,
  styleUrls: ["./array-element-header.component.css"]
})
export class ArrayElementHeaderComponent {

  @Input()
  label: string;

  @Input()
  removeCaption: string;

  @Output()
  removeClick: EventEmitter<void> = new EventEmitter<void>();
}
