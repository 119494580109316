import {
  formatIs,
  or,
  RankedTester,
  rankWith
} from "@jsonforms/core";
import {ResourceType} from "../../resource/resource.type";

export const resourceRendererTester: RankedTester = rankWith(6,
  or(formatIs(ResourceType.image), formatIs(ResourceType.video)));
