import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-add-button-header",
  template: `
    <div class="control-label" fxLayout="row" fxLayoutAlign="start start">
      <button
        mat-icon-button
        (click)="addClick.emit()"
        [matTooltip]="addCaption"
        [attr.aria-label]="addCaption"
        color="primary"
        class="add-button"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  `,
  styleUrls: ["./add-button-header.component.css"]
})
export class AddButtonHeaderComponent {

  @Output()
  addClick: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  addCaption: string;
}
