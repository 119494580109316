import {GroupLayout} from "@jsonforms/core";
import {Meta} from "@rezonence/core";

const keys: (keyof Meta)[] = ["title", "labels"];

export const metaUiSchema: GroupLayout = {
  type: "Group",
  label: "Title and labels",
  elements: keys.map(key => ({type: "Control", scope: `#/properties/${key}`}))
};
