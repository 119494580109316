import { Component, AfterViewInit, ElementRef, ViewChild, EventEmitter, Input, Output, OnDestroy, ViewEncapsulation } from "@angular/core";
import { type Ace, edit } from "ace-builds";
import { Language } from "./language";
import { defaultEditorOptions } from "./default.editor.options";
import { EditorStyling } from "./editor.styling";
import { defaultStyling } from "./default.styling";
import { BehaviorSubject, combineLatest, ReplaySubject, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { BeautifierService } from "./beautifier.service";

@Component({
  selector: "rez-code-editor",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./code-editor.component.html",
  styleUrls: ["./code-editor.component.css"]
})
export class CodeEditorComponent implements AfterViewInit, OnDestroy {

  constructor(private beautifier: BeautifierService) {
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private subscriptions: Subscription[] = [];
  private beautify$ = new BehaviorSubject(true);
  private editorOptions$: Subject<Ace.EditorOptions> = new BehaviorSubject(defaultEditorOptions);

  @Input()
  set editorOptions(options: Partial<Ace.EditorOptions>) {
    this.editorOptions$.next({ ...defaultEditorOptions, ...options });
  }

  @Input()
  set beautify(beautify: boolean) {
    this.beautify$.next(beautify);
  }

  private customStyling$ = new BehaviorSubject(defaultStyling);
  private theme$ = this.customStyling$.pipe(map(o => `ace/theme/${o.theme}`));

  @Input()
  set customStyling(options: EditorStyling) {
    this.customStyling$.next({ ...defaultStyling, ...options });
  }

  private language$ = new ReplaySubject<Language>(1);
  private mode$ = this.language$.pipe(map(l => `ace/mode/${l}`));

  @Input()
  set language(language: Language) {
    this.language$.next(language);
  }

  private textInput$ = new ReplaySubject<string>(1);
  private text$ = this.textInput$
  .pipe(map(t => t || ""))
  .pipe(distinctUntilChanged());

  @Input()
  set text(input: string) {
    this.textInput$.next(input);
  }

  @Output()
  textChange = new EventEmitter<string>();

  @Output()
  isValid: EventEmitter<boolean> = new EventEmitter();

  @Output()
  error: EventEmitter<Ace.Annotation> = new EventEmitter();

  private editorContainer$ = new ReplaySubject<ElementRef<HTMLElement>>(1);

  private editor$ = this.editorContainer$
    .pipe(filter(c => !!c))
    .pipe(map(c => edit(c.nativeElement)));

  private session$ = this.editor$.pipe(map(e => e.getSession()));

  @ViewChild("editor")
  set editorContainer(container: ElementRef<HTMLElement>) {
    this.editorContainer$.next(container);
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      combineLatest([this.session$, this.mode$]).subscribe(([session, mode]) => session.setMode(mode)),
      combineLatest([this.session$, this.text$, this.beautify$]).subscribe(([session, text, beautify]) => this.applyNewText(session, text, beautify)),
      combineLatest([this.editor$, this.theme$]).subscribe(([editor, theme]) => editor.setTheme(theme)),
      combineLatest([this.editor$, this.customStyling$]).subscribe(([editor, customStyling]) => editor.container.style.lineHeight = `${customStyling.lineHeight}`),
      combineLatest([this.editor$, this.editorOptions$]).subscribe(([editor, editorOptions]) => editor.setOptions(editorOptions)),
      this.session$.subscribe(s => {
        s.setUseWorker(true);
        (s as any).on("changeAnnotation", () => this.validate(s.getAnnotations()))
      }),
      this.editor$.subscribe(e => e.on("input", () => this.onTextInput(e)))
    );
  }

  protected validate(annotations: Ace.Annotation[]) {
    const errors = this.toErrors(annotations);
    const firstError = errors.shift();
    if (firstError) {
      this.error.emit(firstError);
    }
    this.isValid.emit(!firstError);
  }

  protected onTextInput(e: Ace.Editor) {
    this.textChange.emit(e.getValue());
  }

  protected applyNewText(session: Ace.EditSession, text: string, beautify: boolean) {
    if (session.getValue() !== text) {
      session.setValue(text);
      if (beautify) {
        this.beautifier.beautify(session);
      }
    }
  }

  protected toErrors(annotations: Ace.Annotation[]): Ace.Annotation[] {
    return annotations.filter(annotation => annotation.type === "error");
  }

}
