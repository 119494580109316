import { Pipe, PipeTransform } from "@angular/core";
import {KeyValue} from "@angular/common";

@Pipe({
  name: "notEmpty"
})
export class NotEmptyPipe implements PipeTransform {

  transform<K, V>(input: KeyValue<K, V[]>[]): KeyValue<K, V[]>[] {
    return input.filter(entry => entry.value && entry.value.length);
  }

}
