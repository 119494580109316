<div *ngIf="config" class="answers">
  <h3 *ngIf="!validityMode">Answers</h3>
  <h3 *ngIf="validityMode">Validity</h3>
  <p *ngIf="!validityMode">Now for the answers. Try to keep them as short and sharp as possible so that readers can
    decide quickly. You can have between two and six answers for any given FreeWall.</p>
  <p *ngIf="validityMode">Let's set the validity now. Select at least one correct answer that will unlock your FreeWall.
  </p>

  <form>
    <div *ngFor="let answer of config.questions[0].answers; let i = index"
         fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="10px">
      <ng-container *ngIf="'Answer ' + (i + 1) as labelText">
        <rez-json-form [schema]="schema"
                       [uiSchema]="{type: 'Control', scope: '#', label: labelText}"
                       [(data)]="answer.ans"
                       fxFlex="fill"
                       *ngIf="!validityMode; else answerTextLabel"
                       (errors)="collectErrors(i, $event)">
        </rez-json-form>
        <ng-template #answerTextLabel>
          <mat-form-field fxFlex="fill" class="answer-input">
            <input matInput type="text" [value]="answer.ans" [disabled]="true" [placeholder]="labelText">
          </mat-form-field>
        </ng-template>
      </ng-container>


      <mat-checkbox [(ngModel)]="answer.tof"
                    [ngModelOptions]="{standalone: true}"
                    color="primary"
                    (change)="onValidityChange(config.questions[0].answers, i)"
                    [disabled]="checkboxDisabled(answer)"
                    *ngIf="validityMode">
        Correct
      </mat-checkbox>
      <button class="remove-answer"
              matTooltip="Remove answer {{i+1}}"
              mat-icon-button
              color="primary"
              (click)="removeAnswer(i)"
              *ngIf="!validityMode && config.questions[0].answers.length >= 3">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </form>


  <button mat-button color="primary"
          (click)="addAnswer()"
          [disabled]="config.questions[0].answers.length >= 6"
          matTooltip="Add another answer"
          *ngIf="!validityMode">
    <mat-icon>add</mat-icon> Add Answer
  </button>

</div>

