import {NgModule} from "@angular/core";
import {CodeEditorComponent} from "./code-editor.component";
import { require as aceRequire, config } from "ace-builds";
import {basePath} from "./base.path";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-beautify";
@NgModule({
    declarations: [
        CodeEditorComponent
    ],
    imports: [],
    exports: [
        CodeEditorComponent
    ]
})
export class CodeEditorModule {
    constructor() {
      config.set("basePath", basePath);
      aceRequire("ace/ext/language_tools");
    }
}
