import {Pipe, PipeTransform} from "@angular/core";
import {ScannedFile} from "@djabry/fs-s3";
import {ResourceType} from "../resource.type";
import {ResourceTypeService} from "./resource-type.service";

@Pipe({name: "byType"})
export class ByTypePipe implements PipeTransform {

  constructor(private resourceTypeService: ResourceTypeService) {
  }

  transform<T extends ScannedFile>(resources: T[], resourceType: ResourceType): T[] {
    return (resources || []).filter(resource => {
      const expectedResourceType = this.resourceTypeService.toResourceType(resource);
      return expectedResourceType.exists ? expectedResourceType.item === resourceType : false;
    });
  }

}
