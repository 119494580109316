import {
  Component,
  ContentChild,
  ElementRef,
  Input, OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import {CellDirective} from "../cell/cell.directive";
import {RowContainerDirective} from "../row-container/row-container.directive";
import {IdMapper} from "./id.mapper";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {GridService} from "./grid.service";
import {combineLatest, from, Observable, ReplaySubject, Subscription} from "rxjs";
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  skip,
} from "rxjs/operators";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.css"],
  providers: [GridService]
})
export class GridComponent implements OnInit, OnDestroy {

  @ViewChild(RowContainerDirective, {read: ElementRef, static: false})
  set rowContainer(ref: ElementRef) {
    if (ref) {
      this.rowContainerElement$.next(ref);
    }
  }

  @Input()
  set preferredWidth(input: number) {
    this.gridService.preferredWidth = input;
  }

  get preferredWidth(): number {
    return this.gridService.preferredWidth;
  }

  @Input()
  set currentItemId(id: string) {
    this.currentItemId$.next(id);
  }

  @ViewChild(CdkVirtualScrollViewport, {static: false})
  set scrollViewport(vp: CdkVirtualScrollViewport) {
    if (vp) {
      this.scrollViewport$.next(vp);
    }
  }

  itemHeight$: Observable<number>;

  @Input()
  gutter: number;

  @Input()
  items: any[];

  @Output()
  currentItemIdChange: Observable<string>;

  @ContentChild(CellDirective, {read: TemplateRef, static: true})
  cellTemplate: TemplateRef<any>;

  @Input()
  idMapper: IdMapper;

  private rowContainerElement$ = new ReplaySubject<ElementRef>();
  private scrollViewport$ = new ReplaySubject<CdkVirtualScrollViewport>(1);
  private subscriptions: Subscription[] = [];
  private currentItemId$ = new ReplaySubject<string>(1);
  private currentRowIndex$ = this.scrollViewport$.pipe(concatMap(vp => vp.scrolledIndexChange));

  constructor(public gridService: GridService) {
    this.currentItemIdChange = combineLatest([
      this.currentItemId$,
      this.scrollViewport$,
      this.gridService.numberOfColumns$,
      this.currentRowIndex$
    ]).pipe(debounceTime(200))
      .pipe(map(([id, vp, numberOfColumns, rowIndex]) => {
        this.scrollToItem(rowIndex, vp, id, numberOfColumns);
        return id;
      }))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay());
  }

  ngOnInit() {
    this.itemHeight$ = this.rowContainerElement$
      .pipe(map(ref =>  Math.round(ref.nativeElement.offsetHeight)))
      .pipe(first());

    this.subscriptions.push(combineLatest([
      this.gridService.numberOfColumns$,
      this.currentRowIndex$.pipe(skip(1))
    ]).pipe(map(([numberOfColumns, rowIndex]) => numberOfColumns * rowIndex))
      .pipe(map(itemIndex => this.idMapper(this.items[itemIndex])))
      .subscribe(this.currentItemId$));
  }

  trackBatch = (index: number, batch: any[]) => this.idMapper(batch[0]);

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private scrollToItem(currentRowIndex: number, vp: CdkVirtualScrollViewport, id: string, numberOfColumns: number) {
    const itemIndex = this.items.findIndex(item => this.idMapper(item) === id);
    if (itemIndex > -1) {
      const rowIndex = Math.floor(itemIndex / numberOfColumns);
      if (rowIndex !== currentRowIndex) {
        vp.scrollToIndex(rowIndex);
      }

    }
  }

}
