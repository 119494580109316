import { Component } from "@angular/core";

@Component({
    selector: "landing",
    template: `
        <div class="arrow-up"></div>
        <mat-card class="landing">
            <mat-card-title>
                Welcome to the YouGov FreeWall builder.
            </mat-card-title>
            <mat-card-content>
                <p>Use this wizard to create your own FreeWall and share it with colleagues.</p>
                <p>YouGov FreeWall guarantees: 100% attention, 100% viewability and 100% engagement.</p>
                <p>Click the <button mat-raised-button color="primary">Create FreeWall</button> button on the top left of your screen to start.</p>
            </mat-card-content>
        </mat-card>
    `,
  styleUrls: ["./LandingComponent.css"]
})
export class LandingComponent {

}
