import { UISchemaElement, VerticalLayout } from "@jsonforms/core";
import { freewallConfigSchema } from "@rezonence/core";
import { UiSchemaService } from "../../core/ui-schema.service";
import { Answer } from "@rezonence/sdk";

const uiSchemaService = new UiSchemaService();
const defaultAnswerFields: (keyof Answer)[] = ["tof", "ans"];
const arrayFields: (keyof Answer)[] = ["trackers", "tags"];

export const answerUiSchema: UISchemaElement = {
  type: "VerticalLayout",
  elements: Object.keys(freewallConfigSchema.definitions.Answer.properties)
    .map((field: keyof Answer) => {
      if (defaultAnswerFields.includes(field)) {
        return uiSchemaService.toControlElement({ field });
      } else if (arrayFields.includes(field)) {
        return {
          type: "VerticalLayout",
          elements: [uiSchemaService.toControlElement({ field })],
          rule: uiSchemaService.toRule({ field: "tof" })
        };
      } else {
        return {
          type: "Control",
          scope: uiSchemaService.toScope(uiSchemaService.fillControlElementRequest({ field })),
          rule: uiSchemaService.toRule({ field: "tof" })
        };
      }
    })
} as VerticalLayout;
