import {Component, Input, OnInit, Output} from "@angular/core";
import {FreeWall} from "@rezonence/core/freewall/freewall";
import {Answer} from "@rezonence/core/config-extractor";
import {freewallConfigSchema} from "@rezonence/core";
import {ControlElement} from "@jsonforms/core";
import {allControl} from "../all.control";
import {ReplaySubject} from "rxjs";
import {ErrorObject} from "ajv";

@Component({
  selector: "app-hint",
  templateUrl: "./hint.component.html",
  styleUrls: ["./hint.component.css"]
})
export class HintComponent implements OnInit {

  schema = freewallConfigSchema.definitions.Question.properties.hnt;
  uiSchema: ControlElement = allControl;

  @Output()
  errors: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  get incorrectAnswer(): Answer {
    if (this.config) {
      return this.config.questions[0].answers.find(answer => !answer.tof);
    }
  }

  @Input()
  config: FreeWall;

  get hint(): string {
    return this.config.questions[0].hnt;
  }

  set hint(input: string) {
    this.config.questions[0].hnt = input;
  }

  constructor() { }

  ngOnInit() {
  }

}
