import { Injectable } from "@angular/core";
import { truthyArrayItem } from "@rezonence/array-utils";
import upperFirst from "lodash/upperFirst";
import { FreewallMetaPair } from "../core/freewall.meta.pair";

@Injectable({
    providedIn: "root"
})
export class FreeWallTitleGenerator {

    toNumberedTitle(base: string, index: number): string {
        return [base, index].filter(truthyArrayItem).join(" ");
    }

    generate<T extends string>(freeWalls: FreewallMetaPair[], type: T): string {

        const existingTitles = freeWalls.map(f => (f.meta || {}).title || "");
        const base = `${upperFirst(type)} FreeWall`;
        let index = 0;
        while (existingTitles.includes(this.toNumberedTitle(base, index))) {
            index++;
        }
        return this.toNumberedTitle(base, index);
    }
}