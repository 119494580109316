import {Component, OnInit} from "@angular/core";
import {JsonFormsAngularService, JsonFormsControl} from "@jsonforms/angular";
import {MicrositeType} from "@rezonence/core/microsite/microsite.type";
import { FreeWallEditorContext } from "../freewall-editor/FreeWallEditorContext";

@Component({
  selector: "app-microsite-renderer",
  templateUrl: "./microsite-renderer.component.html",
  styleUrls: ["./microsite-renderer.component.css"]
})
export class MicrositeRendererComponent extends JsonFormsControl implements OnInit {

  preview = false;
  MicrositeType = MicrositeType;

  constructor(
    public context: FreeWallEditorContext,
    jsonFormsService: JsonFormsAngularService
  ) {
    super(jsonFormsService);
  }
}
