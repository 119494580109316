import { Injectable } from "@angular/core";
import { DatasetHook } from "../../core";
import { Optional } from "@rezonence/sdk";
import { Dataset, FreeWall } from "@rezonence/core";
import { version as currentVersion } from "@rezonence/git";
import valid from "semver/functions/valid";
import major from "semver/functions/major";

/**
 * Upgrade FWs to the latest version if they have an old major version
 */
@Injectable({
  providedIn: "root"
})
export class MinimumVersionHook extends DatasetHook<FreeWall> {

  constructor() {
    super(Dataset.Freewall);
  }

  toVersion(input: string): string {
    return input.replace("v", "");
  }

  toMajorVersion(input: FreeWall): Optional<number> {
    return Optional.of(input.version).map(tag => this.toVersion(tag)).map(v => valid(v) ? major(v) : Optional.empty());
  }

  isOld(majorVersion: number): boolean {
    return major(currentVersion) > majorVersion;
  }

  async transformValue(input: FreeWall): Promise<FreeWall> {
    const fwVersion = this.toMajorVersion(input);
    if (valid(currentVersion) && fwVersion.exists && this.isOld(fwVersion.item)) {
      return {
        ...input,
        version: undefined
      };
    }
    return input;
  }
}
