import {Injectable} from "@angular/core";
import {JsonSchema} from "@jsonforms/core";
import {SchemaError} from "./schema-error";
import {Optional} from "@rezonence/sdk";
import startCase from "lodash/startCase";

@Injectable({
  providedIn: "root"
})
export class ErrorMessageService {

  constructor() {
  }

  toErrorMessage(schema: JsonSchema, data: any, errors: SchemaError[]): string {
    return errors.length ? this.toErrorString(errors[0]) : "";
  }

  toDelimiter(dataPath: string): string {
    return dataPath.startsWith("/") ? "/" : ".";
  }

  toSubject(error: SchemaError): string {
    const fieldName = this.toFieldName(error.instancePath);
    return Optional.of(error.parentSchema && error.parentSchema.title)
      .map(o => o, () => error.schema && error.schema[fieldName] && error.schema[fieldName].title)
      .map(o => o, () => startCase(fieldName)).item;
  }

  toFieldName(dataPath: string): string {
    const delimiter = this.toDelimiter(dataPath);
    return dataPath.split(delimiter).pop();
  }

  toErrorString(error: SchemaError): string {
    return [this.toSubject(error), error.message].join(" ");
  }
}
