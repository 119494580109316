import { CompileFreewallRequest } from "../api/compile.freewall.request";
import { IdentifiedDashboardRequest } from "./identified.dashboard.request";
import { userStorageBucket } from "./user.storage.bucket";
import { Dataset } from "@rezonence/core";
import { demoFolder } from "./demo.folder";
import { ParsedDashboardRequest } from "./parsed.dashboard.request";
import { AdIdParts } from "./ad.id.parts";
import { S3File } from "@jabrythehutt/fs-s3";

export class DashboardRequestConverter {

  constructor() {
  }

  toIdentifiedRequest(request: ParsedDashboardRequest, identityId: string): IdentifiedDashboardRequest {
    return {
      ...request,
      identityId
    };
  }

  toResourceFolder(request: AdIdParts): S3File {
    return {
      bucket: userStorageBucket,
      key: `${request.identityId}/${Dataset.Freewall}/${request.recordId}`
    };
  }

  toAdId(request: AdIdParts): string {
    return `${demoFolder}/${request.identityId}/${request.recordFolder}/${request.recordId}`;
  }

  convert(request: IdentifiedDashboardRequest): CompileFreewallRequest<S3File> {
    const adId = this.toAdId(request);
    return {
      resourceFolders: [
        this.toResourceFolder(request)
      ],
      adId,
      config: request.config,
      options: request.options
    };
  }

}
