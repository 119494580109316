<div *ngIf="error$ | async as error">{{ error }}</div>

<ng-container *ngIf="freeWall$ | async as freeWall; else loader">
  <app-freewall-editor
    *ngIf="adId$ | async as adId"
    actionLabel="Done"
    [recordId]="freeWall.recordId"
    [(dataset)]="dataset"
    (freeWallSaved)="redirectToSource(adId)">
  </app-freewall-editor>
</ng-container>

<ng-template #loader>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
