<div *ngIf="config">
  <h3>
    Media slot 1
  </h3>
  <p>
    Now for the creative. Upload the image that you'd like to be displayed when the FreeWall loads into the article.
    The image should have an aspect ratio of 16:9 and the file should be no larger than 500Kb.
  </p>
  <rez-json-form [schema]="schema"
                 [uiSchema]="{type: 'Control', scope: '#'}"
                 [(data)]="image"
                 (errors)="errors.next($event)">
  </rez-json-form>
</div>

