import { Optional } from "@rezonence/sdk";
import { DisplayDemoRequest } from "./DisplayDemoRequest";
import { defaultDisplayDemoRequest } from "./defaultDisplayDemoRequest";
import { demoSiteOrigin } from "./demoSiteOrigin";

export function toDemoUrl(request: DisplayDemoRequest): URL {
  const url = new URL(demoSiteOrigin);
  const params = Object.entries(request)
    // Remove undefined values
    .filter(([_, value]) => Optional.of(value).exists)
    // Strip default values
    .filter(([key, value]) => !(defaultDisplayDemoRequest[key] === value))
    .map(([key, value]) => [key, `${value}`]);
  const searchParams = new URLSearchParams(params);
  url.search = searchParams.toString();
  return url;
}
