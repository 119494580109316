import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FreeWall} from "@rezonence/core/freewall/freewall";
import { Answer, FreeWallResponse } from "@rezonence/core/config-extractor";
import {WizardService} from "../wizard/wizard.service";
import {ResponseMode} from "../response/response.mode";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: "mapper",
  templateUrl: "./mapper.component.html",
  styleUrls: ["./mapper.component.css"]
})
export class MapperComponent implements OnInit {

  @Input()
  config: FreeWall;

  @Input()
  mode: ResponseMode;

  @Input()
  answer: Answer;

  @Input()
  freeWallId: string;

  get letter(): string {
    return this.mode.toString().substr(0, 1);
  }

  get responseIndex(): number {
    return this.answer[`${this.letter}ix`];
  }

  @Input()
  set responseIndex(ix: number) {
    this.answer[`${this.letter}ix`] = ix;

  }

  @Input()
  advanced: boolean;

  get ramdom(): boolean {
    return this.responseIndex === -1;
  }

  get none(): boolean {
    return this.responseIndex === -2;
  }

  get response(): FreeWallResponse {
    return this.config[`${this.mode}s`][this.responseIndex];
  }

  constructor(private dialog: MatDialog,
              public wizardService: WizardService) {

  }

  createNew() {
    const responses = this.config[`${this.mode}s`];
    const newResponse = this.wizardService.mode.createResponse(this.config, this.mode);
    responses.push(newResponse);
    this.responseIndex = responses.length - 1;
  }

  ngOnInit() {
  }

}
