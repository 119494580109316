import { Injectable } from "@angular/core";
import { SaveItemRequest } from "@rezonence/core";
import { SaveHook } from "@rezonence/freewall-db-hooks";

@Injectable({
    providedIn: "root"
})
export class SaveRecord implements SaveHook {

    public recordIds: string[] = [];

    constructor() {
    }

    async transform(input: SaveItemRequest<any>): Promise<SaveItemRequest<any>> {
        this.recordIds.push(input.recordId);
        return input;
    }

}
