<mat-accordion>
  <div *ngFor="let microsite of microsites; trackBy: trackElement" (click)="micrositeClicked.emit(microsite)">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{microsite.title}}
        </mat-panel-title>
        <div>
          <mat-panel-description>
            {{microsite.templateId | titlecase}}
          </mat-panel-description>
        </div>
      </mat-expansion-panel-header>
      <app-microsite [microsite]="microsite" [recordId]="recordId" [freeWallConfig]="freeWallConfig">
      </app-microsite>
    </mat-expansion-panel>
  </div>
</mat-accordion>
