import { Injectable } from "@angular/core";
import { MicrositeResolver, TemplatedMicrosite } from "@rezonence/core";
import { CreativeConfigDownloader } from "../importer/creative.config.downloader";
import { CreativeMicrositeSourceParser } from "./CreativeMicrositeSourceParser";
import { FreeWallConfig } from "@rezonence/sdk";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class CreativeMicrositeResolver extends MicrositeResolver {

  constructor(sourceParser: CreativeMicrositeSourceParser, downloader: CreativeConfigDownloader) {
    super(sourceParser, downloader);
  }

  async extractFromConfig(config: FreeWallConfig, adId: string): Promise<TemplatedMicrosite[]> {
    return this.toMicrosites({ cdn: new URL(environment.defaultCDN), adId }, config);
  }

}
