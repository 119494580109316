import { SchemaValidator } from "@rezonence/schema-validator";
import { Injectable } from "@angular/core";
import Ajv from "ajv";

@Injectable({
  providedIn: "root"
})
export class Validator extends SchemaValidator {

  constructor(ajv: Ajv) {
    super(ajv);
  }

}
