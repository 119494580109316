import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { JsonFormsRendererRegistryEntry, JsonSchema, UISchemaElement } from "@jsonforms/core";
import Ajv, { ErrorObject } from "ajv";
import { JsonFormService } from "./JsonFormService";

@Component({
  selector: "rez-json-form",
  template: `
    <jsonforms
      [uischema]="uiSchema"
      [schema]="schema | resolveRefs | async"
      [data]="data"
      [renderers]="renderers"
      (errors)="errors.emit($event)"
      (dataChange)="dataChange.emit($event)"
      [ajv]="ajv">
    </jsonforms>`
})
export class JsonFormComponent implements OnInit {

  @Input()
  schema: JsonSchema;

  @Input()
  data: any;

  @Input()
  uiSchema: UISchemaElement;

  @Output()
  errors: EventEmitter<ErrorObject[]> = new EventEmitter<ErrorObject[]>();

  @Output()
  dataChange: EventEmitter<any> = new EventEmitter<any>();

  renderers: JsonFormsRendererRegistryEntry[];

  constructor(public ajv: Ajv, public formService: JsonFormService) { }

  ngOnInit() {
    this.renderers = this.formService.renderers;
  }
}
