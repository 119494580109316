import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Route } from "@rezonence/freewall-creator-config"
import { Optional } from "@rezonence/sdk";
import { Observable, Subscription } from "rxjs";
import { AnalyticsService } from "./analytics";
import { RouteResolver } from "./RouteResolver";
import { ToolbarVisibilityResolver } from "./ToolbarVisibilityResolver";

@Component({
  selector: "app-root",
  templateUrl: "./AppComponent.html",
  styleUrls: ["./AppComponent.css"]
})
export class AppComponent {

  readonly displayToolbar$: Observable<boolean>;
  readonly pageAnalytics: Subscription;

  private readonly titles = {
    [Route.Demo]: "FreeWall Demo",
    [Route.Edit]: "Edit FreeWall"
  }

  constructor(routeResolver: RouteResolver, title: Title, analyticsService: AnalyticsService, toolbarVis: ToolbarVisibilityResolver) {
    this.displayToolbar$ = toolbarVis.toolbarVisible$;
    this.pageAnalytics = analyticsService.recordPages();
    routeResolver.route$.subscribe(route => title.setTitle(this.toTitle(route)));
  }

  toTitle(route: Optional<string>): string {
    return this.titles[route.item] ?? "YouGov FreeWall Builder"
  }
}
