<div class="grid-row" fxLayout="row" fxLayoutAlign="start center" [fxLayoutGap]="gutter + 'px'">
  <ng-container *ngIf="(100 / rowLength) + '%' as itemWidth">
    <ng-container *ngFor="let itemContainer of itemContainers; trackBy: trackElement; let i = index;">
      <div [fxFlex]="itemWidth">
        <ng-container *ngIf="itemContainer.exists">
          <ng-container *ngTemplateOutlet="cellTemplate; context: {item: itemContainer.item, first: i === 0, last: i === rowLength - 1}">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

