import { ExchangeTokenRequest, ExchangeTokenResponse, TokenExchangeClient } from "@rezonence/magic-link";
import { Injectable } from "@angular/core";
import { InfrastructureResolver } from "../core/InfrastructureResolver";
import { Observable, ReplaySubject, Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class TokenExchangeService extends TokenExchangeClient {

    token$: Observable<ExchangeTokenResponse>;
    protected tokenSource$: Subject<ExchangeTokenResponse> = new ReplaySubject(1);

    constructor(infra: InfrastructureResolver) {
        super(new URL(infra.config.magic.endpoint));
        this.token$ = this.tokenSource$.asObservable();
    }

    async exchange(request: ExchangeTokenRequest): Promise<ExchangeTokenResponse> {
        const response = await super.exchange(request);
        this.tokenSource$.next(response);
        return response;
    }
}