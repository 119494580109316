import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { FreeWall } from "@rezonence/core/freewall/freewall";
import {WizardService} from "../wizard/wizard.service";
import {ResponseMode} from "../response/response.mode";
import {Answer} from "@rezonence/sdk";

@Component({
  selector: "app-responses",
  templateUrl: "./responses.component.html",
  styleUrls: ["./responses.component.css"]
})
export class ResponsesComponent implements OnInit {

  slotIndexes: Record<ResponseMode, number> = {
    [ResponseMode.kicker]: 3,
    [ResponseMode.response]: 2
  };

  slotPositions: Record<ResponseMode, string> = {
    [ResponseMode.response]: "top",
    [ResponseMode.kicker]: "bottom"
  };

  @Input()
  freeWallId: string;

  advanced: boolean;

  private _config: FreeWall;

  private _answerIndex: number;
  private _mode: ResponseMode;

  get displayAdvancedSelector(): boolean {
    return !this.isAdvanced;
  }

  get isAdvanced(): boolean {

    const responseIndices: number[] = [];

    return this.config && !!this.config.questions[0].answers.find(answer => {
      // Find an answer that contains an advanced response configuration:
      // Either the response is random or it points to the same response as another answer
      if (!answer.tof) {

        return false;

      } else {

        const responseIndex = answer[`${this.modeLetter}ix`];
        // It is advanced if a response is random, set to none or used for another answer
        if (responseIndex <= -1 || responseIndices.indexOf(responseIndex) > -1 ) {
          return true;
        } else {
          responseIndices.push(responseIndex);
          return false;
        }

      }

    });
  }

  @Input()
  set config(config: FreeWall) {
    this._config = config;
    this.advanced = this.isAdvanced;
    // this.updateResponseIndex();
  }

  get config(): FreeWall {
    return this._config;
  }

  // Response or kicker
  @Input()
  set mode(m: ResponseMode) {
    this._mode = m;
  }

  get mode(): ResponseMode {
    return this._mode;
  }

  get slotPosition(): string {
    return this.slotPosition[this.mode];
  }

  get slotIndex(): number {
    return this.slotIndexes[this.mode];
  }

  get top(): boolean {
    return this.mode === ResponseMode.response;
  }

  get modeLetter(): string {
    return this.mode ? this.mode.toString().substr(0, 1) : undefined;
  }

  @Input()
  set answerIndex(aix: number) {
    this._answerIndex = aix;
  }

  get answerIndex(): number {
    return this._answerIndex;
  }

  get answer(): Answer {
    return this.config.questions[0].answers[this.answerIndex];
  }

  get singleResponse(): boolean {
    const answers = this.config.questions[0].answers;
    const responseIndexField = `${this.modeLetter}ix`;
    const firstResponseIndex = answers[0][responseIndexField];
    return answers.every(answer => answer[responseIndexField] === firstResponseIndex);
  }

  constructor(public wizardService: WizardService) {

  }

  ngOnInit() {
  }

}
