import { TemplatedMicrosite } from "../freewall";
import { LinkMacro, LinkMacroRenderer, Optional, configPathQueryParam, downloadJson, prefixMacro } from "@rezonence/sdk";
import { MicrositeType } from "./microsite.type";
import { MicrositeLinkResolver } from "./MicrositeLinkResolver";
import { ParseMicrositeSourceRequest } from "./ParseMicrositeSourceRequest";
import { MicrositeEntryScript } from "./microsite.entry.script";
import { configFileName } from "@rezonence/sdk/microsite/config.file.name";
import { micrositeDestinationFolder } from "./microsite.destination.folder";

export class MicrositeSourceParser {

  constructor(private linkRenderer: LinkMacroRenderer) {
  }

  toMicrositeConfigLink(micrositeSource: string): Optional<string> {
    if (micrositeSource.startsWith(`${prefixMacro}/${micrositeDestinationFolder}`) && micrositeSource.endsWith(MicrositeEntryScript.index)) {
      return Optional.of(micrositeSource.replace(MicrositeEntryScript.index, configFileName));
    } else if (micrositeSource.startsWith(MicrositeLinkResolver.baseTemplateLink())) {
      const parts = micrositeSource.split("?");
      const searchParams = new URLSearchParams(parts.pop());
      return Optional.of(searchParams.get(configPathQueryParam));
    }
    return Optional.empty();
  }

  async parseMicrositeConfig(configUrl: URL): Promise<TemplatedMicrosite> {
    // Structure of microsite config path is https://..../[templateId]/[id]/config.json, look at the last 3 parts to get the params
    const parts = configUrl.pathname.split("/").slice(-3);
    const [templateId, id] = parts;
    const optionalConfig = await downloadJson(configUrl);
    return {
      templateId: templateId as MicrositeType,
      id,
      config: optionalConfig.item
    }
  }

  renderSource(request: ParseMicrositeSourceRequest): URL {
    const renderedLink = this.linkRenderer.renderAll({
      valueByMacro: {
        [LinkMacro.Cdn]: request.cdn.toString(),
        [LinkMacro.AdId]: request.adId
      },
      link: request.micrositeSource
    });
    return new URL(renderedLink);
  }

  async parse(request: ParseMicrositeSourceRequest): Promise<Optional<TemplatedMicrosite>> {
    const micrositeConfigUrl = this.toMicrositeConfigLink(request.micrositeSource).map(micrositeSource => this.renderSource({
      ...request,
      micrositeSource
    }));
    return Optional.switchPromise(micrositeConfigUrl.map(configUrl => this.parseMicrositeConfig(configUrl)));
  }
}
