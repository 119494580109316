import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/CoreModule";
import { MatDialogModule } from "@angular/material/dialog";
import { QuickstartService, QuickstartOperation, CreateModule } from "../create";
import { TypeformQuickstartService } from "./typeform/TypeformQuickstartService";
import { DataQuickstartService } from "./data/DataQuickstartService";
import { FreewallMetaPair } from "../core/freewall.meta.pair";
import { Optional } from "@rezonence/sdk";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    CreateModule,
    MatDialogModule
  ]
})
export class QuickstartModule {

  constructor(createService: QuickstartService,
    typeformQuickstartService: TypeformQuickstartService,
    dataQuickstartService: DataQuickstartService) {
    const creatorByOperation: Partial<Record<QuickstartOperation, (existing: FreewallMetaPair[]) => Promise<Optional<FreewallMetaPair>>>> = {
      [QuickstartOperation.Typeform]: (existing: FreewallMetaPair[]) => typeformQuickstartService.createTypeform(existing),
      [QuickstartOperation.Data]: (existing: FreewallMetaPair[]) => dataQuickstartService.createSurvey(existing)
    };
    for (const operation of Object.keys(creatorByOperation)) {
      createService.registerCreator(operation as QuickstartOperation, creatorByOperation[operation]);
    }
  }

}
