import { Pipe, PipeTransform } from "@angular/core";
import {MicrositeSchema} from "@rezonence/core";
import {MicrositeType} from "@rezonence/core/microsite/microsite.type";
import { CreativeMicrositeSchemaResolver } from "../CreativeMicrositeSchemaResolver";
import { FreeWallEditorContext } from "../../data-editor";
import { FreewallEditorService } from "../../data-editor/freewall-editor/FreewallEditorService";
import { FreeWallConfig } from "@rezonence/sdk";
import { tag } from "@rezonence/git";

@Pipe({
  name: "micrositeSchema"
})
export class MicrositeSchemaPipe implements PipeTransform {

  constructor(private micrositeSchemaResolver: CreativeMicrositeSchemaResolver, private context: FreeWallEditorContext, private freewallEditorService: FreewallEditorService) {
  }

  async transform(micrositeType: MicrositeType): Promise<MicrositeSchema> {
    const schemaAndValues = await this.freewallEditorService.getValuesForRecordId(this.context.recordId);
    const fwConfig = schemaAndValues.freewall.data as FreeWallConfig;
    const value = await this.micrositeSchemaResolver.resolveSchema({ templateId: micrositeType, version: fwConfig.version || tag });
    return value.item;
  }

}
