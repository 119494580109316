import { Pipe, PipeTransform } from "@angular/core";
import {CodeDataset} from "@rezonence/core";
import {Language} from "@rezonence/code-editor";

@Pipe({
  name: "mode"
})
export class ModePipe implements PipeTransform {

  modeByDataset: Record<CodeDataset, Language> = {
    [CodeDataset.Css]: Language.Css,
    [CodeDataset.CustomCode]: Language.Js
  };

  transform(dataset: CodeDataset): Language {
    return this.modeByDataset[dataset];
  }

}
