<app-array-template
  [(data)]="data"
  [itemTitle]="'microsite'"
  [label]="'Microsites'"
  [minItems]="scopedSchema.minItems || 0"
  [itemFactory]="itemFactory"
  (itemRemoved)="renderer.schemaService.removeFromSchema(schema, $event)">
  <ng-template let-index="index">
    <jsonforms-outlet
      [schema]="scopedSchema"
      [uischema]="micrositeUiSchema"
      [path]="propsPath | composePath: index.toString()">
    </jsonforms-outlet>
  </ng-template>
</app-array-template>
