import { Component, Input, OnInit } from "@angular/core";
import { Route } from "@rezonence/freewall-creator-config";
import { Router } from "@angular/router";

@Component({
  selector: "app-back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.css"]
})
export class BackButtonComponent {

  @Input()
  recordId: string;

  constructor(private router: Router) { }

  async back(): Promise<void> {
    await this.router.navigate([Route.Home], {
      queryParams: { id: `${this.recordId}` },
      queryParamsHandling: "merge"
    });
  }

}
