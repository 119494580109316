import { Pipe, PipeTransform } from "@angular/core";
import {JsonSchema, resolveSchema} from "@jsonforms/core";

@Pipe({
  name: "schemaSection"
})
export class SchemaSectionPipe implements PipeTransform {

  transform(schema: JsonSchema, schemaPath: string, rootSchema?: JsonSchema): JsonSchema {
    return resolveSchema(schema, schemaPath, rootSchema);
  }

}
