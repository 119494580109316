import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ImporterQueryParams, importerQueryParamsSchema } from '@rezonence/freewall-creator-config';
import { map, switchMap, shareReplay } from 'rxjs';
import { CreativeFreeWallImporter } from '../../importer/CreativeFreeWallImporter';
import { ImportFreewallRequest } from '../../importer/import.freewall.request';
import { Validator } from '../Validator';

@Injectable()
export class InPlaceEditorService {
  readonly importedFreeWall$ = this.activatedRoute.queryParams.pipe(
    map((queryParams) => this.validator.validate(queryParams as ImporterQueryParams, importerQueryParamsSchema)),
    map((params) => this.toImportFreeWallRequest(params)),
    switchMap((importRequest) => this.importer.importFreeWall(importRequest)),
    shareReplay(1)
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private importer: CreativeFreeWallImporter,
    private validator: Validator
  ) { }

  private toImportFreeWallRequest(
    params: ImporterQueryParams
  ): ImportFreewallRequest {
    return {
      meta: {
        title: params.sourceId,
        temporary: true,
        labels: []
      },
      adId: params.sourceId,
      includeTrackers: true
    };
  }

}
