import { IMicrosite } from './imicrosite'
import { FreeWallEvent } from './freewall.event'
import { InViewEvent } from './in.view.event'
import { Placement } from './placement'
import { Subject } from './subject'
import { TransitionData } from './transition.data'
import { SourceData } from './source.data'
import { SizeData } from './size.data'
import { FreeWallData } from './freewall.data'
import { messagePrefix } from './message.prefix'
import { InViewData } from './in.veiw.data'
import { AnswerClickData } from './answer.click.data'
import { LinkMacro, LinkMacroRenderer, sleep } from '../utils'
import { ErrorMessage } from './error.message'
import { micrositeConfigMessagePrefix } from './microsite.config.message.prefix'
import fetch from 'cross-fetch'
import { configFileName } from './config.file.name'
import { configQueryParam } from './config.query.param'
import { configPathQueryParam } from './config.path.query.param'
import { legacyMessagePrefix } from './legacy.message.prefix'
import { isRestrictedContext } from './is.restricted.context'
import { AnswerInteractionEvent } from './answer.interaction.event'

export class Microsite<M extends string = LinkMacro> implements IMicrosite {
  stringStarts: string[] = ['FreeWall:', messagePrefix]

  existingConfig: any

  freeWallDataPromise: Promise<FreeWallData>

  freeWallWindowPromise: Promise<Window>

  placementPromise: Promise<Placement>

  constructor (protected linkRenderer: LinkMacroRenderer<LinkMacro | M>, public context: Window = window) {
  }

  isFriendly (): boolean {
    return !isRestrictedContext(this.context)
  }

  getFreeWallData (): Promise<FreeWallData> {
    this.freeWallDataPromise = this.freeWallDataPromise || this.requestFreeWallData()
    return this.freeWallDataPromise
  }

  async getCdn (): Promise<string> {
    const data = await this.getFreeWallData()
    const url = new URL(data.trackerSource)
    return `${url.origin}/`
  }

  async askFreeWallForPlacement (targetWindow: Window): Promise<Placement> {
    return new Promise<Placement>(resolve => {
      const freeWallDataHandler = event => {
        if (event.source && event.source === targetWindow) {
          const freeWallEvent = this.parseEvent<FreeWallData>(event)
          if (freeWallEvent && freeWallEvent.dat && typeof freeWallEvent.dat.placement === 'number') {
            this.context.removeEventListener('message', freeWallDataHandler)
            resolve(freeWallEvent.dat.placement)
          }
        }
      }

      this.context.addEventListener('message', freeWallDataHandler)
      targetWindow.postMessage(messagePrefix + JSON.stringify({ src: 'UNKNOWN' }), '*')
    })
  }

  async rejectAfterPeriod<T> (period: number, errorMessage: string): Promise<T> {
    await sleep(period)
    throw new Error(errorMessage)
  }

  async findFreeWallWindow (): Promise<Window> {
    let targetWindow = this.context.parent
    const windows = [targetWindow]
    while (targetWindow !== this.context.top) {
      targetWindow = targetWindow.parent
      windows.push(targetWindow)
    }

    return Promise.race(
      windows.map(async w => {
        const placement = await this.askFreeWallForPlacement(w)
        return w
      }).concat([
        this.rejectAfterPeriod<Window>(1000, ErrorMessage.FreeWallNotFound)
      ]))
  }

  async getFreeWallWindow (): Promise<Window> {
    this.freeWallWindowPromise = this.freeWallWindowPromise || this.findFreeWallWindow()
    return this.freeWallWindowPromise
  }

  async requestFreeWallData (): Promise<FreeWallData> {
    const targetWindow = await this.getFreeWallWindow()
    const placement = await this.getPlacement()
    return new Promise<FreeWallData>(resolve => {
      const freeWallDataHandler = async event => {
        if (event.source === targetWindow) {
          const freeWallEvent = this.parseEvent<FreeWallData>(event)
          if (freeWallEvent && freeWallEvent.dat && typeof freeWallEvent.dat.placement === 'number') {
            if (freeWallEvent.dat.trackerSource) {
              this.context.removeEventListener('message', freeWallDataHandler)
              // If it's the response to the request for data then complete the process
              resolve(freeWallEvent.dat)
            }
          }
        }
      }

      this.context.addEventListener('message', freeWallDataHandler)
      this.sendMessageToFreeWallWithPlacement(placement, Subject.Data, targetWindow)
    })
  }

  trackClick (data?: SourceData): Promise<void> {
    return this.sendMessageToFreeWall(Subject.Click, data)
  }

  trackEngagement (data?: SourceData): Promise<void> {
    return this.sendMessageToFreeWall(Subject.Engagement, data)
  }

  transitionFreeWall (data?: TransitionData): Promise<void> {
    return this.sendMessageToFreeWall(Subject.Transition, data)
  }

  answerClicked (data: AnswerClickData): Promise<void> {
    return this.sendMessageToFreeWall(Subject.AnswerClick, data)
  }

  unlockFreeWall (data?: TransitionData): Promise<void> {
    return this.sendMessageToFreeWall(Subject.Unlock, data)
  }

  notifyReady<T> (data?: T): Promise<void> {
    return this.sendMessageToFreeWall<T>(Subject.Ready, data)
  }

  sendSize (data?: SizeData): Promise<void> {
    return this.sendMessageToFreeWall(Subject.Size, data)
  }

  toMessageString<T> (event: FreeWallEvent<T>): string {
    return messagePrefix + JSON.stringify(event)
  }

  sendMessageToFreeWallWithPlacement<T> (placement: keyof Placement,
    subject: Subject,
    targetWindow: Window,
    data?: T): void {
    const message: FreeWallEvent<T> = { sub: subject, src: placement, dat: data }
    targetWindow.postMessage(this.toMessageString(message), '*')
  }

  async sendMessageToFreeWall<T> (subject: Subject, data?: T) {
    const targetWindow = await this.getFreeWallWindow()
    const placement = await this.getPlacement()
    await this.sendMessageToFreeWallWithPlacement(placement, subject, targetWindow, data)
  }

  toPlacementString (placementNumber: Placement): keyof Placement {
    return Placement[placementNumber] as keyof Placement
  }

  async getPlacement (): Promise<keyof Placement> {
    this.placementPromise = this.placementPromise || this.askFreeWallForPlacement(await this.getFreeWallWindow())
    const placement = await this.placementPromise
    return this.toPlacementString(placement)
  }

  /**
     * Whether the event is a FreeWall event
     * @param event {object} - The message event to check
     * @returns {boolean} - Whether it's a valid FreeWall event
     */
  isFreeWallEvent (event: MessageEvent): boolean {
    if (event.data && typeof event.data === 'string') {
      return !!this.stringStarts.find(stringStart => {
        return event.data.substring(0, stringStart.length) === stringStart
      })
    }

    return false
  }

  /**
     * Convert the event to a FreeWall event
     * @param event {object} - The message event to parse
     * @returns {object} - The FreeWall event or null if it's not a valid message
     */
  parseEvent<T> (event: MessageEvent): FreeWallEvent<T> {
    if (this.isFreeWallEvent(event)) {
      const stringStart = this.stringStarts.find(start => {
        return event.data.substring(0, start.length) === start
      })

      const eventString = event.data.replace(stringStart, '')
      return JSON.parse(eventString) as FreeWallEvent<T>
    }

    return null
  }

  /**
     * Listen for all in view events
     * @param callback
     */
  listenForViewChange (callback: (inView: InViewEvent) => void) {
    this.listenForFreeWallEvent((event) => {
      if (event.dat && typeof (event.dat as InViewData).inView === 'boolean') {
        callback(event as InViewEvent)
      }
    }, Subject.View)
  }

  /**
     * Listen for all FreeWall event types
     * @param callback {function} - The callback accepting the FreeWall event
     */
  async listenForFreeWallEvent (callback: (freeWallEvent: FreeWallEvent<any>) => void, subject?: Subject) {
    this.context.addEventListener('message', (event) => {
      const freewallEvent = this.parseEvent(event)

      if (freewallEvent) {
        if (!subject || freewallEvent.sub === subject) {
          callback(freewallEvent)
        }
      }
    })

    // Request FreeWall info using the legacy format
    this.context.top.postMessage(legacyMessagePrefix, '*')

    // Request FullFlex FreeWall for info
    await this.sendMessageToFreeWall(Subject.Data)
  }

  listenForAnswerInteraction (callback: (event: AnswerInteractionEvent) => void) {
    this.listenForFreeWallEvent(freeWallEvent => callback(freeWallEvent as AnswerInteractionEvent), Subject.AnswerInteraction)
  }

  /**
     * Listen to answer click events
     * @param callback {function}  - The callback accepting the answer click event
     */
  listenForAnswerClick (callback: (answerClickEvent: AnswerInteractionEvent) => void) {
    this.listenForFreeWallEvent(freeWallEvent => callback(freeWallEvent as AnswerInteractionEvent), Subject.AnswerClick)
  }

  /**
     * Attempt to get the FreeWall config from a window message
     */
  listenForConfig<T> (): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const configListener = event => {
        if (event.data && typeof event.data === 'string') {
          if (event.data.startsWith(`${micrositeConfigMessagePrefix}:`)) {
            const configObjectString = event.data.replace(`${micrositeConfigMessagePrefix}:`, '')
            const configObject = JSON.parse(configObjectString) as T
            resolve(configObject)
            this.context.removeEventListener('message', configListener)
          }
        }
      }

      this.context.addEventListener('message', configListener)

      this.context.parent.postMessage(`${micrositeConfigMessagePrefix}?`, '*')
    })
  }

  /**
     *
     * @param callback {function} - A callback function that receives a possible error and the microsite config
     * @returns {Promise<T>}
     */
  async extractConfig<T>(): Promise<T> {

    if (this.existingConfig) {
      return (this.existingConfig as T)
    } else {
      const url = new URL(this.context.location.href)
      const configPath = url.searchParams.get(configPathQueryParam) || configFileName
      const rawConfig = url.searchParams.get(configQueryParam)

      if (rawConfig) {
        const parsedConfig = JSON.parse(rawConfig) as T
        this.existingConfig = parsedConfig
        return parsedConfig
      } else {
        try {
          const response = await fetch(configPath)
          this.existingConfig = response.json()
          return this.existingConfig
        } catch (err) {
          // If the config object doesn't exist in the directory then listen for the message
          this.existingConfig = await this.listenForConfig<T>()
          return this.existingConfig
        }
      }
    }
  }
}
