import { Injectable } from "@angular/core";
import { LambdaClient, Stage } from "@rezonence/serverless";
import { AWSService } from "./AWSService";
import Lambda from "aws-sdk/clients/lambda";
import { FunctionNameService } from "./function.name.service";
import { serviceName, CompilationRequestType } from "@rezonence/freewall-compiler-functions";
import { CloudCompileRequest } from "@rezonence/freewall-creator-config";
import { CodeExtensionService } from "./CodeExtensionService";
import { CodeDataset, CodeItem, Microsite, MicrositeLinkResolver, MicrositeType, TemplatedMicrosite } from "@rezonence/core";
import { CreativeFileService } from "./CreativeFileService";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CloudFreewallCompiler extends LambdaClient<CloudCompileRequest, void> {

  constructor(private awsService: AWSService,
    functionNameService: FunctionNameService,
    private fileService: CreativeFileService,
    private extensionFileService: CodeExtensionService) {
    super(awsService.getService(Lambda), functionNameService.resolve({
      serviceName,
      nameSuffix: CompilationRequestType.Dashboard
    }, Stage.Production));
  }

  async writeCustomCodeFiles(input: CloudCompileRequest): Promise<void> {
    await Promise.all(Object.values(CodeDataset)
      .filter(v => input[v])
      .map(v => [v, input[v]] as [CodeDataset, CodeItem])
      .map(([dataType, value]) => this.extensionFileService.writeExtensionFile(dataType, value, input.recordId)));
  }

  toMicrositeDestinationKey(m: TemplatedMicrosite, params: { identityId: string, recordId: string }): string {
    const suffix = MicrositeLinkResolver.toConfigLinkSuffix(m);
    const assetPrefix = this.fileService.toFreeWallAssetPrefix(params);
    return `${assetPrefix}/${suffix}`;
  }

  async writeMicrosites(microsites: Microsite[], recordId: string): Promise<void> {
    const identityId = await this.awsService.identityId();
    await Promise.all(microsites.filter(m => m.config && m.templateId !== MicrositeType.existing).map(m => this.fileService.write(JSON.stringify(m.config), {
      bucket: environment.userStorageBucket,
      key: this.toMicrositeDestinationKey(m, { identityId, recordId })
    })))
  }

  async invoke(input: CloudCompileRequest): Promise<void> {
    await Promise.all([
      this.writeCustomCodeFiles(input),
      this.writeMicrosites(input.microsites, input.recordId)
    ]);
    return super.invoke({
      options: {
        transcode: true
      },
      ...input,
      microsites: []
    });
  }
}
