import {Injectable} from "@angular/core";
import {LoadingMessageComponent} from "../../core/loading-message/loading-message.component";
import {MatDialog} from "@angular/material/dialog";
import {CreativeFreeWallDB} from "../../core/CreativeFreeWallDB";
import {AWSService} from "../../core/AWSService";
import {CreativeFileService} from "../../core/CreativeFileService";
import {Dataset, DatasetItem, FreeWall, Meta} from "@rezonence/core";
import cloneDeep from "lodash/cloneDeep";
import {FreewallMetaPair} from "../../core/freewall.meta.pair";
import {Optional} from "@rezonence/sdk";
import { WizardConfig, WizardDialogService } from "@rezonence/ng-schema-wizard";
import {schemaByDataset} from "../../data-editor/schema/schema.by.dataset";
import {UISchemaElement} from "@jsonforms/core";
import {FreewallOrganiserService} from "../freewall/FreewallOrganiserService";

@Injectable({
  providedIn: "root"
})
export class CloneService {

  constructor(public dialog: MatDialog,
              private freeWallDB: CreativeFreeWallDB,
              private awsService: AWSService,
              private wizardDialogService: WizardDialogService,
              private fileService: CreativeFileService,
              private freewallOrganiserService: FreewallOrganiserService) {
  }

  createWizardConfig(meta: Meta): WizardConfig<Meta> {
    const titleKey: keyof Meta = "title";
    return {
      data: meta,
      schema: schemaByDataset[Dataset.Meta],
      steps: [{
        uiSchema: {
          label: "Title",
          type: "Control",
          scope: `#/properties/${titleKey}`} as UISchemaElement
      }]
    };
  }

  async cloneFreeWall(freeWall: FreewallMetaPair): Promise<Optional<FreewallMetaPair>> {
    const wizardConfig = this.createWizardConfig(cloneDeep(freeWall.meta));
    const optionalNewFreeWall = await this.wizardDialogService.requestData(wizardConfig);
    return Optional.switchPromise(optionalNewFreeWall
      .map(async meta => await this.cloneFreeWallData(freeWall, meta)));
  }

  async cloneFreeWallData(freeWall: FreewallMetaPair, meta: Meta): Promise<FreewallMetaPair> {
    const loadingRef = this.dialog.open(LoadingMessageComponent, {disableClose: true});
    loadingRef.componentInstance.progressMessage = "Cloning FreeWall...";
    const identityId = await this.awsService.identityId();
    let recordId: string;
    for (const dataset of Object.values(Dataset)) {
      const itemToClone = await this.freeWallDB.getDatasetItem(dataset, freeWall.recordId);
      if (itemToClone) {
        const savedItem = await this.freeWallDB.saveItem({
          ...itemToClone,
          value: dataset === Dataset.Meta ? meta : itemToClone.value,
          identityId,
          recordId});
        recordId = savedItem.recordId;
      }
    }
    await this.fileService.cloneFreeWallAssets({
      sourceRecordId: freeWall.recordId,
      destinationRecordId: recordId
    });
    await this.freewallOrganiserService.compileAndShareFreewall(recordId, meta);
    loadingRef.close();
    return {...await this.freeWallDB.getDatasetItem(Dataset.Freewall, recordId), meta};
  }
}
