import {LambdaClient} from "@rezonence/serverless";
import {Injectable} from "@angular/core";
import {AWSService} from "../core/AWSService";
import Lambda from "aws-sdk/clients/lambda";
import {MergeProfilesRequest} from "@rezonence/authenticator";
import {InfrastructureResolver} from "../core/InfrastructureResolver";

@Injectable({
  providedIn: "root"
})
export class ProfileMigrationClient extends LambdaClient<MergeProfilesRequest, void> {
  constructor(awsService: AWSService, infra: InfrastructureResolver) {
    super(awsService.getService(Lambda), infra.config.authentication.profileMergerName);
  }
}
