import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WizardComponent } from "./wizard/WizardComponent";
import { RouterModule, Routes } from "@angular/router";
import { CoreModule } from "../core/CoreModule";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ImporterComponent } from "./importer/ImporterComponent";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IntroComponent } from "./intro/intro.component";
import { BannerComponent } from "./banner/banner.component";
import { PreviewComponent } from "./preview/PreviewComponent";
import { TypeComponent } from "./type/type.component";
import { QuestionComponent } from "./question/question.component";
import { AnswersComponent } from "./answers/answers.component";
import { ResponsesComponent } from "./responses/ResponsesComponent";
import { ResponseComponent } from "./response/ResponseComponent";
import { MapperComponent } from "./mapper/MapperComponent";
import { ResponseTileComponent } from "./response/ResponseTileComponent";
import { HintComponent } from "./hint/hint.component";
import { ShareComponent } from "./share/ShareComponent";
import { NavComponent } from "./nav/nav.component";
import { StepDirective } from "./step/step.directive";
import { Route } from "@rezonence/freewall-creator-config";
import { BannerLinkComponent } from "./links/links.component";
import { FullWizardMode } from "./wizard/full.wizard.mode";
import { WIZARD_MODE } from "./wizard/wizard.mode";
import { SimpleOpinionWizardMode } from "./opinion/SimpleOpinionWizardMode";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ResourceModule } from "../resource/ResourceModule";
import { BackButtonComponent } from "./back-button/back-button.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouteParam } from "../data-editor/route.param";
import { PreviewModule } from "../preview";
import { ClipboardModule } from "ngx-clipboard";
import { QuizWizardMode } from "./quiz/quiz.wizard.mode";
import { SchemaWizardModule } from "@rezonence/ng-schema-wizard";
import { DataEditorModule } from "../data-editor";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { WizardModePipe } from "./wizard/WizardModePipe";
import { WizardPage } from "./wizard/WizardPage";
import { AuthGuard } from "../authenticator";
import { FreeWallSchemaModule } from "../freewall/FreeWallSchemaModule";

const routes: Routes = [
  {
    path: `${Route.Wizard}/:${RouteParam.Id}`,
    component: WizardPage,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    SchemaWizardModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    DataEditorModule,
    CoreModule,
    RouterModule.forChild(routes),
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    ResourceModule,
    DataEditorModule,
    FlexLayoutModule,
    PreviewModule,
    ClipboardModule,
    FreeWallSchemaModule,
  ],
  providers: [
    {
      provide: WIZARD_MODE,
      useClass: QuizWizardMode,
      multi: true
    },
    {
      provide: WIZARD_MODE,
      useClass: SimpleOpinionWizardMode,
      multi: true
    },
    {
      provide: WIZARD_MODE,
      useClass: FullWizardMode,
      multi: true
    },
  ],
  declarations: [
    WizardModePipe,
    ResponseTileComponent,
    WizardComponent,
    WizardPage,
    ImporterComponent,
    IntroComponent,
    BannerComponent,
    BannerLinkComponent,
    PreviewComponent,
    TypeComponent,
    QuestionComponent,
    AnswersComponent,
    ResponsesComponent,
    ResponseComponent,
    MapperComponent,
    HintComponent,
    ShareComponent,
    NavComponent,
    StepDirective,
    BackButtonComponent
  ],
  exports: [
    BackButtonComponent
  ]
})
export class CreatorModule {
}
