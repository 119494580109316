import { LinkMacro } from './LinkMacro'
import { RenderMacroRequest } from './RenderMacroRequest'
import { RenderMacrosRequest } from './RenderMacrosRequest'
import { replaceAll } from './replaceAll'
import { macroBrackets } from './macroBrackets'

export class LinkMacroRenderer<T extends string = LinkMacro> {
  toVariants(macro: T): string[] {
    const alternativeMacro = macroBrackets.reduce((previous: string, bracket: string) => {
      return replaceAll({
        input: previous,
        replace: bracket,
        value: '%%'
      })
    }, macro)

    // Also consider the URL-encoded variant
    return [
      alternativeMacro,
      encodeURIComponent(macro),
      encodeURIComponent(alternativeMacro)
    ]
  }

  render(request: RenderMacroRequest<T>): string {
    const variants = [...this.toVariants(request.macro), request.macro]
    return variants.reduce((link, variant) => {
      return replaceAll({
        input: link,
        replace: variant,
        value: request.value
      })
    }, request.link)
  }

  renderAll(request: RenderMacrosRequest<T>): string {
    return Object.keys(request.valueByMacro).reduce((link, macro: T) => {
      const value = request.valueByMacro[macro]
      return this.render({
        link,
        value,
        macro
      })
    }, request.link)
  }
}
