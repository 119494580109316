<ng-container *ngIf="errors | keyvalue | notEmpty as errorDatasets">
  <ng-container *ngIf="values.meta?.data?.title ?? dataset as title">
    <mat-toolbar
      fxLayout="row"
      fxLayoutAlign="start center"
      class="action-toolbar">
      <div class="title" fxFlex="fill" fxLayoutAlign="center">
        {{ title }}
      </div>
      <button
        mat-flat-button
        color="primary"
        [matTooltip]="
          (errorDatasets | datasetErrorMessage: values | async) ??
          'Save ' + title
        "
        (click)="save(title)">
        {{ actionLabel }}
      </button>
    </mat-toolbar>
  </ng-container>
</ng-container>
