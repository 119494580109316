import { NgModule } from '@angular/core'
import Ajv from 'ajv'
import { SchemaWizardModule } from '@rezonence/ng-schema-wizard';
import { registerCustomValidators } from '@rezonence/freewall-compiler';

@NgModule({
  declarations: [],
  imports: [
    SchemaWizardModule
  ],
})
export class FreeWallSchemaModule {
  constructor(ajv: Ajv) {
    registerCustomValidators(ajv);
  }
}
