import { WrappedOptional } from './WrappedOptional'

export class Optional<T> {

  falseVariants = [null, undefined];

  constructor(public item: T | undefined | null) {
  }

  get exists(): boolean {
    return this.falseVariants.findIndex(falseVariant => falseVariant === this.item) === -1
  }

  static empty<T>(): Optional<T> {
    return new Optional<T>(undefined)
  }

  static of<T>(item: T | undefined | null): Optional<T> {
    return new Optional<T>(item)
  }

  static truthy(tf: boolean): Optional<boolean> {
    return tf ? Optional.of(tf) : Optional.empty()
  }

  static async switchPromise<T>(input: Optional<Promise<T>>): Promise<Optional<T>> {
    return input.exists ? Optional.of(await input.item) : Optional.empty<T>()
  }

  static unWrap<T>(wrappedValue: WrappedOptional<T>): Optional<T> {
    let unwrapped = wrappedValue as WrappedOptional<T>
    while (unwrapped instanceof Optional) {
      unwrapped = unwrapped.item as WrappedOptional<T>
    }
    return Optional.of(unwrapped)
  }

  map<O>(mapper: (i: T) => O | undefined | null | Optional<O>, emptyMapper: () => O | Optional<O> = () => Optional.empty()): Optional<O> {
    const optionalResult = this.exists ? mapper(this.item as T) : emptyMapper()
    const wrappedResult = Optional.of(optionalResult)
    return Optional.unWrap<O>(wrappedResult)
  }
}
