import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { Route } from "@rezonence/freewall-creator-config";
import { DemoArticle } from "./DemoArticle";

@NgModule({
    imports: [
        FlexLayoutModule,
        RouterModule.forChild([
            {
                path: Route.Demo,
                component: DemoArticle
            }
        ]),
    ],
    declarations: [
        DemoArticle
    ],

})
export class ArticleModule {

}
