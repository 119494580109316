import { Component, OnInit } from "@angular/core";
import {CreativeFileService} from "../../core/CreativeFileService";
import {CreativeFreeWallDB} from "../../core/CreativeFreeWallDB";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-delete",
  templateUrl: "./delete.component.html",
  styleUrls: ["./delete.component.css"]
})
export class DeleteComponent implements OnInit {

  public freeWallTitle: string;
  public freeWallId: string;
  deleting: boolean;

  constructor(public ref: MatDialogRef<DeleteComponent>,
              private fileService: CreativeFileService,
              private freeWallDB: CreativeFreeWallDB) {

  }

  ngOnInit() {

  }

  cancel() {
    this.ref.close(false);
  }

  async doDelete() {

    this.ref.disableClose = true;
    this.deleting = true;
    await this.fileService.deleteFreeWallData(this.freeWallId);
    await this.freeWallDB.deleteFreeWall(this.freeWallId);
    this.ref.close(true);
  }
}
