import {Pipe, PipeTransform} from "@angular/core";
import {CodeDataset} from "@rezonence/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Pipe({
  name: "placeholder"
})
export class PlaceholderPipe implements PipeTransform {
  placeholderFilenameByDataset: Record<CodeDataset, string> = {
    [CodeDataset.Css]: "css.css",
    [CodeDataset.CustomCode]: "custom-code.js"
  };

  constructor(private httpClient: HttpClient) {
  }

  transform(dataset: CodeDataset): Observable<string> {
    return this.httpClient.get(
      `/assets/example-${this.placeholderFilenameByDataset[dataset]}`,
      {responseType: "text"}
      );
  }

}
