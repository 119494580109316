import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WizardConfig } from "../wizard.config";
import { ErrorObject } from "ajv";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, tap } from "rxjs/operators";
import { WizardStep } from "../wizard.step";
import { ControlElement, createLabelDescriptionFrom } from "@jsonforms/core";

@Component({
  selector: "rez-schema-wizard",
  templateUrl: "./schema-wizard.component.html",
})
export class SchemaWizardComponent<T = any> implements OnInit {

  @Input()
  set config(c: WizardConfig<T>) {
    this._config = c;
    this.errorCollector = c.steps.map(s => new EventEmitter<ErrorObject[]>(true));
    this.stepStatus = this.errorCollector.map((emitter, index) => emitter
      .pipe(map(errors => !errors.length))
      .pipe(distinctUntilChanged())
    );
  }

  get config(): WizardConfig<T> {
    return this._config;
  }

  errorCollector: EventEmitter<ErrorObject[]>[];
  stepStatus: Observable<boolean>[];

  @Output()
  done: EventEmitter<T> = new EventEmitter<T>();

  private _config: WizardConfig<T>;

  constructor() {
  }

  toLabel(step: WizardStep): string {
    const controlElement = step.uiSchema as ControlElement;
    if (controlElement.label) {
      return controlElement.label as string;
    }
    if (controlElement.type === "Control") {
      return createLabelDescriptionFrom(controlElement, this.config.schema).text;
    }
    return "";
  }

  trackElement(index: number): number {
    return index;
  }

  ngOnInit() {

  }

}
