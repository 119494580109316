import { FreeWall } from '@rezonence/core'
import { defaultImage } from './default.image'
import { Answer, FreeWallResponse } from '@rezonence/sdk'

const defaultResponse: FreeWallResponse = {
  img: defaultImage
}

const answers: Answer[] = [
  {
    ans: 'Answer 1',
    tof: true,
    rix: 0,
    kix: 0
  },
  {
    ans: 'Answer 2',
    tof: false,
    rix: 1,
    kix: 1
  }
]

const responses = answers.map(() => defaultResponse)

export const defaultFreeWall: FreeWall = {
  responses,
  kickers: responses,
  questions: [
    {
      que: 'What is the answer?',
      ran: true,
      answers
    }
  ],
  trackers: [],
  image: {
    img: defaultImage
  }
}
