import { DatasetItem, DatasetValue, FreeWallDB } from "@rezonence/core";
import { LoadHook } from "./LoadHook";
import { SaveHook } from "./SaveHook";
import { Inject, Injectable } from "@angular/core";
import { loadHookToken } from "./loadHookToken";
import { saveHookToken } from "./saveHookToken";
import { THookRequest } from "./THookRequest";

@Injectable({
  providedIn: "root"
})
export class DBHookService {
  constructor(
    @Inject(loadHookToken) private loadHooks: LoadHook[],
    @Inject(saveHookToken) private saveHooks: SaveHook[]
  ) { }

  applyHooks<T extends DatasetValue, H extends keyof THookRequest<T>>(
    db: FreeWallDB,
    type: H,
    item: THookRequest<T>[H]["item"],
  ): Promise<THookRequest<T>[H]["item"]> {
    return this.processItem(db, item, type === 'load' ? this.loadHooks : this.saveHooks);
  }

  private async processItem<T extends DatasetValue, H extends keyof THookRequest<T>>(
    db: FreeWallDB,
    item: THookRequest<T>[H]["item"],
    processors: THookRequest<T>[H]["hook"][],
  ): Promise<THookRequest<T>[H]["item"]> {
    let processedItem = item;
    for (const processor of processors) {
      processedItem = await processor.transform(processedItem as DatasetItem<any>, db);
    }
    return processedItem as THookRequest<T>[H]["item"];
  }
}
