import {Pipe, PipeTransform} from "@angular/core";
import {Paths} from "@jsonforms/core";

@Pipe({name: "composePath"})

export class ComposePathPipe implements PipeTransform {

  transform(path: string, section: string): string {
    return Paths.compose(path, section);
  }

}
