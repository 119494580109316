export enum Route {
  Wizard = "wizard",
  Freewall = "freewall",
  Home = "home",
  Callback = "callback",
  Advanced = "advanced",
  Demo = "demo",
  Login = "login",
  Edit = "edit"
}
