import { Injectable } from "@angular/core";
import type DynamoDB from 'aws-sdk/clients/dynamodb'
import { AWSService } from "./AWSService";
import { DatasetValue } from "@rezonence/core/freewall/dataset.value";
import { Meta } from "@rezonence/core/freewall/meta";
import { Dataset } from "@rezonence/core/freewall/dataset";
import { Microsites } from "@rezonence/core/freewall/microsites";
import { FreeWall } from "@rezonence/core/freewall/freewall";
import { DatasetItem, FreeWallDB, SaveItemRequest } from "@rezonence/core";
import { InfrastructureResolver } from "./InfrastructureResolver";
import { DBHookService } from '@rezonence/freewall-db-hooks';

@Injectable({
  providedIn: "root"
})
export class CreativeFreeWallDB extends FreeWallDB {

  constructor(
    private awsService: AWSService,
    infra: InfrastructureResolver,
    private hooksService: DBHookService,
  ) {
    super(
      awsService.dynamodb(),
      infra.config.tables.UserData
    );
  }

  async saveItem<T extends DatasetValue>(item: SaveItemRequest<T>): Promise<DatasetItem<T>> {
    const processedItem = await this.hooksService.applyHooks<T, "save">(this, 'save', item);
    return super.saveItem<T>(processedItem);
  }

  async* queryItems<T extends DatasetValue>(query: DynamoDB.DocumentClient.QueryInput): AsyncIterable<DatasetItem<T>> {
    for await (const item of super.queryItems<T>(query)) {
      const transformed = await this.hooksService.applyHooks<T, "load">(this, 'load', item);
      yield transformed;
    }
  }

  async getItem<T extends DatasetValue>(identityId: string, datasetName: Dataset, recordId: string):
    Promise<DatasetItem<T>> {
    const item = await super.getItem<T>(identityId, datasetName, recordId);
    return this.hooksService.applyHooks<T, "load">(this, 'load', item);
  }

  async getMetadata(freeWallId: string): Promise<Meta> {
    return this.getDatasetValue<Meta>(Dataset.Meta, freeWallId);
  }

  async saveDatasetValue<T extends DatasetValue>(type: Dataset, recordId: string, value: T): Promise<DatasetItem<T>> {
    const identityId = await this.awsService.identityId();
    return this.saveItem({
      identityId,
      recordId,
      value,
      datasetName: type
    });
  }

  async getDatasetValue<T extends DatasetValue>(type: Dataset, recordId: string): Promise<T> {
    const item = await this.getDatasetItem<T>(type, recordId);
    return item ? item.value : null;
  }

  async getDatasetItem<T extends DatasetValue>(type: Dataset, recordId: string): Promise<DatasetItem<T>> {
    const identityId = await this.awsService.identityId();
    return this.getItem<T>(identityId, type, recordId);
  }

  async getMicrosites(freeWallId: string): Promise<Microsites> {
    const microsites = await this.getDatasetValue<Microsites>(Dataset.Microsites, freeWallId);
    return microsites || [];
  }

  async deleteDatasetItem(type: Dataset, recordId: string) {
    const identityId = await this.awsService.identityId();
    return this.deleteItem(identityId, type, recordId);
  }

  async createNewFreeWall(freeWall: FreeWall, title: string): Promise<DatasetItem<FreeWall>> {
    const datasetItem = await this.saveDatasetValue<FreeWall>(Dataset.Freewall, null, freeWall);
    const meta: Meta = {
      title,
      labels: []
    };
    await this.saveDatasetValue<Meta>(Dataset.Meta, datasetItem.recordId, meta);
    return datasetItem;
  }

  async deleteFreeWall(freeWallId: string): Promise<any> {
    const identityId = await this.awsService.identityId();
    await this.deleteAll(identityId, freeWallId);
  }

}
