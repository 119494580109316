<mat-card>
  <mat-toolbar>
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="end center" class="mat-body">
      <mat-slide-toggle [(ngModel)]="preview">Preview</mat-slide-toggle>
    </mat-toolbar-row>
  </mat-toolbar>
  <ng-container *ngIf="preview; else configTemplate">
    <app-microsite-preview [microsite]="microsite"
                           [recordId]="recordId"
                           [freeWallConfig]="freeWallConfig">
    </app-microsite-preview>
  </ng-container>
  <ng-template #configTemplate>
    <app-microsite-config [(microsite)]="microsite">
    </app-microsite-config>
  </ng-template>
</mat-card>


