import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "../../../environments/environment";
import { SharedStatus } from "../../share/shared.status";
import { DeleteComponent } from "../delete/DeleteComponent";
import { Route } from "@rezonence/freewall-creator-config";
import { FreewallMetaPair } from "../../core/freewall.meta.pair";
import { Dataset } from "@rezonence/core";
import { CloneService } from "../clone-wizard/CloneService";
import { delay } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { Mode, ModeResolver } from "../../mode";
import { WizardService } from "../../creator/wizard/wizard.service";
import { wizardModeQueryParam } from "../../creator/wizard/wizard.mode.query.param";

@Component({
  selector: "slide",
  templateUrl: "./slide.component.html",
  styleUrls: ["./slide.component.css"]
})
export class SlideComponent implements OnInit {

  enabled$: Observable<boolean>;

  @Input()
  freeWall: FreewallMetaPair;

  @Output()
  onDeleted: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  freeWallCloned: EventEmitter<FreewallMetaPair> = new EventEmitter();

  environment = environment;
  Route = Route;
  Dataset = Dataset;
  Mode = Mode;

  constructor(private cloneService: CloneService,
    public readonly modeResolver: ModeResolver,
    private wizardService: WizardService,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
    // Delay rendering image in case the slide is being scrolled past
    this.enabled$ = of(true).pipe(delay(500));
  }

  async openDemo(sharedStatus: SharedStatus) {
    const shareLink = sharedStatus.shareLink;
    window.open(shareLink, "_blank");
  }

  async cloneFreeWall() {
    const result = await this.cloneService.cloneFreeWall(this.freeWall);
    if (result.exists) {
      this.freeWallCloned.emit(result.item);
    }
  }

  async deleteFreeWall() {
    const ref = this.dialog.open(DeleteComponent);
    ref.componentInstance.freeWallId = this.freeWall.recordId;
    ref.componentInstance.freeWallTitle = this.freeWall.meta.title;
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.onDeleted.emit();
      }
    });
  }

  toRouterLink(mode: Mode): string {
    return mode === Mode.Advanced ? `/${Route.Advanced}/${this.freeWall.recordId}/${Dataset.Freewall}` :
      `/${Route.Wizard}/${this.freeWall.recordId}`;
  }

  toQueryParams(mode: Mode): Record<string, string> {
    return mode === Mode.Advanced ? {} : {
      [wizardModeQueryParam]: this.wizardService.listModes().find(m => m.isCompatible(this.freeWall.value))?.key
    };
  }
}
