import { Injectable } from "@angular/core";
import { MicrositeSourceParser } from "@rezonence/core";
import { CreativeMacroRenderer } from "../core/creative.macro.renderer";

@Injectable({
  providedIn: "root"
})
export class CreativeMicrositeSourceParser extends MicrositeSourceParser {

  constructor(macroRenderer: CreativeMacroRenderer) {
    super(macroRenderer);
  }

}
