<div *ngIf="config">
  <h3>
    Select FreeWall type
  </h3>
  <div class="divider-horizontal"></div>
  <mat-button-toggle-group [vertical]="true" class="freewall-type-container" [(ngModel)]="freeWallType">

    <h4>Quiz FreeWall</h4>
    <!--<p>The quiz FreeWall presents users with a question to which some answers are wrong. If a user clicks on an incorrect answer then the they will be shown a hint and asked to retry.
        This type of FreeWall is useful for educating the user about a product or brand.</p>-->
    <mat-button-toggle value="quiz">
      Select
    </mat-button-toggle>
    <h4>Survey FreeWall</h4>
    <mat-button-toggle value="survey">
      Select
    </mat-button-toggle>
  </mat-button-toggle-group>

</div>

