import {FreeWallMicrosite} from "./freewall.microsite";
import {micrositeSchema} from "./microsite.schema";

export const micrositesSchema = {
    type: "array",
    items: micrositeSchema,
    minItems: 0,
    maxItems: 1000,
    definitions: micrositeSchema.definitions,
    $schema: micrositeSchema.$schema
};
