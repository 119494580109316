<div class="freewall" #container>

  <div *ngIf="config && css" class="full-height">
    <div class="static-preview" *ngIf="mode !== 'preview'">
      <div id="r3z-addoor" [class.hidden]="mode !== 'banner'">
        <div id="r3z-inst-top" class="r3z-inst">
          <span>▼</span>
          <p id="r3z-inst">{{topInstructions}}
          </p>
          <span>▼</span>
        </div>
        <div id="r3z-banner-card">
          <div id="r3z-ban" [class.focus]="focus === 'banner'">
            <!--<a id="r3z-link" target="_blank" [href]="config.image.lnk">-->
            <a id="r3z-link">
              <img id="r3z-img" [src]="config.image.img | resourceLink:freewallId | async">
            </a>
          </div>
          <div class="r3z-iact" id="r3z-qna" *ngIf="!hint">
            <p id="r3z-quest" [class.focus]="focus === 'question'" [innerHTML]="config.questions[0].que || 'What is the answer?'">
            </p>
            <div id="r3z-anslst" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" [class.focus]="focus === 'answers'">
              <ng-container *ngFor="let answer of config.questions[0].answers; let i = index">
                <a class="r3z-but r3z-ansbut r3z-ansbut-{{i+1}} r3z-ans-{{i+1}}"
                  [style.width]="(((95.0 / config.questions[0].answers.length)))+ '%'"
                  [class.focus]="focus === 'answer' + i">
                  <span [innerHTML]="answer.ans || 'Answer ' + (i+1)"></span>
                </a>
              </ng-container>
            </div>
          </div>
          <div class="r3z-iact" id="r3z-try" *ngIf="hint" [class.focus]="focus === 'hint'">
            <p id="r3z-hint" [innerHTML]="'Hint: '+ (config.questions[0].hnt || 'Enter hint')"></p>
            <div id="r3z-trybut" class="r3z-but">Try Again</div>
          </div>
        </div>
        <a>
          <div id="r3z-inst-bot" class="r3z-inst">
            <span>▲</span>
            <p>{{bottomInstructions}}</p>
            <span>▲</span>
          </div>
        </a>
      </div>
      <div *ngIf="mode === 'banner'">
        <p class="r3z-hide">reenftf icdu revveash asnddo otgh etrneefroerfef iwDi l l. tbnei ospo ledc iirnp
          driaflfuecrietnrta pq uaa nttai tdileoss dseip e”nddoiongg“ onni atthreeicr ap rfioc eyst;i twniatuhq
          ltoawhewr epnriimcreest egde nseprlaelhl yy triecsiutlstailneg eicni rap lhatrigwe rr eqhutaengtoitt ys eovfr
          utch ed ngaomoedd bdenian gy lspopluds btuath tf osru as erhecdauecte ds cpirmoofniotc.E</p>
        <p class="r3z-hide">aceh rvoafr ieectiyr.p tTnheer epfrfiicde aw otuelsd obte ebba sdeldu oown ytgheet
          aqrutasl iltaym iatnpdo deehsti r,asbnialeibt ye eofff otch ef ov ayrtieeitrya vc oau pglneidl lweist hp
          othhse ad egsniirtea roerp on eeerde wt oe ngoe nfeir a,teel pam apxreo friotF.</p>
        <p class="r3z-hide">cei rips ethoto fhii gehr offoerr ethhta td nvaa rsieevtryu,c onnwloy sat is msaalhl
          yqtueainrtaivt yh cwaiEl l .bree nsnoalmat ,s utooroo gliorw earnodm aa lnoit ywhiwl le tbaeg istosledv nbiu
          ts 'ptoetle nttuiba lhlcya oartp paa lgonsosr wf oerh tt hsei sseilhlte rt.a h tA wsoinnkg lyel epvriitcieu
          twniil le Wt h e.ryetfeoirrea vb ey rceovrer ercotf feocri ropn ee lvganriise tay taensd swir oondg tfoonr
          otdh eu ooyt htearhsW.</p>
      </div>
      <div *ngIf="mode === 'response' || mode === 'kicker'">
        <div *ngIf="mode === 'kicker'">
          <div class="blurb">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel tellus rutrum, euismod ex sit amet,
              pharetra orci. Donec euismod mattis nulla sed hendrerit. Cras id erat non urna dapibus tempus ut vitae
              est. Duis nibh dui, malesuada eget feugiat vel, feugiat vel sem. Pellentesque ultrices erat vitae lorem
              rutrum fringilla. Morbi eros ipsum, tincidunt id feugiat eu, dignissim eu nulla. Aliquam ut porta nulla.
              Nam porta dui et consectetur volutpat. Curabitur finibus dictum ante, ut euismod enim.</p>
            <p>Donec dignissim interdum ante, eu fermentum turpis euismod sed. Maecenas tristique rhoncus leo, ac
              sagittis enim vulputate quis. Nullam ut aliquam eros. Sed tellus purus, lobortis id elementum nec, blandit
              in leo. Donec vehicula neque nisl, vel finibus orci accumsan a. Sed lobortis, ligula sit amet porttitor
              fringilla, nulla sem dictum nisl, nec facilisis elit nibh in tellus. Curabitur eu massa sodales, accumsan
              turpis vel, accumsan arcu. Phasellus vel orci commodo, facilisis ante sit amet, laoreet felis. Suspendisse
              potenti.</p>
            <p>Praesent porttitor tortor sed massa ullamcorper aliquam. Duis rutrum augue sit amet malesuada luctus.
              Donec libero sapien, molestie vel elementum nec, hendrerit nec mauris. Fusce euismod gravida lectus, ut
              sollicitudin nulla dictum sit amet. Ut luctus sagittis sem vel mattis. Ut sagittis tristique dictum. Ut
              sed dolor faucibus nisi mattis mollis id non nibh. Nullam metus nisl, vestibulum vel egestas et, sagittis
              in ex. Donec eget massa tortor.</p>
          </div>
        </div>
        <div id="r3z-{{mode}}">
          <ng-container *ngIf="displayResponseVideo()">
            <div class="r3z-vid-home" id="r3z-{{responseLetter}}vid-{{responseIndex}}">
              <div class="r3z-vid-wrap">
                <video [src]="config[mode + 's'][responseIndex].vid | resourceLink:freewallId | async" class="r3z-vid" muted playsinline
                  webkit-playsinline preload="auto">
                </video>
                <img class="r3z-vid-vol r3z-voloff"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHZlcnNpb249IjEiIHdpZHRoPSI1NjEuOSIgaGVpZ2h0PSI1NjEuOSIgdmlld0JveD0iMCAwIDEwNS40IDEwNS4zIj48c3R5bGU+LnMwe2ZpbGw6bm9uZTtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDo0O3N0cm9rZS13aWR0aDo0LjU7c3Ryb2tlOiNmZmY7fTwvc3R5bGU+PGNpcmNsZSBjeD0iNTIuNyIgY3k9IjUyLjY1IiByPSI1MC44IiBzdHlsZT0iZmlsbC1vcGFjaXR5OjAuNTtmaWxsOiMwMDA7c3Ryb2tlLXdpZHRoOjMuODtzdHJva2U6I2ZmZiIvPjxwb2x5Z29uIHBvaW50cz0iMzkuNCAxMy44IDIyLjIgMjguNiA2IDI4LjYgNiA0Ny43IDIyIDQ3LjcgMzkuNCA2Mi44IDM5LjQgMTMuOCAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDguMjUzOTM5NCwxNC4xMjAyMikiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDozLjg7c3Ryb2tlOiNmZmYiLz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjcuOTYyNzcsMTA3LjM0NjM4KSIgc3Ryb2tlPSIjZmZmIj48cGF0aCBkPSJNLTcwLjMtMzkuMDYtMzkuMDItNzAuMzMiIGNsYXNzPSJzMCIvPjxwYXRoIGQ9Im0tMzkuMDItMzkuMDYtMzEuMjgtMzEuMjgiIGNsYXNzPSJzMCIvPjwvZz48L3N2Zz4K">
                <img class="r3z-vid-vol r3z-volon"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHZlcnNpb249IjEiIHdpZHRoPSI1NjEuOSIgaGVpZ2h0PSI1NjEuOSIgdmlld0JveD0iMCAwIDEwNS40IDEwNS4zIj48Y2lyY2xlIGN4PSI1Mi43IiBjeT0iNTIuNiIgcj0iNTAuOCIgc3R5bGU9ImZpbGwtb3BhY2l0eTowLjU7ZmlsbDojMDAwO3N0cm9rZS13aWR0aDozLjg7c3Ryb2tlOiNmZmYiLz48cG9seWdvbiBwb2ludHM9IjYgNDcuNyAyMiA0Ny43IDM5LjQgNjIuOCAzOS40IDEzLjggMzkuNCAxMy44IDIyLjIgMjguNiA2IDI4LjYgIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjI1Mzk0MDksMTQuMDYxNTU1KSIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLXdpZHRoOjMuODtzdHJva2U6I2ZmZiIvPjxwYXRoIGQ9Im01My4zIDcxLjNjOC02LjMgMTQuNi0xMi43IDE0LjYtMTguN2wwIDBjMC02LTYuNi0xMi40LTE0LjYtMTguOGwwIDBjNi4zIDExLjcgNi4zIDI1LjggMCAzNy41bTE3LjUtMTguN2MwIDYuMy01LjIgMTIuMi0xMi4zIDE4LjJsMCAwYzEyLTYuMSAyMi4yLTEyLjUgMjIuMi0xOC4ybDAgMGMwLTUuNy0xMC4zLTEyLjEtMjIuMi0xOC4ybDAgMGM3LjEgNiAxMi4zIDExLjkgMTIuMyAxOC4ybTEyLjggMGMwIDUuNi02LjMgMTAuOS0xNS4yIDE2LjFsMCAwYzUuOC0yLjMgMTEuMi00LjYgMTUuNC03bDAgMGM2LjQtMy41IDkuNy02LjYgOS43LTkuMmwwIDBjMC01LTExLjItMTAuNi0yNS4xLTE2LjFsMCAwYzkgNS4yIDE1LjIgMTAuNSAxNS4yIDE2LjEiIGZpbGw9IiNmZmYiLz48L3N2Zz4K"
                  style="display: none;">
                <img class="r3z-vid-stat r3z-play"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDQ3Ny45IDQ3OS44IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0NjIuOSA0NjQuOCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjQ3Ny45IiBoZWlnaHQ9IjQ3OS44Ij48c3R5bGU+LnMwe2ZpbGw6I2ZmZjt9PC9zdHlsZT48cGF0aCBkPSJtMjQwLjQgNy41Yy0xMTAuNi0wLjMtMjAzLjUgNzYuNi0yMjcgMTgwLjMtMjYuNiAxMTcuMiAzOS40IDIzNS4yIDE1My4xIDI3Mi45IDI2LjcgOC45IDU0LjEgMTIuNCA4Mi4yIDExLjQgOTguNC0zLjUgMTg2LjQtNzEuOCAyMTMuNS0xNzAuMyA3LjktMjguOCAxMC01OCA2LjgtODcuNy04LjYtODEuNS02MS40LTE1NS4zLTE0MC43LTE4OC43LTI4LjYtMTIuMS01OC41LTE4LjEtODcuOS0xNy45em0tMSA0NjIuM2MtOS4zLTAuNy0xOC43LTEuMS0yOC0yLjEtMzQuNC0zLjktNjYuNC0xNS4yLTk1LjMtMzQuMi02MC41LTQwLjItOTUuNS05Ni40LTEwNC4xLTE2OC43LTMuNS0yOS4yLTEtNTcuOSA2LjgtODYuM0M0Ni42IDc3LjggMTM5LjggNy4xIDI0NS44IDEwLjVjMjcuOSAwLjkgNTQuNyA2LjQgODAuNCAxNy4yIDEwMy41IDQzLjIgMTYwLjUgMTU0LjEgMTM1LjUgMjYzLjgtMjMuNSAxMDIuOS0xMTUuNSAxNzgtMjIyLjMgMTc4LjN6IiBzdHlsZT0iZmlsbDojZmZmO3N0cm9rZS13aWR0aDoxNTtzdHJva2U6I2ZmZiIvPjxwYXRoIGQ9Im0xMjkuNCAzNTcuMmM0OS45LTM5LjcgOTEuNC03OS43IDkxLjQtMTE3LjNsMCAwYzAtMzcuNi00MS41LTc3LjYtOTEuNC0xMTcuM2wwIDBjMzkuMiA3My4yIDM5LjIgMTYxLjUgMCAyMzQuNm0xMDkuMi0xMTcuM2MwIDM5LjMtMzIuNSA3Ni40LTc2LjcgMTEzLjhsMCAwYzc0LjctMzguMiAxMzguOC03OC4xIDEzOC44LTExMy44bDAgMGMwLTM1LjYtNjQuMi03NS42LTEzOC44LTExMy44bDAgMGM0NC4yIDM3LjQgNzYuOCA3NC41IDc2LjggMTEzLjhtNzkuOCAwYzAgMzUuMi0zOS4yIDY4LjItOTUuNCAxMDFsMCAwYzM3LTE0LjYgNzAuNS0yOS4xIDk3LTQzLjZsMCAwYzQwLjEtMjEuOCA2MC40LTQxLjIgNjAuNC01Ny41bDAgMGMwLTMxLjQtNjkuOC02Ni41LTE1Ny4zLTEwMC45bDAgMGM1Ni4yIDMyLjggOTUuMyA2NS44IDk1LjMgMTAwLjkiIGZpbGw9IiNmZmYiLz48L3N2Zz4K">
                <img class="r3z-vid-stat r3z-pause r3z-vid-anim"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDQ3OCA0ODAuMyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDYzLjkgNDY2LjMiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI0NzgiIGhlaWdodD0iNDgwLjMiPjxzdHlsZT4uczB7ZmlsbDojZmZmO308L3N0eWxlPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcuMjE4OTU4NiwzLjA3NTMxOTkpIj48cmVjdCB5PSIxMDYuOSIgeD0iMjUwLjgiIGhlaWdodD0iMjYwLjMiIHdpZHRoPSIzOS40IiBmaWxsPSIjZmZmIi8+PHJlY3QgeT0iMTA2LjkiIHg9IjE3My4zIiBoZWlnaHQ9IjI2MC4zIiB3aWR0aD0iMzkuNCIgZmlsbD0iI2ZmZiIvPjwvZz48cGF0aCBkPSJtMjM4LjggNDcyLjhjLTEwOC44LTAuMi0yMDEuOC03Ny0yMjUuNC0xODAuNy0yNS45LTExNC4xIDM1LjgtMjI5LjIgMTQ0LjktMjcwLjEgMjkuNC0xMSA1OS43LTE1LjUgOTEuMS0xNC4zIDk3LjUgMy44IDE4NS44IDcxLjMgMjEzIDE3MC45IDcuOCAyOC42IDkuOCA1Ny42IDYuNyA4Ny03LjggNzMuNC01MS40IDE0MC41LTExNy4zIDE3Ny40LTI2LjUgMTQuOC01NC43IDI0LjMtODQuOSAyNy42LTkuNCAxLTE4LjggMS41LTI4LjEgMi4yem0tMi40LTQ2Mi4zYy0xOC4zLTAuMi0zOS4xIDIuNy01OS42IDguNS05MS4xIDI1LjktMTU1LjYgMTA1LjUtMTY0LjkgMTk2LjItMyAyOS4yLTAuOSA1Ny45IDcgODYuMyAyNy42IDEwMC4xIDEyMC40IDE3MSAyMjYuNCAxNjcuOCAyOC4xLTAuOCA1NS4yLTYuNSA4MS4xLTE3LjMgMTAxLjItNDIuMSAxNjAuNS0xNTEuNiAxMzUuNC0yNjMuMi0yMy4xLTEwMi43LTExNS4xLTE3OC42LTIyNS40LTE3OC4zeiIgc3R5bGU9ImZpbGw6I2ZmZjtzdHJva2Utd2lkdGg6MTU7c3Ryb2tlOiNmZmYiLz48L3N2Zz4K">
                <div class="r3z-vid-progbar" style="width: 53%;">
                </div>
                <div class="r3z-vid-clktrp">
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="displayResponseIframe()">
            <iframe id="r3z-{{responseLetter}}ifrm-{{responseIndex}}" class="r3z-ifrm" frameborder="0" scrolling="no"
              [src]="config[mode + 's'][responseIndex].ifs | resourceLink: freewallId | async">
            </iframe>
          </ng-container>
          <ng-container *ngIf="displayResponseImage()">
            <!--<a id="r3z-{{responseLetter}}link" target="_blank" [href]="config[mode + 's'][responseIndex].lnk">-->
            <a id="r3z-{{responseLetter}}link">
              <img id="r3z-{{responseLetter}}img" [src]="config[mode + 's'][responseIndex].img  | resourceLink: freewallId | async">
            </a>
          </ng-container>
          <ng-container *ngIf="displayResponseBar()">
            <iframe id="r3z-{{responseLetter}}bar-{{responseIndex}}" class="r3z-bar" frameborder="0" scrolling="no"
              [src]="config[mode + 's'][responseIndex].bar  | resourceLink: freewallId | async">
            </iframe>
          </ng-container>
        </div>
      </div>
      <div *ngIf="mode === 'response'">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel tellus rutrum, euismod ex sit amet, pharetra
          orci. Donec euismod mattis nulla sed hendrerit. Cras id erat non urna dapibus tempus ut vitae est. Duis nibh
          dui, malesuada eget feugiat vel, feugiat vel sem. Pellentesque ultrices erat vitae lorem rutrum fringilla.
          Morbi eros ipsum, tincidunt id feugiat eu, dignissim eu nulla. Aliquam ut porta nulla. Nam porta dui et
          consectetur volutpat. Curabitur finibus dictum ante, ut euismod enim.</p>
        <p>Donec dignissim interdum ante, eu fermentum turpis euismod sed. Maecenas tristique rhoncus leo, ac sagittis
          enim vulputate quis. Nullam ut aliquam eros. Sed tellus purus, lobortis id elementum nec, blandit in leo.
          Donec vehicula neque nisl, vel finibus orci accumsan a. Sed lobortis, ligula sit amet porttitor fringilla,
          nulla sem dictum nisl, nec facilisis elit nibh in tellus. Curabitur eu massa sodales, accumsan turpis vel,
          accumsan arcu. Phasellus vel orci commodo, facilisis ante sit amet, laoreet felis. Suspendisse potenti.</p>
        <p>Praesent porttitor tortor sed massa ullamcorper aliquam. Duis rutrum augue sit amet malesuada luctus. Donec
          libero sapien, molestie vel elementum nec, hendrerit nec mauris. Fusce euismod gravida lectus, ut sollicitudin
          nulla dictum sit amet. Ut luctus sagittis sem vel mattis. Ut sagittis tristique dictum. Ut sed dolor faucibus
          nisi mattis mollis id non nibh. Nullam metus nisl, vestibulum vel egestas et, sagittis in ex. Donec eget massa
          tortor.</p>
        <p>Sed et pellentesque massa, vel feugiat lectus. Duis semper arcu at velit vulputate blandit. Sed finibus
          tortor sed cursus vehicula. Mauris varius posuere sollicitudin. Ut interdum purus sed leo varius elementum.
          Duis varius est vitae tempor cursus. Nunc auctor arcu in arcu dictum molestie.</p>
        <p>Ut accumsan dui sed venenatis laoreet. Duis finibus placerat lacus quis congue. Donec sed mauris a magna
          varius porta. Duis porta pellentesque purus sit amet mattis. Quisque diam nulla, aliquam quis erat a,
          porttitor gravida quam. Integer magna urna, convallis ac ex ac, elementum pretium ante. In porta dui quis
          lobortis pulvinar.</p>
        <p>Phasellus eget leo id mauris lacinia convallis eu id odio. Curabitur suscipit faucibus est, eget maximus leo
          imperdiet viverra. Nunc accumsan sapien eget odio posuere ultrices. Nam nec interdum lorem. Nunc facilisis
          neque sed dolor cursus, vel ornare nisi lobortis. Ut a ultrices diam, nec porttitor ligula. Curabitur ut
          lobortis augue.</p>
        <p>Nulla et faucibus ligula. Donec vitae euismod metus. Cras hendrerit, est a molestie commodo, ex dolor
          lobortis elit, elementum auctor turpis lorem a nisl. Donec varius nulla massa, sit amet viverra sapien
          tristique et. Aenean hendrerit eros purus, a ullamcorper quam elementum consequat. Vestibulum hendrerit
          elementum nibh at mattis. Maecenas vel maximus felis, a ullamcorper lacus. Donec at magna nec nisi faucibus
          malesuada vitae vitae ante. Nulla magna nisi, fermentum sed pulvinar sed, placerat et libero. Nam vestibulum,
          nisi ut tincidunt rhoncus, urna libero euismod nibh, eget ullamcorper dolor leo eget libero.</p>
        <p>Nullam vestibulum purus nec leo laoreet, ornare placerat sapien sollicitudin. Donec placerat, tortor nec
          pellentesque vehicula, neque augue hendrerit diam, ut dapibus mi mauris non justo. Aenean justo est, volutpat
          ut risus tristique, maximus egestas neque. Sed et viverra nibh, et tristique arcu. Suspendisse potenti.
          Vestibulum convallis neque et lorem maximus, eget tempus augue iaculis. Etiam scelerisque lacus sed dapibus
          interdum. Praesent venenatis ultrices nisi, non varius nulla fringilla ac. Mauris id commodo libero. Phasellus
          eu lorem eget ipsum convallis mollis vitae at urna. Praesent sed lacus et magna scelerisque dignissim. Integer
          nibh augue, tincidunt id orci ac, dignissim sollicitudin orci. Nunc eros sapien, ultrices ac efficitur a,
          vulputate id purus.</p>
        <p>Phasellus magna tortor, lobortis id sem vel, dictum tincidunt risus. Nam ipsum augue, gravida sed tortor id,
          consequat ultricies arcu. Morbi lacinia odio a elementum bibendum. Nam dui massa, pharetra sed pharetra a,
          semper eu ligula. Nunc vel erat ut leo iaculis viverra. Proin non turpis eget est bibendum posuere sit amet
          eget risus. Cras aliquam lectus vel nisi hendrerit scelerisque. Sed vehicula ligula sit amet scelerisque
          consequat. Donec sit amet nulla nec dolor pulvinar interdum nec at dolor. Pellentesque fringilla nibh id elit
          eleifend, a efficitur velit tempor. Duis aliquam vel eros quis aliquet. Aenean gravida neque viverra porta
          pretium. Aenean tempus, diam vitae porttitor condimentum, quam massa dignissim arcu, mattis tristique tortor
          nisl at arcu.</p>
      </div>
      <div *ngIf="mode === 'kicker'">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel tellus rutrum, euismod ex sit amet, pharetra
          orci. Donec euismod mattis nulla sed hendrerit. Cras id erat non urna dapibus tempus ut vitae est. Duis nibh
          dui, malesuada eget feugiat vel, feugiat vel sem. Pellentesque ultrices erat vitae lorem rutrum fringilla.
          Morbi eros ipsum, tincidunt id feugiat eu, dignissim eu nulla. Aliquam ut porta nulla. Nam porta dui et
          consectetur volutpat. Curabitur finibus dictum ante, ut euismod enim.</p>
        <p>Donec dignissim interdum ante, eu fermentum turpis euismod sed. Maecenas tristique rhoncus leo, ac sagittis
          enim vulputate quis. Nullam ut aliquam eros. Sed tellus purus, lobortis id elementum nec, blandit in leo.
          Donec vehicula neque nisl, vel finibus orci accumsan a. Sed lobortis, ligula sit amet porttitor fringilla,
          nulla sem dictum nisl, nec facilisis elit nibh in tellus. Curabitur eu massa sodales, accumsan turpis vel,
          accumsan arcu. Phasellus vel orci commodo, facilisis ante sit amet, laoreet felis. Suspendisse potenti.</p>
      </div>
    </div>

    <div *ngIf="mode === 'preview'" class="full-height">
      <app-preview [config]="config" [css]="css" [customCode]="customCode" [demoPage]="demoPage" [microsites]="microsites"
        [recordId]="freewallId" [height]="container.offsetHeight">
      </app-preview>
    </div>
  </div>
</div>
