import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StartComponent } from "./start/StartComponent";
import { RouterModule, Routes } from "@angular/router";
import { CoreModule } from "../core/CoreModule";
import { SlideComponent } from "./slide/SlideComponent";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { DeleteComponent } from "./delete/DeleteComponent";
import { Route } from "@rezonence/freewall-creator-config";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { AuthenticatorModule } from "../authenticator/AuthenticatorModule";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SchemaWizardModule } from "@rezonence/ng-schema-wizard";
import { BannerLinkPipe } from "./banner-link/BannerLinkPipe";
import { SharedStatusPipe } from "./shared-status/SharedStatusPipe";
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { BrowserModule } from "@angular/platform-browser";
import { MatMenuModule } from "@angular/material/menu";
import { GridModule } from "../grid/grid.module";
import { ResourceModule } from "../resource/ResourceModule";
import { CreateModule } from "../create/CreateModule";
import { QuizModule } from "../quiz/QuizModule";
import { ImporterModule } from "../importer";
import { CreatorModule } from "../creator";
import { FilterFreewallPipe } from "./filter.freewall.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { QuickstartModule } from "../quickstart/QuickstartModule";
import { LandingComponent } from "./LandingComponent";
import { AuthGuard } from "../authenticator";
import { SaveRecord } from "./SaveRecord";
import { saveHookToken } from "@rezonence/freewall-db-hooks";
import { TempFreeWallPipe } from "./TempFreeWallPipe";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: `/${Route.Home}`,
  },
  {
    path: Route.Home,
    component: StartComponent,
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [
    CreateModule,
    QuizModule,
    QuickstartModule,
    ImporterModule,
    GridModule,
    BrowserAnimationsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonToggleModule,
    MatToolbarModule,
    HttpClientModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    AuthenticatorModule,
    FlexLayoutModule,
    SchemaWizardModule,
    MatGridListModule,
    BrowserModule,
    MatMenuModule,
    ResourceModule,
    CreatorModule
  ],
  declarations: [
    LandingComponent,
    StartComponent,
    SlideComponent,
    DeleteComponent,
    BannerLinkPipe,
    SharedStatusPipe,
    FilterFreewallPipe,
    TempFreeWallPipe,
    SearchBarComponent
  ],
  providers: [
    {
      provide: saveHookToken,
      useClass: SaveRecord,
      multi: true
    }
  ]
})
export class OrganiserModule {
}
