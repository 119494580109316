import { CompileConfigRequest } from '@rezonence/core/freewall-compiler/compile.config.request'
import { DestinationPrefix } from '@rezonence/sdk'
import { CompileFreewallRequest } from '../api'
import { AnyFile } from '@djabry/fs-s3'
import { ParsedCompileFreewallRequest } from '../parser/parsed.compile.freewall.request'
import { ArrayUtils } from '@rezonence/array-utils'
import defaults from 'json-schema-defaults'
import { schemaOf } from '@rezonence/schema-transformer'
import { LocalFile } from '@jabrythehutt/fs-s3-core'

export class RequestBuilder {

  toDestinationFolder(adId: string, baseDestinationFolder: AnyFile = defaults(schemaOf<CompileFreewallRequest<AnyFile>>()).options.baseDestinationFolder): AnyFile {
    return {
      ...baseDestinationFolder,
      key: ArrayUtils.truthyValues([baseDestinationFolder.key, DestinationPrefix.Ads, adId]).join('/')
    }
  }

  async create<T extends LocalFile>(request: ParsedCompileFreewallRequest<T>): Promise<CompileConfigRequest> {
    const destinationFolder = this.toDestinationFolder(request.adId, request.options.baseDestinationFolder)
    return {
      configsToInsert: {},
      templateFiles: [],
      resourceFolders: request.resourceFolders,
      destinationFolder,
      options: {
        debug: request.options.debug
      }
    }
  }
}
