export enum Placeholder {
    NetworkId = 'networkId',
    ConfigId = 'configId',
    DealId = 'dealId',
    LineItemId = 'lineItemId',
    EngagementMacro = 'engagementMacro',
    AdId = 'adId',
    NexusPlacementId = 'nexusPlacementId',
    VastTagUrl = 'vastTagUrl',
    ImageSource = 'imageSource',
    ImageLink = 'imageLink',
    CampaignId = 'campaignId'
}
