import {MicrositeType} from "@rezonence/core/microsite/microsite.type";
import {Placement} from "@rezonence/sdk";

export const preferredPlacement: Record<MicrositeType, Placement> = {
  [MicrositeType.existing]: Placement.Response,
  [MicrositeType.carousel]: Placement.Response,
  [MicrositeType.multiVideo]: Placement.Response,
  [MicrositeType.survey]: Placement.Banner,
  [MicrositeType.vast]: Placement.Response,
  [MicrositeType.typeform]: Placement.Response,
  [MicrositeType.logo]: Placement.Response,
  [MicrositeType.chat]: Placement.Response,
  [MicrositeType.video]: Placement.Banner,
};
