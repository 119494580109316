import { Injectable } from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {Optional} from "@rezonence/sdk";
import {SchemaWizardComponent} from "../wizard/schema-wizard.component";
import {WizardConfig} from "../wizard.config";
import {merge} from "rxjs";
import {first, map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class WizardDialogService {

  constructor(private dialog: MatDialog) {
  }

  async requestData<T>(config: WizardConfig<T>): Promise<Optional<T>> {
    const wizardComponentRef = this.dialog.open(SchemaWizardComponent);
    const wizardComponent = wizardComponentRef.componentInstance as SchemaWizardComponent<T>;
    wizardComponent.config = config;
    const response = await merge(wizardComponentRef.afterClosed(), wizardComponent.done)
      .pipe(map(result => Optional.of(result)))
      .pipe(first())
      .toPromise();
    wizardComponentRef.close();
    return response;
  }

}
