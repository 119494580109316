import { Injectable } from "@angular/core";
import {combineLatest, Observable, ReplaySubject} from "rxjs";
import {debounceTime, distinctUntilChanged, map, shareReplay} from "rxjs/operators";
import {Duration} from "@rezonence/duration";
import { stringChangeComparator } from '../../core/string.change.comparator';

@Injectable()
export class GridService {

  set containerWidth(input: number) {
    this.containerWidth$.next(input);
  }

  set preferredWidth(input: number) {
    this._preferredWidth = input;
    this.preferredWidth$.next(input);
  }

  get preferredWidth(): number {
    return this._preferredWidth;
  }

  numberOfColumns$: Observable<number>;

  private _preferredWidth: number;

  private containerWidth$ = new ReplaySubject<number>(1);
  private preferredWidth$ = new ReplaySubject<number>(1);

  constructor() {
    this.numberOfColumns$ = combineLatest([this.containerWidth$, this.preferredWidth$])
      .pipe(debounceTime(Duration.MsInSecond))
      .pipe(distinctUntilChanged(stringChangeComparator))
      .pipe(map(([containerWidth, preferredWidth]) =>
        this.getNumberOfColumns({containerWidth, preferredWidth})))
      .pipe(shareReplay());
  }

  getNumberOfColumns(request: {containerWidth: number; preferredWidth: number}): number {
    return Math.max(Math.round(request.containerWidth / request.preferredWidth), 1);
  }

}
