import { Injectable } from "@angular/core";
import { AWSService } from "../core/AWSService";
import { DashboardRequestService } from "../core/dashboard-request.service";
import { DemoType } from "./DemoType";
import { recordFolderByDemoType } from "./recordFolderByDemoType";
import { AdIdBuilder } from "./AdIdBuilder";

@Injectable({
  providedIn: "root",
})
export class DemoAdIdResolver {
  constructor(
    private aws: AWSService,
    private requestConverter: DashboardRequestService,
  ) { }

  async createAdIdBuilder(): Promise<AdIdBuilder> {
    return new AdIdBuilder(this.requestConverter, { identityId: await this.aws.identityId() });
  }

  async toAdId(request: { recordId: string, demoType: DemoType }): Promise<string> {
    const adIdBuilder = await this.createAdIdBuilder();
    return adIdBuilder.build(request);
  }

  toSharedAdId(recordId: string): Promise<string> {
    return this.toAdId({ recordId, demoType: DemoType.Shared });
  }

}
