import {Pipe, PipeTransform} from "@angular/core";
import {ErrorMessageService} from "../../core/error-message/error-message.service";
import {Dataset} from "@rezonence/core";
import {KeyValue} from "@angular/common";
import {DatasetDictionary} from "../dataset.dictionary";
import {DataSchemaPair} from "../freewall-editor/data.schema.pair";
import {SchemaError} from "../../core/error-message/schema-error";

@Pipe({
  name: "datasetErrorMessage"
})
export class DatasetErrorMessagePipe implements PipeTransform {

  constructor(private errorMessageService: ErrorMessageService) {
  }

  async transform(errorDatasets: KeyValue<Dataset, SchemaError[]>[],
                  values: Partial<DatasetDictionary<DataSchemaPair>>): Promise<string> {
    if (errorDatasets.length) {
      const firstEntry = errorDatasets[0];
      const dataset = firstEntry.key;
      const schema = values[dataset].schema;
      const value = values[dataset].data;
      return this.errorMessageService.toErrorMessage(schema, value, firstEntry.value);
    }
    return "";
  }

}
