<app-record-container>
  <ng-template let-id="id">
    <ng-container *ngIf="id | datasetValues | async as values">
      <ng-container *ngIf="values.microsites.data as microsites">
        <div [fxHide]="hidden"
             fxLayout="column"
             fxLayoutAlign="center" class="microsite-selector">
          <mat-label class="mat-small">
            {{label}}
          </mat-label>
          <div>
            <ng-container *ngIf="microsites">
              <mat-select [(value)]="data" (valueChange)="onChange({value: $event})">
                <mat-option [value]="undefined">
                  None
                </mat-option>
                <mat-option *ngFor="let microsite of microsites; trackBy: trackElement" [value]="microsite.url">
                  {{microsite.title}}
                </mat-option>
              </mat-select>
            </ng-container>
            <!--<button mat-flat-button (click)="selectMicrosite(id)" [color]="colour">
              <ng-container *ngIf="data; else defaultMessage">
                {{data | fileName}}
              </ng-container>
              <ng-template #defaultMessage>
                Select microsite
              </ng-template>
            </button>-->
          </div>
        </div>
      </ng-container>

    </ng-container>

  </ng-template>
</app-record-container>

