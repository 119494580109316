import './editor-toolbar.component.scss';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CSSConfig,
  Dataset,
  FreeWall,
  Microsites,
  CustomCodeConfig
} from '@rezonence/core';
import { CreativeFreeWallDB } from '../../core/CreativeFreeWallDB';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FreeWallProgressComponent } from '../freewall-progress/freewall-progress.component';
import { CloudFreewallCompiler } from '../../core/CloudFreewallCompiler';
import { DatasetDictionary } from '../dataset.dictionary';
import { ErrorObject } from 'ajv';
import { DataSchemaPair } from '../freewall-editor/data.schema.pair';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-editor-toolbar',
  templateUrl: './editor-toolbar.component.html'
})
export class EditorToolbarComponent {
  @Input()
  recordId: string;

  @Input()
  dataset: Dataset;

  @Input()
  actionLabel: string;

  @Input()
  values: Partial<DatasetDictionary<DataSchemaPair>>;

  @Input()
  errors: Partial<DatasetDictionary<ErrorObject[]>>;

  @Output()
  saved: EventEmitter<void> = new EventEmitter();

  constructor(
    private freeWallDb: CreativeFreeWallDB,
    private compiler: CloudFreewallCompiler,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) { }

  async save(freeWallTitle: string): Promise<void> {
    const progressDialog = await this.openProgressDialog(freeWallTitle);
    try {
      await this.saveFreeWall();
      await this.closeProgressDialog(progressDialog, `Saved ${freeWallTitle}`);
    } catch (err) {
      await this.closeProgressDialog(
        progressDialog,
        `Failed to save ${freeWallTitle}: ${err}`
      );
    }
    this.saved.emit();
  }

  private async openProgressDialog(
    title: string
  ): Promise<MatDialogRef<FreeWallProgressComponent>> {
    const progressDialog = this.dialog.open(FreeWallProgressComponent, {
      disableClose: true
    });
    progressDialog.componentInstance.message = `Saving ${title}...`;
    return progressDialog;
  }

  private async closeProgressDialog(
    progressDialog: MatDialogRef<FreeWallProgressComponent>,
    snack: string
  ): Promise<void> {
    progressDialog.close();
    await lastValueFrom(progressDialog.afterClosed());
    this.snackbar.open(snack, 'OK');
  }

  private async saveFreeWall(): Promise<void> {
    await this.saveDatasetValues();
    await this.compileFreeWall();
  }

  private async saveDatasetValues(): Promise<void> {
    for (const dataset of Object.keys(this.values)) {
      const dataSchemaPair = this.values[dataset] as DataSchemaPair;
      await this.freeWallDb.saveDatasetValue(
        dataset as Dataset,
        this.recordId,
        dataSchemaPair.data
      );
    }
  }

  private async compileFreeWall(): Promise<void> {
    const config = this.values.freewall.data as FreeWall;
    const microsites = this.values.microsites.data as Microsites;
    const css = this.values.css.data as CSSConfig;
    const customCode = this.values.customCode.data as CustomCodeConfig;
    await this.compiler.invoke({
      config,
      microsites,
      recordId: this.recordId,
      css,
      customCode
    });
  }
}
