import {Component, Input, OnInit} from "@angular/core";
import { FreeWallResponse } from "@rezonence/core/config-extractor";
import {FreeWall} from "@rezonence/core/freewall/freewall";
import {ResourceType} from "../../resource/resource.type";
import { Placement } from "@rezonence/freewall-creator-config";
import {ResourceSelectorService} from "../../resource/resource-selector/ResourceSelectorService";
import {Optional} from "@rezonence/sdk";
import {responseField} from "./response.field";
import {SelectedResource} from "../../resource/resource-selector/selected.resource";
import {SelectResourceRequest} from "../../resource/resource-selector/select.resource.request";
import {defaultImage} from "../wizard/default.image";
import {freewallConfigSchema} from "@rezonence/core";
import {allControl} from "../all.control";

@Component({
  selector: "response",
  templateUrl: "./response.component.html",
  styleUrls: ["./response.component.css"]
})
export class ResponseComponent implements OnInit {

  schema = {
    properties: {
      img: {
        ...freewallConfigSchema.definitions.FreeWallResponse.properties.img
      }
    }
  };

  uiSchema = allControl;

  @Input()
  freeWallId: string;

  @Input()
  config: FreeWall;

  @Input()
  responseIndex: number;

  @Input()
  advanced: boolean;

  @Input()
  mode: Placement;

  constructor(private resourceSelector: ResourceSelectorService) {
  }

  get response(): FreeWallResponse {
    return this.config[`${this.mode}s`][this.responseIndex];
  }

  set response(response: FreeWallResponse) {
    this.config[`${this.mode}s`][this.responseIndex] = response;
  }

  get defaultImage(): boolean {
    return this.response && this.response.img && this.response.img === defaultImage;
  }

  async addVideo() {
    await this.selectResource([ResourceType.video]);
  }

  async addImage() {
    await this.selectResource([ResourceType.image]);
  }

  async addMedia() {
    await this.selectResource([ResourceType.image, ResourceType.video]);
  }

  ngOnInit() {
  }

  private async selectResource(resourceTypes: ResourceType[]) {

    const request: SelectResourceRequest = {
      freeWallId: this.freeWallId,
      resourceTypes,
      mode: resourceTypes[0]
    };

    if (resourceTypes.length === 1) {
      const field = responseField[resourceTypes[0]];
      request.selectedResourcePath = this.response[field as string];
    }
    const resource = await this.resourceSelector.selectResource(request);
    this.applyResource(resource);
  }

  private applyResource(optionalResource: Optional<SelectedResource>) {
    if (optionalResource.exists && optionalResource.item.resourceType.exists) {
      const resource = optionalResource.item;
      const field = responseField[resource.resourceType.item];
      this.response[field as string] = resource.resourcePath;
    }

  }

}
