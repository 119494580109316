<app-loading [loadingPromise]="micrositesPromise" progressMessage="Loading microsites...">
</app-loading>
<ng-container *ngIf="micrositesPromise | async as microsites">
  <ng-container *ngIf="microsites.length">
    <p>Select a microsite</p>
    <app-microsites [microsites]="microsites" (micrositeClicked)="micrositeSelected.emit($event)">
    </app-microsites>
  </ng-container>
  <ng-container>
    <p>Create a new microsite</p>
  </ng-container>
</ng-container>
