import {Component, Input, OnInit, TemplateRef} from "@angular/core";
import {Optional} from "@rezonence/sdk";
import {IdMapper} from "../grid/id.mapper";

@Component({
  selector: "app-grid-row",
  templateUrl: "./grid-row.component.html",
  styleUrls: ["./grid-row.component.css"]
})
export class GridRowComponent implements OnInit {

  @Input()
  set rowLength(input: number) {
    this._rowLength = input;
    this.createItemContainers();
  }

  get rowLength(): number {
    return this._rowLength;
  }

  @Input()
  set items(items: any[]) {
    this._items = items;
    this.createItemContainers();
  }

  get items(): any[] {
    return this._items;
  }

  @Input()
  last: boolean;

  itemContainers: Optional<any>[];

  @Input()
  gutter: number;

  @Input()
  cellTemplate: TemplateRef<any>;

  @Input()
  idMapper: IdMapper;

  private _rowLength: number;
  private _items: any[];

  constructor() {
  }

  trackElement = (index: number, element) => this.idMapper(element);

  ngOnInit() {
  }

  private createItemContainers() {
    if (this.rowLength && this.items) {
      const numberOfEmptyItems = Math.max(this.rowLength - this.items.length, 0);
      const emptyItems = (Array.from(new Array(numberOfEmptyItems), () => Optional.empty()));
      this.itemContainers = [...this.items.map(item => Optional.of(item)), ...emptyItems];
    }

  }

}
