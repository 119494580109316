import {Component, Input, Output} from "@angular/core";
import {FreeWall} from "@rezonence/core/freewall/freewall";
import {Answer} from "@rezonence/core/config-extractor";
import { FreeWallConfig, freewallConfigSchema } from "@rezonence/core";
import {animate, style, trigger, transition} from "@angular/animations";
import {WizardService} from "../wizard/wizard.service";
import { defaultFreeWall } from "../wizard/default.freewall";
import {combineLatest, Observable, ReplaySubject} from "rxjs";
import {ErrorObject} from "ajv";
import {ArrayUtils} from "@rezonence/array-utils";
import {map} from "rxjs/operators";
import { WizardModeKey } from "../wizard/wizard.mode.key";
const defaultAdConf = JSON.parse(JSON.stringify(defaultFreeWall)) as FreeWallConfig;

@Component({
  selector: "app-answers",
  templateUrl: "./answers.component.html",
  styleUrls: ["./answers.component.css"],
  animations: [
    trigger("removeAnimation", [
      transition(":enter", [
        style({transform: "translateX(100%)", opacity: 0}),
        animate("400ms", style({transform: "translateX(0)", opacity: 1}))
      ]),
      transition(":leave", [
        style({transform: "translateX(0)", opacity: 1}),
        animate("400ms", style({transform: "translateX(100%)", opacity: 0}))
      ])
    ])
  ]
})

export class AnswersComponent {

  get config(): FreeWall {
    return this._config;
  }

  @Input()
  set config(config: FreeWall) {
    this._config = config;
    this.errorsByAnswer = this.createErrorsByAnswer();
    this.errors = combineLatest(this.errorsByAnswer).pipe(map(errors => ArrayUtils.flatten(errors)));
  }

  schema = freewallConfigSchema.definitions.Answer.properties.ans;

  errorsByAnswer: ReplaySubject<ErrorObject[]>[];

  @Output()
  errors: Observable<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);

  @Input()
  validityMode: boolean;

  private _config: FreeWall;

  constructor(private wizardService: WizardService) {
  }

  createErrorsByAnswer(): ReplaySubject<ErrorObject[]>[] {
    return this.config ?
      this.config.questions[0].answers.map(answer => new ReplaySubject<ErrorObject[]>(1)) :
      [];
  }

  collectErrors(answerIndex: number, errors: ErrorObject[]) {
    this.errorsByAnswer[answerIndex].next(errors);
  }

  checkboxDisabled(answer: Answer): boolean {
    return answer.tof && this.config.questions[0].answers.filter(a => a.tof).length === 1;
  }

  removeAnswer(index: number) {
    this.config.questions[0].answers.splice(index, 1);
    this.errorsByAnswer.splice(index, 1);

    if (this.config.questions[0].answers.filter(answer => answer.tof).length === 0) {
      // Tick the first answer in case there are no correct answers

      this.config.questions[0].answers[0].tof = true;
    }

    // Re-map responses and kickers

  }

  isQuiz(): boolean {
    return this.config.questions[0] && !!this.config.questions[0].answers.find(answer => !answer.tof);
  }

  onValidityChange(answers: Answer[], answerIndex: number): void {
    if (this.wizardService.mode.key === WizardModeKey.SimpleQuiz && answers.every(answer => answer.tof)) {
      const otherAnswer = answers.filter((_, index) => index !== answerIndex).shift();
      if (otherAnswer) {
        otherAnswer.tof = false;
      }
    }
  }

  addAnswer() {

    const answer = this.wizardService.mode.createAnswer(this.config);
    const answers = this.config.questions[0].answers;
    answers.push(answer);
    this.errorsByAnswer.push(new ReplaySubject<ErrorObject[]>(1));

    // Add any required responses and kickers
    const responses = [this.config.responses, this.config.kickers];
    for (const responseSet of responses) {
      while (responseSet.length < answers.length ) {
        // Add default responses
        responseSet.push(JSON.parse(JSON.stringify(defaultAdConf.responses[0])));
      }
    }
  }

}
