import {RuleEffect, UISchemaElement, VerticalLayout} from "@jsonforms/core";
import {Question} from "@rezonence/sdk";
import {freewallConfigSchema} from "@rezonence/core";
import {UiSchemaService} from "../../core/ui-schema.service";
const uiSchemaService = new UiSchemaService();

export const questionUiSchema: UISchemaElement = {
  type: "VerticalLayout",
  elements: Object.keys(freewallConfigSchema.definitions.Question.properties)
    .map((field: keyof Question) => {
      if (field === "hnt") {
        return {
          type: "Control",
          scope: uiSchemaService.toScope(uiSchemaService.fillControlElementRequest({field})),
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              schema: {
                contains: {
                  properties: {
                    tof: {enum: [false]}
                  }
                }
              },
              scope: "#/properties/answers"
            }
          }
        };
      } else if (field === "sco") {
        return {
          type: "Control",
          scope: uiSchemaService.toScope(uiSchemaService.fillControlElementRequest({field})),
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              schema: {
                enum: [true]
              },
              scope: "#/properties/ran"
            }
          }
        };
      } else {
        return uiSchemaService.toControlElement({field});
      }
    })
} as VerticalLayout;
