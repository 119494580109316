/**
 * Some microsites specified in the FreeWall config aren't present in the "microsites" dataset array,
 * this service constructs "existing" microsite objects for each missing link and adds them in.
 */
import { Injectable } from "@angular/core";
import { DatasetDictionary } from "../dataset.dictionary";
import { DatasetValue, FreeWall, Microsite, MicrositeLinkResolver, Microsites, micrositeSchema } from "@rezonence/core";
import { MicrositeType } from "@rezonence/core/microsite/microsite.type";
import { Optional } from "@rezonence/sdk";
import { DataSchemaPair } from "../freewall-editor/data.schema.pair";
import { JsonSchema, JsonSchema7 } from "@jsonforms/core";
import { resolveRefs } from "json-refs";
import cloneDeep from "lodash/cloneDeep";
import { CreativeUtils } from "../../core/creative.utils";
import { tag } from "@rezonence/git";
import { PostLoadHook } from "../freewall-editor";
import { CreativeMicrositeResolver, CreativeMicrositeSchemaResolver } from "../../microsite";
import { MicrositeTitleGenerator } from "../../microsite/MicrositeTitleGenerator";

@Injectable({
  providedIn: "root"
})
export class MicrositesLoaderService implements PostLoadHook {

  constructor(private schemaResolver: CreativeMicrositeSchemaResolver,
    private micrositeResolver: CreativeMicrositeResolver,
    private titleGenerator: MicrositeTitleGenerator,
    private utils: CreativeUtils) {
  }

  transform(input: Partial<DatasetDictionary<DataSchemaPair<DatasetValue>>>): Promise<Partial<DatasetDictionary<DataSchemaPair<DatasetValue>>>> {
    return this.loadMicrosites(input);
  }

  toUrl(link: string): Optional<URL> {
    try {
      return Optional.of(new URL(link));
    } catch (err) {
      return Optional.empty();
    }
  }

  createMicrositeId(microsites: Microsites): string {
    const existingIds = microsites.map(m => m.id);
    let id = this.utils.randomString();
    while (existingIds.includes(id)) {
      id = this.utils.randomString();
    }
    return id;
  }

  toExistingMicrosite(link: string, microsites: Microsites): Microsite {
    const templateId = MicrositeType.existing;
    return {
      url: link,
      id: this.createMicrositeId(microsites),
      templateId,
      config: {},
      title: this.titleGenerator.generate(templateId, microsites)
    };
  }

  async inlineSchema<T extends JsonSchema>(schema: T): Promise<T> {
    const result = await resolveRefs(schema);
    return result.resolved as T;
  }

  async toMicrositeSchema(config: FreeWall, microsite: Microsite): Promise<JsonSchema7> {
    const micrositeInfo = await this.schemaResolver.resolveSchema({ templateId: microsite.templateId, version: config.version || tag });
    const micrositeConfigSchema = micrositeInfo.item.schema as unknown as JsonSchema7;
    const inlinedConfigSchema = await this.inlineSchema(micrositeConfigSchema);
    const inlinedMicrositeSchema: JsonSchema7 = await this.inlineSchema(micrositeSchema);
    inlinedMicrositeSchema.properties.config.properties = inlinedConfigSchema.properties;
    return inlinedMicrositeSchema;
  }

  appendArraySchemaElement(request: { parentSchema: JsonSchema7; childSchema: JsonSchema7 }): JsonSchema7 {
    const parentSchema = cloneDeep(request.parentSchema);
    if (!Array.isArray(parentSchema.items)) {
      parentSchema.items = [];
    }
    parentSchema.items.push(request.childSchema);
    return parentSchema;
  }

  async addMicrositesToSchema(config: FreeWall, inputMicrositesSchema: JsonSchema, microsites: Microsite[]): Promise<JsonSchema> {
    const schemas = await Promise.all(microsites.map(m => this.toMicrositeSchema(config, m)));
    return schemas.reduce((parentSchema, childSchema) => this.appendArraySchemaElement({
      parentSchema,
      childSchema
    }), inputMicrositesSchema as JsonSchema7);
  }

  loadMissingMicrosites(freeWallConfig: FreeWall, existingMicrosites: Microsites): Microsites {
    const freeWallMicrositeLinks = this.micrositeResolver.toMicrositeSources(freeWallConfig);
    const micrositeLinks = existingMicrosites.map(m => m.url);
    return freeWallMicrositeLinks
      .filter(link => !micrositeLinks.includes(link))
      .reduce((allMicrosites, link) =>
      ([
        ...allMicrosites,
        this.toExistingMicrosite(link, allMicrosites)
      ]), existingMicrosites);
  }

  removeMicrositeFromSchema(schema: JsonSchema, index: number) {
    if (Array.isArray(schema.items)) {
      schema.items.splice(index, 1);
    } else {
      throw new Error("Expected a schema for each microsite in the microsites array");
    }
  }

  async loadMicrosites(values: Partial<DatasetDictionary<DataSchemaPair>>):
    Promise<Partial<DatasetDictionary<DataSchemaPair>>> {
    if (values.microsites && values.freewall) {
      const microsites = values.microsites.data as Microsites;
      const freeWall = values.freewall.data as FreeWall;
      const allMicrosites = this.loadMissingMicrosites(freeWall, microsites).map(m => ({
        ...m,
        url: MicrositeLinkResolver.resolveMicrositeLink(m)
      }));
      const schemas = await this.addMicrositesToSchema(freeWall, values.microsites.schema, allMicrosites);
      values.microsites.data = allMicrosites;
      values.microsites.schema = schemas;
    }
    return values;
  }
}
