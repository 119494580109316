import {Injectable} from "@angular/core";
import {Optional} from "@rezonence/sdk";
import {ScannedFile} from "@djabry/fs-s3";
import {SelectResourceRequest} from "./select.resource.request";
import {ResourceType} from "../resource.type";
import {MatDialog} from "@angular/material/dialog";
import {UploadComponent} from "../upload/UploadComponent";
import {merge} from "rxjs";
import {first, map} from "rxjs/operators";
import {ResourceService} from "../resource/ResourceService";
import {SelectedResource} from "./selected.resource";
import {ResourceTypeService} from "../resource/resource-type.service";
import {basename} from "path";
import {prefixMacro} from "@rezonence/sdk";

@Injectable({
  providedIn: "root"
})
export class ResourceSelectorService {

  constructor(private dialog: MatDialog,
              private resourceService: ResourceService,
              private resourceTypeService: ResourceTypeService) {
  }

  async selectResource(request: SelectResourceRequest): Promise<Optional<SelectedResource>> {
    const completeRequest = this.toCompleteRequest(request);
    const ref = this.dialog.open<UploadComponent, ScannedFile>(UploadComponent);
    const uploadComponent = ref.componentInstance;
    uploadComponent.request = completeRequest;
    uploadComponent.currentResourcePath = request.selectedResourcePath;
    const response = await merge(ref.afterClosed(), uploadComponent.resourceSelected)
      .pipe(map(file => Optional.of(file)))
      .pipe(map(optionalFile => optionalFile.map(f => this.toSelectedResource(f))))
      .pipe(first())
      .toPromise();
    ref.close();
    return response;

  }

  toSelectedResource(file: ScannedFile): SelectedResource {
    return {
      file,
      resourceType: this.resourceTypeService.toResourceType(file),
      resourcePath: this.toResourcePath(file)
    };
  }

  toResourcePath(file: ScannedFile): string {
    const filename = basename(file.key);
    return `${prefixMacro}/${filename}`;
  }

  private toCompleteRequest(input: SelectResourceRequest): Required<SelectResourceRequest> {
    const allResourceTypes = Object.values(ResourceType);
    return {
      resourceTypes: allResourceTypes,
      enableSwitchMode: true,
      selectedResourcePath: undefined,
      mode: allResourceTypes[0],
      ...input
    };
  }
}
