import { Injectable } from "@angular/core";
import {CreativeFileService} from "../../core/CreativeFileService";
import {LinkedFile} from "../../core/linked.file";
import {AWSService} from "../../core/AWSService";
import {environment} from "../../../environments/environment";
import {ResourceType} from "../resource.type";
import {basename} from "path";
import {Dataset} from "@rezonence/core";
import {LinkMacro} from "@rezonence/sdk";
import {AnyFile, ScannedFile} from "@djabry/fs-s3";
import {prefixMacro} from "@rezonence/sdk";
import {Duration} from "@rezonence/duration";
import { CreativeMacroRenderer } from "../../core";
import { tag } from "@rezonence/git";

@Injectable({
  providedIn: "root"
})
export class ResourceService {

  constructor(private fileService: CreativeFileService,
    private macroRenderer: CreativeMacroRenderer,
              private awsService: AWSService) {
  }

  async toUserStorageFolderKey(freewallId: string): Promise<string> {
    const identityId = await this.awsService.identityId();
    return `${identityId}/${Dataset.Freewall}/${freewallId}`;
  }

  async toUserStorageFolder(freewallId: string): Promise<AnyFile> {
    const key = await this.toUserStorageFolderKey(freewallId);
    return {
      key,
      bucket: environment.userStorageBucket
    };
  }

  async toLink(request: {file: AnyFile; freewallId: string; userStorageFolder: AnyFile}): Promise<string> {
    const link = request.file.key.replace(request.userStorageFolder.key, prefixMacro);
    return this.renderLinkMacros({...request, link});
  }

  isValidResourceFile(file: ScannedFile): boolean {
    return !!Object.values(ResourceType).find(resourceType => file.mimeType && file.mimeType.startsWith(resourceType));
  }

  toTitle(file: AnyFile): string {
    return basename(file.key);
  }

  async listResources(freewallId: string): Promise<LinkedFile[]> {
    const userStorageFolder = await this.toUserStorageFolder(freewallId);
    const resourceFiles = await this.fileService.list(userStorageFolder);
    const resources = resourceFiles.filter(file => this.isValidResourceFile(file));
    return Promise.all(resources.map(async file => {
      const link = await this.toLink({
        file,
        freewallId,
        userStorageFolder
      });
      const title = this.toTitle(file);
      return {
        ...file,
        link,
        title
      };
    }));
  }

  async renderLinkMacros(request: {freewallId: string; link: string}): Promise<string> {
    if (request.link.startsWith(prefixMacro)) {
      const userStorageFolder = await this.toUserStorageFolder(request.freewallId);
      const key = request.link.replace(prefixMacro, userStorageFolder.key);
      const userStorageFile = {
        ...userStorageFolder,
        key
      };

      return this.fileService.getReadURL(userStorageFile, 3 * Duration.MsInDay / Duration.MsInSecond);

    } else {

      return this.macroRenderer.renderAll({
        link: request.link,
        valueByMacro: {
          [LinkMacro.Cdn]: environment.defaultCDN,
          [LinkMacro.Version]: tag
        }
      });

    }
  }
}
