import { Injectable } from "@angular/core";
import { TemplateFolderResolver } from "@rezonence/core";

@Injectable({
    providedIn: "root"
})
export class CreativeTemplateFolderResolver extends TemplateFolderResolver {
  constructor() {
    super();
  }
}
