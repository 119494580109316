import { Injectable } from "@angular/core";
import { CognitoIdentityCredentials } from "aws-sdk/global";
import { config } from "aws-sdk/global";
import DynamoDB from "aws-sdk/clients/dynamodb";
import { federatedIdentityProvider } from "@rezonence/authenticator";
import { CognitoIdentityServiceFactory } from "@jabrythehutt/aws-factory/src/cognito.identity.service.factory";
import { Optional } from "@rezonence/sdk";
import { InfrastructureResolver } from "./InfrastructureResolver";
import { OpenIdTokenResponse } from "@rezonence/magic-link";
import { concatMap, distinctUntilChanged, lastValueFrom, Observable, shareReplay, take } from "rxjs";
import { MagicLinkService } from "../authenticator";
import { Router } from "@angular/router";
import { Route } from "@rezonence/freewall-creator-config";

@Injectable({
  providedIn: "root"
})
export class AWSService extends CognitoIdentityServiceFactory {
  readonly identityId$: Observable<string>;
  private dbPromise: Promise<DynamoDB.DocumentClient>;

  constructor(private infra: InfrastructureResolver, private magicLinkService: MagicLinkService, private router: Router) {
    super();
    config.region = infra.config.authentication.region;
    this.identityId$ = this.magicLinkService.token$.pipe(
      concatMap(token => this.start(token)),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  toCredentials(optionalToken: Optional<OpenIdTokenResponse>): CognitoIdentityCredentials {
    const baseParams: CognitoIdentityCredentials.CognitoIdentityOptions = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      IdentityPoolId: this.infra.config.authentication.identityPoolId
    };
    const optionalParams = optionalToken.map(token => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      IdentityId: token.identityId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Logins: {
        [federatedIdentityProvider]: token.idToken
      }
    }));
    return new CognitoIdentityCredentials({
      ...baseParams,
      ...optionalParams.item,
    })
  }

  identityId(): Promise<string> {
    return lastValueFrom(this.identityId$.pipe(take(1)));
  }

  dynamodb(): Promise<DynamoDB.DocumentClient> {
    this.dbPromise = this.dbPromise || this.createDocumentClient();
    return this.dbPromise;
  }

  async logOut(): Promise<void> {
    await Promise.all([
      this.signOut(),
      this.magicLinkService.signOut(),
      this.router.navigate([
        `/${Route.Home}`
      ])
    ]);
    location.reload();
  }

  clearServicesCache(): void {
    this.servicesCache = this.servicesCache || {};
  }

  protected async start(optionalToken: Optional<OpenIdTokenResponse>): Promise<string> {
    this.credentials = this.toCredentials(optionalToken);
    // Update the credentials of the document DB client by referencing the associate DynamoDB service
    const documentClientService = ((await this.dynamodb()) as DynamoDB.DocumentClient & ({ service: DynamoDB })).service;
    const services = [...Object.values(this.servicesCache), documentClientService]
    for (const service of services) {
      service.config.update({
        credentials: this.credentials
      });
    }
    await this.authenticate();
    return this.credentials.identityId;
  }

  protected async createDocumentClient(): Promise<DynamoDB.DocumentClient> {
    return new DynamoDB.DocumentClient({ convertEmptyValues: true, service: await this.getService(DynamoDB) });
  }
}
