<div fxLayout="row" class="colour-picker-container" fxLayoutAlign="start center" fxLayoutGap="20px">
  <input [(colorPicker)]="data"
         cpPosition="top-right"
         [style.background]="data"
         class="colour-picker"
         (colorPickerSelect)="onChange({value: $event})"/>
  <mat-label>
    {{label}}
  </mat-label>
</div>


