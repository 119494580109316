import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {SelectMicrositeRequest} from "./select.microsite.request";
import {MicrositeSelectorComponent} from "./MicrositeSelectorComponent";
import {merge} from "rxjs";
import {Optional} from "@rezonence/sdk";
import {first, map} from "rxjs/operators";
import {Microsite} from "@rezonence/core";

@Injectable({
  providedIn: "root"
})
export class MicrositeSelectorService {

  constructor(private dialog: MatDialog) {
  }

  async selectMicrosite(input: SelectMicrositeRequest): Promise<Optional<Microsite>> {
    const ref = this.dialog.open<MicrositeSelectorComponent, Microsite>(MicrositeSelectorComponent);
    const selectorComponent = ref.componentInstance;
    selectorComponent.freeWallId = input.freewallId;
    const response = await merge(ref.afterClosed(), selectorComponent.micrositeSelected)
      .pipe(map(microsite => Optional.of(microsite)))
      .pipe(first())
      .toPromise();
    ref.close();
    return response;
  }
}
