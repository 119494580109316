import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { CreateModule } from "../create/CreateModule";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CreateModule
  ]
})
export class QuizModule {
}
