import { Injectable, Inject } from "@angular/core";
import {DatasetDictionary} from "../dataset.dictionary";
import {CreativeFreeWallDB} from "../../core/CreativeFreeWallDB";
import {DefaultValueService} from "../default-value/default-value.service";
import {freeWallEditorDatasets} from "../freewall.editor.datasets";
import cloneDeep from "lodash/cloneDeep";
import { schemaByDataset } from "../schema/schema.by.dataset";
import {DataSchemaPair} from "./data.schema.pair";
import { postLoadHookToken } from "./postLoadHookToken";
import { PostLoadHook } from "./PostLoadHook";

@Injectable({
  providedIn: "root"
})
export class FreewallEditorService {
  private values: Record<string, Promise<Partial<DatasetDictionary<DataSchemaPair>>>> = {};

  constructor(private db: CreativeFreeWallDB,
              private defaultValueService: DefaultValueService,
    @Inject(postLoadHookToken) private postLoadHooks: PostLoadHook[]) {
  }

  getValuesForRecordId(recordId: string): Promise<Partial<DatasetDictionary<DataSchemaPair>>> {
    this.values[recordId] = this.values[recordId] || this.findValuesForRecordId(recordId);
    return this.values[recordId];
  }

  private async findValuesForRecordId(recordId: string): Promise<Partial<DatasetDictionary<DataSchemaPair>>> {
    let result: Partial<DatasetDictionary<DataSchemaPair>> = {};
    for (const dataset of freeWallEditorDatasets) {
      const data = (await this.db.getDatasetValue(dataset, recordId)) ||
        this.defaultValueService.createDefaultValueForDataset(dataset);
      const schema = cloneDeep(schemaByDataset[dataset]);
      result[dataset] = {data, schema};
    }
    for (const processor of this.postLoadHooks) {
      result = await processor.transform(result, recordId);
    }
    return result;
  }

}
