import {Pipe, PipeTransform} from "@angular/core";
import {CodeDataset, Dataset} from "@rezonence/core";

@Pipe({
  name: "isCode"
})
export class IsCodePipe implements PipeTransform {

  transform(dataset: Dataset): boolean {
    return (Object.values(CodeDataset) as Dataset[]).includes(dataset);
  }

}
