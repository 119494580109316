import { Component } from "@angular/core";
import { JsonFormsAngularService, JsonFormsControl } from "@jsonforms/angular";

@Component({
  selector: "app-colour-renderer",
  templateUrl: "./colour-renderer.component.html",
  styleUrls: ["./colour-renderer.component.css"]
})
export class ColourRendererComponent extends JsonFormsControl {

  constructor(jsonFormsService: JsonFormsAngularService) {
    super(jsonFormsService);
  }

}
