import { crossFetch } from "./crossFetch";
import { Optional } from "./Optional";

export async function downloadJson<T>(url: URL): Promise<Optional<T>> {
  try {
    const response = await crossFetch(url.toString())
    const value = await response.json();
    return Optional.of(value as T);
  } catch (err) {
    if (err.response?.status === 404) {
      return Optional.empty()
    }
    throw err
  }
}
