import {Directive, ElementRef, HostListener, OnInit} from "@angular/core";
import {GridService} from "../grid/grid.service";

@Directive({
  selector: "[appGridContainer]"
})
export class GridContainerDirective implements OnInit {

  constructor(private gridService: GridService,
              public ref: ElementRef) {
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.applyContainerWidth();
  }

  applyContainerWidth() {
    this.gridService.containerWidth = this.ref.nativeElement.offsetWidth;
  }

  ngOnInit(): void {
    this.gridService.containerWidth = this.ref.nativeElement.offsetWidth;
  }

}
