
export enum LinkMacro {
  Cdn = '[CDN]',
  AdId = '[AID]',
  Version = "[VERSION]",
  Hostname = '[R3Z_HOST]',
  Protocol = '[R3Z_PCOL]',
  Timestamp = '[R3Z_CAB]',
  R3zAdId = '[R3Z_AID]',
  SessionId = '[R3Z_SID]',
  ConfigId = '[CID]',
  AnswerIndex = '[AIX]',
  QuestionIndex = '[QIX]',
}
