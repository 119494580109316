<mat-card fxLayout="column" class="form-array">
  <ng-template #controls>
    <mat-card-title>{{label}}
      <div>
        <button mat-icon-button (click)="add()" [matTooltip]="'Add ' + itemTitle">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button (click)="remove(0)" [matTooltip]="'Remove ' + itemTitle" *ngIf="data && data.length > minItems">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>
  </ng-template>
  <ng-template #singleItem>
    <ng-template [ngTemplateOutlet]="controls">
    </ng-template>
    <mat-card fxLayout="column" *ngIf="data && data.length">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {item: data[0], index: 0}">
      </ng-container>
    </mat-card>
  </ng-template>
  <ng-container *ngIf="data && data.length > 1; else singleItem">
    <mat-card-title>{{label}}</mat-card-title>
    <mat-tab-group [(selectedIndex)]="selectedIndex">
      <mat-tab *ngFor="let item of data; let i = index; trackBy: trackElement">
        <ng-template mat-tab-label>
          <app-array-element-header [label]="tabLabelMapper(i, item)"
                                    removeCaption="Remove {{itemTitle}} {{i + 1}}"
                                    (removeClick)="remove(i)">
          </app-array-element-header>
        </ng-template>
        <ng-template matTabContent>
          <mat-card fxLayout="column">
            <ng-container *ngTemplateOutlet="itemTemplate; context: {item: item, index: i}">
            </ng-container>
          </mat-card>
        </ng-template>
      </mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <app-add-button-header (addClick)="add()" [addCaption]="'Add ' + itemTitle"></app-add-button-header>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</mat-card>
