import {Component} from "@angular/core";
import {JsonFormsAngularService, JsonFormsControl} from "@jsonforms/angular";
import {MicrositeSelectorService} from "../../microsite/microsite-selector/MicrositeSelectorService";
import {ThemePalette} from "@angular/material/core";
import {Dataset} from "@rezonence/core";
import {Microsite} from "@rezonence/core";

@Component({
  selector: "app-microsite-selection-renderer",
  templateUrl: "microsite-selection-renderer.component.html",
  styleUrls: ["microsite-selection-renderer.component.css"]
})
export class MicrositeSelectionRendererComponent extends JsonFormsControl {

  Dataset = Dataset;

  constructor(protected jsonFormsService: JsonFormsAngularService,
              private micrositeSelector: MicrositeSelectorService) {
    super(jsonFormsService);
  }

  get colour(): ThemePalette {
    return this.data ? "accent" : "primary";
  }

  async selectMicrosite(freewallId: string) {
    const selectedMicrosite = await this.micrositeSelector.selectMicrosite({freewallId});
  }

  trackElement(index: number, microsite: Microsite): string {
    return microsite ? microsite.id : null;
  }
}
