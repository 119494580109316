<ng-container *ngIf="config">
  <rez-json-form [(data)]="config.data"
                 [schema]="config.schema"
                 [uiSchema]="step.uiSchema"
                 (errors)="errorCollector.next($event)">
  </rez-json-form>
</ng-container>




