import { PipeTransform, Pipe } from "@angular/core";
import { FreeWallConfig } from "@rezonence/core";
import { WizardMode } from "./wizard.mode";
import { WizardService } from "./wizard.service";

@Pipe({
    name: "wizardMode"
})
export class WizardModePipe implements PipeTransform {
    modes: WizardMode[];

    constructor(wizardService: WizardService) {
        this.modes = wizardService.listModes();
    }

    transform(config: FreeWallConfig): WizardMode | undefined {
        return this.modes.find(m => m.isCompatible(config));
    }

}