import { Injectable } from "@angular/core";
import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import { JsonFormsAngularService } from "@jsonforms/angular";

@Injectable({
  providedIn: "root"
})
export class JsonFormService {
  renderers: JsonFormsRendererRegistryEntry[] = [];

  registerRenderer(...entries: JsonFormsRendererRegistryEntry[]) {
    this.renderers.push(...entries);
  }

  getValue<T>(jsonFormsService: JsonFormsAngularService): T {
    return jsonFormsService.getState().jsonforms?.core?.data as T;
  }
}
