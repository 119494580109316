<app-record-container>
  <ng-template let-id="id">
    <ng-container *ngIf="resourceType.exists">
      <div [fxHide]="hidden" fxLayout="column" fxLayoutAlign="center" class="resource-selector">
        <ng-container *ngIf="resourceType.item as title">
          <mat-label class="mat-small">
            {{label}}
          </mat-label>
          <div>
            <mat-chip-list>
              <mat-chip (removed)="clear()" (click)="selectMediaForFreeWallId(id)" color="primary" [style]="{cursor: 'pointer'}"
                [selected]="!!data">
                <ng-container *ngIf="data; else defaultMessage">
                  {{data | fileName}}
                </ng-container>
                <ng-template #defaultMessage>
                  Select {{title}}
                </ng-template>
                <button matChipRemove *ngIf="data" matTooltip="Clear {{title}}">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
              </mat-chip-list>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</app-record-container>