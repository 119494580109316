<ng-container *ngIf="modeResolver.mode$ | async as mode">
  <div class="slide" fxLayout="column" fxLayoutAlign="space-between center">
    <div class="image-container" [routerLink]="toRouterLink(mode)" queryParamsHandling="merge"
      matTooltip="Continue editing {{freeWall.meta?.title}}" [queryParams]="toQueryParams(mode)">
      <ng-container *ngIf="enabled$ | async; else loading">
        <ng-container *ngIf="freeWall.value?.image?.img | resourceLink : freeWall.recordId | async as imageLink; else loading">
          <img [src]="imageLink">
        </ng-container>
      </ng-container>
      <ng-template #loading>
        <img src="/assets/placeholder.png">
      </ng-template>
    </div>
    <mat-toolbar class="toolbar">
      <p class="title">{{(freeWall.meta)?.title}}</p>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <ng-template matMenuContent>
          <ng-container *ngIf="freeWall | sharedStatus | async as sharedStatus">
            <button mat-menu-item (click)="openDemo(sharedStatus)" *ngIf="sharedStatus.shared">
              <mat-icon>remove_red_eye</mat-icon>
              <span>Preview</span>
            </button>
          </ng-container>
          <button mat-menu-item (click)="cloneFreeWall()">
            <mat-icon>file_copy</mat-icon><span>Clone</span>
          </button>
          <button mat-menu-item (click)="deleteFreeWall()">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
          </ng-template>
          </mat-menu>
          </mat-toolbar>
          </div>

</ng-container>
