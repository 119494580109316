<div class="response-tile">
  <div>

    <div class="divider-horizontal">
    </div>
    <div>
      <p class="resource-title">
        <strong>
          <ng-container *ngIf="!showNumbers">
            {{response[field] | fileName}}
          </ng-container >
          <ng-container *ngIf="showNumbers">
            {{sequence}}.&nbsp;{{response[field] | fileName}}
          </ng-container>
        </strong>
      </p>

      <div class="resource-actions">
        <button mat-icon-button color="primary" matTooltip="{{editTooltip}}" (click)="edit()"><mat-icon>mode_edit</mat-icon></button>
        <button mat-icon-button color="primary" matTooltip="Remove {{resourceType}} from this response" (click)="remove()"><mat-icon>clear</mat-icon></button>
      </div>
    </div>


    <div class="resources">
      <div *ngIf="resourceType === 'video'">
        <video [src]="response[field] | resourceLink: freeWallId | async"></video>

        <p *ngIf="top">This video will be played as soon as the content is unlocked.</p>
        <p *ngIf="!top">This video will be displayed at the bottom of the page once the article is unlocked and will automatically start playing once it comes into view.</p>
      </div>
      <div *ngIf="resourceType === 'image'">
        <img [src]="response[field] | resourceLink: freeWallId | async">

        <p *ngIf="showNumbers && top">
          This image will be displayed as soon as the video has finished playing.
        </p>
        <p *ngIf="showNumbers && !top">
          This image will be displayed as soon as the video at the bottom of the page has completed.
        </p>
        <p *ngIf="!showNumbers && top">
          This image will be displayed as soon as the content is unlocked.
        </p>
        <p *ngIf="!showNumbers && !top">
          This image will be displayed at the bottom of the page as soon as the content is unlocked.
        </p>
      </div>
      <div *ngIf="resourceType === 'iframe'">
        <iframe [src]="response[field]"></iframe>
        <p *ngIf="showNumbers && top">
          This microsite will be displayed as soon as the video has finished playing.
        </p>
        <p *ngIf="!showNumbers && top">
          This microsite will be displayed as soon as the content is unlocked.
        </p>
      </div>
    </div>
    <div>
      <form>
        <div *ngIf="resourceType === 'video' && advanced">
          <mat-slide-toggle color="primary" [(ngModel)]="response.ato" >Auto-start video</mat-slide-toggle>
        </div>
        <div *ngIf="resourceType === 'image'">
          <mat-form-field class="response-link-input">
            <input type="text" matInput placeholder="Insert image link here" [(ngModel)]="response.lnk" [ngModelOptions]="{standalone: true}" maxlength="2048">
          </mat-form-field>
        </div>

      </form>

    </div>

  </div>
</div>

