import { Injectable } from "@angular/core";
import { JsonSchema } from "@jsonforms/core";
import { Microsite } from "@rezonence/core";
import { FreeWallEditorContext } from "../freewall-editor/FreeWallEditorContext";
import { FreewallEditorService } from "../freewall-editor/FreewallEditorService";
import { MicrositesLoaderService } from "../microsites/MicrositesLoaderService";
import { FreeWallConfig } from "@rezonence/sdk";

@Injectable()
export class MicrositesRendererSchemaService {
  constructor(
    private context: FreeWallEditorContext,
    private micrositeLoaderService: MicrositesLoaderService,
    private freewallEditorService: FreewallEditorService
  ) { }

  /**
   * Add the schema associated with the new microsite as an element in the microsites schema items array so that
   * the form knows how to validate user input associated with that specific microsite.
   * We modify the underlying schema (rather than the 'schema' member variable of this class) so that our changes
   * aren't overwritten by the one-way data binding mechanism used for propagating the microsites schema to the form
   */
  async addElementToMicrositesSchema(element: Microsite): Promise<void> {
    const schemasAndValues = await this.freewallEditorService.getValuesForRecordId(this.context.recordId);
    const original = schemasAndValues.microsites.schema;
    const fwConfig = schemasAndValues.freewall.data as FreeWallConfig;
    schemasAndValues.microsites.schema = await this.micrositeLoaderService.addMicrositesToSchema(fwConfig, original, [element]);
  }

  async removeFromSchema(schema: JsonSchema, index: number): Promise<void> {
    await this.micrositeLoaderService.removeMicrositeFromSchema(schema, index);
  }
}
