<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <ng-container *ngIf="edit; else demoUrlLabel">
    <rez-json-form [schema]="schema" [(data)]="demoUrlObject" (errors)="errors$.next($event)" fxFlex="fill">
    </rez-json-form>
    <ng-container *ngIf="errors$ | async as errors">
      <div>
        <button mat-flat-button (click)="demoUrlChange.next(demoUrlObject.demoUrl)"
          [disabled]="demoUrlObject.demoUrl === demoUrl || !!errors.length" color="primary"
          matTooltip="View this FreeWall on {{demoUrl}}">
          Go
        </button>
      </div>
      <div>
        <app-share-freewall-demo [recordId]="recordId" [url]="demoUrl"
          [disabled]="(demoUrlObject.demoUrl !== demoUrl) || !!errors.length"
          (share)="shareClick.emit($event)"></app-share-freewall-demo>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #demoUrlLabel>
    <div fxFlex="fill" (click)="edit = !edit" class="demo-url-label">
      {{demoUrl}}
    </div>
  </ng-template>
  <button mat-icon-button color="primary" (click)="edit=!edit">
    <mat-icon *ngIf="!edit">
      keyboard_arrow_down
    </mat-icon>
    <mat-icon *ngIf="edit">
      keyboard_arrow_up
    </mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    matTooltip="Refresh FreeWall"
    (click)="onReload.emit()"
  >
    <mat-icon>
      refresh
    </mat-icon>
  </button>
</div>
