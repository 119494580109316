import {Injectable} from "@angular/core";
import {Dataset, DatasetValue} from "@rezonence/core";
import {schemaByDataset} from "../schema/schema.by.dataset";
import {createDefaultValue} from "@jsonforms/core";

@Injectable({
  providedIn: "root"
})
export class DefaultValueService {

  constructor() {
  }

  createDefaultValueForDataset<T extends DatasetValue>(input: Dataset): T {
   return this.createDefaulSchemaValue<T>(input);
  }

  createDefaulSchemaValue<T extends DatasetValue>(dataset: Dataset): T {
    const schema = schemaByDataset[dataset];
    return createDefaultValue(schema) as T;
  }
}
