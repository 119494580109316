import { NgModule } from "@angular/core";
import { JsonFormsModule } from "@jsonforms/angular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatStepperModule } from "@angular/material/stepper";
import { JsonFormsAngularMaterialModule } from "@jsonforms/angular-material";
import { WizardStepComponent } from "./wizard-step/wizard-step.component";
import { SchemaWizardComponent } from "./wizard/schema-wizard.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { ColorPickerModule } from "ngx-color-picker";
import { ArrayRendererComponent } from "./array-renderer/array-renderer.component";
import { ColourRendererComponent } from "./colour-renderer/colour-renderer.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";
import { SingularPipe } from "./singular/singular.pipe";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { ComposePathPipe } from "./compose-path/compose-path.pipe";
import { ObjectRendererComponent } from "./object-renderer/object-renderer.component";
import { AddButtonHeaderComponent } from "./add-button-header/add-button-header.component";
import { ArrayElementHeaderComponent } from "./array-element-header/array-element-header.component";
import { ArrayTemplateComponent } from "./array-template/array-template.component";
import { JsonFormModule, JsonFormComponent, JsonFormService } from '@rezonence/ng-json-form'
import { renderers } from "./renderers";
import Ajv from "ajv";
import { createAjv } from "@rezonence/schema-validator";

@NgModule({
  declarations: [
    SchemaWizardComponent,
    WizardStepComponent,
    ColourRendererComponent,
    ArrayRendererComponent,
    SingularPipe,
    ComposePathPipe,
    ObjectRendererComponent,
    AddButtonHeaderComponent,
    ArrayElementHeaderComponent,
    ArrayTemplateComponent
  ],
  imports: [
    JsonFormModule.forRoot({ ajv: createAjv() }),
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSelectModule,
    ColorPickerModule,
    JsonFormsModule,
    MatStepperModule,
    FormsModule,
    JsonFormsAngularMaterialModule,
    BrowserModule,
    MatStepperModule,
    BrowserAnimationsModule
  ],
  exports: [
    ArrayTemplateComponent,
    JsonFormComponent,
    WizardStepComponent,
    SchemaWizardComponent,
    ComposePathPipe,
    SingularPipe,
    ArrayElementHeaderComponent,
    AddButtonHeaderComponent
  ]
})
export class SchemaWizardModule {
  constructor(ajv: Ajv, jsonFormService: JsonFormService) {
    jsonFormService.registerRenderer(...renderers);
    ajv.addFormat("color", colour => typeof colour === "string");
  }
}
