
<div *ngIf="!deleting">
  <p>
    Are you sure you want to delete {{freeWallTitle}}?
  </p>
  <button mat-button color="warn" (click)="doDelete()">
    Yes
  </button>
  <button mat-button color="primary" (click)="cancel()">
    No
  </button>
</div>
<div *ngIf="deleting">
  <p>
    Deleting {{freeWallTitle}}
  </p>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
