import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import Ajv from "ajv";
import { SchemaWizardModule } from "@rezonence/ng-schema-wizard";
import { QuickstartService } from "../create/QuickstartService";
import {CreateModule} from "../create/CreateModule";
import { CreateFreewallType } from "../create/CreateFreewallType";
import { ImportWizardService } from "./import-wizard/ImportWizardService";
import { ConfigIdStateService } from "./config-id/ConfigIdStateService";
import { ConfigState } from "./config-id/config.state";
import { DestinationPrefix } from "@rezonence/sdk";
import { PrefixStatePair } from "./config-id/prefix.state.pair";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CreateModule,
    SchemaWizardModule
  ]
})
export class ImporterModule {

  constructor(
    private ajv: Ajv,
    private stateService: ConfigIdStateService,
    createFreeWallService: QuickstartService,
    importWizardService: ImportWizardService
  ) {
    createFreeWallService.registerCreator(CreateFreewallType.Existing,
      () => importWizardService.importFreeWall());

    Object.keys(stateService.prefixStatePairBySchemaFormat).forEach(format => {
      this.ajv.addFormat(format, {
        async: true,
        validate: async rawConfigId => {
          const configId = decodeURIComponent(rawConfigId);
          const prefixStatePair = this.stateService.prefixStatePairBySchemaFormat[format];
          const state = await this.stateService.getState(prefixStatePair.prefix, configId);
          if (prefixStatePair.state !== state) {
            throw this.toStateMisMatchError(prefixStatePair, state);
          }
          return true;
        }
      });
    });
  }

  private toStateMisMatchError(prefixStatePair: PrefixStatePair, state: ConfigState): Error {
    const suffix = state === ConfigState.New ? "doesn't exist" : "exists";
    const prefix = prefixStatePair.prefix === DestinationPrefix.Pub ? "Config" : "Ad ID";
    const errorMessage = [prefix, suffix].join(" ");
    return new Error(errorMessage);
  }
}
