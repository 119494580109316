import { Injectable } from "@angular/core";
import { Dataset } from "@rezonence/core";

@Injectable({
  providedIn: "root"
})
export class FreeWallEditorContext {
  recordId: string;
  dataset: Dataset;
}
