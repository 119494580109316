import {Injectable} from "@angular/core";
import {ScannedFile} from "@djabry/fs-s3";
import {ResourceType} from "../resource.type";
import {Optional} from "@rezonence/sdk";
import {FreeWallResponse} from "@rezonence/sdk";
import {responseField} from "../../creator/response/response.field";

@Injectable({
  providedIn: "root"
})
export class ResourceTypeService {

  toResourceType(input: ScannedFile): Optional<ResourceType> {
    return Optional.of(Object.values(ResourceType)
      .find(resourceType => !!input.mimeType && input.mimeType.startsWith(resourceType)));
  }

  getResourceTypeFromField(field: keyof FreeWallResponse): Optional<ResourceType> {
    return Optional.of(Object.keys(responseField).find(key => responseField[key] === field) as ResourceType);
  }
}
