import "hammerjs";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./AppComponent";
import { OrganiserModule } from "./organiser";
import { RouterModule } from "@angular/router";
import { CreatorModule } from "./creator";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DataEditorModule } from "./data-editor";
import { MigratorModule } from "./migrator";
import { ToolbarComponent } from "./toolbar/ToolbarComponent";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ArticleModule } from "./article";
import { AnalyticsModule } from "./analytics";


@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CreatorModule,
    DataEditorModule,
    RouterModule.forRoot([], { relativeLinkResolution: "legacy" }),
    OrganiserModule,
    BrowserModule,
    HttpClientModule,
    MigratorModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    FlexLayoutModule,
    MatTooltipModule,
    ArticleModule,
    AnalyticsModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
