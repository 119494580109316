import { Component, Input } from "@angular/core";
import { Dataset, FreeWall, Microsites } from "@rezonence/core";
import { combineLatest, concatMap, distinctUntilChanged, map, Observable, ReplaySubject, Subject } from "rxjs";
import { CreativeFreeWallDB } from "../../core/CreativeFreeWallDB";
import { previewFolder } from "../../preview/preview.folder";
import { CreatePreviewRequest } from "../../preview/preview/create.preview.request";
import { ShareRequest } from "../../share/share.request";
import { ShareService } from "../../share/ShareService";
import { CloudFreewallCompiler } from "../../core/CloudFreewallCompiler";

@Component({
  selector: "app-share",
  templateUrl: "./share.component.html",
  styleUrls: ["./share.component.css"]
})
export class ShareComponent {

  shareLink$: Observable<string>;

  protected recordId$: Subject<string> = new ReplaySubject(1);
  protected configHash$: Subject<string> = new ReplaySubject(1);
  protected sourceLink$: Subject<string> = new ReplaySubject(1);

  constructor(private shareService: ShareService, private freeWallDB: CreativeFreeWallDB, private compiler: CloudFreewallCompiler) {
    this.shareLink$ = combineLatest([this.recordId$, this.sourceLink$, this.configHash$.pipe(distinctUntilChanged())])
      .pipe(
        map(([recordId, sourceLink]) => ({ recordId, sourceLink })),
        concatMap(request => this.compileAndShare(request)),
      );
  }

  @Input()
  set recordId(recordId: string) {
    this.recordId$.next(recordId);
  }

  @Input()
  set sourceLink(sourceLink: string) {
    this.sourceLink$.next(sourceLink);
  }

  @Input()
  set configHash(hash: string) {
    this.configHash$.next(hash);
  }

  protected async compileAndShare(request: ShareRequest): Promise<string> {
    const { recordId } = request;
    const storedConfig = await this.freeWallDB.getDatasetValue<FreeWall>(Dataset.Freewall, recordId);
    const microsites = (await this.freeWallDB.getDatasetValue<Microsites>(Dataset.Microsites, recordId)) || [];
    const previewRequest: CreatePreviewRequest = {
      recordId: request.recordId,
      config: storedConfig,
      demoPage: request.sourceLink,
      recordFolder: previewFolder,
      microsites: microsites,
      autoShare: true
    };
    await this.compiler.invoke(previewRequest);
    return this.shareService.share(request);
  }


}
