import { Dataset, DatasetItem, SaveItemRequest } from "@rezonence/core";
import { LoadSaveHook } from "@rezonence/freewall-db-hooks";

/**
 * Adds an empty config object to microsites that don't have a config specified
 */
export class MicrositeConfigInserter implements LoadSaveHook {

  async transform<T extends DatasetItem<any> | SaveItemRequest<any>>(item: T): Promise<T> {
    if (item && item.datasetName === Dataset.Microsites) {
      for (const microsite of item.value || []) {
        microsite.config = microsite.config || {};
      }
    }
    return item;
  }
}

