import {freewallConfigSchema} from "@rezonence/core";
import {UiSchemaService} from "../../core/ui-schema.service";
import { RuleEffect, SchemaBasedCondition, UISchemaElement, VerticalLayout } from "@jsonforms/core";
import {FreeWallResponse} from "@rezonence/sdk";

const uiSchemaService = new UiSchemaService();
const micrositeFields: (keyof FreeWallResponse)[] = ["rld", "pre", "hpx", "rat", "dyn"];

function toKeyField(field: keyof FreeWallResponse): string {
  return micrositeFields.includes(field) ? "ifs" : field === "ato" ? "vid" : undefined;
}

export const responseUiSchema: UISchemaElement = {
  type: "VerticalLayout",
  elements: Object.keys(freewallConfigSchema.definitions.FreeWallResponse.properties)
    .map((field: keyof FreeWallResponse) => {
      const keyField = toKeyField(field);
      let control = keyField
        ? uiSchemaService.toHiddenControlElement({field}, keyField)
        : uiSchemaService.toControlElement({field});
      if (field === "lnk") {
        const dependentFields: (keyof FreeWallResponse)[] = ["vid", "img"];
        const condition: SchemaBasedCondition = {
          scope: "",
          schema: {
            anyOf: dependentFields.map(f => ({
              required: [f]
            }))
          }
        }
        control.rule = {
          effect: RuleEffect.SHOW,
          condition
        }
      }
      return control;
    })
} as VerticalLayout;
