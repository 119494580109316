import { Pipe, PipeTransform } from "@angular/core";
import {ArrayUtils} from "@rezonence/array-utils";

@Pipe({
  name: "flatten"
})
export class FlattenPipe implements PipeTransform {

  transform<T>(input: Record<string | number, T[]> | T[][]): T[] {
    return ArrayUtils.flatten(input);
  }

}
