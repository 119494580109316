import {FileService} from "@djabry/fs-s3/lib/file-service";
import {Injectable} from "@angular/core";
import {AWSService} from "./AWSService";
import {Dataset} from "@rezonence/core/freewall/dataset";
import {environment} from "../../environments/environment";
import S3 from "aws-sdk/clients/s3";
import {AnyFile} from "@djabry/fs-s3/lib";
import {CopyAssetsRequest} from "./copy.assets.request";
import {Region} from "@rezonence/aws";
import {withFreeze} from "timekeeper";
import {Duration} from "@rezonence/duration";

@Injectable({
  providedIn: "root"
})
export class CreativeFileService extends FileService {
  constructor(private awsService: AWSService) {
    super(awsService.getService(S3));
  }

  async deleteFreeWallData(recordId: string): Promise<void> {
    const identityId = await this.awsService.identityId();
    const prefix = this.toFreeWallAssetPrefix({identityId, recordId});
    await this.deleteAll({bucket: environment.userStorageBucket, key: prefix}, true);
  }

  toPublicReadUrl(file: AnyFile, region: Region): string {
    return `https://${file.bucket}.s3-${region}.amazonaws.com/${file.key}`;
  }

  getTruncatedTime(timeWindow: number): Date {
    const currentDate = new Date();
    const validityPeriod = timeWindow * 0.5;
    const startTime = Math.round(Math.floor(currentDate.getTime() / validityPeriod) * validityPeriod);
    return new Date(startTime);
  }

  async getReadURL(file: AnyFile, expires = 900): Promise<string> {
    const s3 = await this.s3Promise;
    // This approach is adapted from https://advancedweb.hu/2019/06/04/cacheable_signed_urls/
    const truncatedTime = this.getTruncatedTime(expires * Duration.MsInSecond);
    return withFreeze(truncatedTime, () =>
      s3.getSignedUrl("getObject", {Bucket: file.bucket, Key: file.key, Expires: expires})
    );
  }

  toFreeWallAssetPrefix(request: {identityId: string; recordId: string}): string {
    return `${request.identityId}/${Dataset.Freewall}/${request.recordId}`;
  }

  async cloneFreeWallAssets(request: CopyAssetsRequest): Promise<void> {
    const identityId = await this.awsService.identityId();
    const bucket = {bucket: environment.userStorageBucket};
    const source = {...bucket, key: this.toFreeWallAssetPrefix({
        identityId,
        recordId: request.sourceRecordId
    })};
    const destination = {...bucket, key: this.toFreeWallAssetPrefix({
        identityId,
        recordId: request.destinationRecordId
    })};
    await this.copy(source, destination, {overwrite: true, skipSame: true});
  }
}
