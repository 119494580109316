import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Dataset } from "@rezonence/core";
import { RouteParam } from "../route.param";
import { Route } from "@rezonence/freewall-creator-config"

@Component({
  templateUrl: './FreeWallEditorPage.html'
})
export class FreeWallEditorPage {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly RouteParam = RouteParam;

  constructor(private router: Router, public readonly route: ActivatedRoute) { }

  onDatasetChange(recordId: string, dataset: Dataset) {
    this.router.navigate([Route.Advanced, recordId, dataset]);
  }
}
