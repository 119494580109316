<ng-container *ngIf="config">
  <mat-vertical-stepper linear>
    <ng-container *ngFor="let s of config.steps; trackBy: trackElement; let i = index;">
      <mat-step [completed]="stepStatus[i] | async" [label]="toLabel(s)">
        <rez-wizard-step [config]="config"
                         [stepIndex]="i"
                         (errors)="errorCollector[i].next($event)">
        </rez-wizard-step>
      </mat-step>
    </ng-container>
    <mat-step label="Done">
      <button mat-flat-button color="primary" (click)="done.emit(config.data)">
        OK
      </button>
    </mat-step>
  </mat-vertical-stepper>
</ng-container>

