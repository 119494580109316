
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FreeWallResponse } from "@rezonence/core/config-extractor";
import { ResourceType } from "../../resource/resource.type";
import { FreeWall } from "@rezonence/core/freewall/freewall";
import { responseField } from "./response.field";
import { Placement } from "@rezonence/freewall-creator-config";
import { ResourceSelectorService } from "../../resource/resource-selector/ResourceSelectorService";
import { ResourceTypeService } from "../../resource/resource/resource-type.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-response-tile",
  templateUrl: "response.tile.component.html",
  styleUrls: ["response.tile.component.css"]
})
export class ResponseTileComponent implements OnInit {

  @Input()
  resourceType: ResourceType;

  @Input()
  config: FreeWall;

  @Input()
  advanced: boolean;

  @Input()
  responseIndex: number;

  @Input()
  mode: Placement;

  @Input()
  freeWallId: string;

  constructor(private resourceTypeService: ResourceTypeService,
    private resourceSelectorService: ResourceSelectorService) {

  }

  get response(): FreeWallResponse {
    return this.config[`${this.mode}s`][this.responseIndex];
  }

  get top(): boolean {
    return this.mode === Placement.response || this.mode === Placement.banner;
  }

  get field(): keyof FreeWallResponse {
    return responseField[this.resourceType];
  }

  remove() {
    delete this.response[this.field];
  }

  get showNumbers(): boolean {

    return this.response.vid && !!(this.response.img || this.response.ifs);
  }

  get sequence(): string {
    if (this.showNumbers) {
      if (this.resourceType === ResourceType.video) {
        return "1";
      } else {
        return "2";
      }
    }

    return "";
  }

  async edit() {
    const resourceTypes = this.showNumbers || this.resourceType === ResourceType.video ?
      [this.resourceType] :
      [ResourceType.image, ResourceType.video];

    const selectedResource = await this.resourceSelectorService.selectResource({
      freeWallId: this.freeWallId,
      resourceTypes,
      mode: resourceTypes[0]
    });

    if (selectedResource.exists && selectedResource.item.resourceType.exists) {
      const resource = selectedResource.item;
      const field = responseField[resource.resourceType.item];
      this.response[field as string] = resource.resourcePath;
    }
  }

  get editTooltip(): string {

    if (this.showNumbers || this.resourceType === ResourceType.video) {

      return `Select another ${this.resourceType}`;
    } else {

      return "Select other media";

    }
  }

  ngOnInit(): void {

  }
}
