import {Injectable} from "@angular/core";
import {DatasetDictionary} from "../dataset.dictionary";
import {DataSchemaPair} from "../freewall-editor/data.schema.pair";
import {CodeExtensionService} from "../../core/CodeExtensionService";
import { CodeDataset } from "@rezonence/core";
import isEmpty from "lodash/isEmpty";
import { PostLoadHook } from "../freewall-editor";

@Injectable({
  providedIn: "root"
})
export class CustomCodeLoaderService implements PostLoadHook {

  constructor(private extensionFileService: CodeExtensionService) {
  }

  async transform(values: Partial<DatasetDictionary<DataSchemaPair>>, recordId: string):
    Promise<Partial<DatasetDictionary<DataSchemaPair>>> {
    for (const dataset of Object.values(CodeDataset)) {
      values[dataset].data = isEmpty(values[dataset].data) ? {
          code: await this.extensionFileService.readExtensionFile({recordId, dataset})
        } : values[dataset].data;
    }
    return values;
  }

}
