import { Injectable } from "@angular/core";
import { Microsite } from "@rezonence/core";
import { Optional } from "@rezonence/sdk";
import { CreateMicrositeService } from "../../microsite/create-microsite/CreateMicrositeService";
import { MicrositesRendererSchemaService } from "./MicrositesRendererSchemaService";

@Injectable()
export class MicrositesRendererService {
  constructor(
    public schemaService: MicrositesRendererSchemaService,
    private createMicrositeService: CreateMicrositeService
  ) { }

  async createNewMicrosite(micrositeId: string): Promise<Optional<Microsite>> {
    const optionalMicrosite = await this.createMicrositeService.createMicrosite(micrositeId);
    if (optionalMicrosite.exists) {
      await this.schemaService.addElementToMicrositesSchema(optionalMicrosite.item);
    }
    return optionalMicrosite;
  }
}
