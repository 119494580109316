import {WizardModeKey} from "../wizard/wizard.mode.key";
import { SimpleOpinionWizardMode } from "../opinion/SimpleOpinionWizardMode"
import {FreeWallConfig} from "@rezonence/core";
import {Category} from "../wizard/category";
import {ArrayUtils} from "@rezonence/array-utils";

export class QuizWizardMode extends SimpleOpinionWizardMode {
  readonly description: string = "Simple quiz";
  enableAnswerResponseMapping: boolean = false;
  readonly key = WizardModeKey.SimpleQuiz;

  async createConfig(): Promise<FreeWallConfig> {
    const quizConfig = await super.createConfig();
    const firstQuestion = ArrayUtils.first(quizConfig.questions);
    firstQuestion.hnt = "Please try again";
    firstQuestion.answers.forEach(
      (answer, index) => index === 0 ? answer.tof = true : answer.tof = false);
    return quizConfig;
  }

  shouldDisplay(config, category: Category, indexWithinCategory?: number): boolean {
    return category === Category.Answers || super.shouldDisplay(config, category, indexWithinCategory);
  }

  isCompatible(config: FreeWallConfig): boolean {
    return this.isImageBanner(config) && config.questions.length === 1 && config.questions[0].answers.some(answer => !answer.tof);
  }
}
