import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Microsite} from "@rezonence/core";
import {FreeWall} from "@rezonence/core";

@Component({
  selector: "app-microsites",
  templateUrl: "./microsites.component.html",
  styleUrls: ["./microsites.component.css"]
})
export class MicrositesComponent {

  @Input()
  set microsites(microsites: Microsite[]) {
    if (JSON.stringify(microsites) !== JSON.stringify(this._microsites)) {
      this._microsites =  microsites;
    }
  }

  get microsites(): Microsite[] {
    return this._microsites;
  }

  @Input()
  recordId: string;

  @Input()
  freeWallConfig: FreeWall;

  @Output()
  micrositeClicked: EventEmitter<Microsite> = new EventEmitter<Microsite>();

  private _microsites: Microsite[];

  trackElement(index: number, _element: any): number {
    return index;
  }

}
