import {Pipe, PipeTransform} from "@angular/core";
import {ScannedFile} from "@djabry/fs-s3";
import {basename} from "path";

@Pipe({name: "filterSelected"})
export class FilterSelectedPipe implements PipeTransform {

  transform<T extends ScannedFile>(resources: T[], currentResourcePath: string): T[] {
    return (resources || []).filter(resource => basename(resource.key) !== basename(currentResourcePath));
  }

}
