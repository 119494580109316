import { compileBucket } from '@rezonence/core/aws'
import { CDN } from "@rezonence/core";
import { companyDomains } from "@rezonence/freewall-creator-config";
import { defaultDemoLink } from "./defaultDemoLink";
import { oldDemoPages } from "./oldDemoPages"
import { Region } from "@rezonence/aws";
import { userStorageBucket } from "@rezonence/freewall-compiler";

export const environment = {
  production: true,
  region: Region.EuIreland,
  defaultCDN: CDN.S3,
  userStorageBucket,
  compileBucket,
  defaultDemoLink,
  oldDemoPages,
  companyDomains,
  gtmId: "GTM-5Z4CN2K"
}
