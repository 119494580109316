import { type LocalFile } from '@jabrythehutt/fs-s3-core'
import { type S3File } from '@jabrythehutt/fs-s3'
import { templateBucket } from '../aws'
import { templateFolder } from "@rezonence/freewall-templates";
import { truthyArrayItem } from '@rezonence/array-utils'
import { CompileFolder } from './CompileFolder'
import { CDN } from '../config-extractor'

export class TemplateFolderResolver<T extends LocalFile = S3File> {
  constructor(private root: T = { key: '', bucket: templateBucket } as LocalFile as T) {
  }

  static join<T extends LocalFile = S3File>(dir: T, ...subFolders: string[]): T {
    return {
      ...dir,
      key: [dir.key, ...subFolders].filter(truthyArrayItem).join('/')
    }
  }

  toBaseFolder(version: string): T {
    return TemplateFolderResolver.join<T>(this.root, version)
  }

  resolve(request: { compileFolder: CompileFolder, version: string }): T {
    const baseFolder = this.toBaseFolder(request.version)
    return TemplateFolderResolver.join<T>(baseFolder, request.compileFolder)
  }

  static toPublicPathName<T extends LocalFile = S3File>(folder: T): string {
    return `${templateFolder}/${folder.key}`
  }

  toUrl(request: { compileFolder: CompileFolder, version: string }, cdn = CDN.S3): URL {
    const folder = this.resolve(request)
    const url = new URL(cdn)
    url.pathname = TemplateFolderResolver.toPublicPathName(folder)
    return url
  }
}
