import { Region } from "@rezonence/aws";
import { compileBucket } from "./compile.bucket";
import { templateBucket } from "./template.bucket";
import { cdnBucket } from "./cdn.bucket";
import { cdnDistributionIds } from "./cdn.distribution.ids";
import { transcoderInputBucket } from "./transcoder.input.bucket";
import { transcoderOutputBucket } from "./transcoder.output.bucket";

export const environment = {
  REGION: Region.EuIreland,
  COMPILE_BUCKET: compileBucket,
  TEMPLATE_BUCKET: templateBucket,
  IDENTITY_POOL_ID: "eu-west-1:b248bc3d-fafb-44b0-9e61-e4eaa56bb51b",
  TRANSCODER_PRESET_ID: "1464259325373-jmpeqw",
  TRANSCODER_PIPELINE_ID: "1462829770557-tqbzad",
  TRANSCODER_INPUT_BUCKET: transcoderInputBucket,
  TRANSCODER_OUTPUT_BUCKET: transcoderOutputBucket,
  LOGS_OUTPUT_BUCKET: "r3z-logs-output",
  LOGS_INPUT_BUCKET: "r3z-logs-input",
  EVENT_COUNT_DEAD_LETTER_BUCKET: "failed-count-updates",
  CDN_BUCKET: cdnBucket,
  CDN_DISTRIBUTION_IDS: cdnDistributionIds,
  USER_STORAGE_BUCKET: "r3z-user-storage"
};
