import { Component } from "@angular/core";
import {JsonFormsAngularService, JsonFormsControl} from "@jsonforms/angular";
import {ResourceSelectorService} from "../../resource/resource-selector/ResourceSelectorService";
import {ResourceType} from "../../resource/resource.type";
import { Optional } from "@rezonence/sdk";

@Component({
  selector: "app-resource-renderer",
  templateUrl: "./resource-renderer.component.html",
  styleUrls: ["./resource-renderer.component.css"]
})
export class ResourceRendererComponent extends JsonFormsControl {

  ResourceType = ResourceType;

  constructor(jsonFormsService: JsonFormsAngularService,
              private resourceSelector: ResourceSelectorService) {
    super(jsonFormsService);
  }

  get resourceType(): Optional<ResourceType> {
    return Optional.of(this.scopedSchema.format as ResourceType);
  }

  async selectMediaForFreeWallId(freeWallId: string) {
    const optionalResource = await this.resourceSelector.selectResource({
      resourceTypes: [this.resourceType.item],
      selectedResourcePath: this.data,
      enableSwitchMode: false,
      mode: this.resourceType.item,
      freeWallId
    });

    if (optionalResource.exists) {
      this.onChange({
        value: optionalResource.item.resourcePath
      });
    }
  }

  get selectMessagePrefix(): string {
    const prefix = "Select";
    return this.data ? `${prefix} different` : prefix;
  }

  clear() {
    this.onChange({
      value: undefined
    });
  }

}
