import { Component, OnInit } from "@angular/core";
import {CreativeFreeWallImporter} from "../../importer/CreativeFreeWallImporter";
import {Router} from "@angular/router";
import { Route } from "@rezonence/freewall-creator-config";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-importer",
  templateUrl: "./importer.component.html",
  styleUrls: ["./importer.component.css"]
})
export class ImporterComponent implements OnInit {

  public adId: string;
  public title: string;

  constructor(public ref: MatDialogRef<ImporterComponent>,
              private importer: CreativeFreeWallImporter,
              private router: Router) {

  }

  async importFreeWall() {

    await this.importer.importFreeWall({
      adId: this.adId,
      meta: {
        title: this.title,
        labels: []
      }
    });
    this.ref.close();
    this.router.navigate([Route.Home], { queryParamsHandling: "merge" });

  }

  ngOnInit() {

    this.importFreeWall();

  }

}
