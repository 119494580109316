import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileNamePipe } from "./file.name.pipe";
import { HttpClientModule } from "@angular/common/http";
import { SafePipe } from "./safe.pipe";
import { LoadingMessageComponent } from "./loading-message/loading-message.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { AnswerTrackersMigrator } from "./AnswerTrackersMigrator";
import { MicrositeConfigInserter } from "./MicrositeConfigInserter";
import { FlattenPipe } from "./flatten/flatten.pipe";
import { ZeroRemover } from "./ZeroRemover";
import { loadHookToken, saveHookToken } from "@rezonence/freewall-db-hooks";
import { RemoveEmptyStringsHook } from "./RemoveEmptyStringsHook";
import { RemoveNullsHook } from "./RemoveNullsHook";
import { ArrayUtils } from "@rezonence/array-utils";
import { CreateIdHook } from "./CreateIdHook";

const providers = ArrayUtils.flatten([
  RemoveNullsHook,
  RemoveEmptyStringsHook,
  ZeroRemover,
  MicrositeConfigInserter,
  AnswerTrackersMigrator
].map(useClass => ([
  { provide: loadHookToken, useClass },
  { provide: saveHookToken, useClass }
]))).map(c => ({
  ...c,
  multi: true
}));

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatProgressBarModule,
  ],
  declarations: [
    FileNamePipe,
    SafePipe,
    LoadingMessageComponent,
    SafeHtmlPipe,
    FlattenPipe
  ],
  exports: [
    SafePipe,
    FlattenPipe,
    FileNamePipe,
    LoadingMessageComponent,
    SafeHtmlPipe
  ],
  providers: [
    ...providers,
    {
      multi: true,
      provide: saveHookToken,
      useClass: CreateIdHook
    }
  ]
})
export class CoreModule {

}
