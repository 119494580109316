import {
  Component, ElementRef, Input, OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter
} from "@angular/core";
import { FreeWall } from "@rezonence/core/freewall/freewall";
import { CSS } from "@rezonence/core/freewall/css";
import { Microsites } from "@rezonence/core/freewall/microsites";
import { ResponseMode } from "../response/response.mode";
import { ResourceService } from "../../resource/resource/ResourceService";
import { CustomCode } from "@rezonence/core";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-creator-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.css"]
})
export class PreviewComponent implements OnInit {

  styleElementId: string;

  @Input()
  demoPage: string;

  @Input()
  css: CSS;

  @Input()
  customCode: CustomCode;

  @Input()
  config: FreeWall;

  @Input()
  freewallId: string;

  @Input()
  topInstructions: string;

  @Input()
  bottomInstructions: string;

  @Input()
  microsites: Microsites;

  @Input()
  mode: string;

  @Input()
  responseIndex: number;

  @Input()
  hint: boolean;

  private _focus: string;

  constructor(
    private elementRef: ElementRef,
    private resourceService: ResourceService) {

  }

  blurAll(parentElement: HTMLElement, apply: boolean) {

    if (parentElement.children && parentElement.children.length) {

      for (let ix = 0; ix < parentElement.children.length; ix++) {
        const child = parentElement.children.item(ix) as HTMLElement;
        this.blurAll(child, apply);
      }

    } else {

      if (parentElement.classList) {
        if (apply) {

          parentElement.classList.add("blur");
        } else {
          parentElement.classList.remove("blur");

        }

      }

    }
  }

  @Input()
  set focus(f: string) {
    this._focus = f;
    const previewElement = this.elementRef.nativeElement as HTMLElement;

    const addoor = previewElement;
    if (f) {

      // Apply blur to all leaf elements
      if (addoor) {
        this.blurAll(addoor, true);
      }

    } else {

      if (addoor) {
        // Remove all blur classes
        this.blurAll(addoor, false);
      }

    }

  }

  get focus(): string {
    return this._focus;
  }


  get responseLetter(): string {
    return this.mode === ResponseMode.response ? "r" : "k";
  }

  get modes(): string {
    return `${this.mode}s`;
  }

  displayResponseIframe(): boolean {
    return this.config && this.config[this.modes][this.responseIndex] &&
      this.config[this.modes][this.responseIndex].ifs && !this.displayResponseVideo();
  }

  displayResponseVideo(): boolean {
    return this.config && this.config[this.modes][this.responseIndex] &&
      this.config[this.modes][this.responseIndex].vid;
  }

  displayResponseBar(): boolean {
    return this.config && this.config[this.modes][this.responseIndex] &&
      this.config[`${this.mode}s`][this.responseIndex].bar;
  }

  displayResponseImage(): boolean {
    return this.config && this.config[this.modes][this.responseIndex] &&
      this.config[this.modes][this.responseIndex].img && !this.displayResponseVideo() &&
      !this.displayResponseIframe();
  }

  ngAfterViewChecked(): void {
  }

  processLink(link: string): Promise<string> {

    return this.resourceService.renderLinkMacros({
      freewallId: this.freewallId,
      link
    });

  }

  ngOnInit() {
    this.styleElementId = "freewall-style";
  }

}
