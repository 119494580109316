import { Meta, Dataset } from "@rezonence/core";
import { environment } from "../../environments/environment";
import { DatasetHook } from "../core";

export class DefaultDemoStripper extends DatasetHook<Meta> {

    constructor() {
        super(Dataset.Meta);
    }

    async transformValue(value: Meta): Promise<Meta> {
        // Don't persist default demo links so that they can be modified automatically at a later date if the default demo page is changed
        if (this.isDefaultDemo(value.demoSourceUrl)) {
            delete value.demoSourceUrl;
        }
        return value;
    }

    isDefaultDemo(demoSourceUrl: string): boolean {
        return [...environment.oldDemoPages, environment.defaultDemoLink].includes(demoSourceUrl);
    }

}