import { LinkMacro, configPathQueryParam, prefixMacro } from "@rezonence/sdk";
import { TemplateFolderResolver } from "../freewall-templates";
import { micrositeDestinationFolder } from "./microsite.destination.folder";
import { MicrositeEntryScript } from "./microsite.entry.script";
import { micrositeTemplateFolderName } from "./microsite.template.folder.name";
import { micrositeConfigFileName } from "./microsite.config.file.name";
import { MicrositeLinkRequest } from "./MicrositeLinkRequest";
import { ExistingMicrosite } from "./ExistingMicrosite";
import { MicrositeType } from "./microsite.type";

export class MicrositeLinkResolver {

  static baseTemplateLink(): string {
    return `${LinkMacro.Cdn}${TemplateFolderResolver.toPublicPathName({
      key: `${LinkMacro.Version}/${micrositeDestinationFolder}`
    })}`
  }

  static toConfigLink(request: MicrositeLinkRequest): string {
    return `${prefixMacro}/${MicrositeLinkResolver.toConfigLinkSuffix(request)}`;
  }

  static toConfigLinkSuffix(request: MicrositeLinkRequest): string {
    return `${micrositeDestinationFolder}/${request.templateId}/${request.id}/${micrositeConfigFileName}`;
  }

  static toTemplateLink(request: MicrositeLinkRequest): string {
    return `${MicrositeLinkResolver.baseTemplateLink()}/${request.templateId}/${micrositeTemplateFolderName}/${MicrositeEntryScript.index}`
  }

  static toMicrositeLink(request: MicrositeLinkRequest): string {
    const configLink = MicrositeLinkResolver.toConfigLink(request);
    return `${MicrositeLinkResolver.toTemplateLink(request)}?${configPathQueryParam}=${configLink}`;
  }

  static resolveMicrositeLink(request: MicrositeLinkRequest | ExistingMicrosite): string {
    return request.templateId === MicrositeType.existing ? (request as ExistingMicrosite).url : MicrositeLinkResolver.toMicrositeLink(request);
  }
}
