import {Injectable} from "@angular/core";
import { WizardDialogService, WizardConfig } from "@rezonence/ng-schema-wizard";
import { FreeWallConfig, Optional } from "@rezonence/sdk";
import { Microsite, MicrositeLinkResolver } from "@rezonence/core";
import { micrositeSchema } from "@rezonence/core";
import { CreativeMicrositeSchemaResolver } from "../CreativeMicrositeSchemaResolver";
import { FreeWallEditorContext } from "../../data-editor";
import { FreewallEditorService } from "../../data-editor/freewall-editor/FreewallEditorService";
import { tag } from "@rezonence/git";

@Injectable({
  providedIn: "root"
})
export class CreateMicrositeService {

  constructor(private wizardDialogService: WizardDialogService,
    private context: FreeWallEditorContext,
    private freewallEditorService: FreewallEditorService,
    private micrositeSchemaResolver: CreativeMicrositeSchemaResolver) {
  }

  async createMicrosite(micrositeId: string): Promise<Optional<Microsite>> {
    const wizardConfig = this.createWizardConfig(micrositeId);
    const optionalData = await this.wizardDialogService.requestData(wizardConfig);
    const schemaAndValues = await this.freewallEditorService.getValuesForRecordId(this.context.recordId);
    const fwConfig = schemaAndValues.freewall.data as FreeWallConfig;
    return Optional.switchPromise(optionalData.map(async microsite => ({
      ...microsite,
      url: MicrositeLinkResolver.toMicrositeLink(microsite),
      config: (await this.micrositeSchemaResolver.create({ templateId: microsite.templateId, version: fwConfig.version || tag })).item
    })));
  }

  private createWizardConfig(id: string): WizardConfig<Microsite> {
    const microsite: Microsite = {
      config: {},
      templateId: undefined,
      title: "",
      id
    };

    const fields: { label: string; field: keyof Microsite }[] = [
      {label: "Microsite type", field: "templateId"},
      {label: "Title", field: "title"}
    ];
    return {
      data: microsite,
      schema: micrositeSchema,
      steps: fields.map(f => ({
        uiSchema: {
          label: f.label,
          type: "Control",
          scope: `#/properties/${f.field}`
        }
      }))
    };
  }

}
