import { previewSites } from "./previewSites";
import { appConfigId, CDN, previewConfigId, TemplateFolderResolver, CompileFolder, PublisherConfig, TemplateFileSuffix } from "@rezonence/core";
import { RezonenceGlobal } from "./RezonenceGlobal";
import { adType as defaultAdType } from "./adType";
import { DestinationPrefix, ConfigResolver, filenamePrefixByDestinationPrefix, FreeWallConfig } from "@rezonence/sdk";
import { Logger } from "@rezonence/freewall-logger";
import { toCodeLocation } from "./toCodeLocation";
import { ScriptLoader } from "@rezonence/freewall-tracking";
import { Stringified } from "type-fest";
import jquery from "jquery";
import { ConfigOf } from "./ConfigOf";
import { Attributes } from "./Attributes";

export class FreewallLoader {
  private freewallLaunched: boolean;

  constructor(public readonly cdn: CDN,
    public readonly _r3z: RezonenceGlobal,
    private scriptLoader: ScriptLoader,
    private logger: Logger,
    private configResolver: ConfigResolver,
    private context: Window,
    private templateResolver: TemplateFolderResolver,
    private adType = defaultAdType,
    private appPublisherConfigId = appConfigId,
    public previewHosts = previewSites) {
    this.freewallLaunched = false;
  }

  rezonenceJQueryLoaded(): boolean {
    return !!this._r3z.jq;
  }

  adCodeLoaded(): boolean {
    return !!this._r3z.onLoad;
  }

  publisherConfigLoaded(): boolean {
    const _r3z = this._r3z;
    return !!_r3z.pub && !!_r3z.pub.fns && !!_r3z.pub.fns.ins;
  }

  launchFreeWall(): void {
    try {
      // check if we have all the prerequisites to run
      if (this.adCodeLoaded() &&
        this.rezonenceJQueryLoaded() &&
        this.publisherConfigLoaded() &&
        !this.freewallLaunched) {
        // only run the onload function if the allLoaded variable
        // is set to false, this prevents us running the function
        // multiple times

        this._r3z.onLoad();
        this.freewallLaunched = true;
      }

    } catch (err) {
      this.logger.error("Failed to launch FreeWall");
      this.logger.error(err);
      throw err;
    }
  }

  isPreviewSite(hostname: string): boolean {
    return !!this.previewHosts.find((site: string) => {
      return hostname.includes(site);
    });
  }

  getConfigId(): string {
    const hostname = this.context.location.hostname;
    const isPreview = this.isPreviewSite(hostname);
    if (isPreview) {
      return previewConfigId;
    }
    if (this._r3z.app) {
      return this._r3z.apd || this.appPublisherConfigId;
    }

    return this._r3z.cid || hostname;
  }

  async loadJQuery() {
    this._r3z.jq = jquery;
  }

  async loadAdCode(): Promise<void> {
    const adId = this._r3z.aid;
    const optionalConfig = await this.configResolver.resolveFreeWallConfig({
      adId,
      cdn: new URL(this.cdn)
    });
    const config = optionalConfig.item;
    const baseUrl = this.templateResolver.toUrl({
      compileFolder: CompileFolder.FullFlex,
      version: config.version
    }, this.cdn);
    const codeSrc = `${baseUrl.toString()}/${filenamePrefixByDestinationPrefix[DestinationPrefix.Ads]}${TemplateFileSuffix.Code}`;
    const attributeValues: Attributes<ConfigOf<FreeWallConfig>> = {
      "data-config": config
    }
    const attributes = this.stringifyValues(attributeValues);
    await this.scriptLoader.load({ src: codeSrc, attributes });
  }

  async loadAdCodeExtension(): Promise<void> {
    const src = toCodeLocation({
      id: this._r3z.aid,
      prefix: DestinationPrefix.Ads,
      cdn: this.cdn,
      suffix: TemplateFileSuffix.Extension
    })
    await this.scriptLoader.load({ src });
  }

  stringifyValues<T>(input: T): Stringified<T> {
    return Object.entries(input).reduce((values, [key, value]) => ({
      ...values,
      [key]: JSON.stringify(value)
    }), {} as Stringified<T>)
  }

  async loadPublisherConfig() {
    if (this.publisherConfigLoaded()) {

    } else {
      const src = toCodeLocation({
        id: this._r3z.cid,
        prefix: DestinationPrefix.Pub,
        cdn: this.cdn,
        suffix: TemplateFileSuffix.Code
      });
      const optionalConfig = await this.configResolver.resolveFromId<PublisherConfig>({
        id: this._r3z.cid,
        prefix: DestinationPrefix.Pub,
        cdn: new URL(this.cdn)
      });
      const attributeValues: Attributes<ConfigOf<PublisherConfig>> = {
        "data-config": optionalConfig.item
      };
      const attributes = this.stringifyValues(attributeValues);
      await this.scriptLoader.load({ src, attributes });
    }

  }

  assignRezonenceVariables() {
    // set the CDN (not really a CDN here) url, the start time and
    // the ad type
    const _r3z = this._r3z;
    _r3z.cdn = this.cdn;
    _r3z.stm = _r3z.stm || (new Date()).getTime();
    _r3z.adt = this.adType;
    _r3z.cid = this.getConfigId();
  }

  hasLoaded(): boolean {
    return !!this._r3z.cdn;
  }

  async runIfNotLoaded(callback: () => Promise<void>): Promise<void> {
    if (!this.hasLoaded()) {
      await callback();
    }
  }

  /**
   *  Load the FreeWall from a specific CDN/S3 location
   *
   */
  async load() {
    await this.runIfNotLoaded(async () => {
      this.assignRezonenceVariables();
      await Promise.all([
        this.loadJQuery(),
        this.loadAdCode(),
        this.loadPublisherConfig()
      ]);
      try {
        await this.loadAdCodeExtension();
      } catch (err) {
        this.logger.info("Failed to load code extension");
      }
      this.launchFreeWall();

    });
  }

  async loadPublisherConfigAndJQueryIfNotLoaded() {
    await this.runIfNotLoaded(async () => {
      this.assignRezonenceVariables();
      await this.loadJQuery();
      await this.loadPublisherConfig();
    });
  }
}
