/**
 * Responsible for enumerating and selecting a wizard mode according to the user-supplied query parameter
 * e.g. "..&mode=Full..."
 */
import { WIZARD_MODE, WizardMode } from "./wizard.mode";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WizardModeKey } from "./wizard.mode.key";
import { wizardModeQueryParam } from "./wizard.mode.query.param";
@Injectable({
  providedIn: "root"
})
export class WizardService {

  constructor(@Inject(WIZARD_MODE) private modes: WizardMode[], private route: ActivatedRoute) {
  }

  listModes(): WizardMode[] {
    return this.modes;
  }

  get key(): WizardModeKey {
    return this.route.snapshot.queryParamMap.get(wizardModeQueryParam) as WizardModeKey || WizardModeKey.Full;
  }

  get mode(): WizardMode {
    return this.listModes().find(mode => mode.key === this.key);
  }

}
