<div *ngIf="config">
  <h3>
    Banner Link
  </h3>
  <div>
    <p>
      You can insert an optional hyper-link to a website you'd like people to visit if they click on your banner. If you
      don't want a link on the banner then you can leave this blank and continue.
    </p>
    <rez-json-form [schema]="schema" [uiSchema]="uiSchema" [(data)]="link"></rez-json-form>
  </div>
</div>
