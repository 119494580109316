import { Directive } from "@angular/core";

@Directive({
  selector: "[appRowContainer]"
})
export class RowContainerDirective {

  constructor() { }

}
