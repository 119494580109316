import { Injectable } from "@angular/core";
import { Creator } from "./Creator";
import { FreewallMetaPair } from "../core/freewall.meta.pair";
import { Optional } from "@rezonence/sdk";
import { QuickstartOperation } from "./QuickstartOperation";

@Injectable({
  providedIn: "root"
})
export class QuickstartService {

  protected actions: Record<QuickstartOperation, Optional<Creator>> = Object.values(QuickstartOperation)
    .reduce((previous, current) => ({
      ...previous,
      [current]: Optional.empty()
    }), {} as Record<QuickstartOperation, Optional<Creator>>);

  registerCreator(type: QuickstartOperation, creator: Creator) {
    this.actions[type] = Optional.of(creator);
  }

  async create(type: QuickstartOperation, existing: FreewallMetaPair[]): Promise<Optional<FreewallMetaPair>> {
    const optionalCreator = this.actions[type];
    const optionalMetaPairPromise = optionalCreator.map(creator => creator(existing));
    const result = await Optional.switchPromise(optionalMetaPairPromise);
    return Optional.unWrap(result);
  }
}
