import {VariantRequest} from "./variant.request";
import {ArrayUtils} from "@rezonence/array-utils";
import {defaultProtocol} from "./default.protocol";
import {topLevelDomain} from "./top.level.domain";
import {sep} from "./sep";

export class S3VariantService {

    static toVariants(request: VariantRequest): string[] {
        return [
            S3VariantService.toVirtualHostedVariant(request),
            S3VariantService.toPathStyleVariant(request),
            S3VariantService.toLegacyGlobalVariant(request),
            S3VariantService.toLegacyRegionVariant(request)
        ];
    }

    static toAwsDomain(region?: string): string {
        return ArrayUtils.truthyValues(["s3", region, topLevelDomain]).join(".");
    }

    static resolveProtocol(protocol?: string): string {
        return protocol || defaultProtocol;
    }

    static toLegacyGlobalVariant(request: VariantRequest): string {
        const protocol = S3VariantService.resolveProtocol(request.protocol);
        return  ArrayUtils.truthyValues([
            `${protocol}//${request.bucket}.${S3VariantService.toAwsDomain()}`,
            request.key
        ]).join(sep);
    }

    static toLegacyRegionVariant(request: VariantRequest): string {
        const protocol = S3VariantService.resolveProtocol(request.protocol);
        return  ArrayUtils.truthyValues([
            `${protocol}//${request.bucket}.s3-${request.region}.${topLevelDomain}`,
            request.key
        ]).join(sep);
    }

    static toVirtualHostedVariant(request: VariantRequest): string {
        const protocol = S3VariantService.resolveProtocol(request.protocol);
        return  ArrayUtils.truthyValues([
            `${protocol}//${request.bucket}.${S3VariantService.toAwsDomain(request.region)}`,
            request.key
        ]).join(sep);
    }

    static toPathStyleVariant(request: VariantRequest): string {
        const protocol = S3VariantService.resolveProtocol(request.protocol);
        return ArrayUtils.truthyValues(
            [
                `${protocol}//${S3VariantService.toAwsDomain(request.region)}`,
                request.bucket,
                request.key
            ]).join(sep);
    }
}
