import { JsonSchema } from "@jsonforms/core";
import { Dataset, freewallConfigSchema, mappingsSchema, metaSchema, campaignSchema, cssSchema, micrositesSchema, profileSchema, customCodeSchema } from "@rezonence/core";
import { DatasetDictionary } from "../dataset.dictionary";

export const schemaByDataset: DatasetDictionary<JsonSchema> = {
  [Dataset.Freewall]: freewallConfigSchema,
  [Dataset.Mappings]: mappingsSchema,
  [Dataset.Meta]: metaSchema,
  [Dataset.Campaign]: campaignSchema,
  [Dataset.Profile]: profileSchema,
  [Dataset.Css]: cssSchema,
  [Dataset.CustomCode]: customCodeSchema,
  [Dataset.Microsites]: micrositesSchema
};
