import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: "app-search-bar",
  templateUrl: "search-bar.component.html",
  styleUrls: ["./search-bar.component.css"]
})
export class SearchBarComponent implements OnInit, OnDestroy {
  search: FormControl;
  _query: string;

  @Output()
  queryChange = new EventEmitter();

  @Input()
  get query() {
    return this._query;
  }

  set query(value: string) {
    this._query = value;
    this.queryChange.emit(this._query);
  }

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.search = new FormControl("");
    this.subscriptions.push(this.search.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(searchInput => this.query = searchInput));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
