import { Injectable } from "@angular/core";
import { QuickstartWizardService } from "../QuickstartWizardService";
import { WizardConfig, WizardDialogService } from "@rezonence/ng-schema-wizard";
import { CreativeFreeWallDB } from "../../core/CreativeFreeWallDB";
import { AWSService } from "../../core/AWSService";
import { FreeWallResponse, Optional } from "@rezonence/sdk";
import { FreewallMetaPair } from "../../core/freewall.meta.pair";
import { EncodedInstructions, FreeWallConfig, Microsite, MicrositeLinkResolver, MicrositeType } from "@rezonence/core";
import { generateTypeformConfigSchema, GenerateTypeformConfig, Placement } from "@rezonence/freewall-creator-config";
import { MicrositeConfig } from "@rezonence/typeform-microsite";
import { FreeWallTitleGenerator, QuickstartOperation } from "../../create";
import { CreativeUtils } from "../../core/creative.utils";
import { tag } from "@rezonence/git";
import { CreativeMicrositeSchemaResolver } from "../../microsite";

@Injectable({
  providedIn: "root"
})
export class TypeformQuickstartService extends QuickstartWizardService {

  fwConfigGeneratorByPlacement: Record<Placement, (micrositeUrl: string) => FreeWallConfig> = {
    [Placement.banner]: (url) => ({
      ...this.baseFreewallConfig,
      iframe: ({ ...this.toIframe(url), dyn: false }),
      instructions: this.generateTypeformInstructions()
    }),
    [Placement.response]: (url) => ({ ...this.baseFreewallConfig, responses: this.toResponses(url) }),
    [Placement.kicker]: (url) => ({ ...this.baseFreewallConfig, kickers: this.toResponses(url) })
  };

  constructor(wizardDialogService: WizardDialogService,
    private schemaResolver: CreativeMicrositeSchemaResolver,
    db: CreativeFreeWallDB,
    titleGenerator: FreeWallTitleGenerator,
    utils: CreativeUtils,
    awsService: AWSService) {
    super(wizardDialogService, titleGenerator, db, utils, awsService);
  }

  async createTypeform(existing: FreewallMetaPair[]): Promise<Optional<FreewallMetaPair>> {
    const wizardConfig = this.createTypeformWizardConfig(existing);
    const response = await this.wizardDialogService.requestData(wizardConfig);
    return Optional.switchPromise(response.map(wizardResponse =>
      this.generateTypeformFreewall(wizardResponse)));
  }

  async generateTypeformFreewall(generateConfig: GenerateTypeformConfig): Promise<FreewallMetaPair> {
    const config: MicrositeConfig = {
      ...await this.schemaResolver.create<MicrositeConfig>({ templateId: MicrositeType.typeform, version: tag }),
      formId: generateConfig.formId
    };
    const micrositeBase: Microsite = {
      ...this.toBaseMicrosite(MicrositeType.typeform, generateConfig.title),
      config
    };
    const microsite = { ...micrositeBase, url: MicrositeLinkResolver.toMicrositeLink(micrositeBase) };
    const freewallConfig = this.fwConfigGeneratorByPlacement[generateConfig.placement](microsite.url);
    return this.saveMicrosite(microsite, freewallConfig);
  }

  createTypeformWizardConfig(existing: FreewallMetaPair[]): WizardConfig<GenerateTypeformConfig> {
    const fields: (keyof GenerateTypeformConfig)[] = [
      "title",
      "formId",
      "placement"
    ];
    return {
      data: {
        ...this.toDefaults<GenerateTypeformConfig>(generateTypeformConfigSchema, existing, QuickstartOperation.Typeform),
        formId: "",
      },
      schema: generateTypeformConfigSchema,
      steps: fields.map(f => this.toWizardStep(f))
    };
  }

  private toResponses(micrositeUrl: string): FreeWallResponse[] {
    return this.baseFreewallConfig.questions[0].answers.map(() => ({
      ifs: micrositeUrl,
      rat: 1
    }));
  }

  private generateTypeformInstructions(): EncodedInstructions {
    return {
      override: true,
      top: true,
      bot: true,
      tov: "Please answer the survey below to reveal the rest of the article.",
      bov: "Please answer the survey above to reveal the rest of the article."
    };
  }

}
