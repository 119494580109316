import {StringOrNumber} from "./StringOrNumber";
import {uniqueArrayItem} from "./uniqueArrayItem";
import {truthyArrayItem} from "./truthyArrayItem";
import {flattener} from "./flattener";

export class ArrayUtils {
    static last<T>(array: T[]): T {
        return array[array.length - 1];
    }

    static first<T>(array: T[]): T {
        return array[0];
    }

    static uniqueValues<T extends StringOrNumber>(input: T[]): T[] {
        return input.filter(uniqueArrayItem);
    }

    static filterEntries<R, K extends keyof R>(record: R, predicate: (key: K) => boolean): R {
        return Object.keys(record).filter(k => predicate(k as K)).reduce((r, k) => ({
            ...r,
            [k]: record[k]
        }), {} as R);
    }

    static uniqueBy<T>(input: T[], mapper: (v: T) => StringOrNumber): T[] {
        const mask = input.map(mapper).map((v, index, arr) => arr.indexOf(v) === index);
        return input.filter((_v, index) => mask[index]);
    }

    static truthyValues<T>(input: Array<T | undefined>): T[] {
        return input.filter(truthyArrayItem);
    }

    static flatten<T>(input: Record<StringOrNumber, T[]> | T[][]): T[] {
        return Object.values(input).reduce(flattener, []);
    }

    static async collect<T>(iterable: AsyncIterable<T>): Promise<T[]> {
        const output = [];
        for await (const item of iterable) {
            output.push(item);
        }
        return output;
    }
}
