import { Injectable } from "@angular/core";
import { SaveItemRequest, FreeWallDB, Dataset } from "@rezonence/core";
import { SaveHook } from "@rezonence/freewall-db-hooks";
import { CreativeUtils } from "./creative.utils";

@Injectable({
    providedIn: "root"
})
export class CreateIdHook implements SaveHook {

    constructor(private utils: CreativeUtils) {
    }

    async transform(input: SaveItemRequest<any>, db: FreeWallDB): Promise<SaveItemRequest<any>> {
        return {
            ...input,
            recordId: input.recordId || await this.createNewIdForDataset(db, input.datasetName, input.identityId)
        }
    }

    async createNewIdForDataset(db: FreeWallDB, dataset: Dataset, identityId: string): Promise<string> {
        let recordId = this.utils.randomString();
        while (await db.getItem(identityId, dataset, recordId)) {
            recordId = this.utils.randomString();
        }
        return recordId;
    }

}
