import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShareLinkComponent } from '../share/share-link/share-link.component';
import { ShareLinkData } from '../share/share-link/share.link.data';
import { DemoAdIdResolver } from './DemoAdIdResolver';
import { ShareService } from '../share/ShareService';
import { Dataset } from '@rezonence/core';
import { LoadingMessageComponent } from '../core/loading-message/loading-message.component';
import { DisplayDemoRequest, toDemoUrl } from '@rezonence/freewall-demo-client';

@Component({
  selector: 'app-share-freewall-demo',
  template: `
    <button
      mat-flat-button
      color="accent"
      matTooltip="Share this demo"
      [disabled]="disabled"
      (click)="onShareClick({url, recordId})">
      Share
    </button>
  `
})
export class ShareFreeWallDemoComponent {
  @Input()
  recordId: string;

  @Input()
  url: string;

  @Input()
  disabled: boolean;

  @Output()
  share: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private adIdResolver: DemoAdIdResolver,
    private dialog: MatDialog,
    private shareService: ShareService
  ) { }

  async onShareClick({
    recordId,
    url
  }: {
    recordId: string;
    url: string;
  }): Promise<void> {
    const dialogRef = this.displayLoadingMessageDialog();
    try {
      await this.shareService.share({ recordId, sourceLink: url });
      const aid = await this.adIdResolver.toSharedAdId(recordId);
      const shareLinkData = await this.toShareLinkData({ aid, url });
      this.displayShareLink(shareLinkData);
      this.share.emit(shareLinkData.link);
    } finally {
      dialogRef.close();
    }
  }

  async toShareLinkData(request: DisplayDemoRequest): Promise<ShareLinkData> {
    const url = toDemoUrl(request);
    return { link: url.toString(), id: request.aid };
  }

  private displayLoadingMessageDialog(): MatDialogRef<LoadingMessageComponent> {
    const loadingRef = this.dialog.open(LoadingMessageComponent, {
      disableClose: true
    });
    loadingRef.componentInstance.progressMessage = 'Sharing your demo...';
    return loadingRef;
  }

  private displayShareLink(shareLinkData: ShareLinkData) {
    const ref = this.dialog.open(ShareLinkComponent);
    ref.componentInstance.shareLinkData = shareLinkData;
  }
}
