import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Route } from "@rezonence/freewall-creator-config";
import { Optional } from "@rezonence/sdk";
import { filter, map, Observable, shareReplay } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RouteResolver {

  readonly route$: Observable<Optional<Route>>;

  constructor(router: Router) {
    this.route$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map((e: NavigationEnd) => new URL(e.url, location.origin)),
      map(url => this.toFirstPathElement(url)),
      map(pathElement => Optional.of(Object.values(Route).find(v => v === pathElement))),
      shareReplay(1)
    )
  }

  toFirstPathElement(url: URL): string {
    return url.pathname.split("/")[1];
  }
}
