import { UISchemaElement } from "@jsonforms/core";
import { Dataset } from "@rezonence/core";
import { metaUiSchema } from "../meta/meta.ui.schema";
import { campaignUiSchema } from "../campaign/campaign.ui.schema";
import { profileUiSchema } from "../profile/profile.ui.schema";
import { micrositesUiSchema } from "../microsites/microsites.ui.schema";
import { cssUiSchema } from "../css";
import { mappingUiSchema } from "../mapping/mapping.ui.schema";
import { freewallUiSchema } from "../freewall/freewall.ui.schema";
import { DatasetDictionary } from "../dataset.dictionary";
import { customCodeUiSchema } from "../custom-code/custom-code.ui.schema";

export const uiSchemaByDataset: DatasetDictionary<UISchemaElement> = {
  [Dataset.Freewall]: freewallUiSchema,
  [Dataset.Mappings]: mappingUiSchema,
  [Dataset.Meta]: metaUiSchema,
  [Dataset.Campaign]: campaignUiSchema,
  [Dataset.Profile]: profileUiSchema,
  [Dataset.Css]: cssUiSchema,
  [Dataset.CustomCode]: customCodeUiSchema,
  [Dataset.Microsites]: micrositesUiSchema
};
