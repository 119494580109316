import { Component, OnInit } from "@angular/core";
import { redirectToDemoSite } from "@rezonence/freewall-demo-client";

@Component({
  selector: 'demo-article',
  template: ``
})
export class DemoArticle implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    redirectToDemoSite();
  }

}
