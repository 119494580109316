import { Component, EventEmitter, Input, Output } from "@angular/core";
import { generateDemoUrlConfigSchema, GenerateDemoUrlConfig } from "@rezonence/freewall-creator-config";
import { ReplaySubject, Subject } from "rxjs";
import { ErrorObject } from "ajv";
import { demoSiteOrigin } from "@rezonence/freewall-demo-client";

@Component({
  selector: "app-demo-url",
  templateUrl: "./demo-url.component.html",
  styleUrls: ["./demo-url.component.css"]
})
export class DemoUrlComponent {

  demoUrlObject: GenerateDemoUrlConfig;

  @Input()
  recordId: string;

  private _demoUrl: string

  @Input()
  set demoUrl(input: string) {
    const demoUrl = input || demoSiteOrigin;
    this._demoUrl = demoUrl;
    this.demoUrlObject = { demoUrl };
  }

  get demoUrl(): string {
    return this._demoUrl;
  }

  @Output()
  demoUrlChange: Subject<string> = new ReplaySubject(1);

  @Output()
  onReload = new EventEmitter();

  schema = generateDemoUrlConfigSchema;

  @Output()
  shareClick: EventEmitter<string> = new EventEmitter<string>();

  edit: boolean;

  errors$: ReplaySubject<ErrorObject[]> = new ReplaySubject<ErrorObject[]>(1);
}
