<div *ngIf="config">
  <h3>
    Question
  </h3>
  <p>
    Now it’s time to enter the question that you would like readers to respond to. Try to keep the question concise, we don’t want people to have to read for too long before they see what you are asking.
  </p>
  <rez-json-form [(data)]="question" [uiSchema]="uiSchema" [schema]="schema" (errors)="errors.next($event)">
  </rez-json-form>
</div>

