import { Component, ContentChild, TemplateRef } from "@angular/core";
import { FreeWallEditorContext } from "../freewall-editor"

@Component({
  selector: "app-record-container",
  templateUrl: "./record-container.component.html",
  styleUrls: ["./record-container.component.css"]
})
export class RecordContainerComponent {

  @ContentChild(TemplateRef, { static: false })
  itemTemplate: TemplateRef<any>;

  constructor(public readonly context: FreeWallEditorContext) {
  }
}
