import { enableProdMode } from "@rezonence/angular";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { resolveSiteConfig } from "@rezonence/site-config";
import { Config } from "@rezonence/freewall-creator-config";
import { AppModule } from "./app";
import { InfrastructureResolver } from "./app/core/InfrastructureResolver";

enableProdMode()

resolveSiteConfig<Config>()
  .then(config => {
    platformBrowserDynamic([
      { provide: InfrastructureResolver, useValue: new InfrastructureResolver(config) },
    ]).bootstrapModule(AppModule);
  });
