import {Pipe, PipeTransform} from "@angular/core";
import {FreewallMetaPair} from "../core/freewall.meta.pair";

@Pipe({
  name: "filterFreewall"
})
export class FilterFreewallPipe implements PipeTransform {

  transform(freewalls: FreewallMetaPair[], query: string): FreewallMetaPair[] {
    return query
      ? freewalls.filter(freewall =>
        this.queryIncludedInTitle(freewall, query) || this.queryIncludedInLabels(freewall, query))
      : freewalls;
  }

  private queryIncludedInTitle(freeWall: FreewallMetaPair, query: string): boolean {
    return freeWall && freeWall.meta && freeWall.meta.title
      && freeWall.meta.title.toLowerCase().includes(query.toLowerCase());
  }

  private queryIncludedInLabels(freeWall: FreewallMetaPair, query: string): boolean {
    return freeWall && freeWall.meta && freeWall.meta.labels
      && freeWall.meta.labels.filter(label =>
        label && label.toLowerCase().includes(query.toLowerCase())).length > 0;
  }
}
