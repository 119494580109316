import fetch from 'cross-fetch'
import { FetchError } from './FetchError'

export async function crossFetch(url: string, options?, fetchInstance = fetch): Promise<Response> {
  const response = await fetchInstance(url, options)
  if (!response.ok) {
    throw new FetchError(response)
  }
  return response
}
