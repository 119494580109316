import { Injectable } from "@angular/core";
import { LoadSaveHook } from "@rezonence/freewall-db-hooks";
import { CSSConfig, Dataset, DatasetItem, SaveItemRequest } from "@rezonence/core";
import { CreativeCssCompiler } from "../../core/creative.css.compiler";

@Injectable()
export class CssMigrationService implements LoadSaveHook {

  constructor(private cssCompiler: CreativeCssCompiler) {
  }

  async transform<T extends SaveItemRequest<any> | DatasetItem<any>>(input: T): Promise<T> {
    if (input && input.datasetName === Dataset.Css) {
      const result = await this.migrate(input as SaveItemRequest<CSSConfig>);
      return result as T;
    }
    return input;
  }

  isLegacyConfig(config: CSSConfig): boolean {
    return !config.code && (!!config.custom || !!config.answers || !!config.buttons);
  }

  async migrate(item: SaveItemRequest<CSSConfig>): Promise<SaveItemRequest<CSSConfig>> {
    const cssConfig = item.value;
    if (cssConfig && this.isLegacyConfig(cssConfig)) {
      cssConfig.code = this.cssCompiler.compile(cssConfig);
    }
    return item;
  }
}
