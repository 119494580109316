import { Injectable } from "@angular/core";
import {basename, extname} from "path";

@Injectable({
  providedIn: "root"
})
export class FileNameSanitizerService {

  constructor() {
  }

  sanitize(fileName: string): string {
    return basename(fileName, extname(fileName))
      .replace(/[^a-zA-Z0-9]/g, "_") + extname(fileName);
  }
}
