import {get, getAll, remove, set} from "es-cookie";
import {Duration} from "@rezonence/duration";
import {ArrayUtils} from "@rezonence/array-utils";
import {trackingCookiePrefix} from "./tracking.cookie.prefix";

/**
 * Sets a cookie
 * @param {string} cookieName - Name of the cookie
 * @param {(string|number)} cookieValue - Value of the cookie
 * @param {Date} [expiryDate] - Expiry date of the cookie, if not set, the cookie will be a session cookie
 * @param {string} [cookiePath=/] - Path of the cookie
 */
export function setCookie(cookieName: string, cookieValue: string,
                          expiryDate?: Date, cookiePath: string = "/"): void {
    set(cookieName, cookieValue, {
        path: cookiePath,
        expires: expiryDate
    });
}

/**
 * Deletes a cookie
 * @param {string} cookieName - Name of the cookie
 */
export function delCookie(cookieName: string): void {
    remove(cookieName);
}

/**
 * Gets a cookie's value.
 * @param {string} cookieName - Name of the cookie
 * @returns {string} Value of the cookie
 */
export function getCookie(cookieName: string): string {
    return get(cookieName);
}

export function getAllCookies(): Record<string, string> {
    return getAll();
}

export function getCookiesWithPrefix(prefix: string): Record<string, string> {
    const cookies = getAllCookies();
    return ArrayUtils.filterEntries(cookies, k => k.startsWith(prefix));
}

/**
 * Tests if cookies can be set
 * @returns {boolean} true if cookies can be set, false otherwise
 */
export function testCookie(): boolean {

    const testKey = trackingCookiePrefix + "test";
    const testValue = "REZONENCE";

    setCookie(testKey, testValue, new Date((new Date()).getTime() + Duration.MsInMinute));
    if (getCookie(testKey) === testValue) {
        delCookie(testKey);
        return true;
    }

    return false;
}
