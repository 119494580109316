import './microsites-renderer.component.scss';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { JsonFormsAngularService } from '@jsonforms/angular';
import { MicrositeUiType } from '../microsite-renderer/microsite.ui.type';
import { MicrositeUiSchema } from '../microsite-renderer/microsite.ui.schema';
import { CreativeUtils } from '../../core/creative.utils';
import { ArrayRendererComponent, ArrayItemUiSchemaService } from '@rezonence/ng-schema-wizard';
import { MicrositesRendererService } from './MicrositesRendererService';
import { MicrositesRendererSchemaService } from './MicrositesRendererSchemaService';

@Component({
  selector: 'app-microsites-renderer',
  templateUrl: './microsites-renderer.component.html',
  providers: [MicrositesRendererService, MicrositesRendererSchemaService]
})
export class MicrositesRendererComponent
  extends ArrayRendererComponent
  implements OnInit, OnDestroy {
  micrositeUiSchema: MicrositeUiSchema = {
    type: MicrositeUiType.Microsite,
    scope: ''
  };

  constructor(
    private utils: CreativeUtils,
    public renderer: MicrositesRendererService,
    jsonFormsService: JsonFormsAngularService,
    arrayItemUiSchemaService: ArrayItemUiSchemaService
  ) {
    super(jsonFormsService, arrayItemUiSchemaService);
    this.itemFactory = () =>
      this.renderer.createNewMicrosite(this.createNewId());
  }

  private createNewId(): string {
    let id = this.createId();
    while (this.data.find((m) => m.id === id)) {
      id = this.createId();
    }
    return id;
  }

  private createId(): string {
    return this.utils.randomString();
  }
}
