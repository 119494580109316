import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ProfileMigrationClient} from "./ProfileMigrationClient";
import {cognitoIdentityIdPrefix} from "./cognito-identity-id-prefix";
import {LoadingMessageComponent} from "../core/loading-message/loading-message.component";
import {interval, timer} from "rxjs";
import {Duration} from "@rezonence/duration";
import {map, takeUntil} from "rxjs/operators";
import { federatedIdentityProvider } from "@rezonence/authenticator";

@Injectable({
  providedIn: "root"
})
export class DataMigrationService {

  updatingMessage = "Loading your data...";

  constructor(private lambdaClient: ProfileMigrationClient,
              public dialog: MatDialog) {
  }

  async migrateData(token: string): Promise<void> {
    const dialogRef = this.dialog.open(LoadingMessageComponent, {disableClose: true});
    const loadingComponent = dialogRef.componentInstance;
    const aliases = this.getAliases();
    const loadingPromise = this.lambdaClient.invoke({ aliases, token, domain: federatedIdentityProvider });
    loadingComponent.progressMessage = this.updatingMessage;
    const timer$ = timer(15 * Duration.MsInMinute);
    interval(Duration.MsInSecond).pipe(takeUntil(timer$)).pipe(map(second => 100 * second / (5 * 60)))
    /* eslint-disable no-return-assign */
      .subscribe(percentage => loadingComponent.value = percentage);
    try {
      await loadingPromise;
    } catch (err) {
      console.log(err);
    } finally {
      dialogRef.close();
    }
  }

  getAliases(): string[] {
    return Object.keys(localStorage)
      .filter(key => key.startsWith(cognitoIdentityIdPrefix))
      .map(key => localStorage.getItem(key));
  }

}
