import Lambda from "aws-sdk/clients/lambda";

export class LambdaClient<I, O> {

    constructor(private lambdaPromise: Promise<Lambda>,
                private functionName: string) {
    }

    async invoke(input: I): Promise<O> {
        const lambda = await this.lambdaPromise;
        const response = await lambda.invoke({
            FunctionName: this.functionName,
            Payload: JSON.stringify(input)
        }).promise();
        const payloadString = response.Payload.toString();
        const payload = payloadString ? JSON.parse(payloadString) as O : payloadString;
        if (response.FunctionError) {
            throw new Error((payload as any).errorMessage);
        }
        return payload as O;
    }
}
