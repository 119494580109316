import {Injectable} from "@angular/core";
import {ResourceService} from "../../resource/resource/ResourceService";
import {RenderLinksRequest} from "./render.links.request";

@Injectable({
  providedIn: "root"
})
export class LinkService {

  constructor(private resourceService: ResourceService) {
  }

  findAllUniqueStringValues(input: any): string[] {
    const strings: string[] = [];
    JSON.parse(JSON.stringify(input), (key, value) => {
      if (typeof value === "string") {
        if (!strings.find(l => l === value)) {
          strings.push(value);
        }
      }
    });
    return strings;
  }

  async renderLinks<T>(input: RenderLinksRequest<T>): Promise<T> {
    const links = this.findAllUniqueStringValues(input.object);
    const resolvedLinks = await Promise.all(links.map(async l => ({
      original: l,
      processed: await this.resourceService.renderLinkMacros({
        freewallId: input.recordId,
        link: l
      })
    })));

    return JSON.parse(JSON.stringify(input.object), (key, value) => {
      if (typeof value === "string") {
        const processedLink = resolvedLinks.find(l => l.original === value);
        return processedLink ? processedLink.processed : value;
      }
      return value;
    });

  }
}
