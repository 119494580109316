import { Pipe, PipeTransform } from "@angular/core";
import {Dataset} from "@rezonence/core";
import {uiSchemaByDataset} from "../ui-schema/ui.schema.by.dataset";
import {ControlElement} from "@jsonforms/core";

@Pipe({
  name: "datasetLabel"
})
export class DatasetLabelPipe implements PipeTransform {

  transform(dataset: Dataset): string {
    const datasetUiSchema = uiSchemaByDataset[dataset];
    return ((datasetUiSchema as ControlElement).label || Dataset[dataset]) as string;
  }

}
