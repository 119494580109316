import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription, filter, map, concatMap, tap } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AnalyticsService {

    constructor(private router: Router, private gtmService: GoogleTagManagerService) {
    }

    recordPages(): Subscription {
        return this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map((e: NavigationEnd) => ({
                event: 'page',
                pageName: e.url
            })),
            concatMap(e => this.gtmService.pushTag(e)),
        ).subscribe();
    }
}