import { Component, OnDestroy, OnInit } from "@angular/core";
import { JsonFormsAngularService, JsonFormsArrayControl } from "@jsonforms/angular";
import defaults from "json-schema-defaults";
import { ArrayControlProps, createDefaultValue, findUISchema, UISchemaElement, UISchemaTester } from "@jsonforms/core";
import { ArrayItemUiSchemaService } from "../ui-schema/array-item-ui-schema.service";
import { Optional } from "@rezonence/sdk";

@Component({
  selector: "app-array-renderer",
  templateUrl: "./array-renderer.component.html",
})
export class ArrayRendererComponent extends JsonFormsArrayControl implements OnInit, OnDestroy {

  props: ArrayControlProps;
  arrayItemUiSchema: UISchemaElement;

  constructor(jsonFormsService: JsonFormsAngularService,
    private arrayItemService: ArrayItemUiSchemaService) {
    super(jsonFormsService);
  }

  itemFactory = async () => this.createItem();

  createItem<T>(): Optional<T> {
    return Optional.of(defaults(this.scopedSchema))
      .map(
        o => o,
        () => createDefaultValue(this.scopedSchema)
      );
  }

  resolveUiSchema(uischemas: {
    tester: UISchemaTester;
    uischema: UISchemaElement;
  }[]): UISchemaElement {
    return this.arrayItemService.getUiSchema(this.propsPath, this.scopedSchema).map(v => v, () =>
      findUISchema(
        uischemas,
        this.scopedSchema,
        this.uischema.scope,
        this.propsPath,
        undefined,
        this.uischema,
        this.rootSchema
      )
    ).item;

  }

  mapAdditionalProps(props: ArrayControlProps): void {
    super.mapAdditionalProps(props);
    this.props = props;
    this.arrayItemUiSchema = this.resolveUiSchema(props.uischemas);
  }

}
