import {Component, Input, OnInit} from "@angular/core";
import {FreeWall} from "@rezonence/core/freewall/freewall";

@Component({
  selector: "freewall-type",
  templateUrl: "./type.component.html",
  styleUrls: ["./type.component.css"]
})
export class TypeComponent implements OnInit {

  set freeWallType(ft: string) {

  }

  get freeWallType(): string {

    let type = "survey";
    if (this.config.questions &&
      this.config.questions.length &&
      !! this.config.questions[0].answers.find(answer => !answer.tof )) {
      type = "quiz";
    }

    return type;
  }

  @Input()
  freeWallId: string;

  @Input()
  config: FreeWall;

  constructor() {

  }

  ngOnInit() {

  }

}
