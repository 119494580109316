import { Magic } from "magic-sdk";
import { AuthenticationRequest } from "./AuthenticationRequest";
import { TokenExchanger } from "./TokenExchanger";
import { Optional } from "@rezonence/sdk";
import { OpenIdTokenResponse } from "./OpenIdTokenResponse";
import { User } from "./User";

export class MagicLinkClient {

    constructor(private exchanger: TokenExchanger, private magic: Magic) {
    }

    async authenticate(request: AuthenticationRequest): Promise<Optional<OpenIdTokenResponse>> {
        // Prevent login state inconsistency between Magic and the client side
        await this.magic.user.logout();
        return this.requestOpenIdToken(request);
    }

    async requestOpenIdToken(request: AuthenticationRequest): Promise<Optional<OpenIdTokenResponse>> {
        const loginResponse = await this.magic.auth.loginWithMagicLink(request);
        const optionalResponse = Optional.of(loginResponse).map(didToken => this.exchangeToken(didToken));
        return Optional.switchPromise(optionalResponse);
    }

    async exchangeToken(didToken: string): Promise<OpenIdTokenResponse> {
        return this.exchanger.exchange({ didToken });
    }

    async signOut(): Promise<void> {
        await this.magic.user.logout();
    }

    async isLoggedIn(): Promise<boolean> {
        const response = await this.magic.user.isLoggedIn();
        return !!response;
    }

    async userMetadata(): Promise<Optional<User>> {
        const loggedIn = await this.isLoggedIn();
        const optionalUser = await Optional.switchPromise(Optional.truthy(loggedIn).map(() => this.magic.user.getMetadata()));
        return Optional.unWrap(optionalUser);
    }

    async refreshToken(): Promise<Optional<OpenIdTokenResponse>> {
        const tokenResponse = await this.magic.user.getIdToken();
        return Optional.switchPromise(Optional.of(tokenResponse).map(didToken => this.exchangeToken(didToken)));
    }

}
