export enum TrackingMode {

    /**
     * @title Disabled
     */
    Disabled = 0,

    /**
     * @title Impressions, unlocks and clicks
     */
    ImpressionsUnlocksAndClicks = 1,

    /**
     * @title All events
     */
    AllEvents = 2
}
