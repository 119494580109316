import {Utils} from "./utils";
import {guid, sleep} from "@rezonence/sdk";
import {KeySorter} from "@rezonence/key-sorter";

export class DefaultUtils implements Utils {

    randomString(length: number = 5): string {
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    toCanonicalForm<T>(object: T): T {
        return KeySorter.sort(object);
    }

    /**
     * Replace the placeholder with the json string
     * @param originalString {string} - The string to be processed
     * @param placeholder {string} - The placeholder string
     * @param jsonString {string} - The json string to insert
     * @returns {string}
     */
    insertJSON(originalString: string, placeholder: string, jsonString: string): string {

        // First check if the string is valid

        jsonString = JSON.stringify(JSON.parse(jsonString) as any);

        // jsonString = jsonString.replace(/(\r\n|\n|\r)/gm, "");
        // jsonString = jsonString.replace(/\\/g, "");
        jsonString = jsonString.replace(/"/g, '\\"');
        jsonString = jsonString.replace(/'/g, "\\'");
        originalString = originalString.replace(`"${placeholder}"`, `'${placeholder}'`);
        originalString = originalString.replace(placeholder, jsonString);
        return originalString;

    }

    /**
     * Replace the placeholder with the css string
     * @param originalString {string} - The string to be processed
     * @param placeholder {string} - The placeholder string
     * @param cssString {string} - The json string to insert
     * @returns {string}
     */
    insertCSS(originalString: string, placeholder: string, cssString: string): string {

        cssString = cssString.replace(/(\r\n|\n|\r)/gm, "");
        cssString = cssString.replace(/"/g, '\\"');
        originalString = originalString.replace(`'${placeholder}'`, `"${placeholder}"`);
        originalString = originalString.replace(placeholder, cssString);
        return originalString;

    }

    guid(): string {
        return guid();
    }

    isWeb(): boolean {
        return typeof window !== "undefined";
    }

    /**
     * Checks if the variable is an array
     * @param varToTest
     */
    isArray(varToTest: any): boolean {

        return varToTest && (varToTest.constructor === Array);

    }

    replaceAll(find: string, replace: string, str: string) {
        // console.log("replacing " + find + " with " + replace);
        return str.replace(new RegExp(find, "g"), replace);
    }

    /**
     * Execute promises in series
     */
    async process<A, B>(inputElements: A[], processor: (element: A) => Promise<B>, concurrency?: number): Promise<B[]> {

        // Support for legacy use of 'parallel' param
        if (typeof concurrency === "boolean") {
            if (concurrency) {
                concurrency = 0;
            } else {
                concurrency = 1;
            }
        } else if (typeof concurrency === "undefined") {
            concurrency = 1;
        }

        let currentBatch = [];
        const batches = [currentBatch];

        inputElements.forEach(element => {

            currentBatch.push(element);

            if (currentBatch.length >= concurrency && concurrency > 0) {
                currentBatch = [];
                batches.push(currentBatch);
            }

        });

        const results: B[] = [];

        for (const batch of batches) {
            const outputElements = await Promise.all(batch.map(async element => processor(element)));
            results.push(...outputElements);
        }

        return results;

    }

    async toPromise<T>(input: T | Promise<T>): Promise<T> {
        return input;
    }

    sleep(periodInMs: number): Promise<void> {
        return sleep(periodInMs);
    }

}
