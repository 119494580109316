import { Pipe, PipeTransform } from "@angular/core";
import { FreewallMetaPair } from "../../core/freewall.meta.pair";
import {SharedStatus} from "../../share/shared.status";
import {environment} from "../../../environments/environment";
import {ShareService} from "../../share/ShareService";

@Pipe({
  name: "sharedStatus"
})
export class SharedStatusPipe implements PipeTransform {

  constructor(private shareService: ShareService) {
  }

  async transform(freeWall: FreewallMetaPair): Promise<SharedStatus> {
    if (freeWall && freeWall.meta) {
      const demoLink = freeWall.meta.demoLink || environment.defaultDemoLink;
      return this.shareService.isShared({sourceLink: demoLink, recordId: freeWall.recordId});
    }
    return {
      shared: false,
      shareLink: undefined
    };
  }

}
