import { DoubleserveConfig } from "./doubleserve.config";
import { AdServerAdapterKey } from "./ad.server.adapter.key";
import { VideoAdUnitCode } from "./video.ad.unit.code";

export const defaultDoubleserveConfig: DoubleserveConfig = {
  adServer: AdServerAdapterKey.GAM,
  targetingParameter: { refresh: "true" },
  placements: {},
  ignoreCapping: false,
  advancedAnalytics: false,
  vfwAdIdByAdUnitCode: {
    [VideoAdUnitCode.video]: "Rezonence/DoubleServe/AutoPlayVFW2s",
    [VideoAdUnitCode.clickToPlayVideo]: "Rezonence/DoubleServe/VideoFreeWall2sC2P",
    [VideoAdUnitCode.unblockedContentVideo]: "Rezonence/DoubleServe/AutoPlayVFW2sContentUnblocked"
  },
  thirdPartyDoubleServeImpressionTrackers: []
};
