import { Injectable } from "@angular/core";
import { MicrositeSchema, MicrositeSchemaResolver, MicrositeType } from "@rezonence/core";
import { CreativeTemplateFolderResolver } from "../core/creative.template.folder.resolver";
import { Optional } from "@rezonence/sdk";
import createDefault from "json-schema-defaults";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class CreativeMicrositeSchemaResolver extends MicrositeSchemaResolver {

  constructor(templateResolver: CreativeTemplateFolderResolver) {
    super(templateResolver);
  }

  resolveSchema(request: { templateId: MicrositeType, version: string }): Promise<Optional<MicrositeSchema>> {
    return super.resolve({
      cdn: new URL(environment.defaultCDN),
      ...request
    })
  }

  async create<T>(request: { templateId: MicrositeType, version: string }): Promise<Optional<T>> {
    const optionalInfo = await this.resolveSchema(request);
    return optionalInfo.map(info => createDefault(info.schema))
  }

}
