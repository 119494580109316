import { Pipe, PipeTransform } from "@angular/core";
import chunk from "lodash/chunk";

@Pipe({
  name: "batch"
})
export class BatchPipe implements PipeTransform {

  transform<T>(array: T[], batchSize: number): T[][] {
    return chunk(array, batchSize);
  }

}
