<div *ngIf="config">
  <h3>
    Hint
  </h3>
  <p>
    A hint will appear when a reader clicks on an incorrect answer (e.g. "{{incorrectAnswer?.ans}}"). Enter a short hint that will allow the reader to quickly try again and select a correct answer.
  </p>
  <rez-json-form [(data)]="hint" [uiSchema]="uiSchema" [schema]="schema" (errors)="errors.next($event)">
  </rez-json-form>

</div>

