import {Categorization, GroupLayout, RuleEffect} from "@jsonforms/core";
import {freewallConfigSchema} from "@rezonence/core";
import {EncodedCap, EncodedIframe, FreeWallImage} from "@rezonence/sdk";
import {UiSchemaService} from "../../core/ui-schema.service";

const uiSchemaService = new UiSchemaService();

const iframeExcludeFields: (keyof EncodedIframe)[] = [];
const cappingExcludeFields: (keyof EncodedCap)[] = ["xer"];
const keyBannerIframeField: keyof EncodedIframe = "src";
const keyBannerImageField: keyof FreeWallImage = "img";

function toKeyField(field: string): string {
  return field === "tov" ? "top" : field === "bov" ? "bot" : undefined;
}

export const freewallUiSchema: Categorization = {
  type: "Categorization",
  label: "FreeWall",
  elements: [
    {
      type: "Category",
      label: "Questions and answers",
      elements: [
        uiSchemaService.toControlElement({
          field: "questions"
        })
      ]
    },
    {
      type: "Category",
      label: "Media slot 1",
      elements: [
        {
          type: "Group",
          label: "Image",
          elements:  Object.keys(freewallConfigSchema.definitions.FreeWallImage.properties)
            .map(field => uiSchemaService.toHiddenControlElement(
              {scopePrefix: "#/properties/image", field},
              keyBannerImageField))
        } as GroupLayout,
        {
          type: "Group",
          label: "Microsite",
          elements: Object.keys(freewallConfigSchema.definitions.EncodedIframe.properties)
            .filter(field => !iframeExcludeFields.includes(field as keyof EncodedIframe))
            .map(field => uiSchemaService.toHiddenControlElement({ scopePrefix: "#/properties/iframe", field }, keyBannerIframeField))
        } as GroupLayout
      ]
    },
    {
      type: "Category",
      label: "Media slot 2",
      elements: [
        uiSchemaService.toControlElement({
          field: "responses"
        })
      ]
    },
    {
      type: "Category",
      label: "Media slot 3",
      elements: [
        uiSchemaService.toControlElement({
          field: "kickers"
        })
      ]
    },
    {
      type: "Category",
      label: "Instructions",
      elements: [
        {
          type: "Group",
          elements: Object.keys(freewallConfigSchema.definitions.EncodedInstructions.properties)
            .map(field => {
              const scopePrefix = "#/properties/instructions";
              let controlElement = uiSchemaService.toControlElement({scopePrefix, field});
              if (field !== "override") {
                controlElement = uiSchemaService.toMultiRuleControlElement(controlElement, [
                  {scopePrefix, field: "override", state: true}
                ], RuleEffect.SHOW);
              }
              const keyField = toKeyField(field);
              if (keyField) {
                controlElement = uiSchemaService.toMultiRuleControlElement(controlElement, [
                  {scopePrefix, field: keyField, state: true}
                ], RuleEffect.SHOW);
              }
              return controlElement;
            })
        } as GroupLayout
      ]
    },
    {
      type: "Category",
      label: "Tracking",
      elements: [
        {
          type: "Group",
          label: "Google analytics tracking",
          elements: [
            uiSchemaService.toControlElement({
              field: "gat"
            }),
          ]
        } as GroupLayout,
        uiSchemaService.toControlElement({
          field: "trackers"
        })
      ]
    },
    {
      type: "Category",
      label: "Capping",
      elements: [
        {
          type: "Group",
          elements: [
            uiSchemaService.toControlElement({
              scopePrefix: "#",
              field: "sho"
            }),
          ]
        } as GroupLayout,
        {
          type: "Group",
          label: freewallConfigSchema.properties.ero.title,
          elements:
            Object.keys(freewallConfigSchema.definitions.EngagementOptimisation.properties)
              .map(field => (uiSchemaService.toControlElement({
                scopePrefix: "#/properties/ero",
                field
              }))),
          rule: uiSchemaService.toRule({
            scopePrefix: "#",
            field: "sho"
          })
        } as GroupLayout,
        {
          type: "Group",
          label: freewallConfigSchema.properties.cap.title,
          elements: Object.keys(freewallConfigSchema.definitions.EncodedCap.properties)
            .filter(field => !cappingExcludeFields.includes(field as keyof EncodedCap))
            .map(field => {
              const scopePrefix = "#/properties/cap/properties";
              return freewallConfigSchema.definitions.EncodedCap.properties[field].$ref
                ? {
                  type: "Group",
                  label: freewallConfigSchema.definitions.EncodedCap.properties[field].title,
                  elements: Object.keys(freewallConfigSchema.definitions.CappingParam.properties)
                    .map(subfield => (
                      {
                        type: "Control",
                        scope: `${scopePrefix}/${field}/properties/${subfield}`
                      }))} : {
                  type: "Control",
                  scope: `${scopePrefix}/${field}`
                };
            }),
          rule: uiSchemaService.toRule({
            field: "sho"
          })
        } as GroupLayout,
      ]
    },
    {
      type: "Category",
      label: "Branding",
      elements: [
        {
          type: "Group",
          label: "FreeWall branding",
          elements: [
            uiSchemaService.toControlElement({
              field: "branding"
            }),
          ]
        } as GroupLayout
      ]
    },
    {
      type: "Category",
      label: "Version",
      elements: [
        {
          type: "Group",
          label: "FreeWall version",
          elements: [
            uiSchemaService.toControlElement({
              field: "version"
            }),
          ]
        } as GroupLayout
      ]
    }
  ]
};
