import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {CreativeFileService} from "../../core/CreativeFileService";
import {ScannedFile} from "@djabry/fs-s3";
import {LinkedFile} from "../../core/linked.file";
import {ResourceType} from "../resource.type";
import {ResourceService} from "../resource/ResourceService";
import {UploadService} from "./upload.service";
import {SelectResourceRequest} from "../resource-selector/select.resource.request";

@Component({
  selector: "app-upload",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"]
})
export class UploadComponent implements OnInit {

  @Input()
  public request: Required<SelectResourceRequest>;

  ready: boolean;

  @Input()
  currentResourcePath: string;

  @Output()
  resourcesUploaded: EventEmitter<ScannedFile[]> = new EventEmitter<ScannedFile[]>();

  @Output()
  resourceSelected: EventEmitter<ScannedFile> = new EventEmitter<ScannedFile>();

  loadingPromise: Promise<any>;
  progressMessage: string;

  @ViewChild("uploadLabel", {static: false})
  uploadLabel: ElementRef;

  existingResources: Promise<LinkedFile[]>;

  constructor(private fileService: CreativeFileService,
              private resourceService: ResourceService,
              private uploadService: UploadService) {

  }

  get accept(): string {
    const suffix = this.request.mode === ResourceType.video ? "mp4" : "*";
    return `${this.request.mode}/${suffix}`;
  }

  ngOnInit() {
    this.request.mode = this.request.mode || this.request.resourceTypes[0];
    this.existingResources = this.loadExistingResources();
    this.loadingPromise = this.existingResources;
    this.progressMessage = "Loading resources...";
  }

  get selectedTab(): number {
    return this.request.resourceTypes.indexOf(this.request.mode);
  }

  set selectedTab(tabIndex: number) {
    this.request.mode = this.request.resourceTypes[tabIndex];
  }

  async loadExistingResources(): Promise<LinkedFile[]> {
    return this.resourceService.listResources(this.request.freeWallId);
  }

  selectResource(file: ScannedFile) {
    this.resourceSelected.emit(file);
  }

  uploadClick() {
    (this.uploadLabel.nativeElement as HTMLLabelElement).click();
  }

  toFiles(event: Event): File[] {
    return Array.from((event.target as any).files);
  }

  toUploadMessage(files: File[]): string {
    if (files.length === 1) {
      return `Uploading ${files[0].name}...`;
    } else {
      return `Uploading ${files.length} files...`;
    }
  }

  async uploadFiles(files: File[]): Promise<void> {
    if (this.request.freeWallId && files.length) {
      const uploadPromise = this.uploadService.uploadFiles(this.request.freeWallId, files);
      this.loadingPromise = uploadPromise;
      this.progressMessage = this.toUploadMessage(files);
      const uploadedFiles = await uploadPromise;
      this.resourcesUploaded.emit([...uploadedFiles]);
      if (uploadedFiles.length === 1) {
        this.selectResource(uploadedFiles.shift());
      } else {
        this.existingResources = this.loadExistingResources();
      }
    }
  }

  async onFiles(event: Event) {
    event.preventDefault();
    const files = this.toFiles(event);
    await this.uploadFiles(files);
  }

}
