import { Injectable } from "@angular/core";
import { CreativeFreeWallDB } from "../../core/CreativeFreeWallDB";
import { AWSService } from "../../core/AWSService";
import { QuickstartWizardService } from "../QuickstartWizardService";
import { WizardConfig, WizardDialogService } from "@rezonence/ng-schema-wizard";
import { generateDataConfigSchema, GenerateDataConfig } from "@rezonence/freewall-creator-config";
import { Answer, EncodedIframe, FreeWallImage, Optional, Question } from "@rezonence/sdk";
import { FreewallMetaPair } from "../../core/freewall.meta.pair";
import { FreeWallConfig, MicrositeLinkResolver, MicrositeType } from "@rezonence/core";
import { Microsite } from "@rezonence/core/freewall";
import { defaultImage } from "../../creator/wizard/default.image";
import { defaultResponseImage } from "./default.response.image";
import { FreeWallTitleGenerator, QuickstartOperation } from "../../create";
import { CreativeUtils } from "../../core/creative.utils";
import { CreativeMicrositeSchemaResolver } from "../../microsite";
import { tag } from "@rezonence/git";

@Injectable({
  providedIn: "root"
})
export class DataQuickstartService extends QuickstartWizardService {

  constructor(wizardDialogService: WizardDialogService,
    private schemaResolver: CreativeMicrositeSchemaResolver,
    titleGenerator: FreeWallTitleGenerator,
    db: CreativeFreeWallDB,
    utils: CreativeUtils,
    awsService: AWSService) {
    super(wizardDialogService, titleGenerator, db, utils, awsService);
  }

  async createSurvey(existing: FreewallMetaPair[]): Promise<Optional<FreewallMetaPair>> {
    const wizardConfig = this.createDataWizardConfig(existing);
    const response = await this.wizardDialogService.requestData(wizardConfig);
    return Optional.switchPromise(response.map(wizardResponse => this.generateDataFreewall(wizardResponse)));
  }

  createDataWizardConfig(existing: FreewallMetaPair[]): WizardConfig<GenerateDataConfig> {
    const fields: (keyof GenerateDataConfig)[] = [
      "title",
      "questionText",
      "answers",
      "showResults"
    ];
    return {
      data: {
        ...this.toDefaults<GenerateDataConfig>(generateDataConfigSchema, existing, QuickstartOperation.Data),
        questionText: "",
        answers: Array.from(new Array(2), () => ({
          answerText: "",
          trackers: []
        })),
      },
      schema: generateDataConfigSchema,
      steps: fields.map(f => this.toWizardStep(f))
    };
  }

  async generateDataFreewall(request: GenerateDataConfig): Promise<FreewallMetaPair> {
    const config = (await this.schemaResolver.create<{ optOut: boolean }>({ templateId: MicrositeType.survey, version: tag })).item;
    delete config.optOut;
    const baseMicrosite = {
      ...this.toBaseMicrosite(MicrositeType.survey, request.title),
      config
    };
    const microsite: Microsite = {
      ...baseMicrosite,
      url: MicrositeLinkResolver.toMicrositeLink(baseMicrosite)
    };
    const freewallConfig = this.toFreewallConfig(request, microsite.url);
    return this.saveMicrosite(microsite, freewallConfig);
  }

  private toFreewallConfig(request: GenerateDataConfig, micrositeUrl: string): FreeWallConfig {
    const answers = request.answers.map(answer => this.toAnswer(answer.answerText));
    const questions: Question[] = [this.toQuestion(request.questionText, answers)];
    const image: FreeWallImage = { img: defaultImage };
    const iframe: EncodedIframe = this.toIframe(micrositeUrl);
    iframe.viz = request.showResults;
    return {
      ...this.baseFreewallConfig,
      questions,
      image,
      iframe,
      responses: [
        {
          img: defaultResponseImage,
          lnk: "https://yougov.com"
        }
      ],
      kickers: []
    };
  }

  private toQuestion(questionText: string, answers: Answer[]): Question {
    return {
      que: questionText,
      hnt: generateDataConfigSchema.properties.hint.default,
      ran: false,
      answers
    };
  }

  private toAnswer(answerText: string): Answer {
    return {
      ans: answerText,
      kix: -2,
      rix: 0,
      tof: true
    };
  }

}
